import { Box, Grid, IconButton, MenuItem, Stack, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import ModalListCustomer from '../Modal/ModalListCustomer';
import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import { CustomDatePicker, Icon, Input, Selection } from '../../../../../components/ui';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const ReceiptPPh23Header = (props) => {
  const date = new Date();
  const [isModalCustomer, setIsModalCustomer] = useState(false);
  const [titleModalCustomer, setTitleModalCustomer] = useState('');

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataMapCustomer, setDataMapCustomer] = useState([])
  const [dataTableCustomer, setDataTableCustomer] = useState([])
  const [columnDataCustomer, setColumnDataCustomer] = useState([])
  const [SelectedDataCustomer, setSelectedDataCustomer] = useState({});
  const [totalRowsCustomer, setTotalRowsCustomer] = useState(50)
  const paramCustomer = "regContact/regContact";

  const customerTypeList = [
    { id: 0, code: 'SH', name: 'Shipper' },
    { id: 1, code: 'CO', name: 'Consignee' },
    { id: 2, code: 'AG', name: 'Agent' },
  ]

  const getDataCustomer = (pageNumber, pageSize, filters) => {
    setIsLoadingCustomer(true);
    let type = 2
    if (props.customerType === 'AG') {
      type = 1
    }
    else if (props.customerType === 'CO') {
      type = 3
    }
    else {
      type = 2;
    }

    ApiService.ContactPostByPage(paramCustomer, type, pageNumber, pageSize, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataCustomer({})
          setDataCustomer(response.data.data.contact);

          let temp = response.data.data.contact
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMapCustomer(indexed)
          handleDataTable(indexed, response.data.data.columns, setDataTableCustomer, 'id');
          setColumnDataCustomer(response.data.data.columns)
          setTotalRowsCustomer(response.data.totalRowCount)

          setIsLoadingCustomer(false)
        }
        setIsLoadingCustomer(false)
      })
      .catch(function (error) {
        setIsLoadingCustomer(false)
        console.error('error saat fetch', error)
      })
  }

  const searchCustomer = (data) => {
    if (data === 'search') {
      if (isModalCustomer === false) {
        setIsModalCustomer(true);
        let cst = 'Shipper'
        if (props.customerType === 3) {
          cst = 'Consignee'
        }
        if (props.customerType === 1) {
          cst = 'Agent'
        }
        setTitleModalCustomer(cst)
        getDataCustomer(1, 50)
      } else {
        setIsModalCustomer(false);
      }
    }
    else if (data === 'delete') {
      props.setCustomerId(0);
      props.setCustomerCode('');
      props.setCustomerName('');

      if (props.isAdjustment) {
        props.SumTotal([]);
        props.setDataCheck([]);
        localStorage.setItem('customerId', 0);
        props.getDataOR(1, 50);
      }
    }
  }

  const getMonths = () => {
    const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
    return Array.from({ length: 12 }, (_, i) => ({
      name: formatter.format(new Date(2024, i)),
      value: i + 1
    }));
  };

  const yearList = () => {
    const arrYear = [];
    const currentYear = date.getFullYear();
    const endYear = currentYear;
    let startYear = currentYear - 4;
    while (startYear <= endYear) {
      arrYear.push(startYear++)
    }

    return arrYear
  }

  function handleSelectJobOwner(value) {
    props.setJobOwnerId(value)
    let data = props.dataJobOwner.find((data) => data.id === value)
    console.log(data)
    props.setJobOwnerCode(data.code)
    props.setJobOwnerName(data.name)
  }

  const customerSection = (isHidden) => {
    return (
      <div ref={props.customerRef}>
        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }} hidden={isHidden}>
          <Input
            label='Customer'
            value={props.customerCode}
            formControlProps={{
              sx: {
                marginTop: '24px'
              }
            }}
            disabled
            readOnly
          />

          <Input
            variant="standard"
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled
            value={props.customerName}
            type='text'
            label={''}
            formControlProps={{
              sx: {
                width: '100%',
              }
            }}
            inputBaseProps={{
              sx: {
                marginTop: '23px !important',
              },
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => searchCustomer('delete')}
                    hidden={props.customerName && !props.isEdit ? false : true}
                  >
                    <Icon
                      iconName={'close'}
                      svgIconProps={{
                        fontSize: 'small',
                        htmlColor: '#0087C2',
                        sx: {
                          height: '12px',
                          width: '12px'
                        }
                      }}
                    />
                  </IconButton>

                  <IconButton
                    onClick={() => searchCustomer('search')}
                    hidden={props.isEdit}
                  >
                    <Icon
                      iconName={'search'}
                      svgIconProps={{
                        fontSize: 'small',
                        htmlColor: '#0087C2',
                        sx: {
                          height: '12px',
                          width: '12px'
                        }
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>
      </div>
    )
  }

  return (
    <div>
      <form>
        <Box>
          <Box>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <div ref={props.infossNoRef}>
                  <Input
                    label="Infoss Number"
                    value={props.infossNo}
                    onChange={(e) => props.setInfossNo(e.target.value)}
                    hidden={!isInfossNo ? true : props.isAdjustment}
                    fullWidth
                    formControlProps={{
                      sx: {
                        width: '100%',
                        marginBottom: '16px !important'
                      }
                    }}
                    disabled={props.isApprovedAcc}
                    message={props.infossNo !== '' ? '' : 'This Field is Required'}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Input
                  label="Reference"
                  value={props.reference}
                  disabled
                  hidden={props.isAdjustment ? true : !props.isEdit}
                  fullWidth
                  formControlProps={{
                    sx: {
                      width: '100%',
                    }
                  }}
                />

                <Selection
                  id='0'
                  label={'Customer Type'}
                  fullWidth={true}
                  placeholder={'Select Customer Type'}
                  value={props.customerType}
                  onChange={(e) => props.setCustomerType(e.target.value)}
                  disabled={props.isEdit}
                  formControlProps={{
                    sx: {
                      marginTop: props.isEdit ? '16px !important' : '0px'
                    }
                  }}
                >
                  {
                    customerTypeList.map((data) => (
                      <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                        {data.name}
                      </MenuItem>
                    ))
                  }
                </Selection>

                <div ref={props.buktiPotongRef}>
                  <Input
                    label="Nomor Bukti Potong"
                    value={props.buktiPotong}
                    onChange={(e) => props.setBuktiPotong(e.target.value)}
                    fullWidth
                    disabled={props.isApprovedAcc}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        width: '100%',
                        marginTop: '16px !important'
                      }
                    }}
                  />
                </div>

                <Stack spacing={'16px'} direction="row" hidden={props.isAdjustment ? true : false} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                  <CustomDatePicker
                    label="Tanggal Bukti Potong"
                    value={props.tanggalBuktiPotong}
                    onChange={props.setTanggalBuktiPotong}
                    fullWidth
                    type={'date'}
                    disabled={props.isApprovedAcc}
                    formControlProps={{
                      sx: {
                        width: '100%',
                        marginTop: '16px !important'
                      }
                    }}
                  />
                  <Selection
                    id='0'
                    label={'Masa Pajak Bukti Potong'}
                    fullWidth={true}
                    placeholder={'Select Bulan'}
                    value={props.masaPajak}
                    onChange={(e) => props.setMasaPajak(e.target.value)}
                    disabled={props.isEdit}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        marginTop: '16px !important'
                      }
                    }}
                  >
                    {
                      getMonths().map((elm) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={elm.value} value={elm.value}>{elm.name}</MenuItem>
                      ))
                    }
                  </Selection>

                  <Typography
                    sx={{
                      color: 'var(--Blue-Primary-1, #083A50)',
                      fontFamily: 'Nunito',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal',
                      marginTop: '36px !important'
                    }}
                  >
                    /
                  </Typography>

                  <Selection
                    id='0'
                    label={''}
                    fullWidth={true}
                    placeholder={'Select Tahun'}
                    value={props.tahunPajak}
                    onChange={(e) => props.setTahunPajak(e.target.value)}
                    disabled={props.isEdit}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        width: '100%',
                        marginTop: '16px !important'
                      }
                    }}
                    selectBaseProps={{
                      sx: {
                        marginTop: '22px !important',
                      },
                    }}
                  >
                    {
                      yearList().map((elm) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={elm} value={elm}>{elm}</MenuItem>
                      ))
                    }
                  </Selection>
                </Stack>

                {customerSection(!props.isAdjustment)}
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Stack spacing={'16px'} direction="row">
                  <div ref={props.principleRef}>
                    <Selection
                      id='0'
                      label={'Principle By'}
                      fullWidth={true}
                      placeholder={'Select Principle By'}
                      value={props.jobOwnerId}
                      onChange={(e) => handleSelectJobOwner(e.target.value)}
                      disabled={props.isEdit}
                      formControlProps={{
                        sx: {
                          // marginTop: '16px !important'
                        }
                      }}
                    >
                      {
                        props.dataJobOwner.map((data) => (
                          <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
                            {data.code}-{data.name}
                          </MenuItem>
                        ))
                      }
                    </Selection>
                  </div>

                  <CustomDatePicker
                    label="Receive On"
                    value={props.receivedOn}
                    onChange={props.setReceivedOn}
                    type={'date'}
                    disabled={props.isApprovedAcc}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        width: '100%',
                        // marginTop: '16px !important'
                      }
                    }}
                  />
                </Stack>

                {customerSection(props.isAdjustment)}

                <Input
                  label={'Remarks'}
                  value={props.remarks}
                  onChange={(e) => props.setRemarks(e.target.value)}
                  multiline
                  disabled={props.isApprovedAcc}
                  hidden={props.isAdjustment ? true : false}
                  fullWidth
                  formControlProps={{
                    sx: {
                      width: '100%',
                      marginTop: '16px !important'
                    }
                  }}
                  inputBaseProps={{
                    rows: 5,
                    sx: {
                      padding: '8px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>

      {
        isModalCustomer && (
          <ModalListCustomer
            setCustomerId={props.setCustomerId}
            setCustomerCode={props.setCustomerCode}
            setCustomerName={props.setCustomerName}

            isModal={isModalCustomer}
            handleClick={searchCustomer}
            titleModal={titleModalCustomer}
            setIsModal={setIsModalCustomer}

            isLoading={isLoadingCustomer}
            data={dataCustomer}
            dataMap={dataMapCustomer}
            dataTable={dataTableCustomer}
            columnData={columnDataCustomer}
            SelectedData={SelectedDataCustomer}
            totalRows={totalRowsCustomer}
            setSelectedData={setSelectedDataCustomer}
            getData={getDataCustomer}
            getDataOR={props.getDataOR}
            isAdjustment={props.isAdjustment}
          />
        )
      }
    </div>
  )
}

export default ReceiptPPh23Header