import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import TabCash from './TabCash';
import TabBank from './TabBank';

const TabsPaymentMethod = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <TabContext value={props.tabValue} className='p-0'>
                    <Tabs
                        sx={{ width: '100%', height: 'fit-content', maxHeight: '32px !important' }}
                        value={props.tabValue}
                        onChange={handleChange}
                        className='p-0'
                        textColor="primary"
                        aria-label="full width tabs example"
                        variant="fullWidth"
                        TabIndicatorProps={{
                            sx: {
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                                backgroundColor: "#0087C2 !important",
                            },
                        }}
                        TabScrollButtonProps={{
                            sx: {
                                backgroundColor: "#0087C2 !important",
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                            },
                        }}
                    >
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="Cash"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "Cash" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Cash
                                </Typography>
                            }
                        />
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="Bank"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "Bank" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Bank
                                </Typography>
                            }
                        />
                    </Tabs>

                    {
                        props.tabValue === "Cash" && (
                            <TabPanel value="Cash" className='mt-1 p-0'>
                                <TabCash
                                    paymentRef={props.paymentRef}
                                    PaymentBy={props.PaymentBy}
                                    isApprovedAcc={props.isApprovedAcc}
                                    isCash={props.isCash}
                                    setIsInput={props.setIsInput}
                                    printing={props.printing}
                                    Rate={props.Rate}
                                    IsPPh23={props.IsPPh23}
                                    isAllPPh={props.isAllPPh}
                                    DataPaymentDetail={props.DataPaymentDetail}
                                    setDataPaymentDetail={props.setDataPaymentDetail}
                                    selectedPaymentVoucher={props.selectedPaymentVoucher}
                                    PPh23AmountReal={props.PPh23AmountReal}
                                    CashPayment={props.CashPayment}
                                    setCashPayment={props.setCashPayment}
                                    TempIsPPh23={props.TempIsPPh23}
                                    setTempIsPPh23={props.setTempIsPPh23}
                                    isAddValue={props.isAddValue}
                                    setIsAddValue={props.setIsAddValue}
                                    calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                                />
                            </TabPanel>
                        )
                    }

                    {
                        props.tabValue === "Bank" && (
                            <TabPanel value="Bank" className='mt-1 p-0'>
                                <TabBank
                                    paymentRef={props.paymentRef}
                                    BankData={props.BankData}
                                    setBankData={props.setBankData}
                                    BankId={props.BankId}
                                    isBank={props.isBank}
                                    TempAmount={props.TempAmount}
                                    PPh23AmountReal={props.PPh23AmountReal}
                                    Rate={props.Rate}
                                    PaymentBy={props.PaymentBy}
                                    setIsInput={props.setIsInput}
                                    paymentVoucherId={props.paymentVoucherId}
                                    IsPPh23={props.IsPPh23}
                                    printing={props.printing}
                                    DataPaymentDetail={props.DataPaymentDetail}
                                    setDataPaymentDetail={props.setDataPaymentDetail}
                                    selectedPaymentVoucher={props.selectedPaymentVoucher}
                                    isAllPPh={props.isAllPPh}
                                    TempSeq={props.TempSeq}
                                    setTempSeq={props.setTempSeq}
                                    isApprovedAcc={props.isApprovedAcc}
                                    TempIsPPh23={props.TempIsPPh23}
                                    setTempIsPPh23={props.setTempIsPPh23}
                                    COACode={props.AccountCode}
                                    COAName={props.AccountName}
                                    isPosted={props.isPosted}
                                    isAddValue={props.isAddValue}
                                    setIsAddValue={props.setIsAddValue}
                                    SettlementTo={props.SettlementTo}
                                    dueDateLast={props.dueDateLast}
                                    setDueDateLast={props.setDueDateLast}
                                    calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                                />
                            </TabPanel>
                        )
                    }
                </TabContext>
            </Box>
        </>
    )
}

export default TabsPaymentMethod;