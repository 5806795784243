import { showToast } from "../components/ui";
import ApiService, { baseurlReport } from "../services/ApiService";
import { newWindowConfig } from "./masks";
import CryptoJS from 'crypto-js';

export function encryptFunction(params) {
    const jsonString = JSON.stringify(params);
    const secretKey = 'G7hP2kR8f9LqWxZ1u3M4sTn7v0bYcX5A';
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.enc.Utf8.parse(secretKey.substring(0, 16));
    const encrypted = CryptoJS.AES.encrypt(jsonString, key, { iv: iv, padding: CryptoJS.pad.Pkcs7 }).toString();
    return encodeURIComponent(encrypted);
}

const PrintPDF = async (setIsLoading, module, reportLink, params = [], isForm = false) => {
    try {
        const response = await ApiService.IdentityRefreshPrintToken();
        setIsLoading(false);
        let printURL = '';
        let encryptParams = params.length > 0 ? encryptFunction(params) : undefined;

        if (response.data.code === 200) {
            showToast({ type: 'success', message: `Print ${module} Success` });
            if (encryptParams !== undefined) {
                printURL = `${baseurlReport}${reportLink}&tempToken=${response.data.data.token}&payloads=${encryptParams}`;
            } else {
                printURL = `${baseurlReport}${reportLink}&tempToken=${response.data.data.token}`;
            }
            window.open(printURL, newWindowConfig);

            if (isForm) {
                setTimeout(
                    function () {
                        window.location.reload();
                    },
                    1250);
            }
        } else {
            showToast({ type: 'error', message: `Can't Print ${module}, ${response.data.error}` });
        }
    } catch (error) {
        setIsLoading(false);
        showToast({ type: 'error', message: `Can't Print ${module}, ${error.toString()}` });
    }
};

const PrintExcel = async (setIsLoading, module, reportLink, params = [], isForm = false) => {
    try {
        const response = await ApiService.IdentityRefreshPrintToken();
        let printURL = '';
        let encryptParams = params.length > 0 ? encryptFunction(params) : undefined;

        if (response.data.code === 200) {
            if (encryptParams !== undefined) {
                printURL = `${baseurlReport}${reportLink}&tempToken=${response.data.data.token}&payloads=${encryptParams}`;
            } else {
                printURL = `${baseurlReport}${reportLink}&tempToken=${response.data.data.token}`;
            }

            const fileResponse = await fetch(printURL);
            if (!fileResponse.ok) {
                throw new Error('Failed to Fetch The File');
            }

            const blob = await fileResponse.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `Print_${module}.xls`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
            setIsLoading(false);
            showToast({ type: 'success', message: `Print ${module} Success` });

            if (isForm) {
                setTimeout(
                    function () {
                        window.location.reload();
                    },
                    1250);
            }

        } else {
            showToast({ type: 'error', message: `Can't Print ${module}, ${response.data.error}` });
            setIsLoading(false);
        }
    } catch (error) {
        setIsLoading(false);
        showToast({ type: 'error', message: `Can't Print ${module}, ${error.toString()}` });
    }
};

const PrintCSV = async (setIsLoading, module, reportLink, params = [], isForm = false) => {
    try {
        const response = await ApiService.IdentityRefreshPrintToken();
        let printURL = '';
        let encryptParams = params.length > 0 ? encryptFunction(params) : undefined;

        if (response.data.code === 200) {
            if (encryptParams !== undefined) {
                printURL = `${baseurlReport}${reportLink}&tempToken=${response.data.data.token}&payloads=${encryptParams}`;
            } else {
                printURL = `${baseurlReport}${reportLink}&tempToken=${response.data.data.token}`;
            }

            const fileResponse = await fetch(printURL);
            if (!fileResponse.ok) {
                throw new Error('Failed to Fetch The File');
            }

            const blob = await fileResponse.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `Print_${module}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(downloadUrl);
            setIsLoading(false);
            showToast({ type: 'success', message: `Print ${module} Success` });

            if (isForm) {
                setTimeout(
                    function () {
                        window.location.reload();
                    },
                    1250);
            }

        } else {
            showToast({ type: 'error', message: `Can't Print ${module}, ${response.data.error}` });
            setIsLoading(false);
        }
    } catch (error) {
        setIsLoading(false);
        showToast({ type: 'error', message: `Can't Print ${module}, ${error.toString()}` });
    }
};

const getDataApproved = async (isModal = false, setIsLoadingBackDrop, param, setDocsLog, handleClickUsage, isLoadingBackDrop, setIsLoading) => {
    if (isModal) setIsLoadingBackDrop(true);

    try {
        const data = {};
        const response = await ApiService.FinAccCustomPost(`${param}/Create?check=true`, data);
        if (response.data.code !== 200) return;

        const resultData = response.data.data;
        let docsArray = [];
        let sequence = 1;

        const addEntries = (entries, usedOn, transactionKey) => {
            entries.forEach(element => {
                docsArray.push({
                    usedOn,
                    transactionNumber: element[transactionKey],
                    id: element.id,
                    sequence: sequence++,
                    ...(element.jobTypeId && { jobTypeId: element.jobTypeId }),
                    ...(element.isDP && { isDP: element.isDP }),
                });
            });
        };

        if (resultData) {
            resultData.paymentVouchers?.length && addEntries(resultData.paymentVouchers, 'Payment Voucher', 'reference');
            resultData.receiptVouchers?.length && addEntries(resultData.receiptVouchers, 'Receipt Voucher', 'reference');
            resultData.temporaryPayments?.length && addEntries(resultData.temporaryPayments, 'Temporary Payment', 'tpNo2');
            resultData.temporaryReceipts?.length && addEntries(resultData.temporaryReceipts, 'Temporary Receipt', 'trNo2');
            resultData.officialReceipts?.length && addEntries(resultData.officialReceipts, 'Official Receipt', 'reference');
        }

        setDocsLog(docsArray);

        if (isModal) {
            setIsLoadingBackDrop(false);
            docsArray.length > 0 ? handleClickUsage() : showToast({ type: 'warning', message: 'All Data Already Been Approved!' });
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        isLoadingBackDrop ? setIsLoadingBackDrop(false) : setIsLoading(false);
        showToast({ type: 'error', message: error.toString() });
    }
};

const handleDataTable = (data, column, setDataTable, uniqueKey) => {
    const dataTable = data.map((el) => {
        const row = {};
        column.forEach((headersEl) => {
            if (el[headersEl.column] !== undefined && !headersEl.hidden && !(headersEl.column === 'noInfoss' && localStorage.getItem('isInfossNoList') !== 'true')) {
                row[headersEl.column] = el[headersEl.column];
            }
        });

        row[uniqueKey] = el[uniqueKey];

        return row;
    });

    setDataTable(dataTable);
};

const autoFocusField = (ref, setActiveTab) => {
    if (!ref.current) return;

    const isHidden = ref.current.offsetParent === null;

    if (isHidden) {
        const tabId = ref.current.getAttribute('data-tab-id');
        if (tabId) {
            setActiveTab(tabId);
        }

        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                const focusableElement = ref.current.querySelector('input, button, [tabindex]');
                if (focusableElement) focusableElement.focus();
            }
        }, 300);
    } else {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        const focusableElement = ref.current.querySelector('input, button, [tabindex]');
        if (focusableElement) focusableElement.focus();
    }
};

export {
    PrintPDF,
    PrintExcel,
    PrintCSV,
    getDataApproved,
    handleDataTable,
    autoFocusField,
}