import { IconButton, Stack, Grid } from '@mui/material';
import React from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';

const TabConsignee = (props) => {
    const searchConsignee = (data) => {
        if (data === 'search') {
            if (props.principleBy !== 0) {
                props.setTipe(3);
                props.setContactTypeId(16);
                props.setTitleModal('Consignee');
                props.handleClick(3);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By!' });
            }
        }
        else if (data === 'delete') {
            props.setConsigneeId(0);
            props.setConsigneeName('');
            props.setConsigneeAddress('');

            if (props.billToConsigneeId === 0) {
                if (props.jobType.serviceType === 'Import') {
                    props.setCreditTermUSD(0);
                    props.setCreditTermDays(0);
                    props.setCreditTermIDR(0);

                    props.setOutstandingInIDR(0);
                    props.setOutstandingInUSD(0);
                }
            }
        }
        else if (data === 'reset') {
            props.setConsigneeId(props.RefConsigneeId);
            props.setConsigneeName(props.RefConsigneeName);
            props.setConsigneeAddress(props.RefConsigneeAddress);
        }
    };

    return (
        <div ref={props.consigneeRef}>
            <Grid
                container
                spacing={'16px'}
            >
                <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    sm={1.5}
                    xs={12}
                >
                    <Input
                        label="Code"
                        value={props.ConsigneeId}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                {
                    props.contactType !== 'MainShipmentOrder' ?
                        <>
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <Input
                                    label="Name"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.ConsigneeName}
                                    onChange={(e) => props.setConsigneeName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={5.5}
                                lg={5.5}
                                sm={5.5}
                                xs={12}
                            >
                                <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                                    <Input
                                        label="Address"
                                        value={props.ConsigneeAddress}
                                        onChange={(e) => props.setConsigneeAddress(e.target.value)}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            rows: 4,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                    />

                                    <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                        <IconButton onClick={() => searchConsignee('reset')}>
                                            <Icon
                                                iconName={'refresh'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '16px',
                                                        width: '16px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <Input
                                    label="Name"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled
                                    value={props.ConsigneeName}
                                    onChange={(e) => props.setConsigneeName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: props.serviceType !== 'Export' ? true : false
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={5.5}
                                lg={5.5}
                                sm={5.5}
                                xs={12}
                            >
                                <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                                    <Input
                                        label="Address"
                                        value={props.ConsigneeAddress}
                                        onChange={(e) => props.setConsigneeAddress(e.target.value)}
                                        multiline
                                        disabled
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            rows: 4,
                                            sx: {
                                                padding: '8px',
                                            },
                                            readOnly: props.serviceType !== 'Export' ? true : false
                                        }}
                                    />

                                    <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                        <IconButton
                                            onClick={() => { searchConsignee('search') }}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '16px',
                                                        width: '16px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchConsignee('delete')}
                                            hidden={props.ConsigneeName ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '16px',
                                                        width: '16px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </>
                }
            </Grid>
        </div>
    )
}

export default TabConsignee;