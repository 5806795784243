import React from 'react'
import TabCargo from './TabCargo';
import TabDetails from './TabDetails';
import TabEstimate from './TabEstimate';
import { Box, Tab, Tabs, Typography } from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Icon } from '../../../../components/ui';

const TabsBooking = (props) => {
  const handleChange = (event, newValue) => {
    props.setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        sx={{
          color: 'var(--Blue-Primary-1, #083A50)',
          fontFamily: 'Nunito',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
        }}
      >
        Booking Detail
      </Typography>

      <TabContext value={props.tabValue}>
        <Tabs
          sx={{ width: '100%', marginTop: '16px', height: 'fit-content', maxHeight: '32px !important' }}
          value={props.tabValue}
          onChange={handleChange}
          textColor="primary"
          TabIndicatorProps={{
            sx: {
              height: 'fit-content !important',
              maxHeight: '32px !important',
              backgroundColor: "#0087C2 !important",
            },
          }}
          TabScrollButtonProps={{
            sx: {
              backgroundColor: "#0087C2 !important",
              height: 'fit-content !important',
              maxHeight: '32px !important',
            },
          }}
        >
          <Tab
            icon={
              <Icon
                iconName={'detail'}
                svgIconProps={{
                  fontSize: 'small',
                  htmlColor: props.tabValue === "Details" ? '#F2FAFF' : '#083A50',
                  sx: {
                    height: '16px',
                    width: '16px',
                    marginRight: '8px',
                  }
                }}
              />
            }
            sx={{
              padding: '4px 12px !important',
              minHeight: '35px',
              textTransform: "none",
              backgroundColor: '#D7F0FF',
              '&.Mui-selected': {
                color: '#f2f2f2',
                backgroundColor: '#0087C2',
              },
            }}
            iconPosition="start"
            value="Details"
            label={
              <Typography
                sx={{
                  color: props.tabValue === "Details" ? '#F2FAFF' : '#083A50',
                  fontFamily: 'Nunito',
                  fontSize: '14px !important',
                  fontStyle: 'normal',
                  fontWeight: '600',
                }}
              >
                Details
              </Typography>
            }
          />

          <Tab
            icon={
              <Icon
                iconName={'cargo'}
                svgIconProps={{
                  fontSize: 'small',
                  htmlColor: props.tabValue === "Cargo" ? '#F2FAFF' : '#083A50',
                  sx: {
                    height: '16px',
                    width: '16px',
                    marginRight: '8px',
                  }
                }}
              />
            }
            sx={{
              padding: '4px 12px !important',
              minHeight: '35px',
              textTransform: "none",
              backgroundColor: '#D7F0FF',
              '&.Mui-selected': {
                color: '#f2f2f2',
                backgroundColor: '#0087C2',
              },
            }}
            iconPosition="start"
            value="Cargo"
            label={
              <Typography
                sx={{
                  color: props.tabValue === "Cargo" ? '#F2FAFF' : '#083A50',
                  fontFamily: 'Nunito',
                  fontSize: '14px !important',
                  fontStyle: 'normal',
                  fontWeight: '600',
                }}
              >
                Cargo
              </Typography>
            }
          />

          <Tab
            icon={
              <Icon
                iconName={'epl'}
                svgIconProps={{
                  fontSize: 'small',
                  htmlColor: props.tabValue === "Epl" ? '#F2FAFF' : '#083A50',
                  sx: {
                    height: '16px',
                    width: '16px',
                    marginRight: '8px',
                  }
                }}
              />
            }
            sx={{
              padding: '4px 12px !important',
              minHeight: '35px',
              textTransform: "none",
              backgroundColor: '#D7F0FF',
              '&.Mui-selected': {
                color: '#f2f2f2',
                backgroundColor: '#0087C2',
              },
            }}
            iconPosition="start"
            value="Epl"
            label={
              <Typography
                sx={{
                  color: props.tabValue === "Epl" ? '#F2FAFF' : '#083A50',
                  fontFamily: 'Nunito',
                  fontSize: '14px !important',
                  fontStyle: 'normal',
                  fontWeight: '600',
                }}
              >
                Estimate Profit Loss
              </Typography>
            }
          />
        </Tabs>

        {
          props.tabValue === 'Details' && (
            <TabPanel value="Details" className='p-0' data-tab-id='Details'>
              <TabDetails
                isEdit={props.isEdit}
                isApproved={props.isApproved}
                isCopy={props.isCopy}
                placeOfReceipt={props.placeOfReceipt}
                placeOfReceiptId={props.placeOfReceiptId}
                placeOfReceiptCode={props.placeOfReceiptCode}
                placeOfDelivery={props.placeOfDelivery}
                placeOfDeliveryId={props.placeOfDeliveryId}
                placeOfDeliveryCode={props.placeOfDeliveryCode}
                portOfLoading={props.portOfLoading}
                portOfLoadingId={props.portOfLoadingId}
                portOfLoadingCode={props.portOfLoadingCode}
                portOfDischarge={props.portOfDischarge}
                portOfDischargeId={props.portOfDischargeId}
                portOfDischargeCode={props.portOfDischargeCode}
                airportDeparture={props.airportDeparture}
                airportDepartureId={props.airportDepartureId}
                airportDepartureCode={props.airportDepartureCode}
                airportDestination={props.airportDestination}
                airportDestinationId={props.airportDestinationId}
                airportDestinationCode={props.airportDestinationCode}
                vessel={props.vessel}
                vesselId={props.vesselId}
                vesselCode={props.vesselCode}
                airline={props.airline}
                airlineId={props.airlineId}
                airlineCode={props.airlineCode}

                isVolume20={props.isVolume20}
                setIsVolume20={props.setIsVolume20}
                isVolume40={props.isVolume40}
                setIsVolume40={props.setIsVolume40}
                isVolume45={props.isVolume45}
                setIsVolume45={props.setIsVolume45}
                isVolumeCBM={props.isVolumeCBM}
                setIsVolumeCBM={props.setIsVolumeCBM}

                setPlaceOfReceipt={props.setPlaceOfReceipt}
                setPlaceOfReceiptId={props.setPlaceOfReceiptId}
                setPlaceOfReceiptCode={props.setPlaceOfReceiptCode}
                setPlaceOfDelivery={props.setPlaceOfDelivery}
                setPlaceOfDeliveryId={props.setPlaceOfDeliveryId}
                setPlaceOfDeliveryCode={props.setPlaceOfDeliveryCode}
                setPortOfLoading={props.setPortOfLoading}
                setPortOfLoadingId={props.setPortOfLoadingId}
                setPortOfLoadingCode={props.setPortOfLoadingCode}
                setPortOfDischarge={props.setPortOfDischarge}
                setPortOfDischargeId={props.setPortOfDischargeId}
                setPortOfDischargeCode={props.setPortOfDischargeCode}
                setAirportDeparture={props.setAirportDeparture}
                setAirportDepartureId={props.setAirportDepartureId}
                setAirportDepartureCode={props.setAirportDepartureCode}
                setAirportDestination={props.setAirportDestination}
                setAirportDestinationId={props.setAirportDestinationId}
                setAirportDestinationCode={props.setAirportDestinationCode}
                setVessel={props.setVessel}
                setVesselId={props.setVesselId}
                setVesselCode={props.setVesselCode}
                setAirline={props.setAirline}
                setAirlineId={props.setAirlineId}
                setAirlineCode={props.setAirlineCode}
                jobTypeId={props.jobTypeId}

                portType={props.portType}
                jobServiceType={props.jobServiceType}

                ETD={props.ETD}
                setETD={props.setETD}
                ETA={props.ETA}
                setETA={props.setETA}
                tipe={props.tipe}
                setTipe={props.setTipe}
                idAgent={props.idAgent}
                setIdAgent={props.setIdAgent}
                agentName={props.agentName}
                setAgentName={props.setAgentName}
                setAgentAddress={props.setAgentAddress}
                idEMKL={props.idEMKL}
                setIdEMKL={props.setIdEMKL}
                EMKLName={props.EMKLName}
                setEMKLName={props.setEMKLName}
                idDepo={props.idDepo}
                setIdDepo={props.setIdDepo}
                depoName={props.depoName}
                setDepoName={props.setDepoName}
                idCarrier={props.idCarrier}
                setIdCarrier={props.setIdCarrier}
                carrierName={props.carrierName}
                setCarrierName={props.setCarrierName}
                containerType={props.containerType}
                setContainerType={props.setContainerType}
                dataContainerType={props.dataContainerType}
                finalDestination={props.finalDestination}
                setFinalDestination={props.setFinalDestination}
                voyageNo={props.voyageNo}
                setVoyageNo={props.setVoyageNo}
                flightNo={props.flightNo}
                setFlightNo={props.setFlightNo}
                flightAb={props.flightAb}
                setFlightAb={props.setFlightAb}
                serviceType={props.serviceType}
                setServiceType={props.setServiceType}
                freight={props.freight}
                setFreight={props.setFreight}
                shipmentVolume={props.shipmentVolume}
                setShipmentVolume={props.setShipmentVolume}
                shipmentVolume20={props.shipmentVolume20}
                setShipmentVolume20={props.setShipmentVolume20}
                shipmentVolume40={props.shipmentVolume40}
                setShipmentVolume40={props.setShipmentVolume40}
                shipmentVolume45={props.shipmentVolume45}
                setShipmentVolume45={props.setShipmentVolume45}
                shipmentVolumeCBM={props.shipmentVolumeCBM}
                setShipmentVolumeCBM={props.setShipmentVolumeCBM}
                shipmentVolumeAll={props.shipmentVolumeAll}
                setShipmentVolumeAll={props.setShipmentVolumeAll}
                shipmentVolumeCollie={props.shipmentVolumeCollie}
                setShipmentVolumeCollie={props.setShipmentVolumeCollie}
                shipmentVolumeKGS={props.shipmentVolumeKGS}
                setShipmentVolumeKGS={props.setShipmentVolumeKGS}
                stuffingDate={props.stuffingDate}
                setStuffingDate={props.setStuffingDate}
                stuffingPlace={props.stuffingPlace}
                setStuffingPlace={props.setStuffingPlace}
                stuffingNote={props.stuffingNote}
                setStuffingNote={props.setStuffingNote}
                remarks={props.remarks}
                setRemarks={props.setRemarks}
                creditTerm={props.creditTerm}
                setCreditTerm={props.setCreditTerm}

                contactTypeId={props.contactTypeId}
                containerData={props.containerData}
                setContainerData={props.setContainerData}
                getContainerSizeData={props.getContainerSizeData}

                handleClickContact={props.handleClickContact}
                setTitleModalContact={props.setTitleModalContact}
                setParamModal={props.setParamModal}

                handleClick2={props.handleClick2}
                titleModal2={props.titleModal2}
                setTitleModal2={props.setTitleModal2}

                receiptRef={props.receiptRef}
                deliveryRef={props.deliveryRef}
                estimateTimeRef={props.estimateTimeRef}
              />
            </TabPanel>
          )
        }

        {
          props.tabValue === 'Cargo' && (
            <TabPanel value="Cargo" className='p-0'>
              <TabCargo
                marksNumber={props.marksNumber}
                setMarksNumber={props.setMarksNumber}
                cargoDescription={props.cargoDescription}
                setCargoDescription={props.setCargoDescription}
                numberOfPackages={props.numberOfPackages}
                setNumberOfPackages={props.setNumberOfPackages}
                packagingType={props.packagingType}
                setPackagingType={props.setPackagingType}
                dataPackagingType={props.dataPackagingType}
                isApproved={props.isApproved}
                isCopy={props.isCopy}
                grossWeight={props.grossWeight}
                setGrossWeight={props.setGrossWeight}
                volume={props.volume}
                setVolume={props.setVolume}
                chargeableWeightVolume={props.chargeableWeightVolume}
                setChargeableWeightVolume={props.setChargeableWeightVolume}
                specialInstructions={props.specialInstructions}
                setSpecialInstructions={props.setSpecialInstructions}
              />
            </TabPanel>
          )
        }

        {
          props.tabValue === 'Epl' && (
            <TabPanel value="Epl" className='p-0'>
              <TabEstimate
                exRate={props.exRate}
                exRateReal={props.exRateReal}
                calculateBookingData={props.calculateBookingData}
                bookingId={props.bookingId}
                jobTypeId={props.jobTypeId}
                dataIncome={props.dataIncome}
                setDataIncome={props.setDataIncome}
                dataCost={props.dataCost}
                setDataCost={props.setDataCost}
                contactTypeId={props.contactTypeId}
                buyingRateInUSD={props.buyingRateInUSD}
                setBuyingRateInUSD={props.setBuyingRateInUSD}
                sellingRateInUSD={props.sellingRateInUSD}
                setSellingRateInUSD={props.setSellingRateInUSD}
                isApproved={props.isApproved}
                isCopy={props.isCopy}
                totalUsd={props.totalUsd}
                setTotalUsd={props.setTotalUsd}
                totalIdr={props.totalIdr}
                setTotalIdr={props.setTotalIdr}
                totalIncomeUsd={props.totalIncomeUsd}
                setTotalIncomeUsd={props.setTotalIncomeUsd}
                totalIncomeIdr={props.totalIncomeIdr}
                setTotalIncomeIdr={props.setTotalIncomeIdr}
                totalCostUsd={props.totalCostUsd}
                setTotalCostUsd={props.setTotalCostUsd}
                totalCostIdr={props.totalCostIdr}
                setTotalCostIdr={props.setTotalCostIdr}
                marginIncome={props.marginIncome}
                marginCost={props.marginCost}

                handleClickContact={props.handleClickContact}
                setTitleModalContact={props.setTitleModalContact}

                isVolume20={props.isVolume20}
                setIsVolume20={props.setIsVolume20}
                isVolume40={props.isVolume40}
                setIsVolume40={props.setIsVolume40}
                isVolume45={props.isVolume45}
                setIsVolume45={props.setIsVolume45}
                shipmentVolume20={props.shipmentVolume20}
                setShipmentVolume20={props.setShipmentVolume20}
                shipmentVolume40={props.shipmentVolume40}
                setShipmentVolume40={props.setShipmentVolume40}
                shipmentVolume45={props.shipmentVolume45}
                setShipmentVolume45={props.setShipmentVolume45}
                shipmentVolumeCBM={props.shipmentVolumeCBM}
                setShipmentVolumeCBM={props.setShipmentVolumeCBM}
                isVolumeCBM={props.isVolumeCBM}
                setIsVolumeCBM={props.setIsVolumeCBM}
                isContainer={props.isContainer}
                setIsContainer={props.setIsContainer}
                containerSize={props.containerSize}
                setContainerSize={props.setContainerSize}
                containerFormat={props.containerFormat}
                setContainerFormat={props.setContainerFormat}
                containerTypeId={props.containerTypeId}
                setContainerTypeId={props.setContainerTypeId}
                containerTypeCode={props.containerTypeCode}
                setContainerTypeCode={props.setContainerTypeCode}
                containerTypeName={props.containerTypeName}
                setContainerTypeName={props.setContainerTypeName}

                contactId={props.contactId}
                setContactId={props.setContactId}
                tempCustName={props.tempCustName}
                setTempCustName={props.setTempCustName}

                accountId={props.accountId}
                tempAccountName={props.tempAccountName}
                description={props.description}
                setAccountId={props.setAccountId}
                accountCode={props.accountCode}
                setAccountCode={props.setAccountCode}
                setTempAccountName={props.setTempAccountName}
                setDescription={props.setDescription}

                idCustomer={props.idCustomer}
                CustomerName={props.CustomerName}
                idAgent={props.idAgent}
                agentName={props.agentName}
                idEMKL={props.idEMKL}
                EMKLName={props.EMKLName}
                idDepo={props.idDepo}
                depoName={props.depoName}
                idCarrier={props.idCarrier}
                carrierName={props.carrierName}

                portType={props.portType}
                jobServiceType={props.jobServiceType}
                containerData={props.containerData}
                quantity={props.quantity}
                setQuantity={props.setQuantity}
                detailEdit={props.detailEdit}
                setDetailEdit={props.setDetailEdit}
              />
            </TabPanel>
          )
        }
      </TabContext>
    </Box>
  )
}

export default TabsBooking