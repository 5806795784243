import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import TableX from '../../../../components/Tables/TableX';
import freezeContainer from '../../../../Styles/freezeContainer';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';
import ButtonGLStatusObserver from './Buttons/ButtonGLStatusObserver';
import { updateDateFormat } from '../../../../utils/date';
import ModalDatePick from './Modals/ModalDatePick';
import { handleFailedGL, handleRePost } from '../../../../utils/constantVariable';
import ModalRePostGL from '../../../../components/Modals/ModalRePostGL';

const GLStatusObserverView = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramGL = 'generalLedger/generalLedger';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dropDownValue, setDropDownValue] = useState('Last Month');

    const [isModalRePost, setIsModalRePost] = useState(false);
    const [failedData, setFailedData] = useState({});

    const [isModal, setIsModal] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Ledger Transaction Status', link: '/Majura/GLStatusObserver', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'General Ledger Transaction Status - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const recallFunction = () => {
            getData(numPage, rowsCount, filter, sortArr, false);
        };

        const intervalId = setInterval(recallFunction, 300000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numPage, rowsCount, filter, sortArr]);

    const handleDate = (type = 'month') => {
        let endDate = new Date();
        let startDate = new Date();

        if (type === 'week') {
            startDate.setDate(endDate.getDate() - 6);
        } else if (type === 'month') {
            startDate.setDate(endDate.getDate() - 29);
        }

        localStorage.setItem('endDate', JSON.stringify(updateDateFormat(endDate)));
        localStorage.setItem('startDate', JSON.stringify(updateDateFormat(startDate)));
        setNumPage(1);
        setRowsCount(50);
        getData(1, 50, []);
    };


    const getData = (pageNumber, pageSize, filters, orderBy, isEnableLoading = true) => {
        if (isEnableLoading) {
            if (isLoadingBackDrop === false) {
                setIsLoading(true);
            }
            else {
                setIsLoadingBackDrop(true);
            }
        }

        let params = [
            {
                "attr": "startDate",
                "value": JSON.parse(localStorage.getItem('startDate'))
            },
            {
                "attr": "endDate",
                "value": JSON.parse(localStorage.getItem('endDate'))
            }
        ];

        ApiService.CustomFinAccPostByPage(paramGL + '/GLStatusObserver', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    if (isEnableLoading) {
                        setSelectedData({})
                    }

                    let temp = response.data.data.glStatusObserver
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FGLED')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        handleDate('month');
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(1, 50, []);
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    function CellDoubleClick(row) {

    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter, sortArr);
    }

    const handleSelectDate = (value) => {
        setDropDownValue(value);

        if (value === 'Last Week') {
            handleDate('week');
        }
        else if (value === 'Last Month') {
            handleDate('month');
        }
        else {
            handleClick();
        }
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonGLStatusObserver
                ReloadData={ReloadData}
                DataRegUserAccess={DataRegUserAccess}
                handleSelectDate={handleSelectDate}
                dropDownValue={dropDownValue}
            />
        )
    }

    const handleClickRePost = () => {
        if (isModalRePost === false) {
            setIsModalRePost(true);
        }
        else {
            setIsModalRePost(false);
        }
    }

    const handlePost = () => {
        handleRePost({
            handleClickRePost: handleClickRePost,
            setIsLoadingBackDrop: setIsLoadingBackDrop,
            failedData: failedData,
            paramGL: paramGL,
            getData: getData,
            numPage: numPage,
            rowsCount: rowsCount,
            filter: filter,
        })
    }

    const handleGL = (data) => {
        let selectedData = dataMap.find((el) => el.id === data.id);
        handleFailedGL({
            id: selectedData.transactionId,
            glStatusCode: selectedData.glStatusCode,
            setIsLoadingBackDrop: setIsLoadingBackDrop,
            paramGL: paramGL,
            setFailedData: setFailedData,
            handleClickRePost: handleClickRePost,
            source: selectedData.category,
        });
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'General Ledger Transaction Status'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {ButtonComponent.RefreshButton(sortArr, rowsCount, columnData, setGoTo, handleReset, setFilterBy,
                            filterBy, setFilter, setNumPage, setRowsCount, getData, stateX, setStateX)}
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            handleFailedGL={handleGL}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <ModalDatePick
                        isModal={isModal}
                        handleClick={handleClick}
                        getData={getData}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                    />
                )
            }

            {
                isModalRePost && (
                    <ModalRePostGL
                        isModal={isModalRePost}
                        handleRePost={handlePost}
                        transactionData={failedData}
                        handleClick={handleClickRePost}
                    />
                )
            }
        </Box>
    )
})

export default GLStatusObserverView;