import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import ModalBankAdvDetail from '../ModalBankAdv/ModalBankAdvDetail';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const BankAdvDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.footer}`]: {
            background: '#E4E8EC',
            color: '#083A50 !important',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
            left: 0,
            bottom: 0,
            zIndex: 2,
            position: 'sticky',
        },
    }));

    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled }

    const [isModalDetail, setIsModalDetail] = useState(false);
    const [titleModalDetail, setTitleModalDetail] = useState('');
    const [isAdd, setIsAdd] = useState(true)

    const [description, setDescription] = useState('')
    const [amountUSD, setAmountUSD] = useState(0)
    const [amountIDR, setAmountIDR] = useState(0)
    const [refId, setRefId] = useState(0)
    const [refNo, setRefNo] = useState('')
    const [refShipmentId, setRefShipmentId] = useState(0)
    const [refShipmentNo, setRefShipmentNo] = useState('')
    const [customerId, setCustomerId] = useState(0)
    const [customerName, setCustomerName] = useState('')
    const [amountUSDPR, setAmountUSDPR] = useState(0)
    const [amountIDRPR, setAmountIDRPR] = useState(0)
    const [amountDiffUSDPR, setAmountDiffUSDPR] = useState(0)
    const [amountDiffIDRPR, setAmountDiffIDRPR] = useState(0)

    const handleAdd = () => {
        if (props.bankId === 0) {
            showToast({ type: 'error', message: 'Invalid Bank' })
        } else {
            if (props.employeeId === 0) {
                showToast({ type: 'error', message: 'Please select Personal Code First!' })
                return
            } else {
                setIsAdd(true)
                setTitleModalDetail('Create ' + props.titleDetail + ' Advance Detail')
                handleClick()
            }
        }
    }

    const handleClick = () => {
        if (isModalDetail === false) {
            setIsModalDetail(true);
        } else {
            setIsModalDetail(false);
        }
    }

    const handleEdit = () => {
        if (props.bankId === 0) {
            showToast({ type: 'error', message: 'Invalid Bank' })
        } else {
            if (props.employeeId === 0) {
                showToast({ type: 'error', message: 'Please select Personal Code First!' })
                return
            } else {
                if (props.selectedDetail.sequence) {
                    setIsAdd(false)
                    setTitleModalDetail('Edit Bank Advance Detail')
                    setDescription(props.selectedDetail.description)
                    setAmountUSD(props.selectedDetail.amountUSD)
                    setAmountIDR(props.selectedDetail.amountIDR)
                    setRefId(props.selectedDetail.refId)
                    setRefNo(props.selectedDetail.refNo)
                    setRefShipmentId(props.selectedDetail.refShipmentId)
                    setRefShipmentNo(props.selectedDetail.refShipmentNo)
                    setCustomerId(props.selectedDetail.customerId)
                    setCustomerName(props.selectedDetail.customerName)
                    setAmountUSDPR(props.selectedDetail.amountUSDPR)
                    setAmountIDRPR(props.selectedDetail.amountIDRPR)
                    setAmountDiffUSDPR(props.selectedDetail.amountDiffUSDPR)
                    setAmountDiffIDRPR(props.selectedDetail.amountDiffIDRPR)
                    handleClick()
                } else {
                    showToast({ type: 'error', message: 'Please Select Data!' })
                }
            }
        }
    }

    const handleDoubleClick = (el) => {
        handleEdit()
    }

    const Delete = () => {
        if (props.employeeId === 0) {
            showToast({ type: 'error', message: 'Please Select Personal Code First!' })
            return
        } else {
            if (props.selectedDetail.sequence) {
                let delRow = props.selectedDetail.sequence
                const tempData = props.dataDetail.filter(dt => dt.sequence !== delRow)
                let tempSequence = 0
                tempData.forEach(row => {
                    tempSequence++
                    row.sequence = tempSequence
                })
                props.setDataDetail(tempData)
                props.sumTotal(tempData, props.rate)
            } else {
                showToast({ type: 'error', message: 'Please Select Data!' })
            }
        }
    }

    return (
        <div ref={props.detailRef}>
            <Typography
                sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: 'normal',
                }}
            >
                {props.titleDetail} Advance Detail
            </Typography>

            <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px', marginTop: '16px' }}>
                <Table sx={{ minWidth: 650 }} stickyHeader>
                    <TableHead
                        sx={{
                            '& th:first-of-type': {
                                borderRadius: '12px 0 0'
                            },
                            '& th:last-child': {
                                borderRadius: '0 12px 0 0'
                            }
                        }}
                    >
                        <TableRow>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Ref Shipment</StyledTableCell>
                            <StyledTableCell>PR / Maintenance</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'right', }}>Amount USD</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'right', }}>Amount IDR</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.dataDetail.length > 0 ?
                                props.dataDetail.map((el) => {
                                    return (
                                        <TableRow
                                            key={el.sequence}
                                            onClick={() => props.setSelectedDetail(el)}
                                            onDoubleClick={() => handleDoubleClick(el)}
                                            sx={
                                                props.selectedDetail.sequence === el.sequence ?
                                                    selectedStyle
                                                    :
                                                    el.rowStatus === 'DEL' ?
                                                        deletedDetailStyle
                                                        :
                                                        {}
                                            }
                                            hover={true}
                                        >
                                            <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                            <StyledTableBodyCell>{el.refShipmentNo !== ''? el.refShipmentNo : '-'}</StyledTableBodyCell>
                                            <StyledTableBodyCell>{el.refNo !== ''? el.refNo : '-'}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                                                {convertToNominal(el.amountUSD)}
                                            </StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                                                {convertToNominal(el.amountIDR)}
                                            </StyledTableBodyCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '12px',
                                                fontstyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            Data Empty
                                        </Typography>
                                    </StyledTableBodyCell>
                                </TableRow>
                        }
                    </TableBody>

                    <TableFooter sx={{ left: 0, bottom: 0, zIndex: 2, position: 'sticky' }}>
                        <TableRow>
                            <StyledTableFooterCell
                                colSpan={3}
                                sx={{
                                    fontWeight: 'bold',
                                    position: 'sticky',
                                    fontSize: 14,
                                    color: '#000',
                                    textAlign: 'center',
                                    background: '#f5f5f5',
                                }}
                            >
                                Total Amount
                            </StyledTableFooterCell>
                            <StyledTableFooterCell
                                sx={{
                                    background: '#f5f5f5',
                                    position: 'sticky',
                                    fontSize: 14,
                                    color: '#000',
                                    textAlign: 'right',
                                }}
                            >
                                {props.totalUSD}
                            </StyledTableFooterCell>
                            <StyledTableFooterCell
                                sx={{
                                    background: '#f5f5f5',
                                    position: 'sticky',
                                    fontSize: 14,
                                    color: '#000',
                                    textAlign: 'right',
                                }}
                            >
                                {props.totalIDR}
                            </StyledTableFooterCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            <div className='mt-4 d-flex' style={{ gap: '12px' }}>
                {ButtonComponent.AddButton(handleAdd, '', props.printing > 0)}
                {ButtonComponent.EditButton(handleEdit, '', props.printing > 0)}
                {ButtonComponent.DeleteButton(Delete, '', props.printing > 0)}
            </div>

            {
                isModalDetail && (
                    <ModalBankAdvDetail
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        titleModalDetail={titleModalDetail}
                        isModal={isModalDetail}
                        handleClick={handleClick}
                        titleModal={titleModalDetail}
                        setIsModal={setIsModalDetail}
                        isAdd={isAdd}
                        BankAdvId={props.BankAdvId}
                        selectedDetail={props.selectedDetail}
                        description={description}
                        setDescription={setDescription}
                        amountUSD={amountUSD}
                        setAmountUSD={setAmountUSD}
                        amountIDR={amountIDR}
                        setAmountIDR={setAmountIDR}
                        refId={refId}
                        setRefId={setRefId}
                        refNo={refNo}
                        setRefNo={setRefNo}
                        refShipmentId={refShipmentId}
                        setRefShipmentId={setRefShipmentId}
                        refShipmentNo={refShipmentNo}
                        setRefShipmentNo={setRefShipmentNo}
                        customerId={customerId}
                        setCustomerId={setCustomerId}
                        customerName={customerName}
                        setCustomerName={setCustomerName}
                        amountUSDPR={amountUSDPR}
                        setAmountUSDPR={setAmountUSDPR}
                        amountIDRPR={amountIDRPR}
                        setAmountIDRPR={setAmountIDRPR}
                        amountDiffUSDPR={amountDiffUSDPR}
                        setAmountDiffUSDPR={setAmountDiffUSDPR}
                        amountDiffIDRPR={amountDiffIDRPR}
                        setAmountDiffIDRPR={setAmountDiffIDRPR}
                        sumTotal={props.sumTotal}
                        rate={props.rate}
                        printing={props.printing}
                        titleDetail={props.titleDetail}
                        advanceTo={props.advanceTo}
                    />
                )
            }
        </div>
    )
}

export default BankAdvDetail