import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Input } from '../../../../components/ui';

const TotalEpl = (props) => {
    return (
        <>
            <div className='row mb-3'>
                <div className='col-12'>
                    <div className='row'>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                marginTop: '8px'
                            }}
                        >
                            Total Estimated
                        </Typography>

                        <Stack direction={'row'} spacing={'12px'} className='mt-3' alignItems={'center'}>
                            <div className='mt-4 d-flex align-items-center justify-content-start' style={{ marginRight: '24px' }}>
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        textAlign: 'center',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    USD
                                </Typography>
                            </div>

                            <Input
                                textAlign='right'
                                label={
                                    props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                        "Inc Consignee"
                                        :
                                        props.jobId === '10' || props.jobId === "9" ?
                                            "Inc Shipper / Cnee"
                                            :
                                            "Inc Shipper"
                                }
                                value={
                                    props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                        props.estIncUSDConsignee
                                        :
                                        props.jobId === '10' || props.jobId === "9" ?
                                            props.estIncUSDShipCons
                                            :
                                            props.estIncUSDShipper
                                }
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Inc Agent'}
                                value={props.estIncUSDAgent}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={
                                    props.jobId === '3' || props.jobId === '4' || props.jobId === '7' || props.jobId === '8' ?
                                        "Cost IATA"
                                        :
                                        props.jobId === "10" || props.jobId === "9" ?
                                            "Cost SSLine/IATA"
                                            :
                                            "Cost SSLine"
                                }
                                value={
                                    props.jobId === '3' || props.jobId === '4' || props.jobId === '7' || props.jobId === '8' ?
                                        props.estCostUSDIATA
                                        :
                                        props.jobId === "10" || props.jobId === "9" ?
                                            props.estCostUSDSSLineIATA
                                            :
                                            props.estCostUSDSSLine
                                }
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost EMKL'}
                                value={props.estCostUSDEMKL}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost Discount'}
                                value={props.estCostUSDDiscount}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost Agent'}
                                value={props.estCostUSDAgent}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost Depo'}
                                value={props.estCostUSDDepo}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            {
                                props.jobId === '10' ?
                                    <Input
                                        textAlign='right'
                                        label={'Cost Trucking'}
                                        value={props.estCostUSDTrucking}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                    />
                                    :
                                    <Input
                                        textAlign='right'
                                        label={'Total USD'}
                                        value={props.estTotalUSD}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                    />
                            }

                            {
                                props.jobId === '10' ?
                                    <Input
                                        textAlign='right'
                                        label={'Total USD'}
                                        value={props.estTotalUSD}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                    />
                                    :
                                    <></>
                            }
                        </Stack>
                    </div>

                    <div className='row mt-4'>
                        <Stack direction={'row'} spacing={'12px'} alignItems={'center'}>
                            <div className='mt-4 d-flex align-items-center justify-content-start' style={{ marginRight: '32px' }}>
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        textAlign: 'center',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    IDR
                                </Typography>
                            </div>

                            <Input
                                textAlign='right'
                                label={
                                    props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                        "Inc Consignee"
                                        :
                                        props.jobId === '10' || props.jobId === "9" ?
                                            "Inc Shipper / Cnee"
                                            :
                                            "Inc Shipper"
                                }
                                value={
                                    props.jobId === '2' || props.jobId === '4' || props.jobId === '6' || props.jobId === '8' ?
                                        props.estIncIDRConsignee
                                        :
                                        props.jobId === '10' || props.jobId === "9" ?
                                            props.estIncIDRShipCons
                                            :
                                            props.estIncIDRShipper
                                }
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Inc Agent'}
                                value={props.estIncIDRAgent}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={
                                    props.jobId === '3' || props.jobId === '4' || props.jobId === '7' || props.jobId === '8' ?
                                        "Cost IATA"
                                        :
                                        props.jobId === "10" || props.jobId === "9" ?
                                            "Cost SSLine/IATA"
                                            :
                                            "Cost SSLine"
                                }
                                value={
                                    props.jobId === '3' || props.jobId === '4' || props.jobId === '7' || props.jobId === '8' ?
                                        props.estCostIDRIATA
                                        :
                                        props.jobId === "10" || props.jobId === "9" ?
                                            props.estCostIDRSSLineIATA
                                            :
                                            props.estCostIDRSSLine
                                }
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost EMKL'}
                                value={props.estCostIDREMKL}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost Discount'}
                                value={props.estCostIDRDiscount}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost Agent'}
                                value={props.estCostIDRAgent}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            <Input
                                textAlign='right'
                                label={'Cost Depo'}
                                value={props.estCostIDRDepo}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />

                            {
                                props.jobId === '10' ?
                                    <Input
                                        textAlign='right'
                                        label={'Cost Trucking'}
                                        value={props.estCostIDRTrucking}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                    />
                                    :
                                    <Input
                                        textAlign='right'
                                        label={'Total IDR'}
                                        value={props.estTotalIDR}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                    />
                            }

                            {
                                props.jobId === '10' ?
                                    <Input
                                        textAlign='right'
                                        label={'Total IDR'}
                                        value={props.estTotalIDR}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                    />
                                    :
                                    <></>
                            }
                        </Stack>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '56px', marginBottom: '8px' }} ref={props.CTCRef}>
                <Stack direction={'row'} spacing={'64px'}>
                    <div style={{ 'width': '100%' }}>
                        <Typography
                            sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '19.6px',
                            }}
                        >
                            Total Income CTC
                        </Typography>

                        <div className='mt-4'>
                            <Stack direction={'row'} spacing={'32px'}>
                                <Input
                                    textAlign='right'
                                    label={'USD'}
                                    value={props.estIncTotalUSD}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                />

                                <Input
                                    textAlign='right'
                                    label={'IDR'}
                                    value={props.estIncTotalIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                />
                            </Stack>
                        </div>
                    </div>

                    <div style={{ 'width': '100%' }}>
                        <Typography
                            sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '19.6px',
                            }}
                        >
                            Total Cost CTC
                        </Typography>

                        <div className='mt-4'>
                            <Stack direction={'row'} spacing={'32px'}>
                                <Input
                                    textAlign='right'
                                    label={'USD'}
                                    value={props.estCostTotalUSD}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                />

                                <Input
                                    textAlign='right'
                                    label={'IDR'}
                                    value={props.estCostTotalIDR}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                />
                            </Stack>
                        </div>
                    </div>
                </Stack>
            </div>
        </>
    )
}

export default TotalEpl