import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

const ListEplButton = ({
    Add,
    Reload,
    Edit,
    Delete,
    UnDelete,
    Print,
    DataRegUserAccess,
    DropDownTitle,
    HandleStatus,
    HandleClosing,
    HandleOpen,
    isApprove,
    isUnApprove,
    ClosingData,
    OpeningData
}) => {
    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(Add, 'New');
            case 'REI':
                return (
                    <>
                        {ButtonComponent.EditButton(Edit, 'Data')}
                        {ButtonComponent.ApproveButton(HandleClosing, 'Closing EPL')}
                        {/* {ButtonComponent.UnApprovalButton(HandleOpen, 'Open EPL')} */}
                    </>
                );
            case 'DEL':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(Delete, 'Data') : null;
            case 'UND':
                return (DropDownTitle === 'Deleted' || DropDownTitle === 'All') ? ButtonComponent.UnDeleteButton(UnDelete, 'Data') : null;
            case 'PRI':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(Print) : null;
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {
                        !isApprove && !isUnApprove ?
                            <>
                                {ButtonComponent.ReloadButton(Reload)}
                                {DataRegUserAccess.map((data) => (
                                    <React.Fragment key={data.accessId}>
                                        {renderButtonByAccessCode(data.accessCode)}
                                    </React.Fragment>
                                ))}
                                {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
                            </>
                            :
                            isApprove ?
                                <>
                                    {ButtonComponent.BackButton(HandleClosing)}
                                    {ButtonComponent.ApproveButton(ClosingData, 'Closing EPL')}
                                </>
                                :
                                <>
                                    {ButtonComponent.BackButton(HandleOpen)}
                                    {ButtonComponent.UnApprovalButton(OpeningData, 'Open EPL')}
                                </>
                    }
                </Grid>
            </div>
        </div>
    );
}

export default ListEplButton;