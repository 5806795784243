import React from 'react'
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

import Grid from '@mui/material/Grid';

const ButtonGLStatusObserver = ({ ReloadData, handleSelectDate, dropDownValue }) => {
    const dropDownOptions = [
        { label: 'Last Week', icon: 'calendar', function: true, functionName: () => handleSelectDate('Last Week') },
        { label: 'Last Month', icon: 'calendar', function: true, functionName: () => handleSelectDate('Last Month') },
        { label: 'Range', icon: 'calendar', function: true, functionName: () => handleSelectDate('Range') },
    ];

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {CustomizeButtonComponent.DropDownButtonStatus(handleSelectDate, dropDownValue, dropDownOptions, 'Date')}
                </Grid>
            </div>
        </div>
    )
}

export default ButtonGLStatusObserver