import { Box, MenuItem, Card, CardContent, Grid } from '@mui/material';
import React, { forwardRef, useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username, isInfossNo } from '../../../../services/ApiService';

import ButtonCreateEditGeneralInvoice from './Buttons/ButtonCreateEditGeneralInvoice';
import GeneralInvoiceHeader from './Components/GeneralInvoiceHeader';
import GeneralInvoiceDetail from './Components/GeneralInvoiceDetail';
import { useImperativeHandle } from 'react';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import { setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal, newWindowConfig } from '../../../../utils/masks.jsx';
import { autoFocusField, PrintPDF } from '../../../../utils/reusableFunction.jsx';

const CreateEditGeneralInvoice = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataGI"));

    const { generalInvoiceId } = useParams();

    const detailRef = useRef(null);
    const infossNoRef = useRef(null);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramGeneralInvoice = 'generalInvoice/generalInvoice';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const paramVAT = 'vat/vat';
    const [VATData, setVATData] = useState([]);

    const history = useNavigate();
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    //General Invoice Variable
    const [RowStatus, setRowStatus] = useState('ACT');
    const [TicketId, setTicketId] = useState(0);
    const [GINumber, setGINumber] = useState('');
    const [Reference, setReference] = useState('');
    const [StatusDC, setStatusDC] = useState('D');
    const [GeneralTo, setGeneralTo] = useState('G');
    const [GeneralToId, setGeneralToId] = useState(0);
    const [Printing, setPrinting] = useState(0);
    const [PrintDate, setPrintDate] = useState('');
    const [PrincipleBy, setPrincipleBy] = useState(0);
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerCode, setCustomerCode] = useState('');
    const [CustomerName, setCustomerName] = useState('');
    const [CityId, setCityId] = useState(0);
    const [CityInt, setCityInt] = useState('');
    const [CityName, setCityName] = useState('');
    const [Address, setAddress] = useState('');
    const [CreditTerm, setCreditTerm] = useState(0);
    const [RateCount, setRateCount] = useState(0);
    const [RateCountReal, setRateCountReal] = useState(0);
    const [RateDate, setRateDate] = useState('');
    const [RateId, setRateId] = useState(0);
    const [ShipmentId, setShipmentId] = useState(0);
    const [ShipmentNo, setShipmentNo] = useState('');
    const [isStampDuty, setIsStampDuty] = useState(false);
    const [stampDutyAmount, setStampDutyAmount] = useState(0);

    const [GeneralInvoiceList, setGeneralInvoiceList] = useState([]);
    const [PaidUSD, setPaidUSD] = useState(false);
    const [DatePaidUSD, setDatePaidUSD] = useState('');
    const [PaidIDR, setPaidIDR] = useState(false);
    const [DatePaidIDR, setDatePaidIDR] = useState('');
    const [SFPId, setSFPId] = useState(0);
    const [SFPNoFormat, setSFPNoFormat] = useState('');
    const [SFPDetailId, setSFPDetailId] = useState(0);
    const [SFPReference, setSFPReference] = useState('');
    const [UniqueKeySFP, setUniqueKeySFP] = useState('');
    const [UniqueKeyInvoice, setUniqueKeyInvoice] = useState('');
    const [IsUseFakturPajak, setIsUseFakturPajak] = useState(false);
    const [VATId, setVATId] = useState(0);
    const [NPWP, setNPWP] = useState('');
    const [AlamatNPWP, setAlamatNPWP] = useState('');

    const [VerifyAcc, setVerifyAcc] = useState(false);
    const [VerifyAccDate, setVerifyAccDate] = useState('');
    const [Period, setPeriod] = useState(0);
    const [YearPeriod, setYearPeriod] = useState(0);
    const [BadDebt, setBadDebt] = useState(false);
    const [BadDebtOn, setBadDebtOn] = useState('');
    const [InternalRef, setInternalRef] = useState('');
    const [SaveOR, setSaveOR] = useState(false);
    const [Deleted, setDeleted] = useState(false);
    const [DeletedOn, setDeletedOn] = useState('');
    const [WfComplete, setWfComplete] = useState(0);

    const [TotalUSD, setTotalUSD] = useState(0);
    const [TotalIDR, setTotalIDR] = useState(0);
    const [TotalVATUSD, setTotalVATUSD] = useState(0);
    const [TotalVATIDR, setTotalVATIDR] = useState(0);
    const [TotalInIDR, setTotalInIDR] = useState(0);
    const [TotalVATInIDR, setTotalVATInIDR] = useState(0);

    const [isEdit, setIsEdit] = useState(false);
    const [isGeneral, setIsGeneral] = useState(false);
    const [isNotGeneral, setIsNotGeneral] = useState(true);
    const [isInclude, setIsInclude] = useState(true);
    const [isJobOwner, setIsJobOwner] = useState(true);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDraft, setIsDraft] = useState(true);
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Invoice', link: '/Majura/GeneralInvoice', style: '' },
        { label: 'Create General Invoice', link: '/Majura/GeneralInvoice/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Invoice', link: '/Majura/GeneralInvoice', style: '' },
        { label: 'Detail General Invoice', link: '/Majura/GeneralInvoice/' + generalInvoiceId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'General Invoice - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    function handleDocumentStatus(rowStatus = 'ACT', isPosted = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isPosted) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const saveLocalData = () => {
        let id = generalInvoiceId ? generalInvoiceId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(VerifyAccDate, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let datePaidUSD = updateDateFormat(DatePaidUSD, true);
        let datePaidIDR = updateDateFormat(DatePaidIDR, true);
        let dateBadDebt = updateDateFormat(BadDebtOn, true);

        var data = {
            "generalInvoice": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": TicketId,
                "generalNo": GINumber,
                "reference": Reference,
                "statusDC": StatusDC,
                "generalTo": GeneralTo,
                "customerId": CustomerId,
                "customerCode": CustomerCode,
                "customerName": CustomerName,
                "cityId": CityId,
                "cityInt": CityInt,
                "cityName": CityName,
                "address": Address,
                "creditTerm": CreditTerm,
                "exRateId": RateId,
                "rate": RateCountReal,
                "exRateDate": RateDate,
                "verifyAcc": VerifyAcc,
                "verifyAccDate": dateVerify,
                "printing": Printing,
                "printedOn": datePrint,
                "totalUSD": TotalUSD,
                "totalIDR": TotalIDR,
                "shipmentOrderId": ShipmentId,
                "shipmentNo": ShipmentNo,
                "paidUSD": PaidUSD,
                "datePaidUSD": datePaidUSD,
                "isStampDuty": isStampDuty,
                "stampDutyAmount": stampDutyAmount,
                "paidIDR": PaidIDR,
                "datePaidIDR": datePaidIDR,
                "badDebt": BadDebt,
                "badDebtOn": dateBadDebt,
                "period": Period,
                "yearPeriod": YearPeriod,
                "saveOR": SaveOR,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "jobOwnerId": PrincipleBy,
                "sfpId": SFPId,
                "sfpNoFormat": SFPNoFormat,
                "sfpDetailId": SFPDetailId,
                "uniqueKeySFP": UniqueKeySFP,
                "uniqueKeyInvoice": UniqueKeyInvoice,
                "isUseFakturPajak": IsUseFakturPajak,
                "vatId": VATId,
                "npwp": NPWP,
                "alamatNPWP": AlamatNPWP,
                "totalVatIdr": TotalVATIDR,
                "totalVatUsd": TotalVATUSD,
                "sfpReference": SFPReference,
                "internalRef": InternalRef,
                "wfComplete": WfComplete,

                "idLama": 0,
            },
            "generalInvoiceDetails": GeneralInvoiceList,
        };

        localStorage.setItem(username + "-DataGI", JSON.stringify(data));
    }

    const getLocalData = () => {
        let arrayGeneralInvoice = [];
        let exRateCount = 0;
        let generalTo = '';
        let isFakturPajak = false;
        let generalInvoiceData = dataLocal.generalInvoice;
        let generalInvoiceDetailData = dataLocal.generalInvoiceDetails;

        if (generalInvoiceId) {
            setIsEdit(true);
        }
        generalTo = generalInvoiceData.generalTo;
        exRateCount = generalInvoiceData.rate;
        isFakturPajak = generalInvoiceData.isUseFakturPajak;
        setInfossNo(generalInvoiceData.noInfoss);
        setRowStatus(generalInvoiceData.rowStatus);
        setTicketId(generalInvoiceData.ticketId);
        setGINumber(generalInvoiceData.generalNo);
        setPrinting(generalInvoiceData.printing);
        setPrincipleBy(generalInvoiceData.jobOwnerId);
        setCustomerId(generalInvoiceData.customerId);
        setCustomerCode(generalInvoiceData.customerCode);
        setCustomerName(generalInvoiceData.customerName);
        handleDocumentStatus(generalInvoiceData.rowStatus, generalInvoiceData.printing > 0 ? true : false)
        setRateCount(convertToNominal(generalInvoiceData.rate));
        setRateCountReal(generalInvoiceData.rate);
        setRateId(generalInvoiceData.exRateId);
        setReference(generalInvoiceData.reference);
        setStatusDC(generalInvoiceData.statusDC);
        setGeneralTo(generalInvoiceData.generalTo);
        setCityId(generalInvoiceData.cityId);
        setCityInt(generalInvoiceData.cityInt);
        setCityName(generalInvoiceData.cityName);
        setAddress(generalInvoiceData.address);
        setCreditTerm(generalInvoiceData.creditTerm);
        setShipmentId(generalInvoiceData.shipmentOrderId);
        setShipmentNo(generalInvoiceData.shipmentNo);
        setTotalUSD(generalInvoiceData.totalUSD);
        setTotalIDR(generalInvoiceData.totalIDR);
        setPaidUSD(generalInvoiceData.paidUSD);
        setPaidIDR(generalInvoiceData.paidIDR);
        setSFPId(generalInvoiceData.sfpId);
        setSFPNoFormat(generalInvoiceData.sfpNoFormat);
        setSFPDetailId(generalInvoiceData.sfpDetailId);
        setSFPReference(generalInvoiceData.sfpReference);
        setUniqueKeyInvoice(generalInvoiceData.uniqueKeyInvoice);
        setUniqueKeySFP(generalInvoiceData.uniqueKeySFP);
        setIsUseFakturPajak(generalInvoiceData.isUseFakturPajak);
        setVATId(generalInvoiceData.vatId);
        setNPWP(generalInvoiceData.npwp);
        setAlamatNPWP(generalInvoiceData.alamatNPWP);
        setBadDebt(generalInvoiceData.badDebt);
        setInternalRef(generalInvoiceData.internalRef);
        setTotalVATUSD(generalInvoiceData.totalVatUsd);
        setTotalVATIDR(generalInvoiceData.totalVatIdr);
        setIsStampDuty(generalInvoiceData.isStampDuty);
        setStampDutyAmount(generalInvoiceData.stampDutyAmount);

        setVerifyAcc(generalInvoiceData.verifyAcc);
        setPeriod(generalInvoiceData.period);
        setYearPeriod(generalInvoiceData.yearPeriod);
        setSaveOR(generalInvoiceData.saveOR);
        setDeleted(generalInvoiceData.deleted);
        setWfComplete(generalInvoiceData.wfComplete);

        setDateFormat(generalInvoiceData.printedOn, setPrintDate);
        setDateFormat(generalInvoiceData.exRateDate, setRateDate);
        setDateFormat(generalInvoiceData.verifyAccDate, setVerifyAccDate);
        setDateFormat(generalInvoiceData.deletedOn, setDeletedOn);
        setDateFormat(generalInvoiceData.datePaidUSD, setDatePaidUSD);
        setDateFormat(generalInvoiceData.datePaidIDR, setDatePaidIDR);
        setDateFormat(generalInvoiceData.badDebtOn, setBadDebtOn);

        setGeneralInvoiceList(generalInvoiceDetailData);
        arrayGeneralInvoice = generalInvoiceDetailData;

        calculateTotal(arrayGeneralInvoice, exRateCount);

        if (generalInvoiceId) {
            setIsEdit(true);
            handleInput(true, false);
        }

        checkInput(generalTo);
        checkInclude(isFakturPajak);

        localStorage.removeItem(username + '-DataGI');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id) => {
        let arrayGeneralInvoice = [];
        let exRateCount = 0;
        let generalTo = '';
        let isFakturPajak = false;
        ApiService.FinAccPostById(paramGeneralInvoice, id)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    let generalInvoiceData = response.data.data.generalInvoice;
                    exRateCount = generalInvoiceData.rate;
                    generalTo = generalInvoiceData.generalTo;
                    isFakturPajak = generalInvoiceData.isUseFakturPajak;
                    setInfossNo(generalInvoiceData.noInfoss);
                    setRowStatus(generalInvoiceData.rowStatus);
                    setTicketId(generalInvoiceData.ticketId);
                    setGINumber(generalInvoiceData.generalNo);
                    setPrinting(generalInvoiceData.printing);
                    setPrincipleBy(generalInvoiceData.jobOwnerId);
                    setCustomerId(generalInvoiceData.customerId);
                    setCustomerCode(generalInvoiceData.customerCode);
                    setCustomerName(generalInvoiceData.customerName);
                    handleDocumentStatus(generalInvoiceData.rowStatus, generalInvoiceData.printing > 0 ? true : false)
                    setRateCount(convertToNominal(generalInvoiceData.rate));
                    setRateCountReal(generalInvoiceData.rate);
                    setRateId(generalInvoiceData.exRateId);
                    setReference(generalInvoiceData.reference);
                    setStatusDC(generalInvoiceData.statusDC);
                    setGeneralTo(generalInvoiceData.generalTo);
                    setCityId(generalInvoiceData.cityId);
                    setCityInt(generalInvoiceData.cityInt);
                    setCityName(generalInvoiceData.cityName);
                    setAddress(generalInvoiceData.address);
                    setCreditTerm(generalInvoiceData.creditTerm);
                    setShipmentId(generalInvoiceData.shipmentOrderId);
                    setShipmentNo(generalInvoiceData.shipmentNo);
                    setTotalUSD(generalInvoiceData.totalUSD);
                    setTotalIDR(generalInvoiceData.totalIDR);
                    setPaidUSD(generalInvoiceData.paidUSD);
                    setPaidIDR(generalInvoiceData.paidIDR);
                    setSFPId(generalInvoiceData.sfpId);
                    setSFPNoFormat(generalInvoiceData.sfpNoFormat);
                    setSFPDetailId(generalInvoiceData.sfpDetailId);
                    setSFPReference(generalInvoiceData.sfpReference);
                    setUniqueKeyInvoice(generalInvoiceData.uniqueKeyInvoice);
                    setUniqueKeySFP(generalInvoiceData.uniqueKeySFP);
                    setIsUseFakturPajak(generalInvoiceData.isUseFakturPajak);
                    setVATId(generalInvoiceData.vatId);
                    setNPWP(generalInvoiceData.npwp);
                    setAlamatNPWP(generalInvoiceData.alamatNPWP);
                    setBadDebt(generalInvoiceData.badDebt);
                    setInternalRef(generalInvoiceData.internalRef);
                    setTotalVATUSD(generalInvoiceData.totalVatUsd);
                    setTotalVATIDR(generalInvoiceData.totalVatIdr);
                    setIsStampDuty(generalInvoiceData.isStampDuty);
                    setStampDutyAmount(generalInvoiceData.stampDutyAmount);

                    setVerifyAcc(generalInvoiceData.verifyAcc);
                    setPeriod(generalInvoiceData.period);
                    setYearPeriod(generalInvoiceData.yearPeriod);
                    setSaveOR(generalInvoiceData.saveOR);
                    setDeleted(generalInvoiceData.deleted);
                    setWfComplete(generalInvoiceData.wfComplete);

                    setDateFormat(generalInvoiceData.printedOn, setPrintDate);
                    setDateFormat(generalInvoiceData.exRateDate, setRateDate);
                    setDateFormat(generalInvoiceData.verifyAccDate, setVerifyAccDate);
                    setDateFormat(generalInvoiceData.deletedOn, setDeletedOn);
                    setDateFormat(generalInvoiceData.datePaidUSD, setDatePaidUSD);
                    setDateFormat(generalInvoiceData.datePaidIDR, setDatePaidIDR);
                    setDateFormat(generalInvoiceData.badDebtOn, setBadDebtOn);

                    setGeneralInvoiceList(generalInvoiceData.generalInvoiceDetails);
                    arrayGeneralInvoice = generalInvoiceData.generalInvoiceDetails;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                calculateTotal(arrayGeneralInvoice, exRateCount);
                handleInput(true, false);
                checkInput(generalTo);
                checkInclude(isFakturPajak);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FGIN')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        GetJobOwnerList(1, 20);
                        handleDocumentStatus();
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    Back()
                })
        }
        else {
            history('/Majura');
        }
    }

    //Function for Principle By
    const GetJobOwnerList = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setPrincipleBy(response.data.data.jobOwner[0].id);
                    }
                    setIsJobOwner(false);
                    setJobOwnerList(response.data.data.jobOwner);

                    GetVATData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={PrincipleBy}
                onChange={(e) => handleCheckJobOwner(e.target.value)}
                disabled={isEdit}
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Packaging Type
    const GetVATData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramVAT, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    setVATData(response.data.data.vat);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        if (generalInvoiceId) {
                            getData(generalInvoiceId);
                        }
                        else {
                            GetExRate();
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetExRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setRateId(response.data.data.exchangeRate.id);
                    setRateCount(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateCountReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setRateDate);

                    if (!dataLocal) {
                        setIsLoadingBackDrop(false);
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    function Save(isPrint = false) {
        if (isLoadingBackDrop) return
        setIsLoadingBackDrop(true);

        let id = generalInvoiceId ? generalInvoiceId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(VerifyAccDate, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let datePaidUSD = updateDateFormat(DatePaidUSD, true);
        let datePaidIDR = updateDateFormat(DatePaidIDR, true);
        let dateBadDebt = updateDateFormat(BadDebtOn, true);

        var data = {
            "generalInvoice": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": TicketId,
                // "generalNo": GINumber,
                "reference": Reference,
                "statusDC": StatusDC,
                "generalTo": GeneralTo,
                "customerId": CustomerId,
                "customerCode": CustomerCode,
                "customerName": CustomerName,
                "isStampDuty": isStampDuty,
                "stampDutyAmount": stampDutyAmount,
                "cityId": CityId,
                "cityInt": CityInt,
                "cityName": CityName,
                "address": Address,
                "creditTerm": CreditTerm,
                "exRateId": RateId,
                "rate": RateCountReal,
                "exRateDate": RateDate,
                "verifyAcc": VerifyAcc,
                "verifyAccDate": dateVerify,
                "printing": Printing,
                "printedOn": datePrint,
                "totalUSD": TotalUSD,
                "totalIDR": TotalIDR,
                "shipmentOrderId": ShipmentId,
                "shipmentNo": ShipmentNo,
                "paidUSD": PaidUSD,
                "datePaidUSD": datePaidUSD,
                "paidIDR": PaidIDR,
                "datePaidIDR": datePaidIDR,
                "badDebt": BadDebt,
                "badDebtOn": dateBadDebt,
                "period": Period,
                "yearPeriod": YearPeriod,
                "saveOR": SaveOR,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "jobOwnerId": PrincipleBy,
                "sfpId": SFPId,
                "sfpNoFormat": SFPNoFormat,
                "sfpDetailId": SFPDetailId,
                "uniqueKeySFP": UniqueKeySFP,
                "uniqueKeyInvoice": UniqueKeyInvoice,
                "isUseFakturPajak": IsUseFakturPajak,
                "vatId": VATId,
                "npwp": NPWP,
                "alamatNPWP": AlamatNPWP,
                "totalVatIdr": TotalVATIDR,
                "totalVatUsd": TotalVATUSD,
                "sfpReference": SFPReference,
                "internalRef": InternalRef,

                "idLama": 0,
            },
            "generalInvoiceDetails": GeneralInvoiceList,
        };

        let errorMessage = undefined;

        if (data.generalInvoice.noInfoss === '' && isInfossNo) {
            errorMessage = 'Please Fill Infoss Number!';
            autoFocusField(infossNoRef);
        }
        else if (data.generalInvoiceDetails.length === 0) {
            errorMessage = 'Please Fill Detail Data!';
            autoFocusField(detailRef);
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(data, paramGeneralInvoice, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (id === 0) {
                            ApiService.CreateFinAcc(paramGeneralInvoice, data).then((res) => {
                                if (res.data.code === 200) {
                                    setIsLoadingBackDrop(false);
                                    message = 'Create Data Success';
                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Majura/GeneralInvoice/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramGeneralInvoice, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isPrint) {
                                        if (titleConfirmation === 'Print') {
                                            if (isDraft === false) {
                                                Print();
                                            }
                                            else {
                                                let reportLink = 'Transactions/Finance/GeneralInvoiceReport/GeneralInvoiceReport/PrintGeneralInvoice?Id=' + generalInvoiceId;
                                                PrintPDF(setIsLoadingBackDrop, 'General Invoice', reportLink, [], true);
                                            }
                                        }
                                    }
                                    else {
                                        setIsLoadingBackDrop(false);
                                        message = 'Update Data Success';
                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                history('/Majura/GeneralInvoice/' + generalInvoiceId, { replace: true });
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: res.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error.toString() });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    function Back() {
        history('/Majura/GeneralInvoice');
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
            setIsDraft(true);
        }
    }

    function ConfirmationGI() {
        if (titleConfirmation === 'Print') {
            CheckDataCondition('Print')
        }
        setIsModalConfirmation(false);
    }

    const CheckDataCondition = async (isAction) => {
        if (!generalInvoiceId) {
            if (isAction === 'Save') {
                setIsLoadingBackDrop(true);
                Save(false);
                return;
            }
            else {
                showToast({ type: 'error', message: 'Create The Data First!' });
                return;
            }
        }

        try {
            setIsLoadingBackDrop(true);

            const response = await ApiService.FinAccPostById(paramGeneralInvoice, generalInvoiceId);

            if (response.data.code !== 200) {
                throw new Error(response.data.message);
            }

            const data = response.data.data.generalInvoice;
            setIsLoadingBackDrop(false);

            const handleSave = () => {
                if (data.printing > 0) {
                    getData(generalInvoiceId);
                    showToast({ type: 'error', message: 'Data Already Been Approved!' });
                } else {
                    Save(false);
                }
            };

            const handlePrintAction = async () => {
                getData(generalInvoiceId);
                setPrinting(data.printing);
                setTitleConfirmation('Print')
                setTitleModalConfirmation('General Invoice');
                handleClickConfirmation();

                if (data.printing === 0) {
                    getDateNow();
                }
                else {
                    setDateFormat(PrintDate, setDueDate);
                }
            }

            switch (isAction) {
                case 'Save':
                    handleSave();
                    break;
                case 'Print':
                    data.printing > 0 ? Print() : Save(true);
                    break;
                case 'PrintPDF':
                    handlePrintAction();
                    break;
                default:
                    showToast({ type: 'error', message: 'Invalid Action!' });
                    break;
            }
        }
        catch (error) {
            setIsLoadingBackDrop(false);
            console.error('Error fetching data:', error);
            showToast({ type: 'error', message: `Error When Read Data! ${error.message || error}` });
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const Print = () => {
        setIsLoadingBackDrop(true);
        let data = {
            'generalInvoice': {
                'id': generalInvoiceId,
                'printing': Printing,
                'statusDC': StatusDC,
                'vatId': VATId,
                'printedOn': dueDate,
            }
        };
        ApiService.FinAccUpdateStatusPrint(paramGeneralInvoice, generalInvoiceId, data)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    let reportLink = 'Transactions/Finance/GeneralInvoiceReport/GeneralInvoiceReport/PrintGeneralInvoice?Id=' + generalInvoiceId + '&isDraft=false';
                    PrintPDF(setIsLoadingBackDrop, 'General Invoice', reportLink, [], true);
                }
                else {
                    showToast({ type: 'error', message: message });
                }
            });
    };

    function AddNew() {
        window.open('/Majura/GeneralInvoice/Create', newWindowConfig);
    }

    function handleInput(isEdit, val) {
        if (isEdit !== true) {
            if (val === true) {
                setIsGeneral(false);
                setIsNotGeneral(true);
                setCustomerId(0);
                setCustomerCode('');
                setCustomerName('');
                setCityId(0);
                setCityInt('');
                setCityName('');
            }
            else {
                setIsGeneral(true);
                setIsNotGeneral(false);
                setAddress('');
            }
            setGeneralInvoiceList([]);
            calculateTotal([], RateCount);
        }
        else {
            setIsGeneral(true);
            setIsNotGeneral(true);
        }
    }

    function checkInput(GeneralTo) {
        if (GeneralTo === 'G') {
            if (Address !== '') {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (CustomerId !== 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    function checkInclude(val) {
        if (val === false) {
            setIsInclude(true);
        }
        else {
            setIsInclude(false);
        }
    }

    function calculateTotal(result, rate) {
        let count1 = 0;
        let count2 = 0;
        let count3 = 0;

        let count4 = 0;
        let count5 = 0;
        let count6 = 0;

        for (let i = 0; i < result.length; i++) {
            count1 = parseFloat(count1) + parseFloat(result[i].amountUSD);
            count2 = parseFloat(count2) + parseFloat(result[i].amountIDR);

            count4 = parseFloat(count4) + parseFloat(result[i].amountVatUSD);
            count5 = parseFloat(count5) + parseFloat(result[i].amountVatIDR);
        }

        count3 = (parseFloat(count1) * parseFloat(rate)) + parseFloat(count2);
        count6 = (parseFloat(count4) * parseFloat(rate)) + parseFloat(count5);

        setTotalUSD(count1);
        setTotalIDR(count2);
        setTotalInIDR(count3);

        setTotalVATUSD(count4);
        setTotalVATIDR(count5);
        setTotalVATInIDR(count6);
    }

    function handleCheckJobOwner(val) {
        setPrincipleBy(val);
        if (isEdit === false) {
            if (val !== undefined || val !== 0 || val !== null || val !== '') {
                setIsJobOwner(false);
            }
            else {
                setIsJobOwner(true);
            }
        }
        else {
            setIsJobOwner(true);
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditGeneralInvoice
                Save={() => CheckDataCondition('Save')}
                Back={Back}
                Print={() => CheckDataCondition('PrintPDF')}
                AddNew={AddNew}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                Printing={Printing}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            generalInvoiceId ?
                                'General Invoice (' + Reference + ')'
                                :
                                'General Invoice ( New Document )'
                        }
                        BreadcrumbsItems={generalInvoiceId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={TicketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <GeneralInvoiceHeader
                                infossNoRef={infossNoRef}
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                generalInvoiceId={generalInvoiceId}
                                isEdit={isEdit}
                                isNotGeneral={isNotGeneral}
                                selectPrincipleBy={selectPrincipleBy}
                                VATData={VATData}
                                isJobOwner={isJobOwner}
                                isGeneral={isGeneral}
                                setIsGeneral={setIsGeneral}
                                handleInput={handleInput}
                                isInclude={isInclude}
                                setIsInclude={setIsInclude}
                                calculateTotal={calculateTotal}

                                GINumber={GINumber}
                                setGINumber={setGINumber}
                                PrincipleBy={PrincipleBy}
                                Reference={Reference}
                                InternalRef={InternalRef}
                                setInternalRef={setInternalRef}
                                GeneralTo={GeneralTo}
                                setGeneralTo={setGeneralTo}
                                GeneralToId={GeneralToId}
                                setGeneralToId={setGeneralToId}
                                PrintDate={PrintDate}
                                setPrintDate={setPrintDate}
                                CustomerId={CustomerId}
                                setCustomerId={setCustomerId}
                                CustomerCode={CustomerCode}
                                setCustomerCode={setCustomerCode}
                                CustomerName={CustomerName}
                                setCustomerName={setCustomerName}
                                SFPNoFormat={SFPReference}
                                setSFPNoFormat={setSFPReference}
                                CityId={CityId}
                                setCityId={setCityId}
                                CityInt={CityInt}
                                setCityInt={setCityInt}
                                CityName={CityName}
                                setCityName={setCityName}
                                Address={Address}
                                setAddress={setAddress}
                                RateCount={RateCount}
                                setRateCount={setRateCount}
                                RateDate={RateDate}
                                setRateDate={setRateDate}
                                StatusDC={StatusDC}
                                setStatusDC={setStatusDC}
                                IsUseFakturPajak={IsUseFakturPajak}
                                setIsUseFakturPajak={setIsUseFakturPajak}
                                VATId={VATId}
                                setVATId={setVATId}
                                NPWP={NPWP}
                                setNPWP={setNPWP}
                                printing={Printing}
                                AlamatNPWP={AlamatNPWP}
                                setAlamatNPWP={setAlamatNPWP}
                                isStampDuty={isStampDuty}
                                setIsStampDuty={setIsStampDuty}
                                stampDutyAmount={stampDutyAmount}
                                setStampDutyAmount={setStampDutyAmount}

                                GeneralInvoiceList={GeneralInvoiceList}
                                setGeneralInvoiceList={setGeneralInvoiceList}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <GeneralInvoiceDetail
                                detailRef={detailRef}
                                generalInvoiceId={generalInvoiceId}
                                checkInput={checkInput}
                                GeneralTo={GeneralTo}
                                GeneralInvoiceList={GeneralInvoiceList}
                                setGeneralInvoiceList={setGeneralInvoiceList}
                                RateCount={RateCount}
                                VATData={VATData}
                                VATId={VATId}
                                calculateTotal={calculateTotal}

                                TotalUSD={TotalUSD}
                                TotalIDR={TotalIDR}
                                printing={Printing}
                                TotalVATUSD={TotalVATUSD}
                                TotalVATIDR={TotalVATIDR}
                                TotalInIDR={TotalInIDR}
                                TotalVATInIDR={TotalVATInIDR}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Box>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationGI}
                        isDraft={isDraft}
                        setIsDraft={setIsDraft}

                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        getDateNow={getDateNow}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        PrintCount={Printing}
                    />
                )
            }

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditGeneralInvoice;