import {
    IconButton, Stack, Grid, InputAdornment, MenuItem,
    FormGroup, Backdrop,
    TableContainer, Table, TableHead, TableRow,
    TableBody, TableCell, Card, CardContent, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react';
import ApiService, { formatCurrency, userCode } from '../../../services/ApiService';
import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import { useState } from 'react';
import ModalContact from './Selects/ModalContact';
import ModalMarketing from './Selects/ModalMarketing';
import DollarInput, { convertMask } from '../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../components/Masks/RupiahInput';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ModalMultiAddress from './Modals/ModalMultiAddress';
import SelectGroup from './Selects/SelectGroup';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import ModalBankAccount from './Modals/ModalBankAccount';
import { Icon, Input, PopUp, Selection, showToast, TickBox } from '../../../components/ui';
import { defaultDate } from '../../../utils/date';
import { autoFocusField, handleDataTable } from '../../../utils/reusableFunction';

function NPWPMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            mask={[
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
                ".",
                /\d/,
                "-",
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
            ]}
        // keepCharPositions
        />
    )
}

NPWPMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const CreateEditRegContact = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    useEffect(() => {
        setIsValidNPWP(npwpRegex.test(props.contactNPWP));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contactNPWP]);

    const npwpRegex = /^\d{3}\.\d{3}\.\d{3}\.\d-\d{3}\.\d{3}$/;
    const [isValidNPWP, setIsValidNPWP] = useState(false);

    const contactTypeRef = useRef(null);
    const addressTableRef = useRef(null);
    const agentRef = useRef(null);
    const creditTermRef = useRef(null);
    const npwpRef = useRef(null);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);

    const [CityId, setCityId] = useState(0);
    const [CityCode, setCityCode] = useState('');
    const [CityName, setCityName] = useState('');
    const [CountryId, setCountryId] = useState(0);
    const [CountryName, setCountryName] = useState('');
    const [ContactAddress, setContactAddress] = useState('');
    const [AddressType, setAddressType] = useState('Pusat');
    const [isMainAddress, setIsMainAddress] = useState(false);
    const [isOversea, setIsOversea] = useState(false);
    const [PostalCode, setPostalCode] = useState('');
    const [isNotDefault, setIsNotDefault] = useState(true);

    const [isModalContact, setIsModalContact] = useState(false);
    const [titleModalContact, setTitleModalContact] = useState('');
    const [isLoadingContact, setIsLoadingContact] = useState(false);
    const paramContact = "Contact/Contact";
    const [dataContact, setDataContact] = useState([]);
    const [dataMapContact, setDataMapContact] = useState([])
    const [dataTableContact, setDataTableContact] = useState([]);
    const [columnDataContact, setColumnDataContact] = useState([])
    const [SelectedDataContact, setSelectedDataContact] = useState({});
    const [totalRowsContact, setTotalRowsContact] = useState(50);
    const [filterContact, setFilterContact] = useState([])
    const [filterByContact, setFilterByContact] = useState([])

    const [isModalMarketing, setIsModalMarketing] = useState(false);
    const [titleModalMarketing, setTitleModalMarketing] = useState('');
    const [isLoadingMarketing, setIsLoadingMarketing] = useState(false);
    const paramMarketing = "Employee/Employee";
    const [dataMarketing, setDataMarketing] = useState([]);
    const [dataMapMarketing, setDataMapMarketing] = useState([])
    const [dataTableMarketing, setDataTableMarketing] = useState([]);
    const [columnDataMarketing, setColumnDataMarketing] = useState([])
    const [SelectedDataMarketing, setSelectedDataMarketing] = useState({});
    const [totalRowsMarketing, setTotalRowsMarketing] = useState(50);

    const [isModalGroup, setIsModalGroup] = useState(false);
    const [titleModalGroup, setTitleModalGroup] = useState('');
    const [isLoadingGroup, setIsLoadingGroup] = useState(false);
    const paramGroup = "RegGroup/RegGroup";
    const [dataGroup, setDataGroup] = useState([]);
    const [dataMapGroup, setDataMapGroup] = useState([])
    const [dataTableGroup, setDataTableGroup] = useState([]);
    const [columnDataGroup, setColumnDataGroup] = useState([])
    const [SelectedDataGroup, setSelectedDataGroup] = useState({});
    const [totalRowsGroup, setTotalRowsGroup] = useState(50);

    const [isModalBank, setIsModalBank] = useState(false);
    const [titleModalBank, setTitleModalBank] = useState('');
    const [selectedDetailBank, setSelectedDetailBank] = useState({});

    const [AccountNo, setAccountNo] = useState('');
    const [AccountBankName, setAccountBankName] = useState('');
    const [AccountName, setAccountName] = useState('');

    const contactStatusData = [
        { id: 1, code: 'PT', name: 'PT' },
        { id: 2, code: 'CV', name: 'CV' },
        { id: 3, code: 'OT', name: 'Others' },
    ];

    function handleCheckLimit(data) {
        const userRole = JSON.parse(localStorage.getItem('roleInt'));

        if (data.creditTermInDays < 0 || data.creditTermInIDR < 0 || data.creditTermInUSD < 0) {
            return false;
        }

        if (data.creditTermInDays > 180) {
            return false;
        }

        if (['DIR', 'SUPADM'].includes(userRole)) {
            return true;
        }

        const checkLimit = (currentValue, newValue, mgrLimit, gmLimit) => {
            if (parseFloat(newValue) !== parseFloat(currentValue)) {
                if (userRole === 'MGRACC') {
                    return newValue <= mgrLimit;
                }
                if (userRole === 'GM') {
                    return newValue <= gmLimit;
                }
                return false;
            }
            return true;
        };

        const { creditTermInIDR, creditTermInUSD } = props.dataContact || {};

        if (creditTermInIDR && data.creditTermInIDR) {
            if (!checkLimit(creditTermInIDR, data.creditTermInIDR, 500_000_000, 1_000_000_000)) {
                return false;
            }
        }

        if (creditTermInUSD && data.creditTermInUSD) {
            if (!checkLimit(creditTermInUSD, data.creditTermInUSD, 35_000, 70_000)) {
                return false;
            }
        }

        return true;
    }

    const Save = () => {
        props.setIsLoadingBackDrop(true);
        let address = '';
        let postalCode = '';
        let cityId = 0;
        let cityName = '';
        let countryId = 0;
        let countryName = '';
        let isOversea = false;

        let groupData = [
            {
                "contactId": props.contactId,
                "contactGroupId": props.groupId,
                "contactGroupCode": props.groupCode,
                "contactGroupName": props.groupName,
            }
        ]

        let masterData = {
            "rowStatus": "ACT",
            "id": props.contactId,
            "code": props.contactCode,
            "contactStatus": props.contactType,
            "contactName": props.contactName,
        }

        props.listAddress.forEach(element => {
            if (element.isDefault === true) {
                address = element.address;
                postalCode = element.postalCode;
                cityId = element.cityId;
                cityName = element.cityName;
                countryId = element.contactCountryId;
                countryName = element.contactCountryName;
            }
        });

        if (props.listAddress.length > 0) {
            isOversea = props.listAddress[props.listAddress.length - 1].isOversea;
        }

        const deleteFunction = (listJobOwner) => {
            return listJobOwner.jobOwnerId !== 0
        }

        const jobOwnerArray = props.listJobOwner.filter(deleteFunction)

        var data = {
            "contact": {
                "rowStatus": 'ACT',
                "id": props.id,
                "contactId": props.contactId,
                "code": props.code,
                "contactName": props.contactName,
                "contactAddress": address,
                "postalCode": postalCode,
                "contactPerson": props.contactPerson,
                "contactStatus": '',
                "nik": props.contactNIK,
                "phone": props.contactPhone,
                "fax": props.contactFax,
                "email": props.contactEmail,
                "npwp": props.contactNPWP,
                "nppkp": props.contactNPPKP,
                "creditTermInDays": parseInt(props.creditTermInDays),
                "creditTermInIDR": parseFloat(props.creditTermInIDR),
                "creditTermInUSD": parseFloat(props.creditTermInUSD),
                "shipmentStatus": props.shipmentStatus,
                "cityId": cityId,
                "cityName": cityName,
                "countryIdDefault": countryId,
                "countryNameDefault": countryName,
                "isOversea": isOversea,
                "marketId": props.marketId,
                "marketingCode": props.marketingCode,
                "marketingName": props.marketingName,
                "lastShipmentDate": defaultDate,
                "portId": 0,
                "portName": "",
                "airPortId": 0,
                "airPortName": "",
                "pic": "",
                "deleted": false,
                "contactTypes": props.listContactType,
                "contactAddresses": props.listAddress,
                "contactJobOwners": jobOwnerArray,
                "contactBanks": props.listBankAccount,
                "contactGroups": groupData,
                "contactMaster": masterData,
            }
        }

        let errMsg = undefined;
        if (data.contact.contactTypes.length === 0) {
            errMsg = 'Please Select At Least 1 Contact Type!';
            autoFocusField(contactTypeRef);
        }
        else if (data.contact.contactAddress === '') {
            errMsg = 'Please Input 1 Contact Address As Default';
            autoFocusField(addressTableRef);
        }
        else if (props.isAgent && (groupData.contactGroupId === 0 || jobOwnerArray.length === 0)) {
            errMsg = 'Please Input Group and Principle for Agent';
            autoFocusField(agentRef);
        }
        else if (handleCheckLimit(data.contact) === false) {
            errMsg = 'Credit Term Exceed The Limit, Please Check It Again!';
            autoFocusField(creditTermRef);
        }
        else if (!data.contact.isOversea && !npwpRegex.test(data.contact.npwp)) {
            errMsg = 'NPWP Format Still Wrong, Please Check It Again!';
            autoFocusField(npwpRef);
        }

        if (errMsg === undefined) {
            ApiService.HelpCreateData(data, props.param, props.contactId)
                .then((res) => {
                    if (res.data.code === 200) {
                        if (props.isNewReg === false) {
                            ApiService.createData(props.param, data)
                                .then((res) => {
                                    let msg = '';
                                    if (res?.data?.code === 200) {
                                        msg = "Create Data " + props.title + " Success";
                                        showToast({ type: 'success', message: msg });

                                        props.handleClick();
                                        props.setIsLoadingBackDrop(false);
                                    }
                                    else {
                                        const errMsg = res?.data?.error
                                        if (errMsg && errMsg.indexOf('duplicate') !== -1) {
                                            props.setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Failed to create data, there is duplicate code or name' })
                                        } else {
                                            props.setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Failed to create data, contact the administrator, ' + errMsg })
                                        }
                                    }
                                })
                                .catch(function (error) {
                                    props.setIsLoadingBackDrop(false);
                                    console.error('Create error: ', error)
                                    showToast({ type: 'error', message: 'Failed to create data, contact the administrator, ' + error })
                                })
                        }
                        else {
                            ApiService.updateData(props.param, data)
                                .then((res) => {
                                    let msg = '';
                                    if (res?.data?.code === 200) {
                                        msg = 'Update Data ' + props.title + ' Success'
                                        showToast({ type: 'success', message: msg });

                                        props.handleClick();
                                        props.setIsLoadingBackDrop(false);
                                    }
                                    else {
                                        const errMsg = res?.data?.error
                                        if (errMsg && errMsg.indexOf('duplicate') !== -1) {
                                            props.setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Failed to update data, there is duplicate code or name' })
                                        } else {
                                            props.setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Failed to update data, contact the administrator, ' + errMsg })
                                        }
                                    }
                                })
                                .catch(function (error) {
                                    console.error('Update error: ', error)
                                    props.setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Failed to update data, contact the administrator, ' + error })
                                })
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        props.setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to create data, contact the administrator, ' + res.data.error })
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    props.setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Failed to create data, contact the administrator, ' + error })
                })
        }
        else {
            setIsLoadingBackDrop(false);
            props.setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errMsg });
        }
    }

    const handleChangeNPWP = (value) => {
        setIsValidNPWP(npwpRegex.test(value));
        props.setContactNPWP(value);
    };

    const handleClickContact = () => {
        if (isModalContact === false) {
            setIsModalContact(true);
            getDataContact(1, 50);
        }
        else {
            setIsModalContact(false);
        }
    };

    const searchContact = (data) => {
        if (data === 'search') {
            if (props.isSearch === true) {
                setTitleModalContact('Contact');
                handleClickContact();
            }
            else {
                if (props.contactName !== '') {
                    setTitleModalContact('Contact');
                    handleClickContact();
                }
                else {
                    showToast({ type: 'error', message: 'Please Input Contact Name First!' })
                }
            }
        }
        else if (data === 'delete') {
            props.emptyState();
            props.setIsSearch(false);
        }
    };

    const handleClickMarketing = () => {
        if (isModalMarketing === false) {
            setIsModalMarketing(true);
            getDataMarketing(1, 50);
        }
        else {
            setIsModalMarketing(false);
        }
    };

    const searchMarketing = (data) => {
        if (data === 'search') {
            setTitleModalMarketing('Marketing');
            handleClickMarketing();
        }
        else if (data === 'delete') {
            props.setMarketId(0);
            props.setMarketingCode('');
            props.setMarketingName('');
        }
    };

    const handleClickGroup = () => {
        if (isModalGroup === false) {
            setIsModalGroup(true);
            getDataGroup(1, 50);
        }
        else {
            setIsModalGroup(false);
        }
    };

    const searchGroup = (data) => {
        if (data === 'search') {
            setTitleModalGroup('Group');
            handleClickGroup();
        }
        else if (data === 'delete') {
            props.setGroupId(0);
            props.setGroupCode('');
            props.setGroupName('');
        }
    };

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingContact(true);

        let filter = [];

        if (props.isSearch === false) {
            filter = [
                ...filter,
                { 'field': 'contactName', 'data': props.contactName },
            ]
        }

        if (filters !== undefined) {
            filter = [...filter, filters];
        }

        ApiService.PostByPage(paramContact, pageNumber, pageSize, filter)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.contact.length > 0) {
                        if (response.data.data.contact.length > 1) {
                            setSelectedDataContact({})
                            setDataContact(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapContact(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableContact, 'id');
                            setColumnDataContact(response.data.data.columns)
                            setTotalRowsContact(response.data.totalRowCount)

                            setIsLoadingContact(false)
                        }
                        else {
                            setIsLoadingBackDrop(true);
                            props.setContactId(response.data.data.contact[0].id)
                            props.setCode(response.data.data.contact[0].code)
                            props.setContactName(response.data.data.contact[0].contactName)
                            setIsModalContact(false);
                            props.setIsSearch(true);
                            ApiService.PostById("regContact/regContact", response.data.data.contact[0].id)
                                .then((res) => {
                                    if (res.data.code === 200) {
                                        if (res.data.data.contact.id !== undefined) {
                                            props.setIsNewReg(true);
                                            props.setId(res.data.data.contact.id);
                                            props.setContactPerson(res.data.data.contact.contactPerson);
                                            props.setContactNIK(res.data.data.contact.nik);
                                            props.setContactPhone(res.data.data.contact.phone);
                                            props.setContactFax(res.data.data.contact.fax);
                                            props.setContactEmail(res.data.data.contact.email);
                                            props.setContactNPWP(res.data.data.contact.npwp);
                                            props.setContactNPPKP(res.data.data.contact.nppkp);
                                            props.setCreditTermInDays(res.data.data.contact.creditTermInDays);
                                            props.setCreditTermInIDR(res.data.data.contact.creditTermInIDR);
                                            props.setCreditTermInUSD(res.data.data.contact.creditTermInUSD);
                                            props.setShipmentStatus(res.data.data.contact.shipmentStatus);
                                            props.setMarketId(res.data.data.contact.marketId);
                                            props.setMarketingCode(res.data.data.contact.marketingCode);
                                            props.setMarketingName(res.data.data.contact.marketingName);
                                            props.setGroupId(res.data.data.contact.groupId);
                                            props.setGroupCode(res.data.data.contact.groupCode);
                                            props.setGroupName(res.data.data.contact.groupName);
                                            props.setListContactType(res.data.data.contact.contactTypes);
                                            props.setListAddress(res.data.data.contact.contactAddresses);
                                            let seq = 1;
                                            let array = res.data.data.contact.contactJobOwners;
                                            array.forEach(element => {
                                                element.sequence = seq;
                                                seq = seq + 1;
                                            });
                                            props.setListJobOwner(array);
                                            props.setListBankAccount(res.data.data.contact.contactBanks);
                                            props.setContactType(res.data.data.contact.contactMaster.contactStatus);

                                            if (res.data.data.contact.contactGroups.length > 0) {
                                                props.setGroupId(res.data.data.contact.contactGroups[0].contactGroupId);
                                                props.setGroupCode(res.data.data.contact.contactGroups[0].contactGroupCode);
                                                props.setGroupName(res.data.data.contact.contactGroups[0].contactGroupName);
                                            }
                                        }

                                        setIsLoadingBackDrop(false);
                                    }
                                    else if (res.data.code === 204) {
                                        props.setIsNewReg(false);
                                        setIsLoadingBackDrop(false);
                                    }
                                    else {
                                        setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: 'Error When Read Reg Contact Data!, ' + res.data.message })
                                    }
                                })
                                .catch(function (error) {
                                    setIsLoadingBackDrop(false);
                                    console.error('error saat fetch', error);
                                    showToast({ type: 'error', message: error.toString() })
                                })
                        }
                    }
                    else {
                        setIsModalContact(false);
                        props.setIsSearch(true);
                    }
                }
                setIsLoadingContact(false)
            })
            .catch(function (error) {
                setIsLoadingContact(false)
                console.error('error saat fetch', error)
            })
    }

    const getDataMarketing = (pageNumber, pageSize, filters) => {
        setIsLoadingMarketing(true);
        let params = [{ 'attr': 'departmentCode', 'value': 'MKT' }];
        ApiService.DynamicPostByPage(paramMarketing, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataMarketing({})
                    setDataMarketing(response.data.data.employees);

                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapMarketing(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableMarketing, 'id');
                    setColumnDataMarketing(response.data.data.columns)
                    setTotalRowsMarketing(response.data.totalRowCount)

                    setIsLoadingMarketing(false)
                }
                setIsLoadingMarketing(false)
            })
            .catch(function (error) {
                setIsLoadingMarketing(false)
                console.error('error saat fetch', error)
            })
    }

    const getDataGroup = (pageNumber, pageSize, filters) => {
        setIsLoadingGroup(true);
        ApiService.DynamicPostByPage(paramGroup, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataGroup({})
                    setDataGroup(response.data.data.group);

                    let temp = response.data.data.group
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapGroup(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableGroup, 'id');
                    setColumnDataGroup(response.data.data.columns)
                    setTotalRowsGroup(response.data.totalRowCount)

                    setIsLoadingGroup(false)
                }
                setIsLoadingGroup(false)
            })
            .catch(function (error) {
                setIsLoadingGroup(false)
                console.error('error saat fetch', error)
            })
    }

    const handleChangeContactType = (data, selected) => {
        const { listContactType, contactTypeData, setIsAgent, setListContactType } = props;

        const getRelatedContactType = (name) => {
            const map = {
                'Shipper': 'Discount Shipper',
                'Consignee': 'Discount Consignee',
                'Discount Shipper': 'Shipper',
                'Discount Consignee': 'Consignee',
            };
            return contactTypeData.find((x) => x.name === map[name]);
        };

        let updatedContactType = [...listContactType];

        if (selected) {
            if (data.name === 'Agent') {
                setIsAgent(true);
            }

            if (['Shipper', 'Consignee', 'Discount Shipper', 'Discount Consignee'].includes(data.name)) {
                const relatedType = getRelatedContactType(data.name);
                if (relatedType) {
                    updatedContactType.push({
                        rowStatus: 'ACT',
                        id: relatedType.id,
                        code: relatedType.code,
                        name: relatedType.name,
                        isNew: true,
                    });
                }
            }

            updatedContactType.push({
                rowStatus: 'ACT',
                id: data.id,
                code: data.code,
                name: data.name,
                isNew: true,
            });
        }
        else {
            if (data.name === 'Agent') {
                setIsAgent(false);
            }

            if (['Shipper', 'Consignee', 'Discount Shipper', 'Discount Consignee'].includes(data.name)) {
                const relatedType = getRelatedContactType(data.name);
                if (relatedType) {
                    updatedContactType = updatedContactType.filter(
                        (x) => x.id !== relatedType.id
                    );
                }
            }

            updatedContactType = updatedContactType.filter((x) => x.id !== data.id);
        }

        setListContactType(updatedContactType);
    };

    const handleClickAddress = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const handleCheckDefault = () => {
        if (props.listAddress.length > 0) {
            props.listAddress.forEach(element => {
                if (element.isDefault === true) {
                    setIsNotDefault(false);
                }
            });
        }
        else {
            setIsNotDefault(true);
        }
    }

    const ShowAddAddress = () => {
        setTitleModal('Add Address');
        setDetailEdit(false);
        if (props.listAddress.length > 0) {
            let data = props.listAddress.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleCheckDefault();
        handleClickAddress();
    };

    const ShowEditAddress = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            setTitleModal('Edit Address');
            setDetailEdit(true);
            handleCheckDefault();

            setCityId(selectedDetail.cityId);
            setCityCode(selectedDetail.cityCode);
            setCityName(selectedDetail.cityName);
            setCountryId(selectedDetail.contactCountryId);
            setCountryName(selectedDetail.contactCountryName);
            setContactAddress(selectedDetail.address);
            setIsMainAddress(selectedDetail.isDefault);
            setAddressType(selectedDetail.addressType);
            setIsOversea(selectedDetail.isOversea);
            setPostalCode(selectedDetail.postalCode);

            handleClickAddress();
        }
    }

    const DeleteAddress = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.listAddress.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setListAddress(result);
            setSelectedDetail({});
        }
    }

    const handleDoubleClick = () => {
        ShowEditAddress();
    }

    const handleClickBankAccount = () => {
        if (isModalBank === false) {
            setIsModalBank(true);
        }
        else {
            setIsModalBank(false);
        }
    };

    const ShowAddBankAccount = () => {
        setTitleModalBank('Add Bank Account');
        handleClickBankAccount();
    };

    const DeleteBankAccount = () => {
        if (!selectedDetailBank.accNo) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            let tempSequence = selectedDetailBank.accNo;

            const deleteFunction = (data) => {
                return data.accNo !== tempSequence;
            }

            const result = props.listBankAccount.filter(deleteFunction);

            props.setListBankAccount(result);
            setSelectedDetail({});
        }
    }

    function handleAddJobOwner() {
        let Sequence = 1;

        if (props.listJobOwner.length > 0) {
            Sequence = props.listJobOwner[props.listJobOwner.length - 1].sequence + 1;
        }

        let data = {
            "rowStatus": "ACT",
            "sequence": Sequence,
            "contactId": props.ContactId,
            "jobOwnerId": 0,
            "userCode": userCode,
        }

        props.setListJobOwner([...props.listJobOwner, data]);
    }

    const handleDeleteJobOwner = (el) => {
        const deleteFunction = (listJobOwner) => {
            return listJobOwner.jobOwnerId !== el.jobOwnerId
        }

        const result = props.listJobOwner.filter(deleteFunction)

        props.setListJobOwner(result);
    }

    const handleChangeJobOwner = (value, el) => {
        let arrayData = [];
        if (props.listJobOwner.filter(data => data.jobOwnerId === value).length > 0) {
            showToast({ type: 'error', message: 'Job Owner Already Registered!' })

            props.listJobOwner.map((data) => {
                if (el.sequence === data.sequence) {
                    data.jobOwnerId = '';
                }

                return data;
            });
        }
        else {
            props.listJobOwner.map((data) => {
                if (el.jobOwnerId === data.jobOwnerId) {
                    data.jobOwnerId = value;
                }

                return data;
            });
        }

        arrayData = [...props.listJobOwner];

        props.setListJobOwner(arrayData);
    }

    const mainContainer = (
        <>
            {
                props.isSearch === true ?
                    <>
                        <form onSubmit={Save} style={{ marginTop: '12px' }}>
                            <Card
                                component="div"
                                style={{
                                    borderRadius: '12px',
                                    backgroundColor: '#FAFAFA',
                                    padding: '8px 24px',
                                    border: '1px solid var(--Grey-Outline-2, #989898)',
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Contact Info
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={'32px'}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <Selection
                                                label="Contact Status"
                                                value={props.contactType}
                                                placeholder={'Select Contact Status'}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                                onChange={(e) => props.setContactType(e.target.value)}
                                                disabled={props.contactId !== 0 ? true : false}
                                            >
                                                {
                                                    contactStatusData.map((data, index) => (
                                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                    ))
                                                }
                                            </Selection>

                                            <Input
                                                label={'NIK'}
                                                value={props.contactNIK}
                                                onChange={(e) => props.setContactNIK(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                            />

                                            <Input
                                                label={'NPPKP'}
                                                value={props.contactNPPKP}
                                                onChange={(e) => props.setContactNPPKP(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <Input
                                                label={'Contact Name'}
                                                value={props.contactName}
                                                onChange={(e) => props.setContactName(e.target.value)}
                                                // disabled = {props.contactId !== 0? true : false}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchContact('delete')}
                                                                hidden={props.isEdit ? true : props.contactName && props.isSearch ? false : true}
                                                                disabled={props.isEdit}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => searchContact('search')}
                                                                disabled={props.isEdit}
                                                                hidden={props.isEdit ? true : !props.isSearch}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            <div ref={npwpRef}>
                                                <Input
                                                    label={'NPWP'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: NPWPMask,
                                                        value: props.contactNPWP,
                                                        onChange: (e) => {
                                                            let text = e.target.value
                                                            if (text) { text = text.toUpperCase() }
                                                            handleChangeNPWP(text)
                                                        }
                                                    }}
                                                    message={props.contactNPWP === '' || isValidNPWP ? '' : 'NPWP Format is Wrong'}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                            <Card
                                component="div"
                                style={{
                                    marginTop: '24px',
                                    borderRadius: '12px',
                                    backgroundColor: '#FAFAFA',
                                    padding: '8px 24px',
                                    border: '1px solid var(--Grey-Outline-2, #989898)',
                                }}
                            >
                                <CardContent>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Contact Detail
                                    </Typography>

                                    <Stack direction={'row'} spacing={'32px'} sx={{ marginTop: '16px' }}>
                                        <Input
                                            type='email'
                                            label={'Email'}
                                            value={props.contactEmail}
                                            onChange={(e) => props.setContactEmail(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />

                                        <Input
                                            label={'Phone'}
                                            value={props.contactPhone}
                                            onChange={(e) => props.setContactPhone(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />

                                        <Input
                                            label={'Fax'}
                                            value={props.contactFax}
                                            onChange={(e) => props.setContactFax(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />
                                    </Stack>

                                    <Stack spacing={'32px'} direction="row" sx={{ marginTop: '16px' }}>
                                        <Selection
                                            label="Shipment Status"
                                            value={props.shipmentStatus}
                                            placeholder={'Select Shipment Status'}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            onChange={(e) => props.setShipmentStatus(e.target.value)}
                                        >
                                            {
                                                props.shipmentStatusData.map((data, index) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Input
                                            label="Contact Person"
                                            value={props.contactPerson}
                                            onChange={(e) => props.setContactPerson(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />
                                    </Stack>

                                    <Stack spacing={'32px'} direction="row" sx={{ marginTop: '16px' }} hidden={props.shipmentStatus !== 'F'}>
                                        <Input
                                            label='Market Code'
                                            value={props.marketingCode}
                                            formControlProps={{
                                                sx: {
                                                    // width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            disabled
                                            inputBaseProps={{
                                                readOnly: true
                                            }}
                                        />

                                        <Input
                                            label='Market Name'
                                            value={props.marketingName}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            disabled
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => searchMarketing('delete')}
                                                            hidden={props.marketingName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton onClick={() => searchMarketing('search')}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Stack>

                                    <Stack spacing={'32px'} direction="row" sx={{ marginTop: '16px' }} ref={creditTermRef}>
                                        <Input
                                            textAlign='right'
                                            label="Credit Term"
                                            value={props.creditTermInDays}
                                            onChange={(e) => props.setCreditTermInDays(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            inputBaseProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        Days
                                                    </InputAdornment>
                                                )
                                            }}
                                        />

                                        <Input
                                            textAlign='right'
                                            label="Credit Term IDR (Max)"
                                            value={props.creditTermInIDR}
                                            onChange={(e) => { props.setCreditTermInIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            inputBaseProps={{
                                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                            }}
                                        />

                                        <Input
                                            textAlign='right'
                                            label="Credit Term USD (Max)"
                                            value={props.creditTermInUSD}
                                            onChange={(e) => { props.setCreditTermInUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            inputBaseProps={{
                                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                            }}
                                        />
                                    </Stack>
                                </CardContent>
                            </Card>

                            <div
                                component="div"
                                style={{
                                    marginTop: '24px',
                                    borderRadius: '12px',
                                    borderColor: 'transparent',
                                    boxShadow: '0px !important',
                                    padding: '8px 42px',
                                }}
                                ref={addressTableRef}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    Address List
                                </Typography>

                                <TableContainer sx={{ maxHeight: 140, marginTop: '16px', background: '#FFF', borderRadius: '12px' }}>
                                    <Table sx={{ minWidth: 650 }} size='small' aria-label="a dense table" stickyHeader>
                                        <TableHead
                                            sx={{
                                                '& th:first-of-type': {
                                                    borderRadius: '12px 0 0'
                                                },
                                                '& th:last-child': {
                                                    borderRadius: '0 12px 0 0'
                                                }
                                            }}
                                        >
                                            <TableRow>
                                                <StyledTableCell>Main</StyledTableCell>
                                                <StyledTableCell>Type</StyledTableCell>
                                                <StyledTableCell>Address</StyledTableCell>
                                                <StyledTableCell>City</StyledTableCell>
                                                <StyledTableCell>Country</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.listAddress.length > 0 ?
                                                    props.listAddress.map((el) => {
                                                        return (
                                                            <TableRow
                                                                key={el.sequence}
                                                                onClick={() => setSelectedDetail(el)}
                                                                onDoubleClick={() => handleDoubleClick()}
                                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                hover={true}
                                                            >
                                                                <StyledTableBodyCell>{el.isDefault === true ? "Yes" : "No"}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.addressType}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.address}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.cityName}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.contactCountryName}</StyledTableBodyCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Primary-One, #083A50)',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: '12px',
                                                                    fontstyle: 'normal',
                                                                    fontWeight: '500',
                                                                    lineHeight: 'normal'
                                                                }}
                                                            >
                                                                Data Empty
                                                            </Typography>
                                                        </StyledTableBodyCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <br></br>
                                {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddAddress, ShowEditAddress, DeleteAddress)}
                            </div>

                            <Card
                                component="div"
                                style={{
                                    marginTop: '24px',
                                    borderRadius: '12px',
                                    backgroundColor: '#FAFAFA',
                                    padding: '8px 24px',
                                    border: '1px solid var(--Grey-Outline-2, #989898)',
                                }}
                                ref={contactTypeRef}
                            >
                                <CardContent>
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Contact Type
                                    </Typography>

                                    <FormGroup sx={{ marginTop: '16px' }}>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            {
                                                props.contactTypeData.map((element, index) => (
                                                    <Grid item xs={2} key={element.id} hidden={element.hidden}>
                                                        <TickBox
                                                            key={element.id}
                                                            size={'small'}
                                                            label={
                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                                        fontFamily: 'Nunito',
                                                                        fontSize: '12px !important',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '500',
                                                                        lineHeight: '20px',
                                                                    }}
                                                                >
                                                                    {element.name}
                                                                </Typography>
                                                            }
                                                            checked={props.listContactType.findIndex((el) => element.id === el.id) !== -1}
                                                            onChange={(e) => handleChangeContactType(element, e.target.checked)}
                                                        />
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </FormGroup>

                                    <div
                                        style={{
                                            marginTop: '24px',
                                            borderRadius: '12px',
                                            borderColor: 'transparent',
                                            boxShadow: '0px !important',
                                        }}
                                        hidden={!props.isAgent}
                                        ref={agentRef}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '19.6px',
                                            }}
                                        >
                                            Contact as Agent
                                        </Typography>

                                        <Stack spacing={'32px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                label='Group Code'
                                                value={props.groupCode}
                                                formControlProps={{
                                                    sx: {
                                                        // width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    readOnly: true
                                                }}
                                                disabled
                                            />

                                            <Input
                                                label='Group Name'
                                                value={props.groupName}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                                disabled
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchGroup('delete')}
                                                                hidden={props.groupName ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton onClick={() => searchGroup('search')}>
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>

                                        <TableContainer sx={{ maxHeight: 140, marginTop: '24px', background: '#FFF', borderRadius: '12px' }}>
                                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                                <TableHead
                                                    sx={{
                                                        '& th:first-of-type': {
                                                            borderRadius: '12px 0 0'
                                                        },
                                                        '& th:last-child': {
                                                            borderRadius: '0 12px 0 0'
                                                        }
                                                    }}
                                                >
                                                    <TableRow>
                                                        <StyledTableCell style={{ textAlign: 'center' }}>Name</StyledTableCell>
                                                        <StyledTableCell style={{ textAlign: 'center' }}>Action</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        props.listJobOwner.length > 0 ?
                                                            props.listJobOwner.map((el) => {
                                                                return (
                                                                    <TableRow key={el.sequence}>
                                                                        <StyledTableBodyCell>
                                                                            <Selection
                                                                                value={el.jobOwnerId}
                                                                                placeholder={'Select Principle'}
                                                                                formControlProps={{
                                                                                    sx: {
                                                                                        width: '100%',
                                                                                        padding: '12px 0px'
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => handleChangeJobOwner(e.target.value, el)}
                                                                            >
                                                                                {
                                                                                    props.jobOwnerData.map((data, index) => (
                                                                                        <MenuItem sx={{ fontSize: '12px' }} id={index} key={index} value={data.id}>
                                                                                            {data.masterCode}
                                                                                        </MenuItem>
                                                                                    ))
                                                                                }
                                                                            </Selection>
                                                                        </StyledTableBodyCell>

                                                                        <StyledTableBodyCell style={{ textAlign: 'center' }}>
                                                                            <IconButton onClick={() => handleDeleteJobOwner(el)}>
                                                                                <Icon
                                                                                    iconName={'delete'}
                                                                                    svgIconProps={{
                                                                                        sx: {
                                                                                            height: '24px',
                                                                                            width: '24px'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </StyledTableBodyCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                            :
                                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'var(--Primary-One, #083A50)',
                                                                            fontFamily: 'Nunito',
                                                                            fontSize: '12px',
                                                                            fontstyle: 'normal',
                                                                            fontWeight: '500',
                                                                            lineHeight: 'normal'
                                                                        }}
                                                                    >
                                                                        Data Empty
                                                                    </Typography>
                                                                </StyledTableBodyCell>
                                                            </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <div className='mt-3' style={{ margin: '0px 0px 0px -12px' }}>
                                            {ButtonComponent.AddButton(handleAddJobOwner, 'Principle')}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>

                            <div
                                component="div"
                                style={{
                                    marginTop: '24px',
                                    borderRadius: '12px',
                                    borderColor: 'transparent',
                                    boxShadow: '0px !important',
                                    padding: '8px 42px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    Bank Account
                                </Typography>

                                <TableContainer sx={{ maxHeight: 140, marginTop: '16px', background: '#FFF', borderRadius: '12px' }}>
                                    <Table sx={{ minWidth: 650 }} stickyHeader>
                                        <TableHead
                                            sx={{
                                                '& th:first-of-type': {
                                                    borderRadius: '12px 0 0'
                                                },
                                                '& th:last-child': {
                                                    borderRadius: '0 12px 0 0'
                                                }
                                            }}
                                        >
                                            <TableRow>
                                                <StyledTableCell>Account No</StyledTableCell>
                                                <StyledTableCell>Account Bank Name</StyledTableCell>
                                                <StyledTableCell>Account Name</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                props.listBankAccount.length > 0 ?
                                                    props.listBankAccount.map((el) => {
                                                        return (
                                                            <TableRow
                                                                key={el.accNo}
                                                                onClick={() => setSelectedDetailBank(el)}
                                                                sx={selectedDetailBank.accNo === el.accNo ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                            >
                                                                <StyledTableBodyCell>{el.accNo}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.bankName}</StyledTableBodyCell>
                                                                <StyledTableBodyCell>{el.accName}</StyledTableBodyCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Primary-One, #083A50)',
                                                                    fontFamily: 'Nunito',
                                                                    fontSize: '12px',
                                                                    fontstyle: 'normal',
                                                                    fontWeight: '500',
                                                                    lineHeight: 'normal'
                                                                }}
                                                            >
                                                                Data Empty
                                                            </Typography>
                                                        </StyledTableBodyCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <br></br>
                                {CustomizeButtonComponent.AddDeleteTabsButton(ShowAddBankAccount, DeleteBankAccount)}
                            </div>
                        </form>
                    </>
                    :
                    <>
                        <Input
                            label='Contact Name'
                            value={props.contactName}
                            onChange={(e) => props.setContactName(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '12px',
                                    padding: '0px 12px'
                                }
                            }}
                        />
                    </>
            }
        </>
    )

    const buttonComponent = (
        <div style={{ padding: '6px 32px 12px' }}>
            {
                props.isSearch === true ?
                    <>
                        {CustomizeButtonComponent.SaveCancelModalButton(Save, false, false)}
                    </>
                    :
                    <>
                        <Box display="flex" alignItems="center" className='mt-2 text-end' justifyContent={'flex-end'}>
                            <Stack spacing={0} direction="row" className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                {ButtonComponent.FindButton((() => searchContact('search')), 'Search', true)}
                            </Stack>
                        </Box>
                    </>
            }
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal + ' ' + props.title}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={props.isSearch === true ? 'lg' : 'sm'}
                fullWidth={true}
                topButton={false}
                actions={buttonComponent}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}
                </div>

                {
                    isLoadingBackDrop === true && (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoadingBackDrop}
                        >
                            <LoadingSpinner />
                        </Backdrop>
                    )
                }
            </PopUp>

            {
                isModalContact && (
                    <ModalContact
                        isModal={isModalContact}
                        handleClick={handleClickContact}
                        titleModal={titleModalContact}
                        setIsModal={setIsModalContact}

                        isLoading={isLoadingContact}
                        setIsLoading={setIsLoadingContact}
                        data={dataContact}
                        dataMap={dataMapContact}
                        dataTable={dataTableContact}
                        columnData={columnDataContact}
                        SelectedData={SelectedDataContact}
                        totalRows={totalRowsContact}
                        setSelectedData={setSelectedDataContact}
                        getData={getDataContact}
                        filter={filterContact}
                        setFilter={setFilterContact}
                        filterBy={filterByContact}
                        setFilterBy={setFilterByContact}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}
                        setIsNewReg={props.setIsNewReg}

                        setId={props.setId}
                        setIsSearch={props.setIsSearch}
                        contactName={props.contactName}
                        setContactId={props.setContactId}
                        setContactCode={props.setCode}
                        setContactName={props.setContactName}
                        setContactPerson={props.setContactPerson}
                        setContactNIK={props.setContactNIK}
                        setContactPhone={props.setContactPhone}
                        setContactFax={props.setContactFax}
                        setContactEmail={props.setContactEmail}
                        setContactNPWP={props.setContactNPWP}
                        setContactNPPKP={props.setContactNPPKP}
                        setCreditTermInDays={props.setCreditTermInDays}
                        setCreditTermInIDR={props.setCreditTermInIDR}
                        setCreditTermInUSD={props.setCreditTermInUSD}
                        setMarketId={props.setMarketId}
                        setMarketingCode={props.setMarketingCode}
                        setMarketingName={props.setMarketingName}
                        setGroupId={props.setGroupId}
                        setGroupCode={props.setGroupCode}
                        setGroupName={props.setGroupName}
                        setShipmentStatus={props.setShipmentStatus}
                        setListContactType={props.setListContactType}
                        setListAddress={props.setListAddress}
                        setListJobOwner={props.setListJobOwner}
                        setContactType={props.setContactType}
                        setListBankAccount={props.setListBankAccount}
                    />
                )
            }

            {
                isModalMarketing && (
                    <ModalMarketing
                        isModal={isModalMarketing}
                        handleClick={handleClickMarketing}
                        titleModal={titleModalMarketing}
                        setIsModal={setIsModalMarketing}

                        isLoading={isLoadingMarketing}
                        data={dataMarketing}
                        dataMap={dataMapMarketing}
                        dataTable={dataTableMarketing}
                        columnData={columnDataMarketing}
                        SelectedData={SelectedDataMarketing}
                        totalRows={totalRowsMarketing}
                        setSelectedData={setSelectedDataMarketing}
                        getData={getDataMarketing}

                        setMarketId={props.setMarketId}
                        setMarketingCode={props.setMarketingCode}
                        setMarketingName={props.setMarketingName}
                    />
                )
            }

            {
                isModalGroup && (
                    <SelectGroup
                        isModal={isModalGroup}
                        handleClick={handleClickGroup}
                        titleModal={titleModalGroup}
                        setIsModal={setIsModalGroup}

                        isLoading={isLoadingGroup}
                        data={dataGroup}
                        dataMap={dataMapGroup}
                        dataTable={dataTableGroup}
                        columnData={columnDataGroup}
                        SelectedData={SelectedDataGroup}
                        totalRows={totalRowsGroup}
                        setSelectedData={setSelectedDataGroup}
                        getData={getDataGroup}

                        setGroupId={props.setGroupId}
                        setGroupCode={props.setGroupCode}
                        setGroupName={props.setGroupName}
                    />
                )
            }

            {
                isModal && (
                    <ModalMultiAddress
                        contactId={props.contactId}
                        isModal={isModal}
                        handleClick={handleClickAddress}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}

                        listAddress={props.listAddress}
                        setListAddress={props.setListAddress}

                        CityId={CityId}
                        setCityId={setCityId}
                        CityCode={CityCode}
                        setCityCode={setCityCode}
                        CityName={CityName}
                        setCityName={setCityName}
                        CountryId={CountryId}
                        setCountryId={setCountryId}
                        CountryName={CountryName}
                        setCountryName={setCountryName}
                        ContactAddress={ContactAddress}
                        setContactAddress={setContactAddress}
                        AddressType={AddressType}
                        setAddressType={setAddressType}
                        isMainAddress={isMainAddress}
                        setIsMainAddress={setIsMainAddress}
                        isOversea={isOversea}
                        setIsOversea={setIsOversea}
                        PostalCode={PostalCode}
                        setPostalCode={setPostalCode}
                        isNotDefault={isNotDefault}
                        setIsNotDefault={setIsNotDefault}
                    />
                )
            }

            {
                isModalBank && (
                    <ModalBankAccount
                        contactId={props.contactId}
                        isModal={isModalBank}
                        handleClick={handleClickBankAccount}
                        titleModal={titleModalBank}
                        selectedDetail={selectedDetailBank}
                        setSelectedDetail={setSelectedDetailBank}

                        listBankAccount={props.listBankAccount}
                        setListBankAccount={props.setListBankAccount}

                        AccountNo={AccountNo}
                        setAccountNo={setAccountNo}
                        AccountBankName={AccountBankName}
                        setAccountBankName={setAccountBankName}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                    />
                )
            }
        </>
    )
}

export default CreateEditRegContact