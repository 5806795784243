import { Grid, Stack, IconButton, MenuItem, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import SelectDataCity from '../Selects/SelectDataCity';
import ApiService, { formatCurrency, isInfossNo } from '../../../../../services/ApiService';
import SelectDataContact from '../Selects/SelectDataContact';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../../components/ui';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { handleDataTable } from '../../../../../utils/reusableFunction';

function NPWPMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            mask={[
                /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
                ".",
                /\d/,
                "-",
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
            ]}
            keepCharPositions
        />
    )
}

NPWPMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const GeneralInvoiceHeader = (props) => {
    const npwpRegex = /^\d{2}\.\d{3}\.\d{3}\.\d-\d{3}\.\d{3}$/;
    const [isValidNPWP, setIsValidNPWP] = useState(false);

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramContact = "regContact/regContact";
    const paramCompany = "company/company";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([])
    const [dataTableC, setDataTableC] = useState([])
    const [columnDataC, setColumnDataC] = useState([])
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    useEffect(() => {
        setIsValidNPWP(npwpRegex.test(props.NPWP));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.NPWP]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCustomer = (data) => {
        if (data === 'search') {
            if (props.PrincipleBy !== 0) {
                setTitleModal('Customer');
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By First!' });
            }
        }
        else if (data === 'delete') {
            props.setCustomerId(0);
            props.setCustomerCode('');
            props.setCustomerName('');
        }
    }

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const paramI = "city/city";
    const [isLoadingI, setIsLoadingI] = useState(false);
    const [dataI, setDataI] = useState([]);
    const [dataMapI, setDataMapI] = useState([])
    const [dataTableI, setDataTableI] = useState([])
    const [columnDataI, setColumnDataI] = useState([])
    const [SelectedDataI, setSelectedDataI] = useState({});
    const [totalRowsI, setTotalRowsI] = useState(50);

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataCity(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const searchCity = (data) => {
        if (data === 'search') {
            setTitleModal2('City');
            handleClick2();
        }
        else if (data === 'delete') {
            props.setCityId(0);
            props.setCityInt('');
            props.setCityName('');
        }
    }

    const GeneralInvoiceToData = [
        { id: 1, code: 0, value: 'G', name: 'General' },
        { id: 2, code: 2, value: 'S', name: 'Shipper' },
        { id: 3, code: 3, value: 'C', name: 'Consignee' },
        { id: 4, code: 1, value: 'A', name: 'Agent' },
        { id: 5, code: 20, value: 'Y', name: 'Company' },
    ]

    const PaymentTypeData = [
        { id: 1, code: 'D', name: 'Debit Note' },
        { id: 2, code: 'C', name: 'Credit Note' },
    ]

    const IncludeFaktur = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ]

    const stampDutyList = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ]

    const handleChangeNPWP = (value) => {
        setIsValidNPWP(npwpRegex.test(value));
        props.setNPWP(value);
    };

    function handleGeneralInvoiceTo(val) {
        props.setGeneralTo(val);
        GeneralInvoiceToData.forEach(element => {
            if (val === element.value) {
                props.setGeneralToId(element.code);
            }
        });

        if (val === 'G') {
            props.handleInput(props.isEdit, true);
        }
        else {
            props.handleInput(props.isEdit, false);
        }
    }

    function handleIncludeFakturPajak(val) {
        let vatId = 0;
        props.setIsUseFakturPajak(val);
        if (val === false) {
            vatId = 0;
            props.setIsInclude(true);
            props.setNPWP('');
            props.setAlamatNPWP('');
            props.setSFPNoFormat('');
        }
        else {
            vatId = 3;
            props.setIsInclude(false);
        }

        props.setVATId(vatId);

        let vatPercent = 0;
        let newArray = props.GeneralInvoiceList;

        if (newArray.length > 0) {
            if (vatId !== 0) {
                props.VATData.forEach(element => {
                    if (vatId === element.id) {
                        vatPercent = element.value;
                    }
                });
            }

            newArray.forEach(element => {
                element.vatId = vatId;
                element.amountVatUSD = parseFloat(element.amountUSD) * parseFloat(vatPercent) / parseFloat(100);
                element.amountVatIDR = parseFloat(element.amountIDR) * parseFloat(vatPercent) / parseFloat(100);
            });

            props.setGeneralInvoiceList(newArray);
            props.calculateTotal(newArray, props.RateCount);
        }
    }

    function handleVAT(val) {
        let vatPercent = 0;
        let newArray = props.GeneralInvoiceList;
        props.setVATId(val);

        if (newArray.length > 0) {
            props.VATData.forEach(element => {
                if (val === element.id) {
                    vatPercent = element.value;
                }
            });

            newArray.forEach(element => {
                element.vatId = val;
                element.amountVatUSD = parseFloat(element.amountUSD) * parseFloat(vatPercent) / parseFloat(100);
                element.amountVatIDR = parseFloat(element.amountIDR) * parseFloat(vatPercent) / parseFloat(100);
            });

            props.setGeneralInvoiceList(newArray);
            props.calculateTotal(newArray, props.RateCount);
        }
    }

    function handleStampDuty(val) {
        props.setIsStampDuty(val);

        if (val === false) {
            props.setStampDutyAmount(0);
        }
        else {
            props.setStampDutyAmount(10000);
        }
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        let type = 0;
        setIsLoadingC(true);
        type = props.GeneralToId;
        if (type !== 20) {
            ApiService.ContactPostByPage(paramContact, type, pageNumber, pageSize, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.contact);

                        let temp = response.data.data.contact
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                        setColumnDataC(response.data.data.columns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else {
            ApiService.PostByPage(paramCompany, pageNumber, pageSize, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.company);

                        let temp = response.data.data.company
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                        setColumnDataC(response.data.data.columns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    const getDataCity = (pageNumber, pageSize, filters) => {
        setIsLoadingI(true);
        ApiService.DynamicPostByPage(paramI, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataI({})
                    setDataI(response.data.data.city);

                    let temp = response.data.data.city
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapI(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableI, 'id');
                    setColumnDataI(response.data.data.columns)
                    setTotalRowsI(response.data.totalRowCount)

                    setIsLoadingI(false)
                }
                setIsLoadingI(false)
            })
            .catch(function (error) {
                setIsLoadingI(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <div ref={props.infossNoRef}>
                                    <Input
                                        label={'Infoss Number'}
                                        value={props.infossNo}
                                        onChange={(e) => props.setInfossNo(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginBottom: '16px !important'
                                            }
                                        }}
                                        disabled={props.printing > 0}
                                        hidden={!isInfossNo}
                                        message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={8}
                                lg={8}
                                sm={8}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label="GI Number"
                                            value={props.GINumber}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <div>
                                            {props.selectPrincipleBy}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Reference'
                                            value={props.Reference}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Internal Ref.'
                                            value={props.InternalRef}
                                            onChange={(e) => props.setInternalRef(e.target.value)}
                                            fullWidth
                                            disabled={props.printing > 0 ? true : false}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Rate'
                                            value={props.RateCount}
                                            textAlign='right'
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Due Date'
                                            // value={props.DueDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <CustomDatePicker
                                            label='Rate Date'
                                            value={props.RateDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    // marginTop: '16px !important'
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Print Date'
                                            value={props.PrintDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'General Invoice To'}
                                    fullWidth={true}
                                    placeholder={'Select General Invoice To'}
                                    value={props.GeneralTo}
                                    onChange={(e) => handleGeneralInvoiceTo(e.target.value)}
                                    disabled={props.isEdit}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        GeneralInvoiceToData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Customer'
                                        value={props.CustomerCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.CustomerName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchCustomer('delete')}
                                                        disabled={props.isNotGeneral}
                                                        hidden={props.printing > 0 ? true : props.CustomerName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        hidden={props.printing > 0 ? true : !props.isNotGeneral && !props.isJobOwner ? false : true}
                                                        onClick={() => searchCustomer('search')}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='City'
                                        value={props.CityInt}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.CityName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchCity('delete')}
                                                        disabled={props.isNotGeneral}
                                                        hidden={props.printing > 0 ? true : props.CityName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        hidden={props.printing > 0 ? true : props.isNotGeneral}
                                                        onClick={() => searchCity('search')}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label={'Address'}
                                    value={props.Address}
                                    onChange={(e) => props.setAddress(e.target.value)}
                                    disabled={props.isGeneral}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 9,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'DC Note'}
                                    fullWidth={true}
                                    placeholder={'Select DC Note'}
                                    value={props.StatusDC}
                                    disabled={props.printing > 0 ? true : false}
                                    onChange={(e) => props.setStatusDC(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        PaymentTypeData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Selection
                                            id='0'
                                            label={'Include Stamp Duty'}
                                            fullWidth={true}
                                            placeholder={'Select Include Stamp Duty'}
                                            value={props.isStampDuty}
                                            disabled={props.printing > 0 ? true : false}
                                            onChange={(e) => handleStampDuty(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        >
                                            {
                                                stampDutyList.map((data, index) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Input
                                            label="Stamp Duty Amount"
                                            value={props.stampDutyAmount}
                                            onChange={(e) => props.setStampDutyAmount(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                            textAlign='right'
                                            disabled={!props.isStampDuty}
                                            hidden={props.printing > 0 ? true : !props.isStampDuty}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                            inputBaseProps={{
                                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <div>
                            <div>
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: 'normal',
                                        marginTop: '16px'
                                    }}
                                >
                                    Faktur Pajak
                                </Typography>
                            </div>

                            <Grid
                                container
                                spacing={'32px'}
                            >
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <Selection
                                        id='0'
                                        label={'Include Faktur Pajak'}
                                        fullWidth={true}
                                        placeholder={'Select Include Faktur Pajak'}
                                        value={props.IsUseFakturPajak}
                                        disabled={props.printing > 0 ? true : false}
                                        onChange={(e) => handleIncludeFakturPajak(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                marginTop: '8px !important'
                                            }
                                        }}
                                    >
                                        {
                                            IncludeFaktur.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Selection>

                                    <Input
                                        label={'NPWP'}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        disabled={props.printing > 0 ? true : props.isInclude}
                                        inputBaseProps={{
                                            inputComponent: NPWPMask,
                                            value: props.NPWP,
                                            onChange: (e) => {
                                                let text = e.target.value
                                                if (text) { text = text.toUpperCase() }
                                                handleChangeNPWP(text)
                                            }
                                        }}
                                        message={props.NPWP === '' || isValidNPWP ? '' : 'NPWP Format is Wrong'}
                                    />

                                    <Input
                                        label='Reference E-Faktur'
                                        value={props.SFPNoFormat}
                                        onChange={(e) => props.setSFPNoFormat(e.target.value)}
                                        disabled={props.printing > 0 ? true : props.isInclude}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <Selection
                                        id='0'
                                        label={'Type Pajak'}
                                        fullWidth={true}
                                        placeholder={'Select Type Pajak'}
                                        value={props.VATId}
                                        onChange={(e) => handleVAT(e.target.value)}
                                        disabled={props.printing > 0 ? true : props.isInclude}
                                        formControlProps={{
                                            sx: {
                                                marginTop: '8px !important'
                                            }
                                        }}
                                    >
                                        {
                                            props.VATData.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.value}%</MenuItem>
                                            ))
                                        }
                                    </Selection>

                                    <Input
                                        label={'Alamat NPWP'}
                                        value={props.AlamatNPWP}
                                        onChange={(e) => props.setAlamatNPWP(e.target.value)}
                                        disabled={props.printing > 0 ? true : props.isInclude}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            rows: 5,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        type={props.GeneralToId}

                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}

                        setCustomerId={props.setCustomerId}
                        setCustomerCode={props.setCustomerCode}
                        setCustomerName={props.setCustomerName}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataCity
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}

                        getData={getDataCity}
                        isLoading={isLoadingI}
                        totalRows={totalRowsI}
                        setTotalRows={setTotalRowsI}
                        SelectedData={SelectedDataI}
                        setSelectedData={setSelectedDataI}
                        data={dataI}
                        columnData={columnDataI}
                        dataMap={dataMapI}
                        dataTable={dataTableI}

                        setCityId={props.setCityId}
                        setCityInt={props.setCityInt}
                        setCityName={props.setCityName}
                    />
                )
            }
        </div>
    )
}

export default GeneralInvoiceHeader;