import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import TableZ from '../../../../components/Tables/TableZ';
import ApiService, { isListAsc } from '../../../../services/ApiService';
import ButtonListReceiptPPh23 from './Component/ButtonListReceiptPPh23';
import { forwardRef } from 'react';
import Backdrop from '@mui/material/Backdrop';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks.jsx';
import TableCheck from '../../../../components/Tables/TableCheck.jsx';
import ModalPrintRekap from './Modal/ModalPrintRekap.jsx';
import ModalRePostGL from '../../../../components/Modals/ModalRePostGL.jsx';
import { handleFailedGL, handleRePost } from '../../../../utils/constantVariable.jsx';
import { handleDataTable } from '../../../../utils/reusableFunction.jsx';

const ListReceiptPPh23 = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate()
  const param = 'officialReceiptPph23/officialReceiptPph23';
  const paramGL = 'generalLedger/generalLedger';
  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [rowsCount, setRowsCount] = useState(50)
  const [numPage, setNumPage] = useState(isListAsc === true? 0 : 1)

  const [dataMap, setDataMap] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [columnData, setColumnData] = useState([])
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50)
  const [filter, setFilter] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [accessLog, setAccessLog] = useState(false);
  const [dataSelected, setDataSelected] = useState({});

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState("");

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');

  const [dropDownTitle, setDropDownTitle] = useState('All');

  const [isApprove, setIsApprove] = useState(false);
  const [isUnApprove, setIsUnApprove] = useState(false);
  const [dataCheck, setDataCheck] = useState([]);

  const [isModalPrintRekap, setIsModalPrintRekap] = useState(false);

  const [isModalRePost, setIsModalRePost] = useState(false);
  const [failedData, setFailedData] = useState({});

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Receipt PPh 23', link: '/Majura/OfficialReceiptPph23', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    localStorage.setItem('isUnApprove', JSON.stringify(false));
    localStorage.setItem('isApprove', JSON.stringify(false));
    document.title = 'Receipt PPh23 - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters, orderBy, data) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let check = 0;
    let params = [];
    let filter = [];

    if (JSON.parse(localStorage.getItem('isApprove')) === true) {
      check = 1;
      params = [
        ...params,
        {
          "attr": "isApprovedAcc",
          "value": "false",
        }
      ]
      filter = [
        ...filters,
        { 'field': 'rowStatus', 'data': 'ACT' },
      ];
    }
    else if (JSON.parse(localStorage.getItem('isUnApprove')) === true) {
      check = 1;
      params = [
        ...params,
        {
          "attr": "isApprovedAcc",
          "value": "true",
        }
      ]
      filter = [
        ...filters,
        { 'field': 'rowStatus', 'data': 'ACT' },
      ];
    }
    else {
      check = 0;
    }

    if (check === 0) {
      if (data) {
        if (data === 'All') {
          filter = [...filters]
        }
        else if (data === 'Deleted') {
          filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
        }
        else if (data === 'Active') {
          filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
        }
      }
      else {
        if (dropDownTitle === 'All') {
          filter = [...filters]
        }
        else if (dropDownTitle === 'Deleted') {
          filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
        }
        else if (dropDownTitle === 'Active') {
          filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
        }
      }
    }

    ApiService.ListFinAccPostByPage(param, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.officialReceiptPph23
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (pageNumber === 0) {
            setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
          }

          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' })
          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }

        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' + error });
      })
  }

  const getDataAccess = () => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FORP')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data.userAccess;
            setDataRegUserAccess(response.data.data.userAccess);

            data.forEach(element => {
              if (element.accessCode === 'LOG') {
                setAccessLog(true);
              }
            });

            getData(isListAsc === true? 0 : 1, 50, []);
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message })
            if (isLoadingBackDrop === false) {
              setIsLoading(false);
            }
            else {
              setIsLoadingBackDrop(false);
            }
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          history('/Majura');
        })
    }
    else {
      history('/Majura');
    }
  }

  const emptyState = () => {
    setNumPage(isListAsc === true? 0 : 1);
    setRowsCount(50);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setGoTo(1);
    setSortArr([]);
    setStateX({});
  };

  const handleReset = () => {
    setFilter([])
    setFilterBy([])
    emptyState();
    getData(isListAsc === true? 0 : 1, 50, []);
  }

  const handleClickDelete = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
      setDataSelected(SelectedData);
    } else {
      setIsModalDelete(false);
    }
  };

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
      setDataSelected(SelectedData);
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationReceiptPPH23() {
    if (titleConfirmation === 'Approve') {
      Approve();
    }
    else if (titleConfirmation === 'Un Approve') {
      UnApprove();
    }
    setIsModalConfirmation(false);
  }

  const Add = () => {
    window.open('/Majura/officialReceiptPph23/Create', newWindowConfig);
  }

  const Edit = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      window.open('/Majura/officialReceiptPph23/' + SelectedData.id, newWindowConfig);
    }
  }

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' })
    setFilter([])
    emptyState();
    getData(isListAsc === true? 0 : 1, 50, []);
  }

  const DeleteData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      if (SelectedData.rowStatus === 'ACT') {
        setTitleModalDelete("Receipt PPh 23 PPh23");
        handleClickDelete();
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted' })
      }
    }
  }

  const DeletePPH = () => {
    ApiService.FinAccDelete(param, dataSelected.id).then((res) => {
      if (res.data.code === 200) {
        getData(numPage, rowsCount, filter);
        showToast({ type: 'success', message: 'Delete Data Success' })
        setIsModalDelete(false);
      } else {
        showToast({ type: 'error', message: 'Delete Data Failed' })
        setIsModalDelete(false);
      }
    });
  };

  const handleAdjustment = () => {
    window.open('/Majura/officialReceiptPph23/Adjustment', newWindowConfig);
  }

  const handleClickPrintRekap = () => {
    if (isModalPrintRekap === false) {
      setIsModalPrintRekap(true);
    }
    else {
      setIsModalPrintRekap(false);
    }
  }

  const PrintData = () => {
    handleClickPrintRekap();
  }

  // const CreateGL = (id) => {
  //   var data = {
  //     "generalLedger": {
  //       "source": "PPH",
  //       "refId": id,
  //       "methodPost": "AUTO",
  //       "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
  //     }
  //   }

  //   ApiService.CreateFinAcc(paramGL, data).then((res) => {
  //     let message = '';
  //     message = res.data.message;
  //     if (res.data.code !== 200) {
  //       showToast({ type: 'error', message: message });
  //     }
  //   });
  // }

  const handleApprove = () => {
    if (isApprove === false) {
      document.title = 'Approve Receipt PPh 23 - ' + JSON.parse(localStorage.getItem('branchName'));
      localStorage.setItem('isApprove', JSON.stringify(true));
      setIsApprove(true);
      setIsUnApprove(false);
    }
    else {
      document.title = 'Receipt PPh 23 - ' + JSON.parse(localStorage.getItem('branchName'));
      localStorage.setItem('isApprove', JSON.stringify(false));
      setIsApprove(false);
      setIsUnApprove(false);
    }

    handleReset();
  }

  const ApproveData = () => {
    if (dataCheck.length <= 0) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      setTitleConfirmation('Approve')
      setTitleModalConfirmation('Receipt PPh 23');
      handleClickConfirmation();
    }
  }

  const Approve = () => {
    let data = [];
    setIsLoadingBackDrop(true);
    const uniqueDataCheck = dataCheck.filter((item, index, self) =>
      index === self.findIndex((t) => t.id === item.id)
    );

    const sortedUniqueDataCheck = uniqueDataCheck.sort((a, b) => {
      const dateA = new Date(a.printedOn);
      const dateB = new Date(b.printedOn);

      return dateA - dateB;
    });

    sortedUniqueDataCheck.forEach(el => {
      if (el.id === sortedUniqueDataCheck[sortedUniqueDataCheck.length - 1].id) {
        data = data + 'PPH:' + el.id;
      }
      else {
        data = data + 'PPH:' + el.id + ',';
      }
    });

    const payload = { "generalLedger": { "processedData": data } };
    ApiService.FinAccCustomPost(paramGL + '/MultiVerify', payload)
      .then((res) => {
        if (res.data.code === 200) {
          showToast({ type: 'success', message: 'Approve Receipt PPh23 Success!' });

          setTimeout(
            function () {
              setDataCheck([]);
              setIsLoadingBackDrop(false);
              getData(numPage, rowsCount, filter);
            },
            1250);
        }
        else {
          setDataCheck([]);
          setIsLoadingBackDrop(false);
          getData(numPage, rowsCount, filter);
          showToast({ type: 'error', message: res.data.message });
        }
      })
      .catch((err) => {
        setDataCheck([]);
        setIsLoadingBackDrop(false);
        getData(numPage, rowsCount, filter);
        showToast({ type: 'error', message: 'Can\'t Approve Receipt PPh23!, ' + err });
      })
  }

  const handleUnApprove = () => {
    if (isUnApprove === false) {
      document.title = 'Un Approve Receipt PPh 23 - ' + JSON.parse(localStorage.getItem('branchName'));
      localStorage.setItem('isUnApprove', JSON.stringify(true));
      setIsUnApprove(true);
      setIsApprove(false);
    }
    else {
      document.title = 'Receipt PPh 23 - ' + JSON.parse(localStorage.getItem('branchName'));
      localStorage.setItem('isUnApprove', JSON.stringify(false));
      setIsUnApprove(false);
      setIsApprove(false);
    }

    handleReset();
  }

  const UnApproveData = () => {
    if (dataCheck.length <= 0) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      setTitleConfirmation('Un Approve')
      setTitleModalConfirmation('Receipt PPh 23');
      handleClickConfirmation();
    }
  }

  const UnApprove = () => {
    let check = 0;
    setIsLoadingBackDrop(true);

    const uniqueDataCheck = dataCheck.filter((item, index, self) =>
      index === self.findIndex((t) => t.id === item.id)
    );

    const sortedUniqueDataCheck = uniqueDataCheck.sort((a, b) => {
      const dateA = new Date(a.printedOn);
      const dateB = new Date(b.printedOn);

      return dateA - dateB;
    });

    sortedUniqueDataCheck.forEach(element => {
      if (check === 0) {
        ApiService.FinAccUpdateStatusUnApprovedAcc(param, element.id)
          .then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
              // CreateGL(res.data.data.ContraId);

              if (check === 0 && sortedUniqueDataCheck[sortedUniqueDataCheck.length - 1].id === element.id) {
                setDataCheck([]);
                setIsLoadingBackDrop(false);
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Un-Approve Receipt PPh 23 Success!' });
              }
            }
            else {
              check = 1;
              setDataCheck([]);
              setIsLoadingBackDrop(false);
              getData(numPage, rowsCount, filter);
              showToast({ type: 'error', message: message });
            }
          })
          .catch(error => {
            check = 1;
            setDataCheck([]);
            setIsLoadingBackDrop(false);
            getData(numPage, rowsCount, filter);
            showToast({ type: 'error', message: 'Can\'t Un-Approve Receipt PPh 23!' });
          });
      }
    });
  }

  function CellDoubleClick(row) {
    Edit()
  }

  function handleStatus(data) {
    setDropDownTitle(data);
    getData(numPage, rowsCount, filter, sortArr, data);
  }

  const handleClickRePost = () => {
    if (isModalRePost === false) {
      setIsModalRePost(true);
    }
    else {
      setIsModalRePost(false);
    }
  }

  const handleGL = (data) => {
    let selectedData = dataMap.find((el) => el.id === data.id);
    handleFailedGL({
      id: selectedData.id,
      glStatusCode: selectedData.glStatusCode,
      setIsLoadingBackDrop: setIsLoadingBackDrop,
      paramGL: paramGL,
      setFailedData: setFailedData,
      handleClickRePost: handleClickRePost,
      source: 'PPH',
    });
  }

  const handlePost = () => {
    handleRePost({
      handleClickRePost: handleClickRePost,
      setIsLoadingBackDrop: setIsLoadingBackDrop,
      failedData: failedData,
      paramGL: paramGL,
      getData: getData,
      numPage: numPage,
      rowsCount: rowsCount,
      filter: filter,
    })
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(isListAsc === true? 0 : 1);
    setRowsCount(value);
    getData(isListAsc === true? 0 : 1, value, filter);
  }

  const ButtonComponents = () => {
    return (
      <ButtonListReceiptPPh23
        Add={Add}
        Edit={Edit}
        ReloadData={ReloadData}
        DeleteData={DeleteData}
        PrintData={PrintData}
        Approve={ApproveData}
        UnApprove={UnApproveData}
        Adjustment={handleAdjustment}
        DataRegUserAccess={DataRegUserAccess}
        HandleStatus={handleStatus}
        DropDownTitle={dropDownTitle}
        isApprove={isApprove}
        handleApprove={handleApprove}
        isUnApprove={isUnApprove}
        handleUnApprove={handleUnApprove}
      />
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
      <CustomToastContainer />

      <Grid container spacing={0} direction="column">
        <HeaderInfo btnPageRef={btnPageRef}
          title={!isApprove && !isUnApprove ? 'Receipt PPh 23' : isApprove ? 'Approve Receipt PPh 23' : 'Un Approve Receipt PPh 23'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div ref={btnTableRef}>
            {
              ButtonComponent.RefreshButton(
                sortArr,
                rowsCount,
                columnData,
                setGoTo,
                handleReset,
                setFilterBy,
                filterBy,
                setFilter,
                setNumPage,
                setRowsCount,
                getData,
                stateX,
                setStateX
              )
            }
          </div>

          <div
            className="rounded-10 table-responsive"
            style={{
              maxHeight: `calc(100vh - ${getContainerHeight()})`,
              marginTop: '16px',
              padding: '2px 16px',
              borderRadius: '12px',
              background: '#F2F2F2'
            }}
          >
            {
              isLoading ?
                <LoadingSpinner />
                :
                <>
                  <div className='mt-1'>
                    {
                      !isApprove && !isUnApprove ?
                        <>
                          {
                            accessLog ?
                              <TableZ
                                rowsCount={rowsCount}
                                source={'TR'}
                                isLoadingBackDrop={isLoadingBackDrop}
                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                setNumPage={setNumPage}
                                setRowsCount={setRowsCount}
                                getData={getData}
                                columnData={columnData}
                                dataMap={dataMap}
                                setDataMap={setDataMap}
                                SelectedData={SelectedData}
                                setSelectedData={setSelectedData}
                                setFilter={setFilter}
                                setFilterBy={setFilterBy}
                                CellDoubleClick={CellDoubleClick}
                                localVar={'finAccTemp'}
                                stateX={stateX}
                                setStateX={setStateX}
                                sortArr={sortArr}
                                setSortArr={setSortArr}
                                goTo={goTo}
                                setGoTo={setGoTo}
                                handleFailedGL={handleGL}
                              />
                              :
                              <TableX
                                uniqueKey={'id'}
                                goTo={goTo}
                                rowsCount={rowsCount}
                                setNumPage={setNumPage}
                                setRowsCount={setRowsCount}
                                getData={getData}
                                columnData={columnData}
                                dataMap={dataMap}
                                dataTable={dataTable}
                                SelectedData={SelectedData}
                                setSelectedData={setSelectedData}
                                setFilter={setFilter}
                                setFilterBy={setFilterBy}
                                CellDoubleClick={CellDoubleClick}
                                stateX={stateX}
                                setStateX={setStateX}
                                sortArr={sortArr}
                                setSortArr={setSortArr}
                                setGoTo={setGoTo}
                                handleFailedGL={handleGL}
                              />
                          }
                        </>
                        :
                        <TableCheck
                          uniqueKey={'id'}
                          rowsCount={rowsCount}
                          setNumPage={setNumPage}
                          setRowsCount={setRowsCount}
                          getData={getData}
                          columnData={columnData}
                          dataMap={dataMap}
                          SelectedData={SelectedData}
                          setSelectedData={setSelectedData}
                          setFilter={setFilter}
                          setFilterBy={setFilterBy}
                          dataCheck={dataCheck}
                          setDataCheck={setDataCheck}
                          stateX={stateX}
                          setStateX={setStateX}
                          sortArr={sortArr}
                          setSortArr={setSortArr}
                          setGoTo={setGoTo}
                        />
                    }
                  </div>
                </>
            }
          </div>

          <div style={{ marginTop: '16px' }}>
            <Paginate
              page={numPage}
              limit={rowsCount}
              totalData={totalRows}
              onChange={onChangePaging}
              onChangeLimit={onChangeLimit}
            />
          </div>
        </Grid>
      </Grid>

      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClickDelete}
            DeleteData={DeletePPH}
          />
        )
      }

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationReceiptPPH23}
          />
        )
      }

      {
        isModalPrintRekap && (
          <ModalPrintRekap
            isModal={isModalPrintRekap}
            handleClick={handleClickPrintRekap}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {
        isModalRePost && (
          <ModalRePostGL
            isModal={isModalRePost}
            handleRePost={handlePost}
            transactionData={failedData}
            handleClick={handleClickRePost}
          />
        )
      }
    </Box>
  )
})

export default ListReceiptPPh23