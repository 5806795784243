import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { CustomDatePicker, Icon, PopUp } from '../../../../../components/ui';
import { updateDateFormat } from '../../../../../utils/date';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ModalDatePick = (props) => {
    const [periodFrom, setPeriodFrom] = useState(new Date());
    const [periodTo, setPeriodTo] = useState(new Date());

    useEffect(() => {
        getDefaultMonth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDefaultMonth = () => {
        let date = new Date();
        setPeriodFrom(updateDateFormat(date));
        setPeriodTo(updateDateFormat(date));
    }

    const handleSetPeriod = (value, name) => {
        if (name === 'From') {
            setPeriodFrom(value);
        } else {
            setPeriodTo(value);
        }
    }

    const Select = () => {
        localStorage.setItem('endDate', JSON.stringify(updateDateFormat(periodTo)));
        localStorage.setItem('startDate', JSON.stringify(updateDateFormat(periodFrom)));
        props.getData(1, 50, []);
        props.setNumPage(1);
        props.setRowsCount(50);
        props.handleClick();
    }

    const mainContainer = (
        <div>
            <Grid container spacing={'16px'} justifyContent="center">
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <CustomDatePicker
                        label={'Period From'}
                        name="period"
                        value={periodFrom}
                        onChange={(e) => { handleSetPeriod(e, 'From') }}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <CustomDatePicker
                        label={'Period To'}
                        name="period"
                        value={periodTo}
                        onChange={(e) => { handleSetPeriod(e, 'To') }}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Select Date Range'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SelectModalButton(Select)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalDatePick