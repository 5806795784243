import { Grid, Stack, IconButton, MenuItem, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import SelectDataContact from '../Selects/SelectDataContact';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../../components/ui';
import { updateDateFormat } from '../../../../../utils/date';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const MemorialHeader = (props) => {
    const [isModal, setIsModal] = useState(false);

    const paramContact = "regContact/regContact";
    const paramCompany = "company/company";
    const paramUser = 'employee/employee';
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([])
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([])
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCustomer = (data) => {
        if (data === 'search') {
            handleClick();
        }
        else if (data === 'delete') {
            props.setCustomerId(0);
            props.setCustomerCode('');
            props.setCustomerName('');

            props.setMemorialDetailList([]);
            props.setCostMemorialRef('');
            props.setCostMemorialNo('');
            props.setCostMemorialId(0);
            props.CalculateTotal([]);
        }
    }

    const SettlementToData = [
        { id: 1, value: 'P01', name: 'General' },
        { id: 2, value: 'P02', name: 'PL To PV' },
        { id: 3, value: 'P05', name: 'Invoice D/N (Branch Company)' },
        { id: 4, value: 'P06', name: 'EPL To Memorial' },
        { id: 5, value: 'P04', name: 'Adjustment AP' },
    ]

    function handleType(val) {
        props.setType(val);
        props.handleCheckType(val);

        if (val === 1) {
            props.setCustomerTypeList(props.CustomerToData);
            props.CustomerToData.forEach(element => {
                if (13 === element.id) {
                    props.setCustomerToInt(element.value);
                    props.setCustomerTo(element.code);
                    props.setCustomer(element.name);
                }
            });
            props.handleCheckNone(0);
            props.setSettlementTo('P01');
            props.handleResetCustomer();
        }
        else {
            if (val === 4) {
                props.setCustomerToInt('');
                props.setCustomerTo(0);
                props.setCustomer('');
                props.handleCheckNone(0);
            }
            else {
                let tempArray = props.CustomerToData.filter(el => el.id !== 13);
                props.setCustomerTypeList(tempArray);
                props.CustomerToData.forEach(element => {
                    if (2 === element.id) {
                        props.setCustomerToInt(element.value);
                        props.setCustomerTo(element.code);
                        props.setCustomer(element.name);
                    }
                });
                props.handleCheckNone(1);
            }
            props.setSettlementTo('P01');
        }
    }

    function handleSettlementTo(val) {
        props.setSettlementTo(val);
        props.setCustomerTo(0);

        if (val !== 'P05') {
            if (val !== 'P04') {
                props.setCustomerTypeList(props.CustomerToData);
                props.CustomerToData.forEach(element => {
                    if (element.value === 'N') {
                        props.setCustomerToInt(element.value);
                        props.setCustomerTo(element.code);
                        props.setCustomer(element.name);
                    }
                });

                props.handleCheckNone(0);
            }
            else {
                const idsToExclude = [1, 8, 9, 10, 11, 12, 13];
                let tempArray = props.CustomerToData.filter(el => !idsToExclude.includes(el.id));
                props.setCustomerTypeList(tempArray);
                props.CustomerToData.forEach(element => {
                    if (2 === element.id) {
                        props.setCustomerToInt(element.value);
                        props.setCustomerTo(element.code);
                        props.setCustomer(element.name);
                    }
                });
                props.handleCheckNone(1);
            }
        }
        else {
            props.setCustomerToInt('');
            props.setCustomerTo(0);
            props.setCustomer('');
            props.handleCheckNone(0);
        }

        props.handleCheckType(props.Type, val);
        props.handleResetCustomer();
    }

    function handleCustomerTo(val) {
        let code = 0;
        props.setCustomerToInt(val);
        props.CustomerToData.forEach(element => {
            if (val === element.value) {
                code = element.code;
                props.setCustomerTo(element.code);
                props.setCustomer(element.name);
            }
        });

        props.handleCheckNone(code);
        props.handleResetCustomer();
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        if (props.CustomerTo !== undefined) {
            let typeX = 0;
            setIsLoadingC(true);
            typeX = parseFloat(props.CustomerTo);
            if (typeX <= 10) {
                ApiService.ContactPostByPage(paramContact, typeX, pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (typeX === 11) {
                ApiService.PostByPage(paramCompany, pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.company);

                            let temp = response.data.data.company
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (typeX === 12) {
                ApiService.DynamicPostByPage(paramUser, 'SEARCH', pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.employees);

                            let temp = response.data.data.employees
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
        }
    };

    function handleMemorialDate(value) {
        props.setMemorialDate(value);
        if (updateDateFormat(new Date()) > updateDateFormat(value)) {
            props.GetExRate(updateDateFormat(value));
        }
    }

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'104px'}
                        >
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <div ref={props.infossNoRef}>
                                    <Input
                                        label={'Infoss Number'}
                                        value={props.infossNo}
                                        onChange={(e) => props.setInfossNo(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginBottom: '16px !important'
                                            }
                                        }}
                                        disabled={props.verifyAcc}
                                        hidden={!isInfossNo}
                                        message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={8}
                                lg={8}
                                sm={8}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'32px'}
                                    hidden={!props.isEdit}
                                >
                                    <Grid
                                        item
                                        xl={7}
                                        lg={7}
                                        sm={7}
                                        xs={12}
                                    >
                                        <Input
                                            label="Memorial No"
                                            value={props.MemorialNo}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={5}
                                        lg={5}
                                        sm={5}
                                        xs={12}
                                    >
                                        <Input
                                            label="Reference"
                                            value={props.Reference}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={7}
                                        lg={7}
                                        sm={7}
                                        xs={12}
                                    >
                                        <Selection
                                            id='0'
                                            label={'Type'}
                                            fullWidth={true}
                                            placeholder={'Select Type'}
                                            value={props.Type}
                                            onChange={(e) => handleType(e.target.value)}
                                            disabled={props.isEdit}
                                            // disabled
                                            formControlProps={{
                                                sx: {
                                                    marginTop: props.isEdit ? '16px !important' : ''
                                                }
                                            }}
                                        >
                                            {
                                                props.memorialTypeList.map((data, index) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                                                ))
                                            }
                                        </Selection>
                                    </Grid>

                                    <Grid
                                        item
                                        xl={5}
                                        lg={5}
                                        sm={5}
                                        xs={12}
                                    >
                                        <CustomDatePicker
                                            label='Memorial Date'
                                            type={'date'}
                                            value={props.MemorialDate}
                                            onChange={handleMemorialDate}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: props.isEdit ? '16px !important' : '0px !important'
                                                }
                                            }}
                                            disabled={props.verifyAcc}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Rate'
                                            value={props.Rate}
                                            textAlign='right'
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Print Date'
                                            value={props.PrintDate}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <CustomDatePicker
                                            label='Rate Date'
                                            value={props.ExRateDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    // marginTop: '16px !important'
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Verify'
                                            value={props.VerifyDate}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Being Settlement For'}
                                    fullWidth={true}
                                    placeholder={'Select Being Settlement For'}
                                    value={props.SettlementTo}
                                    onChange={(e) => handleSettlementTo(e.target.value)}
                                    // disabled={props.isSettlement}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        SettlementToData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <div style={{ marginTop: '16px' }}>
                                    {props.selectPrincipleBy}
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Customer Change'}
                                    fullWidth={true}
                                    placeholder={'Select Customer Change'}
                                    value={props.CustomerToInt}
                                    onChange={(e) => handleCustomerTo(e.target.value)}
                                    disabled={props.isCustomer}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        props.customerTypeList.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Customer'
                                        value={props.CustomerCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                        value={props.CustomerName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchCustomer('delete')}
                                                        disabled={props.isNone}
                                                        hidden={props.verifyAcc ? true : props.CustomerName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => searchCustomer('search')}
                                                        hidden={props.verifyAcc ? true : !props.isNone && !props.isJobOwner ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={props.Customer}
                        setIsModal={setIsModal}
                        type={props.CustomerTo}
                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setCustomerId={props.setCustomerId}
                        setCustomerCode={props.setCustomerCode}
                        setCustomerName={props.setCustomerName}
                    />
                )
            }
        </div>
    )
}

export default MemorialHeader;