import { Box, Grid } from '@mui/material'
import React from 'react'
import TabBookingParty from './TabBookingParty';
import TabConsignee from './TabConsignee';
import TabCustomer from './TabCustomer';
import TabShipper from './TabShipper';

const TabsContact = (props) => {
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <TabCustomer
                            handleClick={props.handleClick}
                            setTitleModal={props.setTitleModal}
                            setTipe={props.setTipe}
                            isEdit={props.isEdit}
                            idCustomer={props.idCustomer}
                            setIdCustomer={props.setIdCustomer}
                            CustomerName={props.CustomerName}
                            setCustomerName={props.setCustomerName}
                            CustomerAddress={props.CustomerAddress}
                            setCustomerAddress={props.setCustomerAddress}
                            creditTermUsd={props.creditTermUsd}
                            setCreditTermUsd={props.setCreditTermUsd}
                            creditTermDays={props.creditTermDays}
                            setCreditTermDays={props.setCreditTermDays}
                            creditTermIdr={props.creditTermIdr}
                            setCreditTermIdr={props.setCreditTermIdr}
                            creditTermUsdReal={props.creditTermUsdReal}
                            setCreditTermUsdReal={props.setCreditTermUsdReal}
                            creditTermIdrReal={props.creditTermIdrReal}
                            setCreditTermIdrReal={props.setCreditTermIdrReal}
                            pic={props.pic}
                            setPic={props.setPic}
                            email={props.email}
                            setEmail={props.setEmail}
                            phone={props.phone}
                            setPhone={props.setPhone}
                            contactTypeId={props.contactTypeId}
                            jobServiceType={props.jobServiceType}
                            isApproved={props.isApproved}
                            idShipper={props.idShipper}
                            setIdShipper={props.setIdShipper}
                            ShipperName={props.ShipperName}
                            setShipperName={props.setShipperName}
                            ShipperAddress={props.ShipperAddress}
                            setShipperAddress={props.setShipperAddress}
                            idConsignee={props.idConsignee}
                            setIdConsignee={props.setIdConsignee}
                            ConsigneeName={props.ConsigneeName}
                            setConsigneeName={props.setConsigneeName}
                            ConsigneeAddress={props.ConsigneeAddress}
                            setConsigneeAddress={props.setConsigneeAddress}

                            picRef={props.picRef}
                            emailRef={props.emailRef}
                            phoneRef={props.phoneRef}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        {
                            props.jobServiceType === 'Export' || props.jobServiceType === 'Domestic' ?
                                <div style={{ marginBottom: '24px' }}>
                                    <TabConsignee
                                        handleClick={props.handleClick}
                                        jobServiceType={props.jobServiceType}
                                        setTitleModal={props.setTitleModal}
                                        setTipe={props.setTipe}
                                        idConsignee={props.idConsignee}
                                        setIdConsignee={props.setIdConsignee}
                                        ConsigneeName={props.ConsigneeName}
                                        setConsigneeName={props.setConsigneeName}
                                        ConsigneeAddress={props.ConsigneeAddress}
                                        setConsigneeAddress={props.setConsigneeAddress}
                                        contactTypeId={props.contactTypeId}
                                        isApproved={props.isApproved}
                                    />
                                </div>
                                :
                                props.jobServiceType === 'Import' ?
                                    <div style={{ marginBottom: '24px' }}>
                                        <TabShipper
                                            handleClick={props.handleClick}
                                            jobServiceType={props.jobServiceType}
                                            setTitleModal={props.setTitleModal}
                                            setTipe={props.setTipe}
                                            idShipper={props.idShipper}
                                            setIdShipper={props.setIdShipper}
                                            ShipperName={props.ShipperName}
                                            setShipperName={props.setShipperName}
                                            ShipperAddress={props.ShipperAddress}
                                            setShipperAddress={props.setShipperAddress}
                                            contactTypeId={props.contactTypeId}
                                            isApproved={props.isApproved}
                                        />
                                    </div>
                                    :
                                    <></>
                        }

                        <TabBookingParty
                            handleClick={props.handleClick}
                            setTitleModal={props.setTitleModal}
                            setTipe={props.setTipe}
                            idBookingParty={props.idBookingParty}
                            BookingPartyName={props.BookingPartyName}
                            BookingPartyAddress={props.BookingPartyAddress}
                            isApproved={props.isApproved}
                            setIdBookingParty={props.setIdBookingParty}
                            setBookingPartyName={props.setBookingPartyName}
                            setBookingPartyAddress={props.setBookingPartyAddress}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>

    )
}

export default TabsContact