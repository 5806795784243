import { IconButton, Stack, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import ModalFlight from '../Modals/ModalFlight';
import { CustomDatePicker, Icon, Input, showToast } from '../../../../components/ui';
import { getDefaultDateFormat } from '../../../../utils/date';

const TabFlight = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [flightId, setFlightId] = useState(0);
    const [flightCode, setFlightCode] = useState('');
    const [flightName, setFlightName] = useState('');
    const [flightNo, setFlightNo] = useState('');
    const [etd, setETD] = useState('');
    const [airportToId, setAirportToId] = useState(0);
    const [airportToCode, setAirportToCode] = useState('');
    const [airportToName, setAirportToName] = useState('');
    const [airportFromId, setAirportFromId] = useState(0);
    const [airportFromCode, setAirportFromCode] = useState('');
    const [airportFromName, setAirportFromName] = useState('');
    const [cityFromId, setCityFromId] = useState(0);
    const [cityFromCode, setCityFromCode] = useState('');
    const [cityFromName, setCityFromName] = useState('');
    const [cityToId, setCityToId] = useState(0);
    const [cityToCode, setCityToCode] = useState('');
    const [cityToName, setCityToName] = useState('');

    function getDateNow() {
        setETD(getDefaultDateFormat(new Date()));
    };

    const AddPlaceOfReceipt = (data) => {
        if (data === 'search') {
            props.setTitleModal('Place Of Receipt');
            props.handleClick('Place Of Receipt');
        }
        else if (data === 'delete') {
            props.setReceiptPlaceId(0);
            props.setReceiptPlaceCode('');
            props.setReceiptPlaceName('');
            props.setTempReceiptPlaceName('');
        }
    };

    const AddAirPortDeparture = (data) => {
        if (data === 'search') {
            props.setTitleModal('AirPort of Departure');
            props.handleClick('AirPort of Departure');
        }
        else if (data === 'delete') {
            props.setDepartureAirPortId(0);
            props.setDepartureAirPortCode('');
            props.setDepartureAirPortName('');
            props.setTempLoadingPortName('');
        }
    };

    const AddAirPortDestination = (data) => {
        if (data === 'search') {
            props.setTitleModal('AirPort of Destination');
            props.handleClick('AirPort of Destination');
        }
        else if (data === 'delete') {
            props.setDestinationAirPortId(0);
            props.setDestinationAirPortCode('');
            props.setDestinationAirportName('');
            props.setTempDischargePortName('');
        }
    };

    const AddPlaceOfDelivery = (data) => {
        if (data === 'search') {
            props.setTitleModal('Place Of Delivery');
            props.handleClick('Place Of Delivery');
        }
        else if (data === 'delete') {
            props.setDeliveryPlaceId(0);
            props.setDeliveryPlaceCode('');
            props.setDeliveryPlaceName('');
            props.setTempDeliveryPlaceName('');
        }
    };

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
        }
        else {
            setIsModal2(false);
        }
    };

    const ShowAddFlight = () => {
        getDateNow();
        setTitleModal2('Add Flight');
        setDetailEdit(false);
        if (props.flightData.length > 0) {
            let data = props.flightData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleClick2();
    };

    const ShowEditFlight = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleModal2('Edit Flight');
            setDetailEdit(true);

            setFlightId(selectedDetail.flightId);
            setFlightCode(selectedDetail.flightCode);
            setFlightName(selectedDetail.flightName);
            setFlightNo(selectedDetail.flightNo);
            setAirportFromId(selectedDetail.airportFromId);
            setAirportFromCode(selectedDetail.airPortFromCode);
            setAirportFromName(selectedDetail.airPortFromName);
            setAirportToId(selectedDetail.airportToId);
            setAirportToCode(selectedDetail.airPortToCode);
            setAirportToName(selectedDetail.airPortToName);
            setCityFromId(selectedDetail.cityFromId);
            setCityFromCode(selectedDetail.cityFromCode);
            setCityFromName(selectedDetail.cityFromName);
            setCityToId(selectedDetail.cityToId);
            setCityToCode(selectedDetail.cityToCode);
            setCityToName(selectedDetail.cityToName);
            setETD(getDefaultDateFormat(selectedDetail.etd));

            handleClick2();
        }
    }

    const DeleteFlight = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            for (let i = 0; i < props.dataTracking.length; i++) {
                if (props.dataTracking[i].sequenceRouting === tempSequence) {
                    DeleteDataTracking(props.dataTracking[i]);
                    break;
                }
            }

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.flightData.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setFlightData(result);

            setSelectedDetail({});
            showToast({ type: 'success', message: 'Flight Deleted Successfully!' });
        }
    }

    const DeleteDataTracking = (selectData) => {
        let tempSequence = selectData.sequence;

        const deleteFunction = (data) => {
            return data.sequence !== tempSequence;
        }

        const result = props.dataTracking.filter(deleteFunction);

        if (result.length > 0) {
            tempSequence = 0;

            result.forEach((el) => {
                tempSequence += 1;
                el.sequence = tempSequence;
            })
        }

        props.setDataTracking(result);
    }

    function handleChangeLocation(type, value) {
        if (type === 'loading') {
            props.setLoadingPortName(value);
        }
        else if (type === 'receipt') {
            props.setReceiptPlaceName(value);
        }
        else if (type === 'destination') {
            props.setDestinationAirportName(value);
        }
        else if (type === 'delivery') {
            props.setDeliveryPlaceName(value);
        }
    }

    return (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '12px' }}>
                        <Input
                            label={'Place of Receipt'}
                            value={props.ReceiptPlaceName && props.TempReceiptPlaceName && props.ReceiptPlaceName.toLowerCase().includes(props.TempReceiptPlaceName.toLowerCase()) ? props.ReceiptPlaceCode : ''}
                            formControlProps={{
                                sx: {
                                    marginTop: '24px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                            }}
                            disabled
                        />

                        <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.ReceiptPlaceName}
                            type='text'
                            label={''}
                            disabled
                            onChange={(e) => handleChangeLocation('receipt', e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => AddPlaceOfReceipt('delete')}
                                            hidden={props.ReceiptPlaceName ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => AddPlaceOfReceipt('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                        <Input
                            label="Airport of Destination"
                            value={props.DestinationAirportName && props.TempDischargePortName && props.DestinationAirportName.toLowerCase().includes(props.TempDischargePortName.toLowerCase()) ? props.DestinationAirPortCode : ''}
                            formControlProps={{
                                sx: {
                                    marginTop: '24px',
                                    width: '75%'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                            }}
                            disabled
                        />

                        <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.DestinationAirportName}
                            onChange={(e) => handleChangeLocation('destination', e.target.value)}
                            type='text'
                            label={''}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => AddAirPortDestination('delete')}
                                            hidden={props.DestinationAirPortCode ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => AddAirPortDestination('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                    <div ref={props.estimateTimeRef}>
                        <CustomDatePicker
                            label={"ETD (Current Period " + props.period + ")"}
                            value={props.ETD}
                            onChange={props.setETD}
                            type={'date'}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />
                    </div>

                    <CustomDatePicker
                        label="ATD (Actual)"
                        value={props.ATD}
                        onChange={props.setATD}
                        disabled
                        type={'date'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '12px' }}>
                        <Input
                            label="Airport of Departure"
                            value={props.DepartureAirPortName && props.TempLoadingPortName && props.DepartureAirPortName.toLowerCase().includes(props.TempLoadingPortName.toLowerCase()) ? props.DepartureAirPortCode : ''}
                            formControlProps={{
                                sx: {
                                    marginTop: '24px',
                                    width: '75%'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                            }}
                            disabled
                        />

                        <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.DepartureAirPortName}
                            onChange={(e) => handleChangeLocation('destination', e.target.value)}
                            type='text'
                            label={''}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => AddAirPortDeparture('delete')}
                                            hidden={props.DepartureAirPortCode ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => AddAirPortDeparture('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                        <Input
                            label="Place of Delivery"
                            value={props.DeliveryPlaceName && props.TempDeliveryPlaceName && props.DeliveryPlaceName.toLowerCase().includes(props.TempDeliveryPlaceName.toLowerCase()) ? props.DeliveryPlaceCode : ''}
                            formControlProps={{
                                sx: {
                                    marginTop: '24px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                            }}
                            disabled
                        />

                        <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.DeliveryPlaceName}
                            type='text'
                            label={''}
                            disabled
                            onChange={(e) => handleChangeLocation('delivery', e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => AddPlaceOfDelivery('delete')}
                                            hidden={props.DeliveryPlaceName ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => AddPlaceOfDelivery('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                    <CustomDatePicker
                        label={"ETA (Current Period " + props.period + ")"}
                        value={props.ETA}
                        onChange={props.setETA}
                        type={'date'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />

                    <CustomDatePicker
                        label="ATA (Actual)"
                        value={props.ATA}
                        onChange={props.setATA}
                        disabled
                        type={'date'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <div style={{ marginTop: '16px' }}>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        marginTop: '8px'
                    }}
                >
                    Add Flight
                </Typography>

                <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'right' }}>No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Flight</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>ETD</StyledTableCell>
                                <StyledTableCell>AirPort From</StyledTableCell>
                                <StyledTableCell>AirPort To</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.flightData.length > 0 ?
                                    props.flightData.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => setSelectedDetail(el)}
                                                onDoubleClick={() => ShowEditFlight()}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.sequence}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.flightCode}-{el.flightNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{new Date(el.etd).toLocaleDateString("id").split('T')[0]}</StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.airPortFromCode}, {el.cityFromName}</StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.airPortToCode}, {el.cityToName}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ marginTop: '16px' }}>
                    {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddFlight, ShowEditFlight, DeleteFlight)}
                </div>
            </div>

            {
                isModal2 && (
                    <ModalFlight
                        jobType={props.jobType}
                        getDateNow={getDateNow}
                        isModal2={isModal2}
                        handleClick2={handleClick2}
                        titleModal2={titleModal2}
                        detailTypeId={props.detailTypeId}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}

                        ShipmentId={props.ShipmentId}
                        setShipmentId={props.setShipmentId}

                        setAirLineName={props.setAirLineName}

                        flightData={props.flightData}
                        setFlightData={props.setFlightData}

                        flightId={flightId}
                        setFlightId={setFlightId}
                        flightCode={flightCode}
                        setFlightCode={setFlightCode}
                        flightName={flightName}
                        setFlightName={setFlightName}
                        flightNo={flightNo}
                        setFlightNo={setFlightNo}
                        etd={etd}
                        setETD={setETD}
                        airportToId={airportToId}
                        setAirportToId={setAirportToId}
                        airportToCode={airportToCode}
                        setAirportToCode={setAirportToCode}
                        airportToName={airportToName}
                        setAirportToName={setAirportToName}
                        airportFromId={airportFromId}
                        setAirportFromId={setAirportFromId}
                        airportFromCode={airportFromCode}
                        setAirportFromCode={setAirportFromCode}
                        airportFromName={airportFromName}
                        setAirportFromName={setAirportFromName}
                        cityFromId={cityFromId}
                        setCityFromId={setCityFromId}
                        cityFromCode={cityFromCode}
                        setCityFromCode={setCityFromCode}
                        cityFromName={cityFromName}
                        setCityFromName={setCityFromName}
                        cityToId={cityToId}
                        setCityToId={setCityToId}
                        cityToCode={cityToCode}
                        setCityToCode={setCityToCode}
                        cityToName={cityToName}
                        setCityToName={setCityToName}
                    />
                )
            }
        </>
    )
}

export default TabFlight;