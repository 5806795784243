import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { Box, Grid, Card, CardContent, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ModalData from './ModalBc/ModalData';
import TabsBooking from './TabBc/TabsBooking';
import TabsContact from './TabBc/TabsContact';
import MainBooking from './ContentBc/MainBooking';
import SelectContact from './ModalBc/SelectContact';
import ModalMarketing from './ModalBc/ModalMarketing';
import ModalConfirmEPL from './ModalBc/ModalConfirmEPL';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ApiService, { branchId, companyId, countryId, isInfossNo, userCode, userId, username, } from '../../../services/ApiService';
import ModalApprovalCredit from '../../../components/Modals/ModalApprovalCredit';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { showToast, Selection } from '../../../components/ui';
import ButtonBooking from './ComponentBc/ButtonBooking';
import ButtonInfo from '../../../components/Components/ButtonInfo';
import { defaultDate, setDateFormat } from '../../../utils/date';
import { convertToNominal, convertToNumeric, newWindowConfig } from '../../../utils/masks.jsx';
import { autoFocusField, handleDataTable, PrintPDF } from '../../../utils/reusableFunction.jsx';
import ModalUsageData from '../../../components/Modals/ModalUsageData.jsx';

const CreateEditBooking = forwardRef((props, ref) => {
    const isFetchingParallel = true
    const paramRegJobType = 'regJobType/regJobType';
    const paramBooking = 'bookingConfirmation/bookingConfirmation'
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataBooking"));

    const { bookingId } = useParams()
    const { isToDo } = useParams()
    const { isCopy } = useParams();

    const picRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const receiptRef = useRef(null);
    const deliveryRef = useRef(null);
    const marketingRef = useRef(null);
    const estimateTimeRef = useRef(null);
    const infossNoRef = useRef(null);

    const [rowStatus, setRowStatus] = useState('ACT');
    const [bookingNo, setBookingNo] = useState('');
    const [reference, setReference] = useState('');
    const [bookingDate, setBookingDate] = useState('');
    const [jobType, setJobType] = useState('');
    const [jobTypeId, setJobTypeId] = useState(0);
    const [portType, setPortType] = useState('');
    const [jobServiceType, setJobServiceType] = useState('');
    const [jobTypeCode, setJobTypeCode] = useState();
    const [refShipper, setRefShipper] = useState('');
    const [bookingStatus, setBookingStatus] = useState(0);
    const [dataJobType, setDataJobType] = useState([]);
    const [shipmentStatusId, setShipmentStatusId] = useState(2);
    const [enableMarketing, setEnableMarketing] = useState(true);
    const [marketId, setMarketId] = useState(0)
    const [marketName, setMarketName] = useState('')
    const [approvalDate, setApprovalDate] = useState('')
    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);
    const [jobOwnerId, setJobOwnerId] = useState(0)

    const history = useNavigate();

    const [idCustomer, setIdCustomer] = useState(0);
    const [CustomerName, setCustomerName] = useState('');
    const [CustomerAddress, setCustomerAddress] = useState('');
    const [idShipper, setIdShipper] = useState(0);
    const [ShipperName, setShipperName] = useState('');
    const [ShipperAddress, setShipperAddress] = useState('');
    const [idConsignee, setIdConsignee] = useState(0);
    const [ConsigneeName, setConsigneeName] = useState('');
    const [ConsigneeAddress, setConsigneeAddress] = useState('');
    const [idBookingParty, setIdBookingParty] = useState(0);
    const [BookingPartyName, setBookingPartyName] = useState('');
    const [BookingPartyAddress, setBookingPartyAddress] = useState('');
    const [creditTermDays, setCreditTermDays] = useState(0);
    const [creditTermUsd, setCreditTermUsd] = useState('0');
    const [creditTermIdr, setCreditTermIdr] = useState('0');
    const [creditTermUsdReal, setCreditTermUsdReal] = useState(0);
    const [creditTermIdrReal, setCreditTermIdrReal] = useState(0);
    const [pic, setPic] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [contactTypeId, setContactTypeId] = useState();
    const [dataStatusShipment, setDataStatusShipment] = useState([]);
    const paramStatusShipment = 'shipmentStatus/shipmentStatus';

    const [placeOfReceiptId, setPlaceOfReceiptId] = useState(0)
    const [placeOfReceipt, setPlaceOfReceipt] = useState('')
    const [placeOfReceiptCode, setPlaceOfReceiptCode] = useState('')
    const [placeOfDeliveryId, setPlaceOfDeliveryId] = useState(0)
    const [placeOfDelivery, setPlaceOfDelivery] = useState('')
    const [placeOfDeliveryCode, setPlaceOfDeliveryCode] = useState('')
    const [portOfLoadingId, setPortOfLoadingId] = useState(0)
    const [portOfLoading, setPortOfLoading] = useState('')
    const [portOfLoadingCode, setPortOfLoadingCode] = useState('')
    const [portOfDischargeId, setPortOfDischargeId] = useState(0)
    const [portOfDischarge, setPortOfDischarge] = useState('')
    const [portOfDischargeCode, setPortOfDischargeCode] = useState('')
    const [airportDepartureId, setAirportDepartureId] = useState(0)
    const [airportDeparture, setAirportDeparture] = useState('')
    const [airportDepartureCode, setAirportDepartureCode] = useState('')
    const [airportDestinationId, setAirportDestinationId] = useState(0)
    const [airportDestination, setAirportDestination] = useState('')
    const [airportDestinationCode, setAirportDestinationCode] = useState('')
    const [ETD, setETD] = useState('');
    const [ETA, setETA] = useState('');
    const [tipe, setTipe] = useState(0);
    const [idAgent, setIdAgent] = useState(0);
    const [agentName, setAgentName] = useState('');
    const [agentAddress, setAgentAddress] = useState('')
    const [idEMKL, setIdEMKL] = useState(0);
    const [EMKLName, setEMKLName] = useState('');
    const [idDepo, setIdDepo] = useState(0);
    const [depoName, setDepoName] = useState('');
    const [idCarrier, setIdCarrier] = useState(0);
    const [carrierName, setCarrierName] = useState('');
    const [vesselId, setVesselId] = useState(0);
    const [airlineId, setAirlineId] = useState(0);
    const [vessel, setVessel] = useState('');
    const [airline, setAirline] = useState('');
    const [vesselCode, setVesselCode] = useState('');
    const [airlineCode, setAirlineCode] = useState('');
    const [containerTypeId, setContainerTypeId] = useState(0);
    const [containerTypeCode, setContainerTypeCode] = useState('');
    const [containerTypeName, setContainerTypeName] = useState('');
    const [containerFormat, setContainerFormat] = useState('All');
    const [dataContainerType, setDataContainerType] = useState([]);
    const paramContainerType = 'ContainerType/ContainerType';
    const [finalDestination, setFinalDestination] = useState('');
    const [voyageNo, setVoyageNo] = useState('');
    const [flightNo, setFlightNo] = useState('');
    const [flightAb, setFlightAb] = useState('');
    const [serviceType, setServiceType] = useState('None')
    const [freight, setFreight] = useState('P')
    const [shipmentVolume, setShipmentVolume] = useState('Others');
    const [shipmentVolume20, setShipmentVolume20] = useState(0);
    const [shipmentVolume40, setShipmentVolume40] = useState(0);
    const [shipmentVolume45, setShipmentVolume45] = useState(0);
    const [shipmentVolumeCBM, setShipmentVolumeCBM] = useState(0);

    const [isVolume20, setIsVolume20] = useState(false);
    const [isVolume40, setIsVolume40] = useState(false);
    const [isVolume45, setIsVolume45] = useState(false);
    const [isVolumeCBM, setIsVolumeCBM] = useState(false);
    const [containerSize, setContainerSize] = useState('All');

    const [shipmentVolumeAll, setShipmentVolumeAll] = useState(0);
    const [shipmentVolumeCollie, setShipmentVolumeCollie] = useState(0)
    const [shipmentVolumeKGS, setShipmentVolumeKGS] = useState(0);
    const [stuffingDate, setStuffingDate] = useState('');
    const [stuffingPlace, setStuffingPlace] = useState('');
    const [stuffingNote, setStuffingNote] = useState('');
    const [remarks, setRemarks] = useState('');
    const [creditTerm, setCreditTerm] = useState(false);

    const [marksNumber, setMarksNumber] = useState('');
    const [cargoDescription, setCargoDescription] = useState('')
    const [numberOfPackages, setNumberOfPackages] = useState('')
    const [packagingType, setPackagingType] = useState('');
    const [dataPackagingType, setDataPackagingType] = useState([]);
    const paramPackagingType = 'packagingType/packagingType';
    const [grossWeight, setGrossWeight] = useState('');
    const [volume, setVolume] = useState('')
    const [chargeableWeightVolume, setChargeableWeightVolume] = useState('')
    const [specialInstructions, setSpecialInstructions] = useState('')

    const [exRate, setExRate] = useState(0)
    const [exRateReal, setExRateReal] = useState(0)
    const [dataIncome, setDataIncome] = useState([]);
    const [dataCost, setDataCost] = useState([]);
    const [buyingRateInUSD, setBuyingRateInUSD] = useState(0)
    const [sellingRateInUSD, setSellingRateInUSD] = useState(0)

    const [totalUsd, setTotalUsd] = useState(0)
    const [totalIdr, setTotalIdr] = useState(0)
    const [totalIncomeUsd, setTotalIncomeUsd] = useState(0)
    const [totalIncomeIdr, setTotalIncomeIdr] = useState(0)
    const [totalCostUsd, setTotalCostUsd] = useState(0)
    const [totalCostIdr, setTotalCostIdr] = useState(0)
    const [marginIncome, setMarginIncome] = useState(0);
    const [marginCost, setMarginCost] = useState(0);

    const [isApproved, setIsApproved] = useState(false)
    const [wfComplete, setWfComplete] = useState(2)
    const [isEdit, setIsEdit] = useState(false)

    const [isModalContact, setIsModalContact] = useState(false);
    const [titleModalContact, setTitleModalContact] = useState('');

    const [isLoadingContact, setIsLoadingContact] = useState(false);
    const [dataContact, setDataContact] = useState([]);
    const [dataMapContact, setDataMapContact] = useState([])
    const [dataTableContact, setDataTableContact] = useState([]);
    const [columnDataContact, setColumnDataContact] = useState([])
    const [SelectedDataContact, setSelectedDataContact] = useState({});
    const [totalRowsContact, setTotalRowsContact] = useState(50)

    const paramContact = "regcontact/regcontact";

    const [isModalData, setIsModalData] = useState(false);
    const [titleModalData, setTitleModalData] = useState('');

    const [isLoadingModalData, setIsLoadingModalData] = useState(false);
    const [dataModalData, setDataModalData] = useState([]);
    const [dataTableModal, setDataTableModal] = useState([])
    const [dataMapModalData, setDataMapModalData] = useState([])
    const [columnDataModalData, setColumnDataModalData] = useState([])
    const [SelectedDataModalData, setSelectedDataModalData] = useState({});
    const [totalRowsModalData, setTotalRowsModalData] = useState(50)

    const [paramModal, setParamModal] = useState('');

    const [contactId, setContactId] = useState(0)
    const [tempCustName, setTempCustName] = useState('')

    const [accountId, setAccountId] = useState(0)
    const [accountCode, setAccountCode] = useState('');
    const [isContainer, setIsContainer] = useState(false);
    const [tempAccountName, setTempAccountName] = useState('')
    const [description, setDescription] = useState('')
    const [quantity, setQuantity] = useState('')
    const [detailEdit, setDetailEdit] = useState(false)

    const [isModalMarketing, setIsModalMarketing] = useState(false);
    const [titleModalMarketing, setTitleModalMarketing] = useState('');

    const [isLoadingMarketing, setIsLoadingMarketing] = useState(false);
    const [dataMarketing, setDataMarketing] = useState([]);
    const [dataTableMarketing, setDataTableMarketing] = useState([])
    const [dataMapMarketing, setDataMapMarketing] = useState([])
    const [columnDataMarketing, setColumnDataMarketing] = useState([])
    const [SelectedDataMarketing, setSelectedDataMarketing] = useState({});
    const [totalRowsMarketing, setTotalRowsMarketing] = useState(50)

    const paramMarketing = "employee/employee";
    const [isLoading, setIsLoading] = useState(true);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isModalConfirmEPL, setIsModalConfirmEPL] = useState(false);
    const [tempTitle, setTempTitle] = useState('');
    const [tempContactName, setTempContactName] = useState('');

    const [isModalApproval, setIsModalApproval] = useState(false);
    const [titleModalApproval, setTitleModalApproval] = useState("");
    const [titleApproval, setTitleApproval] = useState("");
    const [cstName, setCstName] = useState('');
    const [creditType, setCreditType] = useState('');
    const [isApprovalCredit, setIsApprovalCredit] = useState(false);
    const [approvalDataList, setApprovalDataList] = useState([]);
    const [approvalReason, setApprovalReason] = useState('');
    const [approvalIDR, setApprovalIDR] = useState(0);
    const [approvalUSD, setApprovalUSD] = useState(0);

    const [ticketId, setTicketId] = useState(0);

    const [containerData, setContainerData] = useState([]);

    const [tabValue, setTabValue] = useState('Details');
    const [documentStatus, setDocumentStatus] = useState('');

    const [isRemarks, setIsRemarks] = useState(false);
    const [CreditRemarks, setCreditRemarks] = useState('');
    const [isCustomNote, setIsCustomNote] = useState(false);
    const [CustomNote, setCustomNote] = useState('');

    const [isModalUsage, setIsModalUsage] = useState(false);
    const [titleModalUsage, setTitleModalUsage] = useState('');
    const [usageDataList, setUsageDataList] = useState([]);

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Booking Confirmation', link: '/Majura/BookingConfirmation', style: '' },
        { label: 'Create Booking Confirmation', link: '/Majura/BookingConfirmation/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Booking Confirmation', link: '/Majura/BookingConfirmation', style: '' },
        { label: 'Detail Booking Confirmation', link: '/Majura/BookingConfirmation/' + bookingId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        let id = 0;
        if (bookingId) {
            if (isCopy === 'true') {
                id = 0;
            }
            else {
                id = parseInt(bookingId);
            }
        }
        let WFComplete = wfComplete
        var dataDetail = [...dataIncome, ...dataCost]
        var data = {
            "bookingConfirmation": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "ticketId": 0,
                "bookingNo": bookingNo,
                "bookingNo2": "",
                "bookingDate": bookingDate,
                "reference": reference,
                "shipmentStatusId": shipmentStatusId,
                "siReference": refShipper,
                "contactTypeId": contactTypeId,
                "jobTypeId": jobTypeId,
                "jobTypeCode": jobTypeCode,
                "marketId": marketId,
                "marketName": marketName,
                "exRate": exRateReal,
                "customerId": idCustomer,
                "customerName": CustomerName,
                "customerAddress": CustomerAddress,
                "picName": pic,
                "picPhone": phone,
                "picEmail": email,
                "receiptCityId": placeOfReceiptId,
                "receiptCityCode": placeOfReceiptCode,
                "receiptCityName": placeOfReceipt,
                "deliveryCityId": placeOfDeliveryId,
                "deliveryCityCode": placeOfDeliveryCode,
                "deliveryCityName": placeOfDelivery,
                "loadingPortId": portOfLoadingId,
                "loadingPortCode": portOfLoadingCode,
                "loadingPortName": portOfLoading,
                "dischargePortId": portOfDischargeId,
                "dischargePortCode": portOfDischargeCode,
                "dischargePortName": portOfDischarge,
                "departureAirportId": airportDepartureId,
                "departureAirportCode": airportDepartureCode,
                "departureAirportName": airportDeparture,
                "destinationAirportId": airportDestinationId,
                "destinationAirportCode": airportDestinationCode,
                "destinationAirportName": airportDestination,
                "goodDescription": cargoDescription,
                "finalDestination": finalDestination,
                "remarks": remarks,
                "carrierId": idCarrier,
                "carrierName": carrierName,
                "stuffingDate": stuffingDate,
                "stuffingPlace": stuffingPlace,
                "stuffingNote": stuffingNote,
                "creditTerm": Boolean(creditTerm),
                "serviceType": serviceType,
                "freight": freight,
                "shipmentVolume": shipmentVolume,
                "shipmentVolume20": parseInt(shipmentVolume20),
                "shipmentVolume40": parseInt(shipmentVolume40),
                "shipmentVolume45": parseInt(shipmentVolume45),
                "shipmentVolumeAll": parseInt(shipmentVolumeAll),
                "shipmentVolumeCBM": parseInt(shipmentVolumeCBM),
                "shipmentVolumeCollie": parseInt(shipmentVolumeCollie),
                "shipmentVolumeKGS": parseInt(shipmentVolumeKGS),
                "validUntil": defaultDate,
                "approved": false,
                "approvedBy": "",
                "approvedOn": defaultDate,
                "printing": 0,
                "printedOn": defaultDate,
                "deleted": false,
                "deletedBy": "",
                "deletedOn": defaultDate,
                "saveShipmentOrder": false,
                "bookingPartyId": idBookingParty,
                "bookingPartyName": BookingPartyName,
                "bookingPartyAddress": BookingPartyAddress,
                "approvedCreditLimit": false,
                "approvedCreditLimitBy": '0',
                "approvedCreditLimitOn": defaultDate,
                "approvedCreditLimitRemarks": "",
                "vesselId": vesselId,
                "vesselName": vessel,
                "voyageNo": voyageNo,
                "flightId": airlineId,
                "flightName": airline,
                "flightNo": flightNo,
                "flightAbbrevation": flightAb,
                "etd": ETD,
                "eta": ETA,
                "buyingRateInUSD": convertToNumeric(buyingRateInUSD),
                "sellingRateInUSD": convertToNumeric(sellingRateInUSD),
                "containerTypeId": containerTypeId,
                "agentId": idAgent,
                "agentName": agentName,
                "agentAddress": agentAddress,
                "emklId": idEMKL,
                "emklName": EMKLName,
                "depoId": idDepo,
                "depoName": depoName,
                "nonProfitMarginRemarks": "",
                "isNonProfitMargin": false,
                "bookingStatus": 0,
                "shipperId": idShipper,
                "shipperName": ShipperName,
                "shipperAddress": ShipperAddress,
                "consigneeId": idConsignee,
                "consigneeName": ConsigneeName,
                "consigneeAddress": ConsigneeAddress,
                "marksNumber": marksNumber,
                "cargoDescription": cargoDescription,
                "numberOfPackages": numberOfPackages,
                "packagingType": packagingType,
                "grossWeight": grossWeight,
                "volume": volume,
                "chargeableWeightVolume": chargeableWeightVolume,
                "specialInstructions": specialInstructions,
                "wfComplete": WFComplete,
                "userCode": userCode,
                "userId": userId,
                "creditTermInDays": creditTermDays,
                "creditTermInIDR": creditTermIdrReal,
                "creditTermInUSD": creditTermUsdReal,
                "jobTypeName": jobType,
                'jobOwnerId': jobOwnerId,
                'noInfoss': infossNo
            },
            "bookingConfirmationDetails": dataDetail,
            "bookingConfirmationContainers": containerData
        }
        localStorage.setItem(username + "-DataBooking", JSON.stringify(data));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal()
        }
    }));

    useEffect(() => {
        document.title = 'Booking Confirmation - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId]);

    const errorToast = (location) => {
        showToast({ type: 'error', message: `Can't get${location ? (` ${location} `) : ''}data.` });
    }

    const getDataLocal = () => {
        if (bookingId) {
            setIsEdit(true);
        }

        let rowStatus = 'ACT';
        let isApprove = false;
        let wfComplete = 2;
        var result = dataLocal.bookingConfirmation
        setDateFormat(result.bookingDate, setBookingDate);
        setRowStatus(result.rowStatus);
        setDateFormat(result.approvedOn, setApprovalDate);
        setBookingNo(result.bookingNo);
        setJobOwnerId(result.jobOwnerId);
        setReference(result.reference)
        setShipmentStatusId(result.shipmentStatusId)
        if (result.shipmentStatusId === 1 && result.approved === false) {
            setEnableMarketing(false);
        }
        else {
            setEnableMarketing(true);
        }
        setRefShipper(result.siReference)
        setContactTypeId(result.contactTypeId)
        setJobTypeId(result.jobTypeId)
        setJobTypeCode(result.jobTypeCode)
        setMarketId(result.marketId)
        setMarketName(result.marketName)
        setExRate(convertToNominal(result.exRate))
        setExRateReal(result.exRate)
        setIdCustomer(result.customerId)
        setCustomerName(result.customerName)
        setCustomerAddress(result.customerAddress)
        setPic(result.picName)
        setPhone(result.picPhone)
        setEmail(result.picEmail)
        setInfossNo(result.noInfoss)
        setPlaceOfReceiptId(result.receiptCityId)
        setPlaceOfReceiptCode(result.receiptCityCode)
        setPlaceOfReceipt(result.receiptCityName)
        setPlaceOfDeliveryId(result.deliveryCityId)
        setPlaceOfDeliveryCode(result.deliveryCityCode)
        setPlaceOfDelivery(result.deliveryCityName)
        setPortOfLoadingId(result.loadingPortId)
        setPortOfLoadingCode(result.loadingPortCode)
        setPortOfLoading(result.loadingPortName)
        setPortOfDischargeId(result.dischargePortId)
        setPortOfDischargeCode(result.dischargePortCode)
        setPortOfDischarge(result.dischargePortName)
        setAirportDepartureId(result.departureAirportId)
        setAirportDepartureCode(result.departureAirportCode)
        setAirportDeparture(result.departureAirportName)
        setAirportDestinationId(result.destinationAirportId)
        setAirportDestinationCode(result.destinationAirportCode)
        setAirportDestination(result.destinationAirportName)
        setCargoDescription(result.goodDescription)
        setFinalDestination(result.finalDestination)
        setRemarks(result.remarks)
        setIdCarrier(result.carrierId)
        setCarrierName(result.carrierName)
        setDateFormat(result.stuffingDate, setStuffingDate);
        setStuffingPlace(result.stuffingPlace)
        setStuffingNote(result.stuffingNote)
        setCreditTerm(result.creditTerm)
        setServiceType(result.serviceType)
        setFreight(result.freight)
        setShipmentVolume(result.shipmentVolume)
        setShipmentVolume20(result.shipmentVolume20)
        if (result.shipmentVolume20 > 0) {
            setIsVolume20(true);
        }
        setShipmentVolume40(result.shipmentVolume40)
        if (result.shipmentVolume40 > 0) {
            setIsVolume40(true);
        }
        setShipmentVolume45(result.shipmentVolume45)
        if (result.shipmentVolume45 > 0) {
            setIsVolume45(true);
        }
        setShipmentVolumeCBM(result.shipmentVolumeCBM)
        if (result.shipmentVolumeCBM) {
            setIsVolumeCBM(true);
        }
        setShipmentVolumeCollie(result.shipmentVolumeCollie)
        setShipmentVolumeKGS(result.shipmentVolumeKGS)
        setIdBookingParty(result.bookingPartyId)
        setBookingPartyName(result.bookingPartyName)
        setBookingPartyAddress(result.bookingPartyAddress)
        setVesselId(result.vesselId)
        setVessel(result.vesselName)
        setVoyageNo(result.voyageNo)
        setAirlineId(result.flightId)
        setAirline(result.flightName)
        setFlightNo(result.flightNo)
        setFlightAb(result.flightAbbrevation)
        setDateFormat(result.etd, setETD);
        setDateFormat(result.eta, setETA);
        setBuyingRateInUSD(result.buyingRateInUSD)
        setSellingRateInUSD(result.sellingRateInUSD)
        setIdAgent(result.agentId);
        setAgentName(result.agentName);
        setIdEMKL(result.emklId);
        setEMKLName(result.emklName);
        setIdDepo(result.depoId);
        setDepoName(result.depoName);
        setIdShipper(result.shipperId);
        setShipperName(result.shipperName);
        setShipperAddress(result.shipperAddress);
        setIdConsignee(result.consigneeId);
        setConsigneeName(result.consigneeName);
        setConsigneeAddress(result.consigneeAddress);
        setMarksNumber(result.marksNumber);
        setCargoDescription(result.cargoDescription);
        setNumberOfPackages(result.numberOfPackages);
        setPackagingType(result.packagingType);
        setGrossWeight(result.grossWeight);
        setVolume(result.volume);
        setChargeableWeightVolume(result.chargeableWeightVolume);
        setSpecialInstructions(result.specialInstructions);
        setCreditTermUsdReal(result.creditTermInUSD);
        setCreditTermIdrReal(result.creditTermInIDR);
        setCreditTermDays(result.creditTermInDays);
        setCreditTermUsd(convertToNominal(result.creditTermInUSD));
        setCreditTermIdr(convertToNominal(result.creditTermInIDR));
        setJobType(result.jobTypeName);
        setIsApproved(result.approved);
        setWfComplete(result.wfComplete);

        var dataDetail = dataLocal.bookingConfirmationDetails;
        var dataDetailIncome = dataDetail.filter(row => row.isIncome === true)
        var dataDetailCost = dataDetail.filter(row => row.isIncome === false)
        var dataContainer = dataLocal.bookingConfirmationContainers;
        setDataIncome(dataDetailIncome)
        setDataCost(dataDetailCost)
        setContainerData(dataContainer)
        calculateBookingData(dataDetail, result.exRate)

        rowStatus = result.rowStatus;
        isApprove = result.approved;
        wfComplete = result.wfComplete;

        handleDocumentStatus(rowStatus, isApprove, wfComplete);

        if (dataContainer !== undefined) {
            getContainerSizeData(dataContainer)
        }

        if (result.jobTypeId) {
            ApiService.PostById(paramRegJobType, result.jobTypeId)
                .then((res) => {
                    if (res.data.code === 200) {
                        var result = res.data.data.jobType;
                        setJobType(result.name);
                        setPortType(result.portType !== '' ? result.portType : 'DOM')
                        setJobServiceType(result.serviceType !== '' ? result.serviceType : 'Domestic')
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })

        }
        localStorage.removeItem(username + '-DataBooking')
        setTimeout(function () { setIsLoading(false); }, 1250);
    }

    const getData = (id) => {
        let rate = 0;
        let rowStatus = 'ACT';
        let isApprove = false;
        ApiService.OperationPostById(paramBooking, id)
            .then((response) => {
                if (response.data.code === 200) {
                    var result = response.data.data.bookingConfirmation
                    var dataContainer = result.bookingConfirmationContainers;
                    setDateFormat(result.bookingDate, setBookingDate);
                    rowStatus = result.rowStatus;
                    setRowStatus(result.rowStatus);
                    setDateFormat(result.approvedOn, setApprovalDate);
                    setBookingNo(result.bookingNo);
                    setJobOwnerId(result.jobOwnerId);
                    setReference(result.reference)
                    setShipmentStatusId(result.shipmentStatusId)
                    if (result.shipmentStatusId === 1 && result.approved === false) {
                        setEnableMarketing(false);
                    }
                    else {
                        setEnableMarketing(true);
                    }
                    setRefShipper(result.siReference)
                    setContactTypeId(result.contactTypeId)
                    setJobTypeId(result.jobTypeId)
                    setJobTypeCode(result.jobTypeCode)
                    setMarketId(result.marketId)
                    setMarketName(result.marketName)
                    setExRate(convertToNominal(result.exRate))
                    setInfossNo(result.noInfoss)
                    setExRateReal(result.exRate)
                    if (isCopy !== 'true') {
                        rate = result.exRate;
                    }
                    setIdCustomer(result.customerId)
                    setCustomerName(result.customerName)
                    setCustomerAddress(result.customerAddress)
                    setPic(result.picName)
                    setPhone(result.picPhone)
                    setEmail(result.picEmail)
                    setPlaceOfReceiptId(result.receiptCityId)
                    setPlaceOfReceiptCode(result.receiptCityCode)
                    setPlaceOfReceipt(result.receiptCityName)
                    setPlaceOfDeliveryId(result.deliveryCityId)
                    setPlaceOfDeliveryCode(result.deliveryCityCode)
                    setPlaceOfDelivery(result.deliveryCityName)
                    setPortOfLoadingId(result.loadingPortId)
                    setPortOfLoadingCode(result.loadingPortCode)
                    setPortOfLoading(result.loadingPortName)
                    setPortOfDischargeId(result.dischargePortId)
                    setPortOfDischargeCode(result.dischargePortCode)
                    setPortOfDischarge(result.dischargePortName)
                    setAirportDepartureId(result.departureAirportId)
                    setAirportDepartureCode(result.departureAirportCode)
                    setAirportDeparture(result.departureAirportName)
                    setAirportDestinationId(result.destinationAirportId)
                    setAirportDestinationCode(result.destinationAirportCode)
                    setAirportDestination(result.destinationAirportName)
                    setCargoDescription(result.goodDescription)
                    setFinalDestination(result.finalDestination)
                    setRemarks(result.remarks)
                    setIdCarrier(result.carrierId)
                    setCarrierName(result.carrierName)
                    setDateFormat(result.stuffingDate, setStuffingDate);
                    setStuffingPlace(result.stuffingPlace)
                    setStuffingNote(result.stuffingNote)
                    setCreditTerm(result.creditTerm)
                    setServiceType(result.serviceType)
                    setFreight(result.freight)
                    setShipmentVolume(result.shipmentVolume)
                    setShipmentVolume20(result.shipmentVolume20)
                    if (result.shipmentVolume20 > 0) {
                        setIsVolume20(true);
                    }
                    setShipmentVolume40(result.shipmentVolume40)
                    if (result.shipmentVolume40 > 0) {
                        setIsVolume40(true);
                    }
                    setShipmentVolume45(result.shipmentVolume45)
                    if (result.shipmentVolume45 > 0) {
                        setIsVolume45(true);
                    }
                    setShipmentVolumeCBM(result.shipmentVolumeCBM)
                    if (result.shipmentVolumeCBM) {
                        setIsVolumeCBM(true);
                    }
                    setShipmentVolumeCollie(result.shipmentVolumeCollie)
                    setShipmentVolumeKGS(result.shipmentVolumeKGS)
                    setIdBookingParty(result.bookingPartyId)
                    setBookingPartyName(result.bookingPartyName)
                    setBookingPartyAddress(result.bookingPartyAddress)
                    setVesselId(result.vesselId)
                    setVessel(result.vesselName)
                    setVoyageNo(result.voyageNo)
                    setAirlineId(result.flightId)
                    setAirline(result.flightName)
                    setFlightNo(result.flightNo)
                    setFlightAb(result.flightAbbrevation)
                    setDateFormat(result.etd, setETD, false, isApprove ? false : true);
                    setDateFormat(result.eta, setETA, false, isApprove ? false : true);
                    setBuyingRateInUSD(result.buyingRateInUSD)
                    setSellingRateInUSD(result.sellingRateInUSD)
                    setIdAgent(result.agentId)
                    setAgentName(result.agentName)
                    setIdEMKL(result.emklId)
                    setEMKLName(result.emklName)
                    setIdDepo(result.depoId)
                    setDepoName(result.depoName)
                    setIdShipper(result.shipperId)
                    setShipperName(result.shipperName)
                    setShipperAddress(result.shipperAddress)
                    setIdConsignee(result.consigneeId)
                    setConsigneeName(result.consigneeName)
                    setConsigneeAddress(result.consigneeAddress)
                    setMarksNumber(result.marksNumber)
                    setCargoDescription(result.cargoDescription)
                    setNumberOfPackages(result.numberOfPackages)
                    setPackagingType(result.packagingType)
                    setGrossWeight(result.grossWeight)
                    setVolume(result.volume)
                    setChargeableWeightVolume(result.chargeableWeightVolume)
                    setSpecialInstructions(result.specialInstructions)
                    setCreditTermUsdReal(result.creditTermInUSD)
                    setCreditTermIdrReal(result.creditTermInIDR)
                    setCreditTermUsd(convertToNominal(result.creditTermInUSD))
                    setCreditTermIdr(convertToNominal(result.creditTermInIDR))
                    setCreditTermDays(result.creditTermInDays)
                    setJobType(result.jobTypeName)
                    setIsApproved(result.approved)
                    setWfComplete(result.wfComplete)
                    setTicketId(result.ticketId)

                    var dataDetail = result.bookingConfirmationDetails;

                    if (isCopy === 'true') {
                        dataDetail.forEach(element => {
                            element.originalUsd = 0;
                            element.originalRate = 0;
                            element.amount = 0;
                            element.perQty = 0;
                            element.quantity = 0;
                        });
                    }

                    var dataDetailIncome = dataDetail.filter(row => row.isIncome === true)
                    var dataDetailCost = dataDetail.filter(row => row.isIncome === false)
                    setDataIncome(dataDetailIncome)
                    setDataCost(dataDetailCost)

                    calculateBookingData(dataDetail, rate)
                    setIsEdit(true);

                    if (!result.approved) {
                        let params = [{ 'attr': 'contactId', 'value': '' + result.customerId }]

                        ApiService.DynamicPostByPage(paramContact, 'SEARCH', 1, 1, params, [])
                            .then((res) => {
                                if (res.data.code === 200) {
                                    var result = res.data.data.contact[0];
                                    setCreditTermDays(result.creditTermInDays)
                                    setCreditTermUsdReal(result.creditTermInUSD)
                                    setCreditTermIdrReal(result.creditTermInIDR)
                                    let formatUSD = convertToNominal(result.creditTermInUSD);
                                    let formatIDR = convertToNominal(result.creditTermInIDR);
                                    setCreditTermUsd(formatUSD)
                                    setCreditTermIdr(formatIDR)
                                }
                            })
                            .catch(function (error) {
                                console.error('error saat fetch', error)
                                showToast({ type: 'error', message: error.toString() });
                            })
                    }

                    isApprove = result.approved;

                    handleDocumentStatus(rowStatus, isApprove, result.wfComplete);

                    if (isCopy === 'true') {
                        setBookingStatus(0)
                        setBookingNo('');
                        setReference('')
                        setIsApproved(false);
                        isApprove = false;

                        if (result.shipmentStatusId === 1) {
                            setEnableMarketing(false);
                        }
                        else {
                            setEnableMarketing(true);
                        }

                        GetExRateDate(true);
                        setWfComplete(2);
                        setRefShipper('');

                        setVesselId(0);
                        setVessel('');
                        setVoyageNo('');
                        setAirlineId(0);
                        setAirline('');
                        setFlightNo('');
                        setFlightAb('');
                        setInfossNo('');

                        setIdAgent(0);
                        setAgentName('');
                        setIdEMKL(0);
                        setEMKLName('');
                        setIdDepo(0);
                        setDepoName('');
                        setIdCarrier(0);
                        setCarrierName('');

                        setMarksNumber('');
                        setCargoDescription('');
                        setNumberOfPackages('');
                        setPackagingType('');
                        setGrossWeight('');
                        setVolume('');
                        setChargeableWeightVolume('');
                        setSpecialInstructions('');

                        setStuffingDate('');
                        setStuffingPlace('');
                        setStuffingNote('');
                        setServiceType('None');
                        setFreight('P');
                        setCreditTerm(false);
                        setShipmentVolume('Others');
                        setShipmentVolume20(0);
                        setShipmentVolume40(0);
                        setShipmentVolumeAll(0);
                        setShipmentVolumeCBM(0);
                        setShipmentVolumeCollie(0);
                        setShipmentVolumeKGS(0);

                        setCargoDescription('');

                        handleDocumentStatus('ACT', false, 2);
                        getDateNow();

                        let params = [{ 'attr': 'contactId', 'value': '' + result.customerId }]

                        ApiService.DynamicPostByPage(paramContact, 'SEARCH', 1, 1, params, [])
                            .then((res) => {
                                if (res.data.code === 200) {
                                    var result = res.data.data.contact[0];
                                    setCreditTermDays(result.creditTermInDays)
                                    setCreditTermUsdReal(result.creditTermInUSD)
                                    setCreditTermIdrReal(result.creditTermInIDR)
                                    let formatUSD = convertToNominal(result.creditTermInUSD);
                                    let formatIDR = convertToNominal(result.creditTermInIDR);
                                    setCreditTermUsd(formatUSD)
                                    setCreditTermIdr(formatIDR)
                                }
                            })
                            .catch(function (error) {
                                console.error('error saat fetch', error)
                                showToast({ type: 'error', message: error.toString() });
                            })

                        ApiService.PostByExRateDate()
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let count = convertToNominal(response.data.data.exchangeRate.exRate1);
                                    setExRate(count);
                                    setExRateReal(response.data.data.exchangeRate.exRate1);
                                    calculateBookingData(dataDetail, response.data.data.exchangeRate.exRate1)
                                }
                            })
                            .catch(function (error) {
                                console.error('error saat fetch', error)
                                showToast({ type: 'error', message: error.toString() });
                            })
                    }

                    if (dataContainer !== undefined) {
                        setContainerData(dataContainer);
                        getContainerSizeData(dataContainer);
                    }

                    ApiService.PostById(paramRegJobType, result.jobTypeId)
                        .then((res) => {
                            if (res.data.code === 200) {
                                var resulted = res.data.data.jobType;
                                setJobType(resulted.name);
                                setJobServiceType(resulted.serviceType ? resulted.serviceType : 'Domestic');
                                setPortType(resulted.portType ? resulted.portType : 'DOM');

                                if (isCopy === 'true') {
                                    if (resulted.serviceType === 'Export') {
                                        if (result.shipperId === 0) {
                                            setIdShipper(result.customerId)
                                            setShipperName(result.customerName)
                                            setShipperAddress(result.customerAddress)
                                        }

                                        setIdConsignee(0)
                                        setConsigneeName('')
                                        setConsigneeAddress('')
                                    }
                                    else if (resulted.serviceType === 'Import') {
                                        if (result.consigneeId === 0) {
                                            setIdConsignee(result.customerId)
                                            setConsigneeName(result.customerName)
                                            setConsigneeAddress(result.customerAddress)
                                        }

                                        setIdShipper(0)
                                        setShipperName('')
                                        setShipperAddress('')
                                    }
                                    else {
                                        if (result.shipperId === 0) {
                                            setIdShipper(result.customerId)
                                            setShipperName(result.customerName)
                                            setShipperAddress(result.customerAddress)
                                        }

                                        setIdConsignee(0)
                                        setConsigneeName('')
                                        setConsigneeAddress('')
                                    }
                                }
                            }
                        })
                        .catch(function (error) {
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })

                    if (!dataLocal) {
                        setIsLoading(false)
                    }
                }
                else {
                    errorToast('booking confirmation')
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                errorToast('booking confirmation')
                Back();
            })
    }

    function getDateNow() {
        if (!dataLocal) {
            setIsLoading(false)
        }
        setDateFormat(new Date(), setBookingDate);
        setDateFormat(new Date(), setETD);
        setDateFormat(new Date(), setETA);
        setDateFormat(new Date(), setStuffingDate);
        calculateBookingData([], 0);
        setCreditTermUsd(convertToNominal(0));
        setCreditTermIdr(convertToNominal(0));
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoading(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        const dataUA = response.data.data.userAccess;
                        setDataRegUserAccess(dataUA);

                        if (isFetchingParallel) {
                            getAdditionalData()
                        } else {
                            GetJobType(1, 20);
                        }
                    } else {
                        showToast({ type: 'error', message: "You don't have access to this page." });
                        Back();
                    }
                })
                .catch(function (error) {
                    setIsLoading(false)
                    showToast({ type: 'error', message: "You don't have access to this page." });
                    Back()
                })
        } else {
            showToast({ type: 'error', message: "You don't have access to this page." });
            history('/Majura');
        }
    }

    // *** EXPERIMENTAL PARALLEL FETCH API
    const getAdditionalData = async () => {
        try {
            const [resJT, resJO, resSS, resCT, resPT] = await Promise.all([
                ApiService.PostByPage(paramRegJobType, 1, 999),
                ApiService.PostByPage(paramJobOwner, 1, 999),
                ApiService.PostByPage(paramStatusShipment, 1, 999),
                ApiService.PostByPage(paramContainerType, 1, 999),
                ApiService.PostByPage(paramPackagingType, 1, 999)
            ])

            if (resJT.data.code === 200) {
                setDataJobType(resJT.data.data.jobType);
            } else {
                errorToast('job type')
                Back()
            }

            if (resJO.data.code === 200) {
                setJobOwnerId(resJO.data.data.jobOwner?.[0]?.id || 0);
                setJobOwnerData(resJO.data.data.jobOwner);
            } else {
                errorToast('job owner')
                Back()
            }

            if (resSS.data.code === 200) {
                setDataStatusShipment(resSS.data.data.shipmentStatus);
            } else {
                errorToast('shipment status')
                Back()
            }

            if (resCT.data.code === 200) {
                setDataContainerType(resCT.data.data.containerType);
            } else {
                errorToast('container type')
                Back()
            }

            if (resPT.data.code === 200) {
                setDataPackagingType(resPT.data.data.packagingType);
            } else {
                errorToast('packaging type')
                Back()
            }

            GetExRateDate(false);
        } catch (error) {
            errorToast('additional')
            Back()
        }
    }

    const GetJobType = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramRegJobType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    GetStatusShipment(1, 20);
                    GetJobOwnerData(1, 20);
                } else {
                    errorToast('job type')
                    Back();
                }
            })
            .catch(function (error) {
                errorToast('job type')
                Back();
            })
    }

    const GetJobOwnerData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobOwnerId(response.data.data.jobOwner?.[0]?.id || 0);
                    setJobOwnerData(response.data.data.jobOwner);
                } else {
                    errorToast('job owner')
                    Back();
                }
            })
            .catch(function (error) {
                errorToast('job owner')
                Back();
            })
    };

    const selectPrincipleBy = (isDisabled) => (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                value={jobOwnerId}
                fullWidth={true}
                placeholder={'Select Principle By'}
                onChange={(e) => setJobOwnerId(e.target.value)}
                disabled={isDisabled}
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetStatusShipment = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramStatusShipment, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataStatusShipment(response.data.data.shipmentStatus);

                    GetContainerType(1, 50);
                }
                else {
                    errorToast('status shipment')
                    Back();
                }
            })
            .catch(function (error) {
                errorToast('status shipment')
                Back();
            })
    }

    const GetContainerType = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramContainerType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataContainerType(response.data.data.containerType);

                    GetPackagingType(1, 50);
                }
                else {
                    errorToast('container type')
                    Back();
                }
            })
            .catch(function (error) {
                errorToast('container type')
                Back();
            })
    }

    const GetPackagingType = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramPackagingType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataPackagingType(response.data.data.packagingType);

                    GetExRateDate(false);
                }
                else {
                    errorToast('packaging type')
                    Back();
                }
            })
            .catch(function (error) {
                errorToast('packaging type')
                Back();
            })
    }

    const GetExRateDate = (isBool) => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    let count = convertToNominal(response.data.data.exchangeRate.exRate1);
                    setExRate(count);
                    setExRateReal(response.data.data.exchangeRate.exRate1);

                    if (dataLocal) {
                        setIsLoading(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        if (bookingId) {
                            if (isBool === false) {
                                getData(bookingId);
                                setBookingStatus(1);
                            }
                        }
                        else {
                            getDateNow()
                            if (JSON.parse(localStorage.getItem('roleCode')) === 'MKT' && JSON.parse(localStorage.getItem('roleId')) !== 9) {
                                setMarketingUser(JSON.parse(localStorage.getItem('employeeId')));
                            }
                            handleDocumentStatus('ACT', false, 2);
                        }
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Failed to get exchange rate data., ' + response.data.message });
                    Back();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: 'Failed to get exchange rate data., ' + error });
                Back();
            })
    }

    function setMarketingUser(id) {
        ApiService.PostById(paramMarketing, id)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.employee;
                    setShipmentStatusId(1);
                    setMarketId(data.id);
                    setMarketName(data.name);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleClickUsage = () => {
        if (isModalUsage === false) {
            setIsModalUsage(true);
        }
        else {
            setIsModalUsage(false);
        }
    }

    function ConfirmationBC() {
        if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Un Approve') {
            UnApprove();
        }
        else if (titleConfirmation === 'Submit') {
            Save(true);
        }
        else if (titleConfirmation === 'Print') {
            checkDataCondition('Print');
        }
        setIsModalConfirmation(false);
    }

    const checkDataCondition = (isAction) => {
        if (bookingId) {
            setIsLoading(true);
            ApiService.OperationPostById(paramBooking, bookingId)
                .then((response) => {
                    if (response.data.code === 200) {
                        setIsLoading(false);
                        let data = response.data.data.bookingConfirmation;

                        if (isAction === 'Save') {
                            if (data.approved && isCopy !== 'true') {
                                getData(bookingId);
                                showToast({ type: 'error', message: 'Data Already Approved!' });
                            }
                            else {
                                let isSubmit = data.wfComplete;
                                Save(isSubmit === 19 ? true : false, false);
                            }
                        }
                        else if (isAction === 'Print') {
                            if (data.approved) {
                                UpdateStatusPrint();
                            } else {
                                let isSubmit = data.wfComplete;
                                Save(isSubmit === 19 ? true : false, true);
                            }
                        }
                        else if (isAction === 'PrintPDF') {
                            setTitleConfirmation('Print')
                            setTitleModalConfirmation('Booking Confirmation');
                            handleClickConfirmation();
                        }
                        else if (isAction === 'Approve') {
                            if (data.approved) {
                                getData(bookingId);
                                showToast({ type: 'error', message: 'Already Approved!' });
                            }
                            else if (data.wfComplete !== 19) {
                                getData(bookingId);
                                showToast({ type: 'error', message: 'Please Submit The Data First!' });
                            }
                            else {
                                if (checkBalanceCTC() === true) {
                                    setTitleConfirmation('Approve')
                                    setTitleModalConfirmation('Booking Confirmation');
                                    handleClickConfirmation();
                                }
                                else {
                                    showToast({ type: 'error', message: 'CTC Detail Not Balance!' });
                                }
                            }
                        }
                        else if (isAction === 'Un Approve') {
                            if (data.shipmentId === 0) {
                                if (data.approved) {
                                    setTitleConfirmation('Un Approve')
                                    setTitleModalConfirmation('Booking Confirmation');
                                    handleClickConfirmation();
                                }
                                else {
                                    getData(bookingId);
                                    showToast({ type: 'error', message: "Data Hasn't Been Approved Yet!" });
                                }
                            }
                            else {
                                getDataShipment(data.shipmentId);
                            }
                        }
                    }
                    else {
                        setIsLoading(false);
                        showToast({ type: 'error', message: 'Error When Read Data!, ' + response.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Read Data!, ' + error });
                })
        }
        else if (isAction === 'Save') {
            Save(false, false);
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const getDataShipment = (shipmentId) => {
        let sequence = 1;
        let tempArray = [];

        ApiService.OperationPostById('shipmentOrder/shipmentOrder', shipmentId)
            .then((response) => {
                if (response.data.code === 200) {
                    let temp = response.data.data.shipmentOrder;

                    tempArray = [
                        {
                            'sequence': sequence,
                            'usedOn': 'Shipment Order',
                            'transactionNumber': temp.shipmentNo
                        }
                    ];

                    setUsageDataList(tempArray);
                    setTitleModalUsage('Booking Confirmation');
                    handleClickUsage();

                    setIsLoading(false);
                    showToast({ type: 'error', message: "Booking Confirmation Already Used in Shipment Order" });
                }
            })
            .catch(function (error) {
                setIsLoading(false);

                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    function handleApproveData(approveRemarks) {
        Save(true, true, 'Approve', approveRemarks);
    }

    function Save(isSubmit = false, isPrint = false, type = 'Print', approveRemarks = '') {
        if (isLoading) return
        setIsLoading(true);

        let id = 0;
        if (bookingId) {
            if (isCopy === 'true') {
                id = 0;
            }
            else {
                id = parseInt(bookingId);
            }
        }

        let WFComplete = wfComplete
        if (isSubmit) {
            WFComplete = 19
        }

        let stuffDate = stuffingDate !== '' ? stuffingDate : defaultDate;
        let etd = ETD !== '' ? ETD : defaultDate;
        let eta = ETA !== '' ? ETA : defaultDate;
        let bookDate = bookingDate !== '' ? bookingDate : defaultDate;

        var dataDetail = [...dataIncome, ...dataCost]

        var data = {
            "bookingConfirmation": {
                "rowStatus": rowStatus,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "ticketId": 0,
                "bookingNo": bookingNo,
                "bookingNo2": "",
                "bookingDate": bookDate,
                "reference": reference,
                "shipmentStatusId": shipmentStatusId,
                "siReference": refShipper,
                "contactTypeId": contactTypeId,
                "jobTypeId": jobTypeId,
                "jobTypeCode": jobTypeCode,
                "marketId": marketId,
                "marketName": marketName,
                "exRate": exRateReal,
                "customerId": idCustomer,
                "customerName": CustomerName,
                "customerAddress": CustomerAddress,
                "picName": pic,
                "picPhone": phone,
                "picEmail": email,
                "receiptCityId": placeOfReceiptId,
                "receiptCityCode": placeOfReceiptCode,
                "receiptCityName": placeOfReceipt,
                "deliveryCityId": placeOfDeliveryId,
                "deliveryCityCode": placeOfDeliveryCode,
                "deliveryCityName": placeOfDelivery,
                "loadingPortId": portOfLoadingId,
                "loadingPortCode": portOfLoadingCode,
                "loadingPortName": portOfLoading,
                "dischargePortId": portOfDischargeId,
                "dischargePortCode": portOfDischargeCode,
                "dischargePortName": portOfDischarge,
                "departureAirportId": airportDepartureId,
                "departureAirportCode": airportDepartureCode,
                "departureAirportName": airportDeparture,
                "destinationAirportId": airportDestinationId,
                "destinationAirportCode": airportDestinationCode,
                "destinationAirportName": airportDestination,
                "goodDescription": cargoDescription,
                "finalDestination": finalDestination,
                "remarks": remarks,
                "carrierId": idCarrier,
                "carrierName": carrierName,
                "stuffingDate": stuffDate,
                "stuffingPlace": stuffingPlace,
                "stuffingNote": stuffingNote,
                "creditTerm": Boolean(creditTerm),
                "serviceType": serviceType,
                "freight": freight,
                "shipmentVolume": shipmentVolume,
                "shipmentVolume20": parseInt(shipmentVolume20),
                "shipmentVolume40": parseInt(shipmentVolume40),
                "shipmentVolume45": parseInt(shipmentVolume45),
                "shipmentVolumeAll": parseInt(shipmentVolumeAll),
                "shipmentVolumeCBM": parseFloat(shipmentVolumeCBM),
                "shipmentVolumeCollie": parseFloat(shipmentVolumeCollie),
                "shipmentVolumeKGS": parseFloat(shipmentVolumeKGS),
                "validUntil": defaultDate,
                "approved": false,
                "approvedBy": "",
                "approvedOn": defaultDate,
                "printing": 0,
                "printedOn": defaultDate,
                "deleted": false,
                "deletedBy": "",
                "deletedOn": defaultDate,
                "saveShipmentOrder": false,
                "bookingPartyId": idBookingParty,
                "bookingPartyName": BookingPartyName,
                "bookingPartyAddress": BookingPartyAddress,
                "approvedCreditLimit": false,
                "approvedCreditLimitBy": '0',
                "approvedCreditLimitOn": defaultDate,
                "approvedCreditLimitRemarks": "",
                "vesselId": vesselId,
                "vesselName": vessel,
                "voyageNo": voyageNo,
                "flightId": airlineId,
                "flightName": airline,
                "flightNo": flightNo,
                "flightAbbrevation": flightAb,
                "etd": etd,
                "eta": eta,
                "buyingRateInUSD": convertToNumeric(buyingRateInUSD),
                "sellingRateInUSD": convertToNumeric(sellingRateInUSD),
                "containerTypeId": containerTypeId,
                "agentId": idAgent,
                "agentName": agentName,
                "agentAddress": agentAddress,
                "emklId": idEMKL,
                "emklName": EMKLName,
                "depoId": idDepo,
                "depoName": depoName,
                "nonProfitMarginRemarks": "",
                "isNonProfitMargin": true,
                "bookingStatus": 0,
                "shipperId": idShipper,
                "shipperName": ShipperName,
                "shipperAddress": ShipperAddress,
                "consigneeId": idConsignee,
                "consigneeName": ConsigneeName,
                "consigneeAddress": ConsigneeAddress,
                "marksNumber": marksNumber,
                "cargoDescription": cargoDescription,
                "numberOfPackages": numberOfPackages,
                "packagingType": packagingType,
                "grossWeight": grossWeight,
                "volume": volume,
                "chargeableWeightVolume": chargeableWeightVolume,
                "specialInstructions": specialInstructions,
                "wfComplete": WFComplete,
                "userCode": userCode,
                "userId": userId,
                "creditTermInDays": creditTermDays,
                "creditTermInIDR": creditTermIdrReal,
                "creditTermInUSD": creditTermUsdReal,
                "jobTypeName": jobType,
                'jobOwnerId': jobOwnerId,
                'noInfoss': infossNo
            },
            "bookingConfirmationDetails": dataDetail,
            "bookingConfirmationContainers": containerData
        }

        let message = undefined;
        const phoneRegex = /^[\d+\-\s]{9,14}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!isApproved) {
            if (jobTypeId === 0) {
                message = 'Please Input Job Type!';
            }
            else if (idCustomer === 0) {
                message = 'Please Input Customer!';
            }
            else if (pic === '') {
                message = 'Please Input PIC!';
                autoFocusField(picRef);
            }
            else if (placeOfReceiptId === 0) {
                message = 'Please Input Place of Receipt!';
                if (tabValue !== 'Details') {
                    setTabValue('Details');

                    setTimeout(
                        function () {
                            autoFocusField(receiptRef, setTabValue);
                        },
                        500);
                } else {
                    autoFocusField(receiptRef, setTabValue);
                }
            }
            else if (placeOfDeliveryId === 0) {
                message = 'Please Input Place of Delivery!';
                if (tabValue !== 'Details') {
                    setTabValue('Details');

                    setTimeout(
                        function () {
                            autoFocusField(deliveryRef, setTabValue);
                        },
                        500);
                } else {
                    autoFocusField(deliveryRef, setTabValue);
                }
            }
            else if (data.bookingConfirmation.picPhone !== '' && !phoneRegex.test(data.bookingConfirmation.picPhone)) {
                message = 'Please Input The Correct Phone Number Format!';
                autoFocusField(phoneRef);
            }
            else if (data.bookingConfirmation.picEmail !== '' && !emailRegex.test(data.bookingConfirmation.picEmail)) {
                message = 'Please Input The Correct Email Format!';
                autoFocusField(emailRef);
            }
            else if (parseInt(shipmentStatusId) === 1 && (marketId === 0 || marketName === '')) {
                message = 'Please Select Marketing Name or Change The Shipment Status!';
                autoFocusField(marketingRef);
            }
            else if (new Date(data.bookingConfirmation.eta) < new Date(data.bookingConfirmation.etd)) {
                message = 'ETA Must Be Greater Than or Same As ETD!';
                autoFocusField(estimateTimeRef, setTabValue);
            }
            else if (data.bookingConfirmation.noInfoss === '' && isInfossNo) {
                message = 'Please Fill Infoss Number!';
                autoFocusField(infossNoRef);
            }

            if (message === undefined) {
                ApiService.HelpCreateData(data, paramBooking, id)
                    .then((res) => {
                        if (res.data.code === 200) {
                            if (id === 0) {
                                ApiService.CreateOperation(paramBooking, data)
                                    .then((res) => {
                                        if (res.data.code === 200) {
                                            message = 'Success create data';

                                            showToast({ type: 'success', message: message });

                                            setTimeout(
                                                function () {
                                                    setIsLoading(false);
                                                    localStorage.setItem("jobTypeId", JSON.stringify(data.bookingConfirmation.jobTypeId));
                                                    localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                    history("/Majura/BookingConfirmation/" + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                    window.location.reload();
                                                    localStorage.removeItem('tempId');
                                                },
                                                1250);
                                        }
                                        else {
                                            setIsLoading(false);
                                            showToast({ type: 'error', message: res.data.error });
                                        }
                                    })
                                    .catch(function (error) {
                                        setIsLoading(false);
                                        console.log(error);
                                        showToast({ type: 'error', message: 'Data Format Aren\'t Match with Table Format, ' + error });
                                    });
                            }
                            else {
                                ApiService.EditOperation(paramBooking, data)
                                    .then((res) => {
                                        if (res.data.code === 200) {
                                            if (!isPrint) {
                                                if (!isSubmit) {
                                                    message = 'Success submit data';
                                                }
                                                else {
                                                    message = 'Success update data';
                                                }

                                                showToast({ type: 'success', message: message });

                                                setTimeout(
                                                    function () {
                                                        setIsLoading(false);
                                                        localStorage.setItem("jobTypeId", JSON.stringify(data.bookingConfirmation.jobTypeId));
                                                        history("/Majura/BookingConfirmation/" + bookingId, { replace: true });
                                                        window.location.reload();
                                                    },
                                                    1250);
                                            }
                                            else {
                                                if (type === 'Print') {
                                                    UpdateStatusPrint();
                                                }
                                                else {
                                                    if (isApprovalCredit) {
                                                        ApprovalCreditData(approveRemarks);
                                                    }
                                                    else {
                                                        ApproveData();
                                                    }
                                                }
                                            }
                                        }
                                        else {
                                            setIsLoading(false);
                                            showToast({ type: 'error', message: res.data.error });
                                        }
                                    })
                                    .catch(function (error) {
                                        setIsLoading(false);
                                        console.log(error);
                                        showToast({ type: 'error', message: 'Data Format Aren\'t Match with Table Format, ' + error });
                                    });
                            }
                        }
                        else {
                            setIsLoading(false);
                            showToast({ type: 'error', message: 'Failed to Create Help Data' });
                        }
                    })
                    .catch(function (error) {
                        setIsLoading(false);
                        showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator' });
                    })
            }
            else {
                setIsLoading(false);
                showToast({ type: 'error', message: message });
            }
        }
        else {
            setIsLoading(false);
            showToast({ type: 'error', message: 'Can\'t Save or Submit Because Data Already Approved!' });
        }

        localStorage.removeItem('DataBooking')
    }

    const SubmitData = () => {
        if (wfComplete === 19) {
            showToast({ type: 'error', message: 'Data Already Submitted!' });
        }
        else {
            setTitleConfirmation('Submit')
            setTitleModalConfirmation('Booking Confirmation');
            handleClickConfirmation();
        }
    }

    function Back() {
        if (!isToDo) {
            history('/Majura/BookingConfirmation')
        } else {
            history('/Majura')
            window.location.reload()
        }
        localStorage.removeItem('DataBooking')
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);

            setIsRemarks(false);
            setCreditRemarks('');
            setIsCustomNote(false);
            setCustomNote('');
        }
    }

    function UpdateStatusPrint() {
        if (isCopy !== 'true') {
            setIsLoading(true);
            ApiService.OperationUpdateStatusPrint(paramBooking, bookingId).then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    let reportLink = 'Transactions/Operation/GeneralReport/GeneralReport/PrintBookingConfirmation?Id=' + bookingId;
                    PrintPDF(setIsLoading, 'Booking Confirmation', reportLink, [], true);
                }
                else {
                    showToast({ type: 'error', message: message });
                }
            });
        }
        else {
            showToast({ type: 'error', message: 'Please Create The Booking Confirmation First!' });
        }
    }

    const handleClickConfirmEPL = () => {
        if (isModalConfirmEPL === false) {
            setIsModalConfirmEPL(true);
        }
        else {
            setIsModalConfirmEPL(false);
        }
    }

    const handleClickApproval = () => {
        if (isModalApproval === false) {
            setIsModalApproval(true);
        }
        else {
            setIsModalApproval(false);
            setApprovalDataList([]);
            setApprovalReason('');
            setCstName('');
            setCreditType('');
        }
    }

    const checkBalanceCTC = () => {
        let InIDR = 0;
        let InUSD = 0;
        let CoIDR = 0;
        let CoUSD = 0;

        dataIncome.forEach(element => {
            if (element.isCostToCost === true) {
                if (element.amountCrr === 0) {
                    InUSD = InUSD + element.amount;
                }
                else {
                    InIDR = InIDR + element.amount;
                }
            }
        });

        dataCost.forEach(element => {
            if (element.isCostToCost === true) {
                if (element.amountCrr === 0) {
                    CoUSD = CoUSD + element.amount;
                }
                else {
                    CoIDR = CoIDR + element.amount;
                }
            }
        });

        if (InUSD === CoUSD && InIDR === CoIDR) {
            return true;
        }

        return false;
    }

    function Approve() {
        setIsLoading(true);
        const dataDetail = [...dataIncome, ...dataCost];

        if (!dataDetail?.length) {
            setIsLoading(false);
            showToast({ type: 'error', message: 'Please Input EPL Data!' });
            return;
        }

        const checkAndSetContact = (id, name, title) => {
            if (id !== 0 && !dataDetail.some(item => item.contactId === id)) {
                setTempTitle(title);
                setTempContactName(name);
                return true;
            }
            return false;
        };

        if (
            checkAndSetContact(idAgent, agentName, 'Agent') ||
            checkAndSetContact(idEMKL, EMKLName, 'EMKL') ||
            checkAndSetContact(idDepo, depoName, 'Depo') ||
            checkAndSetContact(idCarrier, carrierName, 'Carrier')
        ) {
            setIsLoading(false);
            handleClickConfirmEPL();
            return;
        }

        checkCreditTermLimit();
    }

    function checkCreditTermLimit() {
        setIsLoading(true);
        setIsModalConfirmEPL(false);
        ApiService.OutstandingInvoicePostBySearch('shipmentOrder/shipmentOrder', idCustomer, 0, bookingId)
            .then(response => {
                if (response.data.code !== 200) {
                    setIsLoading(false);
                    showToast({ type: 'error', message: response.data.error });
                    return;
                }

                const { invoicesCreditTermDays, invoicesCreditTermAmount } = response.data.data.shipmentOrderOSInv;
                let tempArray = [];
                let creditType = '';
                let checked = false;

                if (invoicesCreditTermDays.length > 0) {
                    creditType = 'Days';
                    tempArray = invoicesCreditTermDays.map((item, index) => ({
                        sequence: index + 1,
                        usedOn: 'Invoice',
                        transactionNumber: item.invoiceNo,
                    }));
                    checked = true;
                }

                if (!checked) {
                    let totalIDR = convertToNumeric(totalIncomeIdr);
                    let totalUSD = convertToNumeric(totalIncomeUsd);
                    for (let item of invoicesCreditTermAmount) {
                        totalIDR += parseFloat(item.totalInIDR);
                        totalUSD += parseFloat(item.totalInUSD);
                        tempArray.push({
                            sequence: tempArray.length + 1,
                            usedOn: 'Invoice',
                            transactionNumber: item.invoiceNo,
                            amountIDR: item.totalInIDR,
                            amountUSD: item.totalInUSD,
                        });
                    }

                    const exceedsCreditLimit = convertToNumeric(totalIDR) > convertToNumeric(creditTermIdrReal) || convertToNumeric(totalUSD) > convertToNumeric(creditTermUsdReal);
                    if (exceedsCreditLimit) {
                        checked = true;
                        setApprovalIDR(totalIDR);
                        setApprovalUSD(totalUSD);
                        const exceedsCreditLimitIDR = convertToNumeric(totalIDR) > convertToNumeric(creditTermIdrReal);
                        creditType = exceedsCreditLimitIDR ? 'Amount IDR' : 'Amount USD';
                        tempArray.push({
                            sequence: tempArray.length + 1,
                            usedOn: 'Booking Confirmation',
                            transactionNumber: reference,
                            amountIDR: totalIncomeIdr,
                            amountUSD: totalIncomeUsd,
                        });
                    }
                    else {
                        checked = false;
                        creditType = '';
                        tempArray = [];
                    }
                }

                setIsLoading(false);

                if (!checked) {
                    setIsApprovalCredit(false);
                    Save(true, true, 'Approve');
                } else {
                    setIsApprovalCredit(true);
                    setApprovalDataList(tempArray);
                    setCstName(CustomerName);
                    setTitleModalApproval('Booking Confirmation');
                    setTitleApproval('Approve');
                    setCreditType(creditType);
                    handleClickApproval();
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error fetching data:', error);
            });
    }

    const ApprovalCreditData = (approvalReason) => {
        setIsLoading(true);
        setIsModalConfirmEPL(false);
        setIsModalApproval(false);

        let data = {
            "bookingConfirmation": {
                "approvedCreditLimitRemarks": approvalReason
            }
        }
        ApiService.ApprovalCreditOperation('bookingConfirmation/bookingConfirmation', bookingId, data)
            .then((res) => {
                let message = '';
                message = res.data.error;
                if (res.data.code === 200) {
                    ApproveData();
                }
                else {
                    setIsLoading(false);
                    showToast({ type: 'error', message: message });
                }
            })
    }

    const ApproveData = () => {
        setIsModalConfirmEPL(false);
        setIsLoading(true);
        ApiService.ApproveOperation('bookingConfirmation/bookingConfirmation', bookingId).then((res) => {
            let message = '';
            message = res.data.error;
            if (res.data.code === 200) {
                showToast({ type: 'success', message: "Approve Booking No " + bookingId });

                setTimeout(
                    function () {
                        setIsLoading(false);
                        history("/Majura/BookingConfirmation/" + bookingId, { replace: true });
                        window.location.reload()
                    },
                    1250);
            }
            else {
                setIsLoading(false);
                showToast({ type: 'error', message: message });
            }
        })
    }

    function UnApprove() {
        setIsLoading(true);
        ApiService.UnApproveOperation('bookingConfirmation/bookingConfirmation', bookingId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                showToast({ type: 'success', message: message });

                setTimeout(
                    function () {
                        setIsLoading(false);
                        history("/Majura/BookingConfirmation/" + bookingId, { replace: true });
                        window.location.reload()
                    },
                    1250);
            }
            else {
                setIsLoading(false);
                showToast({ type: 'error', message: message });
            }
        })
    }

    function Add() {
        window.open('/Majura/BookingConfirmation/Create', newWindowConfig);
    }

    const handleClickContact = () => {
        if (isModalContact === false) {
            setIsModalContact(true);
            getModalContact(1, 50, [])
        } else {
            setIsModalContact(false);
        }
    };

    const getModalContact = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingContact(true);
        var tipex = JSON.parse(localStorage.getItem("ContactType"));
        if (tipex === 23) {
            if (jobServiceType !== 'Import') {
                tipex = 2;
            }
            else {
                tipex = 3;
            }
        }
        if (tipex != null && tipex !== '') {
            if (tipex < 11) {
                ApiService.ContactPostByPage(paramContact, tipex, pageNumber, pageSize, filters, orderBy)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataContact({})
                            setDataContact(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapContact(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableContact, 'id');
                            setColumnDataContact(response.data.data.columns)
                            setTotalRowsContact(response.data.totalRowCount)

                            setIsLoadingContact(false)
                        }
                        setIsLoadingContact(false)
                    })
                    .catch(function (error) {
                        setIsLoadingContact(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data' });
                        setIsModalContact(false);
                    })
            }
            else {
                ApiService.ContactPostByPage(paramContact, 2, pageNumber, pageSize, filters, orderBy)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataContact({})
                            setDataContact(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapContact(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableContact, 'id');
                            setColumnDataContact(response.data.data.columns)
                            setTotalRowsContact(response.data.totalRowCount)

                            setIsLoadingContact(false)
                        }
                        setIsLoadingContact(false)
                    })
                    .catch(function (error) {
                        setIsLoadingContact(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data' });
                        setIsModalContact(false);
                    })
            }
        }
    }

    const handleClickModalData = () => {
        if (isModalData === false) {
            setIsModalData(true);
            getModalData(1, 50)
        } else {
            setIsModalData(false);
        }
    };

    const getModalData = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingModalData(true);
        let param = localStorage.getItem("paramModal")
        if (param != null && param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters, orderBy)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataModalData({})
                        let temp = [];
                        switch (param) {
                            case 'city/city':
                                setDataModalData(response.data.data.city);
                                temp = response.data.data.city
                                break;
                            case 'seaport/seaport':
                                setDataModalData(response.data.data.seaPort);
                                temp = response.data.data.seaPort
                                break;
                            case 'airport/airport':
                                setDataModalData(response.data.data.airPort);
                                temp = response.data.data.airPort
                                break;
                            case 'vessel/vessel':
                                temp = response.data.data.vessel
                                setDataModalData(response.data.data.vessel);
                                break;
                            case 'airline/airline':
                                setDataModalData(response.data.data.airLine);
                                temp = response.data.data.airLine
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapModalData(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableModal, 'id');
                        setColumnDataModalData(response.data.data.columns)
                        setTotalRowsModalData(response.data.totalRowCount)

                        setIsLoadingModalData(false)
                    }
                    setIsLoadingModalData(false)
                })
                .catch(function (error) {
                    setIsLoadingModalData(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data' });
                    setIsModalData(false);
                })
        }
    }

    const handleClickMarketing = () => {
        if (isModalMarketing) {
            setIsModalMarketing(false)
        } else {
            setIsModalMarketing(true)
            getDataMarketing(1, 50)
        }
    }

    const getDataMarketing = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingMarketing(true);
        let params = [{ 'attr': 'departmentCode', 'value': 'MKT' }];
        ApiService.DynamicPostByPage(paramMarketing, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataMarketing({})
                    setDataMarketing(response.data.data.employees);

                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapMarketing(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableMarketing, 'id');
                    setColumnDataMarketing(response.data.data.columns)
                    setTotalRowsMarketing(response.data.totalRowCount)

                    setIsLoadingMarketing(false)
                }
                setIsLoadingMarketing(false)
            })
            .catch(function (error) {
                setIsLoadingMarketing(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' });
                setIsModalMarketing(false)
            })
    }

    function calculateBookingData(result, rate) {
        let countIn = 0
        let countCo = 0
        let totalUsd = 0
        let totalIdr = 0
        let totalIncomeUsd = 0
        let totalIncomeIdr = 0
        let totalCostUsd = 0
        let totalCostIdr = 0

        let marginRatioProfit = 0;
        let marginRatioCost = 0;

        result.forEach(el => {
            if (el.isIncome) {
                if (el.amountCrr === 1) {
                    if (el.sign) {
                        totalIncomeIdr += el.amount
                    }
                    else {
                        totalIncomeIdr -= el.amount
                    }
                }
                else {
                    if (el.sign) {
                        totalIncomeUsd += el.amount
                    }
                    else {
                        totalIncomeUsd -= el.amount
                    }
                }

                countIn += 1;
            }
            else {
                if (el.amountCrr === 1) {
                    if (el.sign) {
                        totalCostIdr += el.amount
                    }
                    else {
                        totalCostIdr -= el.amount
                    }
                }
                else {
                    if (el.sign) {
                        totalCostUsd += el.amount
                    }
                    else {
                        totalCostUsd -= el.amount
                    }
                }

                countCo += 1;
            }
        })
        totalUsd = totalIncomeUsd - totalCostUsd
        totalIdr = totalIncomeIdr - totalCostIdr

        let formatTotalUSD = convertToNominal(totalUsd);
        let formatTotalIDR = convertToNominal(totalIdr);
        setTotalUsd(formatTotalUSD)
        setTotalIdr(formatTotalIDR)

        setTotalIncomeUsd(totalIncomeUsd)
        setTotalIncomeIdr(totalIncomeIdr)

        setTotalCostUsd(totalCostUsd)
        setTotalCostIdr(totalCostIdr)

        let totalIncomeInIdr = 0;
        let totalCostInIdr = 0;
        let marginRatio = 0;

        totalIncomeInIdr = ((parseFloat(totalIncomeUsd) * parseFloat(rate)) + parseFloat(totalIncomeIdr));
        totalCostInIdr = ((parseFloat(totalCostUsd) * parseFloat(rate)) + parseFloat(totalCostIdr));
        marginRatio = parseFloat(totalIncomeInIdr) - parseFloat(totalCostInIdr);

        if (countIn > 0) {
            if (totalIncomeInIdr > 0) {
                marginRatioProfit = (parseFloat(marginRatio / totalIncomeInIdr) * 100).toFixed(0);
            }
        }

        if (countCo > 0) {
            if (totalCostInIdr > 0) {
                marginRatioCost = (parseFloat(marginRatio / totalCostInIdr) * 100).toFixed(0);
            }
        }

        setMarginIncome(marginRatioProfit);
        setMarginCost(marginRatioCost);
    }

    function getContainerSizeData(containerData) {
        let count20 = 0;
        let count40 = 0;
        let count45 = 0;
        let countAll = 0;

        if (containerData?.length > 0) {
            for (let i = 0; i < containerData?.length; i++) {
                if (containerData[i].rowStatus !== 'DEL') {
                    if (containerData[i].containerSize === '20\'' || containerData[i].containerSize === '20\'HC') {
                        count20 = parseInt(count20) + parseInt(containerData[i].amount);
                        setIsVolume20(true);
                    }

                    if (containerData[i].containerSize === '40\'' || containerData[i].containerSize === '40\'HC') {
                        count40 = parseInt(count40) + parseInt(containerData[i].amount);
                        setIsVolume40(true);
                    }

                    if (containerData[i].containerSize === '45\'' || containerData[i].containerSize === '45\'HC') {
                        count45 = parseInt(count45) + parseInt(containerData[i].amount);
                        setIsVolume45(true);
                    }

                    containerData[i].formatContainer = containerData[i].containerSize + ' - ' + containerData[i].containerTypeCode;

                    countAll = parseInt(countAll) + parseInt(containerData[i].amount);
                }
            }
        }

        let arrayData = containerData;

        setShipmentVolume20(count20);
        setShipmentVolume40(count40);
        setShipmentVolume45(count45);
        setShipmentVolumeAll(countAll);
        setContainerData(arrayData);
    }

    function emptyState() {
        setIdCustomer(0);
        setCustomerName('');
        setCustomerAddress('');
        setIdShipper(0);
        setShipperName('');
        setShipperAddress('');
        setIdConsignee(0);
        setConsigneeName('');
        setConsigneeAddress('');
        setPic('');
        setEmail('');
        setPhone('');
        setIdCarrier(0);
        setCarrierName('');
        setVesselId(0);
        setVesselCode('');
        setVessel('');
        setVoyageNo('');
        setAirlineId(0);
        setAirlineCode('');
        setAirline('');
        setFlightNo('');
        setPortOfLoadingId(0);
        setPortOfLoadingCode('');
        setPortOfLoading('');
        setPortOfDischargeId(0);
        setPortOfDischargeCode('');
        setPortOfDischarge('');
        setAirportDepartureId(0);
        setAirportDepartureCode('');
        setAirportDeparture('');
        setAirportDestinationId(0);
        setAirportDestinationCode('');
        setAirportDestination('');
        setCreditTermDays(0);
        setCreditTermIdr('0');
        setCreditTermIdrReal(0);
        setCreditTermUsd('0');
        setCreditTermUsdReal(0);

        setDataIncome([]);
        setDataCost([]);
        calculateBookingData([], exRateReal);
    }

    function handleDocumentStatus(rowStatus, isApproved, wfComplete) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            if (wfComplete === 19) {
                setDocumentStatus('SUBMITTED');
            }
            else if (wfComplete === 2) {
                setDocumentStatus('DRAFT');
            }
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonBooking
                Save={() => checkDataCondition('Save')}
                Back={Back}
                Print={() => checkDataCondition('PrintPDF')}
                wfComplete={wfComplete}
                Approve={() => checkDataCondition('Approve')}
                Add={Add}
                isCopy={isCopy}
                Submit={SubmitData}
                UnApprove={() => checkDataCondition('Un Approve')}
                DataRegUserAccess={DataRegUserAccess}
                isEdit={isEdit}
                isApprove={isApproved}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            isCopy === 'true' ?
                                'Booking Confirmation (New Document)'
                                :
                                bookingId ?
                                    'Booking Confirmation (' + reference + ')'
                                    :
                                    'Booking Confirmation (New Document)'
                        }
                        BreadcrumbsItems={bookingId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={isCopy === 'true' ? 0 : ticketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <Box component="div">
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <MainBooking
                                    infossNo={infossNo}
                                    setInfossNo={setInfossNo}
                                    isEdit={isEdit}
                                    selectPrincipleBy={selectPrincipleBy}
                                    isCopy={isCopy}
                                    enableMarketing={enableMarketing}
                                    setEnableMarketing={setEnableMarketing}
                                    bookingNo={bookingNo}
                                    setBookingNo={setBookingNo}
                                    reference={reference}
                                    setReference={setReference}
                                    bookingDate={bookingDate}
                                    setBookingDate={setBookingDate}
                                    jobType={jobType}
                                    setJobType={setJobType}
                                    jobTypeId={jobTypeId}
                                    setJobTypeId={setJobTypeId}
                                    refShipper={refShipper}
                                    changeRefShipper={setRefShipper}
                                    bookingStatus={bookingStatus}
                                    setBookingStatus={setBookingStatus}
                                    dataJobType={dataJobType}
                                    paramRegJobType={paramRegJobType}
                                    emptyState={emptyState}
                                    GetJobType={GetJobType}
                                    shipmentStatusId={shipmentStatusId}
                                    setShipmentStatusId={setShipmentStatusId}
                                    contactTypeId={contactTypeId}
                                    setContactTypeId={setContactTypeId}
                                    dataStatusShipment={dataStatusShipment}
                                    setMarketId={setMarketId}
                                    marketName={marketName}
                                    setMarketName={setMarketName}
                                    setJobTypeCode={setJobTypeCode}
                                    isApproved={isApproved}
                                    approvalDate={approvalDate}
                                    setApprovalDate={setApprovalDate}

                                    portType={portType}
                                    setPortType={setPortType}
                                    jobServiceType={jobServiceType}
                                    setJobServiceType={setJobServiceType}

                                    handleClick={handleClickMarketing}
                                    setTitleModal={setTitleModalMarketing}
                                    setIsModal={setIsModalMarketing}

                                    marketingRef={marketingRef}
                                    infossNoRef={infossNoRef}
                                />
                            </CardContent>
                        </Card>

                        {
                            jobTypeId !== 0 ?
                                <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                    <CardContent>
                                        <TabsContact
                                            isEdit={isEdit}
                                            idCustomer={idCustomer}
                                            setIdCustomer={setIdCustomer}
                                            CustomerName={CustomerName}
                                            setCustomerName={setCustomerName}
                                            CustomerAddress={CustomerAddress}
                                            setCustomerAddress={setCustomerAddress}
                                            idShipper={idShipper}
                                            setIdShipper={setIdShipper}
                                            ShipperName={ShipperName}
                                            setShipperName={setShipperName}
                                            ShipperAddress={ShipperAddress}
                                            setShipperAddress={setShipperAddress}
                                            idConsignee={idConsignee}
                                            setIdConsignee={setIdConsignee}
                                            ConsigneeName={ConsigneeName}
                                            setConsigneeName={setConsigneeName}
                                            ConsigneeAddress={ConsigneeAddress}
                                            setConsigneeAddress={setConsigneeAddress}
                                            idBookingParty={idBookingParty}
                                            setIdBookingParty={setIdBookingParty}
                                            BookingPartyName={BookingPartyName}
                                            setBookingPartyName={setBookingPartyName}
                                            BookingPartyAddress={BookingPartyAddress}
                                            setBookingPartyAddress={setBookingPartyAddress}
                                            creditTermUsd={creditTermUsd}
                                            setCreditTermUsd={setCreditTermUsd}
                                            creditTermIdr={creditTermIdr}
                                            setCreditTermIdr={setCreditTermIdr}
                                            creditTermUsdReal={creditTermUsdReal}
                                            setCreditTermUsdReal={setCreditTermUsdReal}
                                            creditTermIdrReal={creditTermIdrReal}
                                            setCreditTermIdrReal={setCreditTermIdrReal}
                                            creditTermDays={creditTermDays}
                                            setCreditTermDays={setCreditTermDays}
                                            pic={pic}
                                            setPic={setPic}
                                            email={email}
                                            setEmail={setEmail}
                                            phone={phone}
                                            setPhone={setPhone}
                                            contactTypeId={contactTypeId}
                                            isApproved={isApproved}
                                            isCopy={isCopy}

                                            handleClick={handleClickContact}
                                            setTitleModal={setTitleModalContact}
                                            setTipe={setTipe}
                                            jobServiceType={jobServiceType}

                                            picRef={picRef}
                                            emailRef={emailRef}
                                            phoneRef={phoneRef}
                                        />
                                    </CardContent>
                                </Card>
                                :
                                <></>
                        }

                        {
                            idCustomer !== 0 ?
                                <div>
                                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                                        <CardContent>
                                            <TabsBooking
                                                isEdit={isEdit}
                                                isApproved={isApproved}
                                                isCopy={isCopy}
                                                tabValue={tabValue}
                                                setTabValue={setTabValue}
                                                placeOfReceipt={placeOfReceipt}
                                                placeOfReceiptId={placeOfReceiptId}
                                                placeOfReceiptCode={placeOfReceiptCode}
                                                placeOfDelivery={placeOfDelivery}
                                                placeOfDeliveryId={placeOfDeliveryId}
                                                placeOfDeliveryCode={placeOfDeliveryCode}
                                                portOfLoading={portOfLoading}
                                                portOfLoadingId={portOfLoadingId}
                                                portOfLoadingCode={portOfLoadingCode}
                                                portOfDischarge={portOfDischarge}
                                                portOfDischargeId={portOfDischargeId}
                                                portOfDischargeCode={portOfDischargeCode}
                                                airportDeparture={airportDeparture}
                                                airportDepartureId={airportDepartureId}
                                                airportDepartureCode={airportDepartureCode}
                                                airportDestination={airportDestination}
                                                airportDestinationId={airportDestinationId}
                                                airportDestinationCode={airportDestinationCode}
                                                vessel={vessel}
                                                vesselId={vesselId}
                                                vesselCode={vesselCode}
                                                airline={airline}
                                                airlineId={airlineId}
                                                airlineCode={airlineCode}

                                                setPlaceOfReceipt={setPlaceOfReceipt}
                                                setPlaceOfReceiptId={setPlaceOfReceiptId}
                                                setPlaceOfReceiptCode={setPlaceOfReceiptCode}
                                                setPlaceOfDelivery={setPlaceOfDelivery}
                                                setPlaceOfDeliveryId={setPlaceOfDeliveryId}
                                                setPlaceOfDeliveryCode={setPlaceOfDeliveryCode}
                                                setPortOfLoading={setPortOfLoading}
                                                setPortOfLoadingId={setPortOfLoadingId}
                                                setPortOfLoadingCode={setPortOfLoadingCode}
                                                setPortOfDischarge={setPortOfDischarge}
                                                setPortOfDischargeId={setPortOfDischargeId}
                                                setPortOfDischargeCode={setPortOfDischargeCode}
                                                setAirportDeparture={setAirportDeparture}
                                                setAirportDepartureId={setAirportDepartureId}
                                                setAirportDepartureCode={setAirportDepartureCode}
                                                setAirportDestination={setAirportDestination}
                                                setAirportDestinationId={setAirportDestinationId}
                                                setAirportDestinationCode={setAirportDestinationCode}
                                                setVessel={setVessel}
                                                setVesselId={setVesselId}
                                                setVesselCode={setVesselCode}
                                                setAirline={setAirline}
                                                setAirlineId={setAirlineId}
                                                setAirlineCode={setAirlineCode}

                                                ETD={ETD}
                                                setETD={setETD}
                                                ETA={ETA}
                                                setETA={setETA}
                                                tipe={tipe}
                                                setTipe={setTipe}
                                                idAgent={idAgent}
                                                setIdAgent={setIdAgent}
                                                agentName={agentName}
                                                setAgentName={setAgentName}
                                                setAgentAddress={setAgentAddress}
                                                idCustomer={idCustomer}
                                                CustomerName={CustomerName}
                                                freight={freight}
                                                setFreight={setFreight}
                                                idEMKL={idEMKL}
                                                setIdEMKL={setIdEMKL}
                                                EMKLName={EMKLName}
                                                setEMKLName={setEMKLName}
                                                idDepo={idDepo}
                                                setIdDepo={setIdDepo}
                                                depoName={depoName}
                                                setDepoName={setDepoName}
                                                idCarrier={idCarrier}
                                                setIdCarrier={setIdCarrier}
                                                carrierName={carrierName}
                                                setCarrierName={setCarrierName}
                                                containerType={containerTypeId}
                                                setContainerType={setContainerTypeId}
                                                dataContainerType={dataContainerType}
                                                finalDestination={finalDestination}
                                                setFinalDestination={setFinalDestination}
                                                voyageNo={voyageNo}
                                                setVoyageNo={setVoyageNo}
                                                flightNo={flightNo}
                                                setFlightNo={setFlightNo}
                                                flightAb={flightAb}
                                                setFlightAb={setFlightAb}
                                                serviceType={serviceType}
                                                setServiceType={setServiceType}
                                                shipmentVolume={shipmentVolume}
                                                setShipmentVolume={setShipmentVolume}
                                                shipmentVolume20={shipmentVolume20}
                                                setShipmentVolume20={setShipmentVolume20}
                                                shipmentVolume40={shipmentVolume40}
                                                setShipmentVolume40={setShipmentVolume40}
                                                shipmentVolume45={shipmentVolume45}
                                                setShipmentVolume45={setShipmentVolume45}
                                                shipmentVolumeAll={shipmentVolumeAll}
                                                setShipmentVolumeAll={setShipmentVolumeAll}
                                                shipmentVolumeCBM={shipmentVolumeCBM}
                                                setShipmentVolumeCBM={setShipmentVolumeCBM}
                                                isVolume20={isVolume20}
                                                setIsVolume20={setIsVolume20}
                                                isVolume40={isVolume40}
                                                setIsVolume40={setIsVolume40}
                                                isVolume45={isVolume45}
                                                setIsVolume45={setIsVolume45}
                                                isVolumeCBM={isVolumeCBM}
                                                setIsVolumeCBM={setIsVolumeCBM}
                                                containerSize={containerSize}
                                                setContainerSize={setContainerSize}
                                                containerFormat={containerFormat}
                                                setContainerFormat={setContainerFormat}
                                                containerTypeId={containerTypeId}
                                                setContainerTypeId={setContainerTypeId}
                                                containerTypeCode={containerTypeCode}
                                                setContainerTypeCode={setContainerTypeCode}
                                                containerTypeName={containerTypeName}
                                                setContainerTypeName={setContainerTypeName}
                                                shipmentVolumeCollie={shipmentVolumeCollie}
                                                setShipmentVolumeCollie={setShipmentVolumeCollie}
                                                shipmentVolumeKGS={shipmentVolumeKGS}
                                                setShipmentVolumeKGS={setShipmentVolumeKGS}
                                                stuffingDate={stuffingDate}
                                                setStuffingDate={setStuffingDate}
                                                stuffingPlace={stuffingPlace}
                                                setStuffingPlace={setStuffingPlace}
                                                stuffingNote={stuffingNote}
                                                setStuffingNote={setStuffingNote}
                                                remarks={remarks}
                                                setRemarks={setRemarks}
                                                creditTerm={creditTerm}
                                                setCreditTerm={setCreditTerm}

                                                marksNumber={marksNumber}
                                                setMarksNumber={setMarksNumber}
                                                cargoDescription={cargoDescription}
                                                setCargoDescription={setCargoDescription}
                                                numberOfPackages={numberOfPackages}
                                                setNumberOfPackages={setNumberOfPackages}
                                                packagingType={packagingType}
                                                setPackagingType={setPackagingType}
                                                dataPackagingType={dataPackagingType}
                                                grossWeight={grossWeight}
                                                setGrossWeight={setGrossWeight}
                                                volume={volume}
                                                setVolume={setVolume}
                                                jobType={jobType}
                                                chargeableWeightVolume={chargeableWeightVolume}
                                                setChargeableWeightVolume={setChargeableWeightVolume}
                                                specialInstructions={specialInstructions}
                                                setSpecialInstructions={setSpecialInstructions}

                                                exRate={exRate}
                                                exRateReal={exRateReal}
                                                calculateBookingData={calculateBookingData}
                                                dataIncome={dataIncome}
                                                setDataIncome={setDataIncome}
                                                dataCost={dataCost}
                                                setDataCost={setDataCost}
                                                jobTypeId={jobTypeId}
                                                contactTypeId={contactTypeId}
                                                buyingRateInUSD={buyingRateInUSD}
                                                setBuyingRateInUSD={setBuyingRateInUSD}
                                                sellingRateInUSD={sellingRateInUSD}
                                                setSellingRateInUSD={setSellingRateInUSD}
                                                totalUsd={totalUsd}
                                                setTotalUsd={setTotalUsd}
                                                totalIdr={totalIdr}
                                                setTotalIdr={setTotalIdr}
                                                totalIncomeUsd={totalIncomeUsd}
                                                setTotalIncomeUsd={setTotalIncomeUsd}
                                                totalIncomeIdr={totalIncomeIdr}
                                                setTotalIncomeIdr={setTotalIncomeIdr}
                                                totalCostUsd={totalCostUsd}
                                                setTotalCostUsd={setTotalCostUsd}
                                                totalCostIdr={totalCostIdr}
                                                setTotalCostIdr={setTotalCostIdr}
                                                marginIncome={marginIncome}
                                                marginCost={marginCost}

                                                handleClickContact={handleClickContact}
                                                setTitleModalContact={setTitleModalContact}
                                                setParamModal={setParamModal}

                                                handleClick2={handleClickModalData}
                                                setTitleModal2={setTitleModalData}

                                                contactId={contactId}
                                                setContactId={setContactId}
                                                tempCustName={tempCustName}
                                                setTempCustName={setTempCustName}

                                                bookingId={bookingId}
                                                accountId={accountId}
                                                accountCode={accountCode}
                                                setAccountId={setAccountId}
                                                setAccountCode={setAccountCode}
                                                isContainer={isContainer}
                                                setIsContainer={setIsContainer}
                                                description={description}
                                                setDescription={setDescription}
                                                tempAccountName={tempAccountName}
                                                setTempAccountName={setTempAccountName}

                                                quantity={quantity}
                                                setQuantity={setQuantity}
                                                detailEdit={detailEdit}
                                                setDetailEdit={setDetailEdit}

                                                portType={portType}
                                                jobServiceType={jobServiceType}
                                                containerData={containerData}
                                                setContainerData={setContainerData}
                                                getContainerSizeData={getContainerSizeData}

                                                receiptRef={receiptRef}
                                                deliveryRef={deliveryRef}
                                                estimateTimeRef={estimateTimeRef}
                                            />
                                        </CardContent>
                                    </Card>
                                </div>
                                :
                                <></>
                        }
                    </Box>
                </Grid>

                {
                    isModalContact && (
                        <SelectContact
                            isModal={isModalContact}
                            handleClick={handleClickContact}
                            titleModal={titleModalContact}
                            tipe={tipe}
                            jobServiceType={jobServiceType}
                            setIsModal={setIsModalContact}
                            setIdCustomer={setIdCustomer}
                            setCustomerName={setCustomerName}
                            setCustomerAddress={setCustomerAddress}
                            setIdShipper={setIdShipper}
                            setShipperName={setShipperName}
                            setShipperAddress={setShipperAddress}
                            setIdConsignee={setIdConsignee}
                            setConsigneeName={setConsigneeName}
                            setConsigneeAddress={setConsigneeAddress}
                            setIdBookingParty={setIdBookingParty}
                            setBookingPartyName={setBookingPartyName}
                            setBookingPartyAddress={setBookingPartyAddress}
                            setIdAgent={setIdAgent}
                            setAgentName={setAgentName}
                            setAgentAddress={setAgentAddress}
                            setIdEMKL={setIdEMKL}
                            setEMKLName={setEMKLName}
                            setIdDepo={setIdDepo}
                            setDepoName={setDepoName}
                            setIdCarrier={setIdCarrier}
                            setCarrierName={setCarrierName}

                            setCreditTermUsd={setCreditTermUsd}
                            setCreditTermDays={setCreditTermDays}
                            setCreditTermIdr={setCreditTermIdr}
                            setCreditTermUsdReal={setCreditTermUsdReal}
                            setCreditTermIdrReal={setCreditTermIdrReal}
                            setPic={setPic}
                            setEmail={setEmail}
                            setPhone={setPhone}
                            contactTypeId={contactTypeId}
                            setTempCustId={setContactId}
                            setTempCustName={setTempCustName}

                            isLoading={isLoadingContact}
                            data={dataContact}
                            dataMap={dataMapContact}
                            dataTable={dataTableContact}
                            columnData={columnDataContact}
                            SelectedData={SelectedDataContact}
                            getData={getModalContact}
                            totalRows={totalRowsContact}
                            setSelectedData={setSelectedDataContact}
                        />
                    )
                }

                {
                    isModalData && (
                        <ModalData
                            isModal2={isModalData}
                            handleClick2={handleClickModalData}
                            titleModal2={titleModalData}
                            placeOfReceipt={placeOfReceipt}
                            placeOfReceiptId={placeOfReceiptId}
                            placeOfReceiptCode={placeOfReceiptCode}
                            placeOfDelivery={placeOfDelivery}
                            placeOfDeliveryId={placeOfDeliveryId}
                            placeOfDeliveryCode={placeOfDeliveryCode}
                            portOfLoading={portOfLoading}
                            portOfLoadingId={portOfLoadingId}
                            portOfLoadingCode={portOfLoadingCode}
                            portOfDischarge={portOfDischarge}
                            portOfDischargeId={portOfDischargeId}
                            portOfDischargeCode={portOfDischargeCode}
                            airportDeparture={airportDeparture}
                            airportDepartureId={airportDepartureId}
                            airportDepartureCode={airportDepartureCode}
                            airportDestination={airportDestination}
                            airportDestinationId={airportDestinationId}
                            airportDestinationCode={airportDestinationCode}
                            vessel={vessel}
                            vesselId={vesselId}
                            vesselCode={vesselCode}
                            airline={airline}
                            flightAb={flightAb}
                            airlineId={airlineId}
                            airlineCode={airlineCode}

                            setPlaceOfReceipt={setPlaceOfReceipt}
                            setPlaceOfReceiptId={setPlaceOfReceiptId}
                            setPlaceOfReceiptCode={setPlaceOfReceiptCode}
                            setPlaceOfDelivery={setPlaceOfDelivery}
                            setPlaceOfDeliveryId={setPlaceOfDeliveryId}
                            setPlaceOfDeliveryCode={setPlaceOfDeliveryCode}
                            setPortOfLoading={setPortOfLoading}
                            setPortOfLoadingId={setPortOfLoadingId}
                            setPortOfLoadingCode={setPortOfLoadingCode}
                            setPortOfDischarge={setPortOfDischarge}
                            setPortOfDischargeId={setPortOfDischargeId}
                            setPortOfDischargeCode={setPortOfDischargeCode}
                            setAirportDeparture={setAirportDeparture}
                            setAirportDepartureId={setAirportDepartureId}
                            setAirportDepartureCode={setAirportDepartureCode}
                            setAirportDestination={setAirportDestination}
                            setAirportDestinationId={setAirportDestinationId}
                            setAirportDestinationCode={setAirportDestinationCode}
                            setVessel={setVessel}
                            setVesselId={setVesselId}
                            setVesselCode={setVesselCode}
                            setAirline={setAirline}
                            setFlightAb={setFlightAb}
                            setAirlineId={setAirlineId}
                            setAirlineCode={setAirlineCode}

                            paramModal={paramModal}
                            setIsModal2={setIsModalData}

                            getData={getModalData}
                            isLoading={isLoadingModalData}
                            data={dataModalData}
                            dataTable={dataTableModal}
                            dataMap={dataMapModalData}
                            columnData={columnDataModalData}
                            SelectedData={SelectedDataModalData}
                            setSelectedData={setSelectedDataModalData}
                            totalRows={totalRowsModalData}
                        />
                    )
                }

                {
                    isModalMarketing && (
                        <ModalMarketing
                            setMarketId={setMarketId}
                            setMarketName={setMarketName}
                            handleClick={handleClickMarketing}
                            isModal={isModalMarketing}
                            setIsModal={setIsModalMarketing}
                            titleModal={titleModalMarketing}

                            isLoading={isLoadingMarketing}
                            data={dataMarketing}
                            dataTable={dataTableMarketing}
                            dataMap={dataMapMarketing}
                            columnData={columnDataMarketing}
                            SelectedData={SelectedDataMarketing}
                            totalRows={totalRowsMarketing}
                            setSelectedData={setSelectedDataMarketing}
                            getData={getDataMarketing}
                        />
                    )
                }

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationBC}

                            isRemarks={isRemarks}
                            Remarks={CreditRemarks}
                            setRemarks={setCreditRemarks}
                            isCustomNote={isCustomNote}
                            CustomNote={CustomNote}
                        />
                    )
                }

                {
                    isModalConfirmEPL && (
                        <ModalConfirmEPL
                            isModal={isModalConfirmEPL}
                            handleClick={handleClickConfirmEPL}
                            title={tempTitle}
                            contactName={tempContactName}
                            ConfirmationData={() => checkCreditTermLimit()}
                        />
                    )
                }

                {
                    isModalApproval && (
                        <ModalApprovalCredit
                            isModal={isModalApproval}
                            titleModal={titleModalApproval}
                            title={titleApproval}
                            customerName={cstName}
                            creditType={creditType}
                            handleClick={handleClickApproval}
                            approvalDataList={approvalDataList}
                            approvalReason={approvalReason}
                            setApprovalReason={setApprovalReason}
                            approvalData={handleApproveData}
                            approvalIDR={approvalIDR}
                            approvalUSD={approvalUSD}
                        />
                    )
                }

                {
                    isModalUsage && (
                        <ModalUsageData
                            isModal={isModalUsage}
                            titleModal={titleModalUsage}
                            handleClick={handleClickUsage}
                            usageDataList={usageDataList}
                        />
                    )
                }
            </Box>

            {
                idCustomer !== 0 ?
                    <ButtonInfo
                        ButtonComponents={ButtonComponents}
                    />
                    :
                    <></>
            }

            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000000 }}
                        open={isLoading}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditBooking

