import { Tab, Tabs, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';

import TabCash from '../Tabs/TabCash';
import TabBank from '../Tabs/TabBank';

const TemporaryReceiptFor = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    function checkMethod(list) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].cashUSD !== undefined && list[i].cashIDR !== undefined) {
                if (list[i].cashUSD > 0 || list[i].cashIDR > 0) {
                    return 1;
                }
            }

            if (list[i].bankUSD !== undefined && list[i].bankIDR !== undefined) {
                if (list[i].bankUSD > 0 || list[i].bankIDR > 0) {
                    return 2;
                }
            }
        };

        return 0;
    }

    return (
        <div>
            <form>
                <div ref={props.paymentRef}>
                    <Typography
                        sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Payment Method
                    </Typography>

                    <div style={{ marginTop: '16px' }}>
                        <TabContext value={props.tabValue} className='p-0'>
                            <Tabs
                                sx={{ width: '100%', height: 'fit-content', maxHeight: '32px !important' }}
                                value={props.tabValue}
                                onChange={handleChange}
                                className='p-0'
                                textColor="primary"
                                aria-label="full width tabs example"
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="Cash"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "Cash" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cash
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="Bank"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "Bank" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Bank
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {
                                props.tabValue === "Cash" && (
                                    <TabPanel value="Cash" className='mt-1 p-0'>
                                        <TabCash
                                            calculateTotalCash={props.calculateTotalCash}
                                            checkMethod={checkMethod}
                                            Rate={props.Rate}
                                            isApprovedAcc={props.isApprovedAcc}
                                            CashPaymentUSD={props.CashPaymentUSD}
                                            setCashPaymentUSD={props.setCashPaymentUSD}
                                            CashPaymentIDR={props.CashPaymentIDR}
                                            setCashPaymentIDR={props.setCashPaymentIDR}
                                            selectedShipmentOrder={props.selectedShipmentOrder}
                                            temporaryReceiptId={props.temporaryReceiptId}
                                            ShipmentOrderList={props.ShipmentOrderList}
                                            setShipmentOrderList={props.setShipmentOrderList}
                                            TotalCashUSD={props.TotalCashUSD}
                                            setTotalCashUSD={props.setTotalCashUSD}
                                            TotalCashIDR={props.TotalCashIDR}
                                            setTotalCashIDR={props.setTotalCashIDR}
                                            Printing={props.Printing}
                                        />
                                    </TabPanel>
                                )
                            }

                            {
                                props.tabValue === "Bank" && (
                                    <TabPanel value="Bank" className='mt-1 p-0'>
                                        <TabBank
                                            calculateTotalBank={props.calculateTotalBank}
                                            temporaryReceiptId={props.temporaryReceiptId}
                                            BankData={props.BankData}
                                            setBankData={props.setBankData}
                                            isApprovedAcc={props.isApprovedAcc}
                                            selectedShipmentOrder={props.selectedShipmentOrder}
                                            Rate={props.Rate}
                                            checkMethod={checkMethod}
                                            ShipmentOrderList={props.ShipmentOrderList}
                                            setShipmentOrderList={props.setShipmentOrderList}
                                            TotalBankUSD={props.TotalBankUSD}
                                            setTotalBankUSD={props.setTotalBankUSD}
                                            TotalBankIDR={props.TotalBankIDR}
                                            setTotalBankIDR={props.setTotalBankIDR}
                                            Printing={props.Printing}
                                        />
                                    </TabPanel>
                                )
                            }
                        </TabContext>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TemporaryReceiptFor;