import { Grid, Stack, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import SelectDataEmployee from '../Selects/SelectDataEmployee';
import SelectDataCashAdvance from '../Selects/SelectDataCashAdvance';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../../components/ui';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const CashAdvSettlementHeader = (props) => {
    const [titleModalCA, setTitleModalCA] = useState('');
    const [isModalCA, setIsModalCA] = useState(false);
    const paramCA = "cashAdvance/cashAdvance";
    const [isLoadingCA, setIsLoadingCA] = useState(false);
    const [dataCA, setDataCA] = useState([]);
    const [SelectedDataCA, setSelectedDataCA] = useState({});
    const [totalRowsCA, setTotalRowsCA] = useState(50);
    const [dataMapCA, setDataMapCA] = useState([]);
    const [dataTableCA, setDataTableCA] = useState([]);
    const [columnDataCA, setColumnDataCA] = useState([]);

    const [titleModalE, setTitleModalE] = useState('');
    const [isModalE, setIsModalE] = useState(false);
    const paramE = "employee/employee";
    const [isLoadingE, setIsLoadingE] = useState(false);
    const [dataE, setDataE] = useState([]);
    const [SelectedDataE, setSelectedDataE] = useState({});
    const [totalRowsE, setTotalRowsE] = useState(50);
    const [dataMapE, setDataMapE] = useState([]);
    const [dataTableE, setDataTableE] = useState([]);
    const [columnDataE, setColumnDataE] = useState([]);

    const DataStatusCashAdvance = [
        { id: 1, name: 'Part' },
        { id: 2, name: 'Paid' },
    ];

    const handleClickCashAdvance = () => {
        if (isModalCA === false) {
            setIsModalCA(true);
            getDataCashAdvance(1, 50, []);
        }
        else {
            setIsModalCA(false);
        }
    };

    const searchCashAdvance = (data) => {
        if (data === 'search') {
            if (props.EmployeeId !== 0) {
                setTitleModalCA('Cash Advance');
                handleClickCashAdvance();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data Personal First!' });
            }
        }
        else if (data === 'delete') {
            props.setCashAdvanceId(0);
            props.setCashAdvanceNo('');

            props.setTotalIDR(0);
            props.setTotalUSD(0);

            props.setBalanceCAIDR(0);
            props.setBalanceCAUSD(0);
            props.setBalanceSettlementIDR(0);
            props.setBalanceSettlementUSD(0);
            props.setBalanceIDR(0);
            props.setBalanceUSD(0);
        }
    };

    const handleClickEmployee = () => {
        if (isModalE === false) {
            setIsModalE(true);
            getDataEmployee(1, 50);
        }
        else {
            setIsModalE(false);
        }
    };

    const searchEmployee = (data) => {
        if (data === 'search') {
            setTitleModalE('Employee');
            handleClickEmployee();
        }
        else if (data === 'delete') {
            props.setEmployeeId(0);
            props.setEmployeeCode('');
            props.setEmployeeName('');
            props.setEmployeeNickname('');

            props.setCashAdvanceId(0);
            props.setCashAdvanceNo('');

            props.setTotalIDR(0);
            props.setTotalUSD(0);

            props.setBalanceCAIDR(0);
            props.setBalanceCAUSD(0);
            props.setBalanceSettlementIDR(0);
            props.setBalanceSettlementUSD(0);
            props.setBalanceIDR(0);
            props.setBalanceUSD(0);
        }
    };

    const getDataCashAdvance = (pageNumber, pageSize, filters) => {
        setIsLoadingCA(true);
        let param = []
        param = [
            ...param,
            { 'attr': 'employeeId', 'value': '' + props.EmployeeId },
            { 'attr': 'paid', 'value': '' + false },
            { 'attr': 'rowStatus', 'value': 'ACT' },
            { 'attr': 'printing', 'value': '0', 'mathSign': '!=' },
        ];
        // let filter = { 
        // 'field': 'employeeName', 'data': '' + props.EmployeeNickname
        // };
        // filters = [...filters, filter];
        ApiService.FinAccPostByPage(paramCA, 'SEARCH', pageNumber, pageSize, param, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCA({});
                    setDataCA(response.data.data.cashAdvance);

                    let temp = response.data.data.cashAdvance
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCA(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableCA, 'id');
                    setColumnDataCA(response.data.data.columns.headerColumns);
                    setTotalRowsCA(response.data.totalRowCount);

                    setIsLoadingCA(false);
                }
                setIsLoadingCA(false);
            })
            .catch(function (error) {
                setIsLoadingCA(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataEmployee = (pageNumber, pageSize, filters) => {
        setIsLoadingE(true);
        let param = [];
        ApiService.DynamicPostByPage(paramE, 'SEARCH', pageNumber, pageSize, param, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataE({})
                    setDataE(response.data.data.employees);

                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapE(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableE, 'id');
                    setColumnDataE(response.data.data.columns)
                    setTotalRowsE(response.data.totalRowCount)

                    setIsLoadingE(false)
                }
                setIsLoadingE(false)
            })
            .catch(function (error) {
                setIsLoadingE(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    return (
        <div>
            <form>
                <Box>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <div ref={props.infossNoRef}>
                                <Input
                                    label={'Infoss Number'}
                                    value={props.infossNo}
                                    onChange={(e) => props.setInfossNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginBottom: '16px !important'
                                        }
                                    }}
                                    disabled={props.Printing > 0}
                                    hidden={!isInfossNo}
                                    message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={8}
                            lg={8}
                            sm={8}
                            xs={12}
                        >
                            <Input
                                label="C.A. Settlement No"
                                value={props.SettlementCashAdvanceNo2}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Rate'
                                value={props.Rate}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label="Reference"
                                value={props.Reference}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Selection
                                id='0'
                                label={'Status Cash Advance'}
                                fullWidth={true}
                                placeholder={'Select Status Cash Advance'}
                                value={props.StatusCashAdvance}
                                disabled={props.Printing > 0 ? true : false}
                                onChange={(e) => props.setStatusCashAdvance(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        marginTop: '16px !important'
                                    }
                                }}
                            >
                                {
                                    DataStatusCashAdvance.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                    ))
                                }
                            </Selection>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <div ref={props.employeeRef}>
                                <Stack spacing={'16px'} direction="row" className='mt-3'>
                                    <Input
                                        label="Personal"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.EmployeeCode}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '50%',
                                            }
                                        }}
                                        disabled
                                    />

                                    <Input
                                        value={props.EmployeeName}
                                        disabled
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchEmployee('delete')}
                                                        hidden={props.isEdit ? true : props.EmployeeName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => searchEmployee('search')}
                                                        hidden={props.isEdit}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Stack>
                            </div>

                            <div ref={props.cashAdvanceRef}>
                                <Input
                                    label='For Cash Advance No'
                                    value={props.CashAdvanceNo}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchCashAdvance('delete')}
                                                    hidden={props.isEdit ? true : props.CashAdvanceNo ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => searchCashAdvance('search')}
                                                    disabled={props.EmployeeId === 0 ? true : false}
                                                    hidden={props.isEdit ? true : props.EmployeeId === 0 ? true : false}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <CustomDatePicker
                                label='Rate Date'
                                value={props.ExRateDate}
                                type={'date'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <CustomDatePicker
                                label="Print Date"
                                value={props.PrintedOn}
                                type={'date'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModalE && (
                    <SelectDataEmployee
                        isModal={isModalE}
                        handleClick={handleClickEmployee}
                        titleModal={titleModalE}
                        setIsModal={setIsModalE}
                        getData={getDataEmployee}
                        isLoading={isLoadingE}
                        totalRows={totalRowsE}
                        setTotalRows={setTotalRowsE}
                        SelectedData={SelectedDataE}
                        setSelectedData={setSelectedDataE}
                        data={dataE}
                        columnData={columnDataE}
                        dataMap={dataMapE}
                        dataTable={dataTableE}
                        setEmployeeId={props.setEmployeeId}
                        setEmployeeCode={props.setEmployeeCode}
                        setEmployeeName={props.setEmployeeName}
                        setEmployeeNickname={props.setEmployeeNickname}
                    />
                )
            }

            {
                isModalCA && (
                    <SelectDataCashAdvance
                        isModal={isModalCA}
                        handleClick={handleClickCashAdvance}
                        titleModal={titleModalCA}
                        setIsModal={setIsModalCA}
                        getData={getDataCashAdvance}
                        isLoading={isLoadingCA}
                        totalRows={totalRowsCA}
                        setTotalRows={setTotalRowsCA}
                        SelectedData={SelectedDataCA}
                        setSelectedData={setSelectedDataCA}
                        data={dataCA}
                        columnData={columnDataCA}
                        dataMap={dataMapCA}
                        dataTable={dataTableCA}
                        setCashAdvanceId={props.setCashAdvanceId}
                        setCashAdvanceNo={props.setCashAdvanceNo}
                        TotalIDR={props.TotalIDR}
                        TotalUSD={props.TotalUSD}
                        setBalanceCAIDR={props.setBalanceCAIDR}
                        setBalanceCAUSD={props.setBalanceCAUSD}
                        setBalanceCAIDRReal={props.setBalanceCAIDRReal}
                        setBalanceCAUSDReal={props.setBalanceCAUSDReal}
                        setBalanceSettlementIDR={props.setBalanceSettlementIDR}
                        setBalanceSettlementUSD={props.setBalanceSettlementUSD}
                        setBalanceSettlementIDRReal={props.setBalanceSettlementIDRReal}
                        setBalanceSettlementUSDReal={props.setBalanceSettlementUSDReal}
                        calculateBalance={props.calculateBalance}
                    />
                )
            }
        </div>
    )
}

export default CashAdvSettlementHeader;