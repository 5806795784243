import { IconButton, Stack, Grid } from '@mui/material';
import React from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';

const TabAgent = (props) => {
    const searchAgent = (data) => {
        if (data === 'search') {
            if (props.principleBy !== 0) {
                props.setTipe(1);
                props.setContactTypeId(11);
                props.setTitleModal('Agent');
                props.handleClick(1);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By!' });
            }
        }
        else if (data === 'delete') {
            props.setAgentId(0);
            props.setAgentName('');
            props.setAgentAddress('');
        }
        else if (data === 'reset') {
            props.setAgentId(props.RefAgentId);
            props.setAgentName(props.RefAgentName);
            props.setAgentAddress(props.RefAgentAddress);
        }
    };

    return (
        <div ref={props.agentRef}>
            <Grid
                container
                spacing={'16px'}
            >
                <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    sm={1.5}
                    xs={12}
                >
                    <Input
                        label="Code"
                        value={props.AgentId}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={5}
                    lg={5}
                    sm={5}
                    xs={12}
                >
                    <Input
                        label="Name"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.AgentName}
                        onChange={(e) => props.setAgentName(e.target.value)}
                        disabled={props.contactType === 'MainShipmentOrder' ? true : false}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: props.contactType === 'MainShipmentOrder' ? true : false,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={5.5}
                    lg={5.5}
                    sm={5.5}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                        <Input
                            label="Address"
                            value={props.AgentAddress}
                            onChange={(e) => props.setAgentAddress(e.target.value)}
                            disabled={props.contactType === 'MainShipmentOrder' ? true : false}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: props.contactType === 'MainShipmentOrder' ? true : false,
                                rows: 4,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                        />

                        {
                            props.contactType === 'MainShipmentOrder' ?
                                <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                    <IconButton onClick={() => searchAgent('search')} disabled={props.isJobClose}>
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton onClick={() => searchAgent('delete')}
                                        hidden={props.AgentAddress ? false : true}
                                        disabled={props.isJobClose}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </Stack>
                                :
                                <>
                                    <IconButton onClick={() => searchAgent('reset')} sx={{ marginTop: '36px !important' }} disabled={props.isJobClose}>
                                        <Icon
                                            iconName={'refresh'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}

export default TabAgent;