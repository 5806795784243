import React, { memo, useCallback, useMemo } from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
  Stack,
  Tooltip,
  alpha,
  styled
} from '@mui/material';

import { InputProps } from './Input.Props';
import Icon from '../Icon/Icon';

const CustomInputBase = styled(InputBase)(({ theme, align }) => ({
  border: '1.5px solid',
  borderColor: '#989898',
  borderRadius: '8px',
  fontSize: 12,
  backgroundColor: 'transparent',
  display: 'flex',
  minHeight: '32px',
  padding: '0px 12px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  background: 'transparent',
  marginTop: '3px !important',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  '&.Mui-focused': {
    boxShadow: `${alpha('#989898', 0.25)} 0 0 0 0.2rem`,
    borderColor: '#989898',
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    '&.Mui-focused': {
      boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.error.main,
    }
  },
  '&.Mui-readOnly': {
    cursor: 'default',
  },
  '&.Mui-disabled': {
    backgroundColor: '#EAEAEA',
    cursor: 'not-allowed',
  },
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    padding: 0,
    textAlign: align,
    cursor: 'inherit',
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.575) !important',
      '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.575) !important'
    },
  },
}));

const Input = React.memo(({
  // *** Props
  id,
  label,
  error,
  disabled,
  hidden,
  multiline,
  onChange,
  onClick,
  onFocus,
  readOnly,
  placeholder,
  type,
  value,
  tooltipTitle,
  defaultValue,
  required,
  message,
  textAlign = 'left',
  onKeyDown,

  // *** Default MUI Props
  formControlProps,
  formLabelProps,
  inputBaseProps,
}) => {
  const handleChange = useCallback(
    (event) => {
      const upperCaseValue = label === undefined ? event.target.value.toUpperCase() : (label.toLowerCase().includes('password') ? event.target.value : event.target.value.toUpperCase());
      if (onChange) {
        const syntheticEvent = { ...event, target: { ...event.target, value: upperCaseValue } };
        onChange(syntheticEvent);
      }
    },
    [onChange, label]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (type === 'date' || type === 'month') {
        e.preventDefault();
      } else if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [type, onKeyDown]
  );

  const formLabelStyles = useMemo(() => ({
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '600',
    color: '#083A50 !important',
    textAlign: textAlign,
    ...formLabelProps?.sx,
  }), [textAlign, formLabelProps]);

  return (
    <FormControl
      variant='standard'
      error={error}
      disabled={disabled}
      required={required}
      hidden={hidden}
      {...formControlProps}
    >
      {/* CUSTOM FORM LABEL */}
      <Stack direction={'row'} spacing={'2px'} alignItems={'center'}>
        {label && (
          <FormLabel
            htmlFor={formLabelProps?.htmlFor || id || ''}
            {...formLabelProps}
            sx={formLabelStyles}
          >
            {label}
          </FormLabel>
        )}

        {tooltipTitle && (
          <Tooltip title={tooltipTitle} placement="right" arrow>
            <span>
              <Icon
                iconName={'info'}
                svgIconProps={{
                  htmlColor: '#083A50',
                  sx: {
                    height: '20px',
                    width: '20px'
                  }
                }}
              />
            </span>
          </Tooltip>
        )}
      </Stack>

      {/* CUSTOM FORM INPUT */}
      <CustomInputBase
        id={id}
        error={error}
        multiline={multiline}
        onChange={handleChange}
        onClick={onClick}
        onFocus={onFocus}
        readOnly={readOnly}
        placeholder={placeholder}
        type={type}
        value={value}
        defaultValue={defaultValue}
        align={textAlign}
        onKeyDown={handleKeyDown}
        {...inputBaseProps}
      />
      {message && (
        <FormHelperText error={error}>
          {message}
        </FormHelperText>
      )}
    </FormControl>
  )
});

Input.propTypes = InputProps

export default memo(Input)