import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import TableX from '../../../components/Tables/TableX';
import ApiService, { isListAsc } from '../../../services/ApiService';
import Backdrop from '@mui/material/Backdrop';
import { forwardRef } from 'react';
import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import ButtonRestorePostingClosingPage from './Buttons/ButtonRestorePostingClosingPage.jsx';
import ModalAddRestore from './Modals/ModalAddRestore.jsx';
import ModalReOpenAccess from './Modals/ModalReOpenAccess.jsx';
import { handleDataTable } from '../../../utils/reusableFunction.jsx';

const RestorePostingClosingPage = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate()
    const param = 'Help/RegBatchGroupId/RegBatchGroupId';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(isListAsc === true? 0 : 1)

    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [restoreTo, setRestoreTo] = useState('');
    const [lastMonth, setLastMonth] = useState('');
    const [startMonth, setStartMonth] = useState('');

    const [isModalLock, setIsModalLock] = useState(false);
    const [titleModalLock, setTitleModalLock] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Restore Posting & Closing', link: '/Majura/RestorePostingClosing', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Restore Posting & Closing - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        ApiService.ListOperationDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.regBatchGroupId
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });

                    if (isLoadingBackDrop === false) {
                        setIsLoading(true);
                    }
                    else {
                        setIsLoadingBackDrop(true);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error });
            })
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FCAD')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getData(isListAsc === true? 0 : 1, 50, [])
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });

                        if (isLoadingBackDrop === false) {
                            setIsLoading(true);
                        }
                        else {
                            setIsLoadingBackDrop(true);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const emptyState = () => {
        setNumPage(isListAsc === true? 0 : 1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    const handleClick = () => {
        if (isModal) {
            setIsModal(false);
            setTitleModal('');
            setRestoreTo('');
        }
        else {
            setIsModal(true);
            setTitleModal('Restore Data');
        }
    }

    const handleRestoreData = () => {
        let month = new Date().getMonth() + 1;

        // if (month !== 1) {
        setIsLoadingBackDrop(true);
        ApiService.PostById('branch/branch', JSON.parse(localStorage.getItem('branchId')))
            .then((res) => {
                if (res !== null) {
                    let dateNow = new Date().getDate();
                    let data = res.data.data.branch;

                    if (dateNow <= data.closingDate) {
                        month = month - 1;
                    }

                    let currentYear = new Date().getFullYear();
                    let year = currentYear;

                    if (month === 0) {
                        month = 12;
                        year = currentYear - 1;
                    }

                    let formattedMonth = month.toString().padStart(2, '0');
                    let formattedLastMonthWithYear = `${year}-${formattedMonth}`;
                    setLastMonth(formattedLastMonthWithYear);
                    setRestoreTo(formattedLastMonthWithYear);

                    let startMonth = parseInt(formattedMonth) - 12;
                    if (startMonth < 1) {
                        startMonth += 12;
                        year -= 1;
                    }
                    let formattedStartMonth = startMonth.toString().padStart(2, '0');
                    let formattedStartMonthWithYear = `${year}-${formattedStartMonth}`;
                    setStartMonth(formattedStartMonthWithYear);
                    setIsLoadingBackDrop(false);
                    handleClick();
                }
            })
        // }
        // else {
        //     showToast({ type: 'error', message: 'Cannot Restore Data, Because The Current Month Is January!' });
        // }
    }

    const restoreData = () => {
        let errorMsg = undefined;
        setIsLoadingBackDrop(true);
        if (restoreTo === '') {
            errorMsg = 'Please Input The Restore To Field!';
        }

        if (errorMsg === undefined) {
            let data = {
                "regBatchGroupId": {
                    "dateFrom": restoreTo,
                }
            }

            ApiService.CreateOperation(param, data)
                .then((response) => {
                    if (response.data.code === 200) {
                        handleClick();
                        showToast({ type: 'success', message: 'Restore Data Success!' });
                        history('/Majura/RestorePostingClosing/' + response.data.data.BatchGroupId);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Restore Data Failed!, ' + response.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMsg });
        }
    }

    const CellDoubleClick = (row) => {
        history('/Majura/RestorePostingClosing/' + row.batchGroupId);
    }

    const handleLockUserAccess = () => {
        if (isModalLock) {
            setIsModalLock(false);
            setTitleModalLock('');
        }
        else {
            setIsModalLock(true);
            setTitleModalLock('Re-open User Access');
        }
    }

    const handleReset = () => {
        setFilter([])
        setFilterBy([])
        emptyState();
        getData(isListAsc === true? 0 : 1, 50, [])
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(isListAsc === true? 0 : 1);
        setRowsCount(value);
        getData(isListAsc === true? 0 : 1, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <ButtonRestorePostingClosingPage
                AddNew={handleRestoreData}
                LockUserAccess={handleLockUserAccess}
                DataRegUserAccess={DataRegUserAccess}
                isLock={false}
                isDisabledLock={false}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />

            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Restore Posting & Closing'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'batchGroupId'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModal && (
                    <ModalAddRestore
                        titleModal={titleModal}
                        isModal={isModal}
                        handleClick={handleClick}
                        restoreTo={restoreTo}
                        setRestoreTo={setRestoreTo}
                        lastMonth={lastMonth}
                        restoreData={restoreData}
                        startMonth={startMonth}
                    />
                )
            }

            {
                isModalLock && (
                    <ModalReOpenAccess
                        titleModal={titleModalLock}
                        isModal={isModalLock}
                        handleClick={handleLockUserAccess}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default RestorePostingClosingPage