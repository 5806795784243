import { Grid, Stack, MenuItem, IconButton, InputAdornment, } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import SelectDataContact from '../Selects/SelectDataContact';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../../components/ui';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const OfficialReceiptDetail = ({
    setCustomerId,
    setCustomerName,
    setIsNPWP,
    setInvoiceList,
    setInvoiceIdList,
    calculateTotalInvoice,
    calculateTotalTR,
    calculateTotalRV,
    calculateTotalCash,
    calculateTotalBank,
    calculateTotalDifferent,
    setPaymentByCode,
    PaymentByCode,
    resetDataList,
    ORNumber,
    PrintDate,
    VerifyAccDate,
    RateCount,
    RateDate,
    selectPrincipleBy,
    isEdit,
    isJobOwner,
    Printing,
    CustomerId,
    CustomerName,
    infossNo,
    setInfossNo,
    infossNoRef,
    customerRef
}) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "invoice/invoice";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([])
    const [dataTableC, setDataTableC] = useState([])
    const [columnDataC, setColumnDataC] = useState([])

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCustomer = (data) => {
        if (data === 'search') {
            setTitleModal('Customer');
            handleClick();
        }
        else if (data === 'delete') {
            setCustomerId(0);
            setCustomerName('');
            setIsNPWP(false);

            setInvoiceList([]);
            setInvoiceIdList([]);
            calculateTotalInvoice([]);
            calculateTotalTR([]);
            calculateTotalRV([]);
            calculateTotalCash([]);
            calculateTotalBank([]);
            calculateTotalDifferent([]);
        }
    };

    const DataPaymentBy = [
        { id: 1, code: 2, name: 'Shipper' },
        { id: 2, code: 3, name: 'Consignee' },
        { id: 3, code: 1, name: 'Agent' },
        { id: 4, code: 10, name: 'Shipper Trucking' },
    ];

    function handlePaymentCode(val) {
        setPaymentByCode(val.toString());
        setCustomerId(0);
        setCustomerName('');
        resetDataList();
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        var type = PaymentByCode;
        if (type != null && type !== '') {
            let params = [];
            params = [
                ...params,
                {
                    'attr': 'customerTypeId',
                    'value': '' + type,
                    'isDistinct': true
                },
                {
                    'attr': 'customerId',
                    'value': '',
                    'isDistinct': true
                },
                { 'attr': 'debetCredit', 'value': 'D' },
                { 'attr': 'deleteType', 'value': '0' },
                { 'attr': 'ORId', 'value': '0' },
                { 'attr': 'isPosted', 'value': 'true' },
                { 'attr': 'paid', 'value': 'false' }
            ];

            ApiService.OperationDynamicPostByPage(paramC, 'SEACON', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.invoices);

                        let temp = response.data.data.invoices
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableC, 'id');
                        setColumnDataC(response.data.data.columns.headerColumns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false);
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else {
            setIsLoadingC(false);
            showToast({ type: 'error', message: 'Please Contact The Administrator!' });
        }
    };

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={2}
                                lg={2}
                                sm={2}
                                xs={12}
                            >
                                <div ref={infossNoRef}>
                                    <Input
                                        label={'Infoss Number'}
                                        value={infossNo}
                                        onChange={(e) => setInfossNo(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginBottom: '16px !important'
                                            }
                                        }}
                                        disabled={Printing > 0 ? true : false}
                                        hidden={!isInfossNo}
                                        message={infossNo !== '' ? '' : 'This Field is Required'}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={2}
                                lg={2}
                                sm={2}
                                xs={12}
                                hidden={!isEdit}
                            >
                                <Input
                                    label="OR Number"
                                    value={ORNumber}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <CustomDatePicker
                                    label="Print Date"
                                    value={PrintDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <CustomDatePicker
                                    label="Verify"
                                    value={VerifyAccDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={isEdit ? 8 : 10}
                                lg={isEdit ? 8 : 10}
                                sm={isEdit ? 8 : 10}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Payment By'}
                                    fullWidth={true}
                                    placeholder={'Select Payment By'}
                                    value={PaymentByCode}
                                    onChange={(e) => handlePaymentCode(e.target.value)}
                                    disabled={isEdit}
                                    formControlProps={{
                                        sx: {
                                            // marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        DataPaymentBy.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <div>
                                    {selectPrincipleBy}
                                </div>

                                <div ref={customerRef}>
                                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                        <Input
                                            label='Customer'
                                            value={CustomerId}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '24px'
                                                }
                                            }}
                                        />

                                        <Input
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={CustomerName}
                                            type='text'
                                            label={''}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                            inputBaseProps={{
                                                sx: {
                                                    marginTop: '23px !important',
                                                },
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => searchCustomer('delete')}
                                                            disabled={isJobOwner || isEdit}
                                                            hidden={CustomerName && Printing === 0 ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={() => searchCustomer('search')}
                                                            disabled={isJobOwner || isEdit}
                                                            hidden={Printing > 0}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            disabled
                                        />
                                    </Stack>
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={2}
                                lg={2}
                                sm={2}
                                xs={12}
                            >
                                <Input
                                    label='Rate'
                                    value={RateCount}
                                    textAlign='right'
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <CustomDatePicker
                                    label='Rate Date'
                                    value={RateDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataContact
                        resetDataList={resetDataList}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        tipe={PaymentByCode}
                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setCustomerId={setCustomerId}
                        setCustomerName={setCustomerName}
                        setIsNPWP={setIsNPWP}
                    />
                )
            }
        </div>
    )
}

export default OfficialReceiptDetail;