import React, { useState } from 'react'
import TabCostAgent from '../TabEPL/TabCostAgent'
import TabCostDepo from '../TabEPL/TabCostDepo'
import TabCostEMKL from '../TabEPL/TabCostEMKL'
import TabCostIATA from '../TabEPL/TabCostIATA'
import TabCostDiscountConsignee from '../TabEPL/TabCostDiscountConsignee'
import TabCostDiscountShipper from '../TabEPL/TabCostDiscountShipper'
import TabCostSSLine from '../TabEPL/TabCostSSLine'
import TabCostTrucking from '../TabEPL/TabCostTrucking'
import TabIncAgent from '../TabEPL/TabIncAgent'
import TabIncConsignee from '../TabEPL/TabIncConsignee'
import TabIncShipper from '../TabEPL/TabIncShipper'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import ModalDetail from '../ModalEPL/ModalDetail'
import ModalContact from '../ModalEPL/ModalContact'
import ApiService from '../../../../services/ApiService'
import ModalHandlingFee from '../ModalEPL/ModalHandlingFee'
import ModalProfitSplit from '../ModalEPL/ModalProfitSplit'
import ModalStorage from '../ModalEPL/ModalStorage'
import ModalTrucking from '../ModalEPL/ModalTrucking'
import { convertToNumeric } from '../../../../utils/masks.jsx'
import { showToast } from '../../../../components/ui/index.jsx'
import { handleDataTable } from '../../../../utils/reusableFunction.jsx'

const TabEPL = (props) => {
    const [showDetail, setShowDetail] = useState(false)
    const [TabType, setTabType] = useState('')
    const [contact, setContact] = useState('')
    const [contactType, setContactType] = useState('')
    const [isIncome, setIsIncome] = useState(true)

    const [isHandlingFee, setIsHandlingFee] = useState(false);

    const [isProfitSplit, setIsProfitSplit] = useState(false);

    const [isStorage, setIsStorage] = useState(false);

    const [isTrucking, setIsTrucking] = useState(false);

    const [showModalContact, setShowModalContact] = useState(false)
    const [showModalAccount, setShowModalAccount] = useState(false)
    const [SelectedContact, setSelectedContact] = useState({})
    const [SelectedAccount, setSelectedAccount] = useState({})

    const [Desc, setDesc] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const param = "regContact/regContact";
    const [data, setData] = useState([]);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [totalRows, setTotalRows] = useState(50)

    const [isLoadingAccount, setIsLoadingAccount] = useState(false);
    const paramAccount = "account/account";
    const [dataAccount, setDataAccount] = useState([]);
    const [dataMapAccount, setDataMapAccount] = useState([])
    const [dataTableAccount, setDataTableAccount] = useState([])
    const [columnDataAccount, setColumnDataAccount] = useState([])
    const [totalRowsAccount, setTotalRowsAccount] = useState(50)

    const [isAdd, setIsAdd] = useState(true)

    const [contactId, setContactId] = useState(0)
    const [contactName, setContactName] = useState('')
    const [accountId, setAccountId] = useState(0)
    const [accountCode, setAccountCode] = useState('')
    const [accountName, setAccountName] = useState('')
    const [COACode, setCOACode] = useState('')
    const [COAName, setCOAName] = useState('')
    const [SplitAccount, setSplitAccount] = useState(false);
    const [Currency, setCurrency] = useState(1);
    const [CostTrucking, setCostTrucking] = useState(false);
    const [CostToCost, setCostToCost] = useState(false);
    const [QtyUnit, setQtyUnit] = useState('');
    const [PerUnitCost, setPerUnitCost] = useState('');
    const [Amount, setAmount] = useState('');
    const [Size, setSize] = useState('All');
    const [OriginalRate, setOriginalRate] = useState('');
    const [OriginalUSD, setOriginalUSD] = useState('');
    const [sign, setSign] = useState(true)
    const [eplId, setEplId] = useState(0)
    const [sequence, setSequence] = useState(0)
    const [containerFormat, setContainerFormat] = useState('All');
    const [containerTypeId, setContainerTypeId] = useState(0);
    const [containerTypeCode, setContainerTypeCode] = useState('');
    const [containerTypeName, setContainerTypeName] = useState('');
    const [isOthers, setIsOthers] = useState(false);
    const [refId, setRefId] = useState(0);

    const [isViewOnly, setIsViewOnly] = useState(false);
    const [isAdditional, setIsAdditional] = useState(false);

    const [openCloseIS, setOpenCloseIS] = useState(true);
    const [openCloseIS2, setOpenCloseIS2] = useState(true);
    const [openCloseIC, setOpenCloseIC] = useState(true);
    const [openCloseIC2, setOpenCloseIC2] = useState(true);
    const [openCloseIA, setOpenCloseIA] = useState(true);
    const [openCloseIA2, setOpenCloseIA2] = useState(true);
    const [openCloseCS, setOpenCloseCS] = useState(true);
    const [openCloseCS2, setOpenCloseCS2] = useState(true);
    const [openCloseCI, setOpenCloseCI] = useState(true);
    const [openCloseCI2, setOpenCloseCI2] = useState(true);
    const [openCloseCE, setOpenCloseCE] = useState(true);
    const [openCloseCE2, setOpenCloseCE2] = useState(true);
    const [openCloseCRS, setOpenCloseCRS] = useState(true);
    const [openCloseCRS2, setOpenCloseCRS2] = useState(true);
    const [openCloseCRC, setOpenCloseCRC] = useState(true);
    const [openCloseCRC2, setOpenCloseCRC2] = useState(true);
    const [openCloseCA, setOpenCloseCA] = useState(true);
    const [openCloseCA2, setOpenCloseCA2] = useState(true);
    const [openCloseCD, setOpenCloseCD] = useState(true);
    const [openCloseCD2, setOpenCloseCD2] = useState(true);
    const [openCloseCT, setOpenCloseCT] = useState(true);
    const [openCloseCT2, setOpenCloseCT2] = useState(true);

    const [isDummy, setIsDummy] = useState(false);

    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    let countErr = 0

    const clearForm = () => {
        setSelectedAccount({})
        setSelectedContact({})
        setSplitAccount(false);
        setDesc('');
        setCurrency(1);
        setCostTrucking(false);
        setCostToCost(false);
        setQtyUnit(0);
        setPerUnitCost(0);
        setAmount(0);
        setOriginalRate(0);
        setOriginalUSD(0);
        setSign(true)
        setEplId(0)
        setRefId(0);

        setDesc('')
        setContactId(0)
        setContactName('')
        setAccountId(0)
        setAccountCode('')
        setAccountName('')
        setCOACode('')
        setCOAName('')
        setSplitAccount(false)
        setCostTrucking(false)
        setCostToCost(false)
        setQtyUnit('')
        setPerUnitCost('')
        setAmount('')
        setOriginalRate('')
        setOriginalUSD('')
        setSign(true)
        setEplId(0)
        props.setIsContainer(false);
        props.setContainerSize('All');

        setContainerTypeId(0);
        setContainerTypeCode('');
        setContainerTypeName('');
        setContainerFormat('All');
        setIsOthers(false);
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        if (contactType !== '' || contactType !== undefined) {
            setIsLoading(true)
            ApiService.ContactPostByPage(param, contactType, pageNumber, pageSize, filters)
                .then((response) => {

                    if (response.status === 200) {
                        setSelectedContact({})
                        setData(response.data.data.contact);

                        let temp = response.data.data.contact
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMap(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                        setColumnData(response.data.data.columns)
                        setTotalRows(response.data.totalRowCount)

                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false)
                    console.error('error saat fetch', error)
                    countErr++
                    if (countErr < 3) {
                        getDataContact(1, 50)
                    } else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                        setShowModalContact(false);
                    }
                })
        }
    }

    const getDataAccount = (pageNumber, pageSize, filters) => {
        setIsLoadingAccount(true);
        let params = [];
        if (props.portType === 'SEA') {
            params = [...params, { 'attr': 'Type', 'value': 'SEA' }];
        }
        else if (props.portType === 'AIR') {
            params = [...params, { 'attr': 'Type', 'value': 'AIR' }];
        }
        ApiService.DynamicPostByPage(paramAccount, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedAccount({})
                    setDataAccount(response.data.data.account);

                    let temp = response.data.data.account
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapAccount(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableAccount, 'id');
                    setColumnDataAccount(response.data.data.columns)
                    setTotalRowsAccount(response.data.totalRowCount)

                    setIsLoadingAccount(false)
                }
                setIsLoadingAccount(false)
            })
            .catch(function (error) {
                setIsLoadingAccount(false)
                console.error('error saat fetch', error)
                countErr++
                if (countErr < 3) {
                    getDataAccount(1, 50)
                } else {
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                    setShowModalAccount(false);
                }
            })
    }

    const getDataContainer = () => {
        let check = 0;
        ApiService.OperationPostById('shipmentOrder/shipmentOrder', props.ShipmentId)
            .then((res) => {
                if (res.data.code === 200) {
                    let containerData = res.data.data.shipmentOrder.shipmentOrderSeaContainers;

                    containerData.forEach(element => {
                        if (element.containerNo.includes('DUMY') === true) {
                            check = 1;
                        }
                    });

                    if (check === 0) {
                        setIsDummy(false);
                    }
                    else {
                        setIsDummy(true);
                    }
                }
            })
            .catch(function (error) {

            })
    }

    const handleAdd = (type, contact, contactType, isInc) => {
        setIsAdd(true)
        setIsViewOnly(false)
        setIsAdditional(false)
        clearForm()
        setTabType(type)
        setContact(contact)
        setContactType(contactType)
        setIsIncome(isInc)
        getDataContainer();

        if (contactType === 1) {
            setContactId(props.agentId);
            setContactName(props.agentName);
            handleModalDetail()
        }
        else if (contactType === 2 || contactType === 8) {
            setContactId(props.shipperId);
            setContactName(props.shipperName);
            handleModalDetail()
        }
        else if (contactType === 3 || contactType === 9) {
            setContactId(props.consigneeId);
            setContactName(props.consigneeName);
            handleModalDetail()
        }
        else {
            props.setIsLoadingBackDrop(true);
            ApiService.OperationPostById('shipmentOrder/shipmentOrder', props.ShipmentId)
                .then((res) => {
                    if (res.data.code === 200) {
                        let data = res.data.data.shipmentOrder;
                        if (contactType === 4 && data.ssLineId !== 0) {
                            setContactId(data.ssLineId);
                            setContactName(data.ssLineName);
                        }
                        else if (contactType === 5 && data.iataId !== 0) {
                            setContactId(data.iataId);
                            setContactName(data.iataName);
                        }
                        else if (contactType === 6 && data.emklId !== 0) {
                            setContactId(data.emklId);
                            setContactName(data.emklName);
                        }
                        else if (contactType === 7 && data.depoId !== 0) {
                            setContactId(data.depoId);
                            setContactName(data.depoName);
                        }
                        props.setIsLoadingBackDrop(false);
                        handleModalDetail()
                    }
                })
                .catch(function (error) {
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!, ' + error });
                })
        }
    }

    const handleEdit = (type, contact, contactType, isInc) => {
        let data = []
        switch (contactType) {
            case 1:
                if (isInc === true) {
                    data = props.selectedDetailIncAgent
                }
                else {
                    data = props.selectedDetailCostAgent
                }
                break;
            case 2:
                if (isInc === true) {
                    data = props.selectedDetailIncShipper
                }
                else {
                    data = props.selectedDetailCostDiscountShipper
                }
                break;
            case 3:
                if (isInc === true) {
                    data = props.selectedDetailIncConsignee
                }
                else {
                    data = props.selectedDetailCostDiscountConsignee
                }
                break;
            case 4:
                data = props.selectedDetailCostSSLine
                break;
            case 5:
                data = props.selectedDetailCostIATA
                break;
            case 6:
                data = props.selectedDetailCostEMKL
                break;
            case 7:
                data = props.selectedDetailCostDepo
                break;
            case 8:
                data = props.selectedDetailCostDiscountShipper
                break;
            case 9:
                data = props.selectedDetailCostDiscountConsignee
                break;
            case 10:
                data = props.selectedDetailCostTrucking
                break;
            default:
                return
        }
        if (data.sequence === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            setIsAdd(false)
            handleModalDetail()
            setTabType(type)
            setContact(contact)
            setContactType(contactType)
            setIsIncome(isInc)

            setCurrency(data.amountCrr)
            props.setContainerSize(data.containerSize)
            props.setIsContainer(data.isContainer)
            setContainerTypeId(data.containerTypeId);
            setContainerTypeCode(data.containerTypeCode);
            setContainerTypeName(data.containerTypeName);
            setCOACode(data.coaCode)
            setCOAName(data.coaName)
            setContainerFormat(data.formatContainer);
            setDesc(data.description)
            setContactId(data.customerId)
            setContactName(data.customerName)
            setAccountId(data.accountId)
            setRefId(data.refId);
            setAccountCode(data.accountCode)
            setAccountName(data.accountName)
            if (data.accountName === 'OTHER') {
                setIsOthers(true);
            }
            else {
                setIsOthers(false);
            }
            setSplitAccount(data.isSplitIncCost)
            setCostTrucking(data.isCostTrucking)
            setCostToCost(data.isCostToCost)
            setQtyUnit(data.quantity)
            setPerUnitCost(data.perQty)

            if (data.bookingDetailId === 0) {
                setIsAdditional(false);

                if (data.refId === 0) {
                    setIsViewOnly(false);
                }
                else {
                    setIsViewOnly(true);
                }
            }
            else {
                setIsAdditional(true);

                if (data.refId === 0) {
                    setIsViewOnly(false);
                }
                else {
                    setIsViewOnly(true);
                }
            }


            if (data.amountCrr === 0) {
                setAmount(data.amountUSD)
            }
            else {
                setAmount(data.amountIDR)
            }

            setOriginalRate(data.originalRate)
            setOriginalUSD(data.originalUsd)
            setSign(data.sign)
            setEplId(data.eplId)
            setSequence(data.sequence)
            getDataContainer();
        }
    }

    const handleConvert = (type, contact, contactType, isInc) => {
        let data = {};
        let listData = [];
        switch (contactType) {
            case 1:
                if (isInc === true) {
                    data = props.selectedDetailIncAgent;
                    listData = props.IncAgentList;
                }
                else {
                    data = props.selectedDetailCostAgent;
                    listData = props.CostAgentList;
                }
                break;
            case 2:
                if (isInc === true) {
                    data = props.selectedDetailIncShipper;
                    listData = props.IncShipperList;
                }
                else {
                    data = props.selectedDetailCostDiscountShipper;
                    listData = props.CostDiscountShipperList;
                }
                break;
            case 3:
                if (isInc === true) {
                    data = props.selectedDetailIncConsignee;
                    listData = props.IncConsigneeList;
                }
                else {
                    data = props.selectedDetailCostDiscountConsignee;
                    listData = props.CostDiscountConsigneeList;
                }
                break;
            case 4:
                data = props.selectedDetailCostSSLine;
                listData = props.CostSSLineList;
                break;
            case 5:
                data = props.selectedDetailCostIATA;
                listData = props.CostIATAList;
                break;
            case 6:
                data = props.selectedDetailCostEMKL;
                listData = props.CostEMKLList;
                break;
            case 7:
                data = props.selectedDetailCostDepo;
                listData = props.CostDepoList;
                break;
            case 10:
                data = props.selectedDetailCostTrucking;
                listData = props.CostTruckingList;
                break;
            default:
                return
        }
        if (data.sequence === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            if (data.amountCrr === 0 && data.bookingDetailId !== 0 && data.refId === 0) {
                let check = 0;
                for (let i = 0; i < listData.length; i++) {
                    if (data.containerTypeId === listData[i].containerTypeId && data.customerId === listData[i].customerId && data.containerSize === listData[i].containerSize && data.accountId === listData[i].accountId && listData[i].amountCrr === 1 && listData[i].bookingDetailId === 0) {
                        check = 1;
                        break;
                    }
                }

                if (check === 0) {
                    setIsAdd(true)
                    handleModalDetail()
                    setTabType(type)
                    setContact(contact)
                    setContactType(contactType)
                    setIsIncome(isInc)

                    setCurrency(1)
                    props.setContainerSize(data.containerSize)
                    props.setIsContainer(data.isContainer)
                    setContainerTypeId(data.containerTypeId);
                    setCOACode(data.coaCode)
                    setCOAName(data.coaName)
                    setContainerTypeCode(data.containerTypeCode);
                    setContainerTypeName(data.containerTypeName);
                    setContainerFormat(data.formatContainer);
                    setDesc(data.description)
                    setContactId(data.customerId)
                    setContactName(data.customerName)
                    setAccountId(data.accountId)
                    setAccountCode(data.accountCode)
                    setAccountName(data.accountName)
                    if (data.accountName === 'OTHER') {
                        setIsOthers(true);
                    }
                    else {
                        setIsOthers(false);
                    }
                    setSplitAccount(data.isSplitIncCost)
                    setCostTrucking(data.isCostTrucking)
                    setCostToCost(data.isCostToCost)
                    setQtyUnit(data.quantity)
                    setPerUnitCost(convertToNumeric(0));
                    setOriginalUSD(convertToNumeric(data.perQty));
                    setIsViewOnly(false);
                    setIsAdditional(false);
                    setAmount(0)
                    if (data.originalRate > 0) {
                        setOriginalRate(convertToNumeric(data.originalRate))
                        setPerUnitCost(convertToNumeric(data.originalRate) * convertToNumeric(data.perQty));
                        setAmount(convertToNumeric(data.originalRate) * convertToNumeric(data.perQty) * convertToNumeric(data.quantity));
                    }
                    else {
                        setOriginalRate(convertToNumeric(props.rate))
                        setPerUnitCost(convertToNumeric(props.rate) * convertToNumeric(data.perQty));
                        setAmount(convertToNumeric(props.rate) * convertToNumeric(data.perQty) * convertToNumeric(data.quantity));
                    }
                    setSign(data.sign)
                    setEplId(data.eplId)
                }
                else {
                    showToast({ type: 'error', message: 'Data Have Been Converted!' })
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Can\'t Be Converted!' })
            }
        }
    }

    const handleDelete = (contactType, isInc) => {
        let dataSelect = []
        if (isInc) {
            switch (contactType) {
                case 1:
                    dataSelect = props.selectedDetailIncAgent
                    break;
                case 2:
                    dataSelect = props.selectedDetailIncShipper
                    break;
                case 3:
                    dataSelect = props.selectedDetailIncConsignee
                    break;
                default:
                    return
            }
        }
        else {
            switch (contactType) {
                case 1:
                    dataSelect = props.selectedDetailCostAgent
                    break;
                case 2:
                    dataSelect = props.selectedDetailCostDiscountShipper
                    break;
                case 3:
                    dataSelect = props.selectedDetailCostDiscountConsignee
                    break;
                case 4:
                    dataSelect = props.selectedDetailCostSSLine
                    break;
                case 5:
                    dataSelect = props.selectedDetailCostIATA
                    break;
                case 6:
                    dataSelect = props.selectedDetailCostEMKL
                    break;
                case 7:
                    dataSelect = props.selectedDetailCostDepo
                    break;
                case 10:
                    dataSelect = props.selectedDetailCostTrucking
                    break;
                default:
                    return
            }
        }
        if (dataSelect.sequence !== undefined) {
            if (dataSelect.refId === 0) {
                let delRow = dataSelect.sequence
                const tempdata = props.dataDetail.filter(dt => dt.sequence !== delRow)
                let tempSequence = 0
                tempdata.forEach(row => {
                    tempSequence++
                    row.sequence = tempSequence
                })
                props.setDataDetail(tempdata)
                props.countSum(tempdata)
                props.setIncAgentList(tempdata.filter(dt => dt.customerTypeId === 1 && dt.isIncome === true))
                props.setCostAgentList(tempdata.filter(dt => dt.customerTypeId === 1 && dt.isIncome === false))
                props.setIncShipperList(tempdata.filter(dt => dt.customerTypeId === 2 && dt.isIncome === true))
                props.setIncConsigneeList(tempdata.filter(dt => dt.customerTypeId === 3 && dt.isIncome === true))
                props.setCostSSLineList(tempdata.filter(dt => dt.customerTypeId === 4))
                props.setCostIATAList(tempdata.filter(dt => dt.customerTypeId === 5))
                props.setCostEMKLList(tempdata.filter(dt => dt.customerTypeId === 6))
                props.setCostDepoList(tempdata.filter(dt => dt.customerTypeId === 7))
                props.setCostDiscountShipperList(tempdata.filter(dt => dt.customerTypeId === 2 && dt.isIncome === false))
                props.setCostDiscountConsigneeList(tempdata.filter(dt => dt.customerTypeId === 3 && dt.isIncome === false))
                props.setCostTruckingList(tempdata.filter(dt => dt.customerTypeId === 10))
            }
            else {
                let type = '';
                if (dataSelect.isIncome === true) {
                    type = 'Invoice';
                }
                else {
                    if (dataSelect.customerTypeId === 1) {
                        type = 'Invoice';
                    }
                    else {
                        type = 'Payment Request';
                    }
                }

                showToast({ type: 'error', message: 'Data Already Created Into ' + type + '!' })
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
    }

    const handleDemurrage = () => {

    }

    const handleModalDetail = () => {
        if (showDetail === false) {
            setShowDetail(true)
        }
        else {
            setShowDetail(false)
        }
    }

    const handleClickHF = () => {
        if (isHandlingFee === false) {
            setIsHandlingFee(true);
        }
        else {
            setIsHandlingFee(false);
        }
    }

    const handleClickPS = () => {
        if (isProfitSplit === false) {
            setIsProfitSplit(true);
        }
        else {
            setIsProfitSplit(false);
        }
    }

    const handleClickS = () => {
        if (isStorage === false) {
            setIsStorage(true);
        }
        else {
            setIsStorage(false);
        }
    }

    const handleClickT = () => {
        if (isTrucking === false) {
            setIsTrucking(true);
        }
        else {
            setIsTrucking(false);
        }
    }

    const handleAddSt = (type, contact, contactType, isInc) => {
        setIsAdd(true)
        setIsViewOnly(false)
        setIsAdditional(false)
        handleClickS()
        clearForm()
        setTabType(type)
        setContact(contact)
        setContactType(contactType)
        setIsIncome(isInc)

        if (contactType === 2) {
            setContactId(props.shipperId);
            setContactName(props.shipperName);
        }
        else if (contactType === 3) {
            setContactId(props.consigneeId);
            setContactName(props.consigneeName);
        }
    }

    const handleAddHF = (type, contact, contactType, isInc) => {
        setIsAdd(true)
        setIsViewOnly(false)
        setIsAdditional(false)
        handleClickHF()
        clearForm()
        setTabType(type)
        setContact(contact)
        setContactType(contactType)
        setIsIncome(isInc)

        setContactId(props.agentId);
        setContactName(props.agentName);
    }

    const handleAddPS = (type, contact, contactType, isInc) => {
        setIsAdd(true)
        setIsViewOnly(false)
        setIsAdditional(false)
        handleClickPS()
        clearForm()
        setTabType(type)
        setContact(contact)
        setContactType(contactType)
        setIsIncome(isInc)

        setContactId(props.agentId);
        setContactName(props.agentName);
    }

    const handleAddTrucking = (type, contact, contactType, isInc) => {
        setIsAdd(true)
        setIsViewOnly(false)
        setIsAdditional(false)
        handleClickT()
        clearForm()
        setTabType(type)
        setContact(contact)
        setContactType(contactType)
        setIsIncome(isInc)

        setContactId(props.agentId);
        setContactName(props.agentName);
    }

    const tabIncShipper = (tabValue) => {
        return (
            tabValue === 'IncShipper' && (
                <TabPanel value="IncShipper" className='p-0'>
                    <TabIncShipper
                        key={1}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailIncShipper={props.setSelectedDetailIncShipper}
                        selectedDetailIncShipper={props.selectedDetailIncShipper}
                        IncShipperList={props.IncShipperList}
                        setIncShipperList={props.setIncShipperList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        handleDemurrage={handleDemurrage}
                        handleModalDetail={handleModalDetail}
                        setIsIncome={setIsIncome}
                        handleAddSt={handleAddSt}
                        estIncUSDShipper={props.estIncUSDShipper}
                        estIncIDRShipper={props.estIncIDRShipper}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalIncSShipper={props.totalIncSShipper}
                        totalIncNShipper={props.totalIncNShipper}
                        openCloseIS={openCloseIS}
                        setOpenCloseIS={setOpenCloseIS}
                        openCloseIS2={openCloseIS2}
                        setOpenCloseIS2={setOpenCloseIS2}
                    />
                </TabPanel>
            )
        )
    }

    const tabIncConsignee = (tabValue) => {
        return (
            tabValue === 'IncConsignee' && (
                <TabPanel value="IncConsignee" className='p-0'>
                    <TabIncConsignee
                        key={2}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailIncConsignee={props.setSelectedDetailIncConsignee}
                        selectedDetailIncConsignee={props.selectedDetailIncConsignee}
                        IncConsigneeList={props.IncConsigneeList}
                        setIncConsigneeList={props.setIncConsigneeList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        handleDemurrage={handleDemurrage}
                        setIsIncome={setIsIncome}
                        handleAddSt={handleAddSt}
                        estIncUSDConsignee={props.estIncUSDConsignee}
                        estIncIDRConsignee={props.estIncIDRConsignee}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalIncSConsignee={props.totalIncSConsignee}
                        totalIncNConsignee={props.totalIncNConsignee}
                        openCloseIC={openCloseIC}
                        setOpenCloseIC={setOpenCloseIC}
                        openCloseIC2={openCloseIC2}
                        setOpenCloseIC2={setOpenCloseIC2}
                    />
                </TabPanel>
            )
        )
    }

    const tabIncAgent = (tabValue) => {
        return (
            tabValue === 'IncAgent' && (
                <TabPanel value="IncAgent" className='p-0'>
                    <TabIncAgent
                        key={3}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailIncAgent={props.setSelectedDetailIncAgent}
                        selectedDetailIncAgent={props.selectedDetailIncAgent}
                        IncAgentList={props.IncAgentList}
                        setIncAgentList={props.setIncAgentList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleAddHF={handleAddHF}
                        handleAddPS={handleAddPS}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estIncUSDAgent={props.estIncUSDAgent}
                        estIncIDRAgent={props.estIncIDRAgent}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalIncSAgent={props.totalIncSAgent}
                        totalIncNAgent={props.totalIncNAgent}
                        openCloseIA={openCloseIA}
                        setOpenCloseIA={setOpenCloseIA}
                        openCloseIA2={openCloseIA2}
                        setOpenCloseIA2={setOpenCloseIA2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostSSLine = (tabValue) => {
        return (
            tabValue === 'CostSSLine' && (
                <TabPanel value="CostSSLine" className='p-0'>
                    <TabCostSSLine
                        key={4}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostSSLine={props.setSelectedDetailCostSSLine}
                        selectedDetailCostSSLine={props.selectedDetailCostSSLine}
                        CostSSLineList={props.CostSSLineList}
                        setCostSSLineList={props.setCostSSLineList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estCostUSDSSLine={props.estCostUSDSSLine}
                        estCostIDRSSLine={props.estCostIDRSSLine}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSSSLine={props.totalCostSSSLine}
                        totalCostNSSLine={props.totalCostNSSLine}
                        openCloseCS={openCloseCS}
                        setOpenCloseCS={setOpenCloseCS}
                        openCloseCS2={openCloseCS2}
                        setOpenCloseCS2={setOpenCloseCS2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostIATA = (tabValue) => {
        return (
            tabValue === 'CostIATA' && (
                <TabPanel value="CostIATA" className='p-0'>
                    <TabCostIATA
                        key={5}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostIATA={props.setSelectedDetailCostIATA}
                        selectedDetailCostIATA={props.selectedDetailCostIATA}
                        CostIATAList={props.CostIATAList}
                        setCostIATAList={props.setCostIATAList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estCostUSDIATA={props.estCostUSDIATA}
                        estCostIDRIATA={props.estCostIDRIATA}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSIATA={props.totalCostSIATA}
                        totalCostNIATA={props.totalCostNIATA}
                        openCloseCI={openCloseCI}
                        setOpenCloseCI={setOpenCloseCI}
                        openCloseCI2={openCloseCI2}
                        setOpenCloseCI2={setOpenCloseCI2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostEMKL = (tabValue) => {
        return (
            tabValue === 'CostEMKL' && (
                <TabPanel value="CostEMKL" className='p-0'>
                    <TabCostEMKL
                        key={6}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostEMKL={props.setSelectedDetailCostEMKL}
                        selectedDetailCostEMKL={props.selectedDetailCostEMKL}
                        CostEMKLList={props.CostEMKLList}
                        setCostEMKLList={props.setCostEMKLList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        handleAddTrucking={handleAddTrucking}
                        estCostUSDEMKL={props.estCostUSDEMKL}
                        estCostIDREMKL={props.estCostIDREMKL}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSEMKL={props.totalCostSEMKL}
                        totalCostNEMKL={props.totalCostNEMKL}
                        openCloseCE={openCloseCE}
                        setOpenCloseCE={setOpenCloseCE}
                        openCloseCE2={openCloseCE2}
                        setOpenCloseCE2={setOpenCloseCE2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostDiscountShipper = (tabValue) => {
        return (
            tabValue === 'CostDiscountShipper' && (
                <TabPanel value="CostDiscountShipper" className='p-0'>
                    <TabCostDiscountShipper
                        key={7}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostDiscountShipper={props.setSelectedDetailCostDiscountShipper}
                        selectedDetailCostDiscountShipper={props.selectedDetailCostDiscountShipper}
                        CostDiscountShipperList={props.CostDiscountShipperList}
                        setCostDiscountShipperList={props.setCostDiscountShipperList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estCostUSDDiscountShipper={props.estCostUSDDiscountShipper}
                        estCostIDRDiscountShipper={props.estCostIDRDiscountShipper}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSDiscountShipper={props.totalCostSDiscountShipper}
                        totalCostNDiscountShipper={props.totalCostNDiscountShipper}
                        openCloseCRS={openCloseCRS}
                        setOpenCloseCRS={setOpenCloseCRS}
                        openCloseCRS2={openCloseCRS2}
                        setOpenCloseCRS2={setOpenCloseCRS2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostDiscountConsignee = (tabValue) => {
        return (
            tabValue === 'CostDiscountConsignee' && (
                <TabPanel value="CostDiscountConsignee" className='p-0'>
                    <TabCostDiscountConsignee
                        key={8}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostDiscountConsignee={props.setSelectedDetailCostDiscountConsignee}
                        selectedDetailCostDiscountConsignee={props.selectedDetailCostDiscountConsignee}
                        CostDiscountConsigneeList={props.CostDiscountConsigneeList}
                        setCostDiscountConsigneeList={props.setCostDiscountConsigneeList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estCostUSDDiscountConsignee={props.estCostUSDDiscountConsignee}
                        estCostIDRDiscountConsignee={props.estCostIDRDiscountConsignee}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSDiscountConsignee={props.totalCostSDiscountConsignee}
                        totalCostNDiscountConsignee={props.totalCostNDiscountConsignee}
                        openCloseCRC={openCloseCRC}
                        setOpenCloseCRC={setOpenCloseCRC}
                        openCloseCRC2={openCloseCRC2}
                        setOpenCloseCRC2={setOpenCloseCRC2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostAgent = (tabValue) => {
        return (
            tabValue === 'CostAgent' && (
                <TabPanel value="CostAgent" className='p-0'>
                    <TabCostAgent
                        key={9}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostAgent={props.setSelectedDetailCostAgent}
                        selectedDetailCostAgent={props.selectedDetailCostAgent}
                        CostAgentList={props.CostAgentList}
                        setCostAgentList={props.setCostAgentList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        handleDemurrage={handleDemurrage}
                        isIncome={isIncome}
                        handleAddHF={handleAddHF}
                        handleAddPS={handleAddPS}
                        setIsIncome={setIsIncome}
                        estCostUSDAgent={props.estCostUSDAgent}
                        estCostIDRAgent={props.estCostIDRAgent}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSAgent={props.totalCostSAgent}
                        totalCostNAgent={props.totalCostNAgent}
                        openCloseCA={openCloseCA}
                        setOpenCloseCA={setOpenCloseCA}
                        openCloseCA2={openCloseCA2}
                        setOpenCloseCA2={setOpenCloseCA2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostDepo = (tabValue) => {
        return (
            tabValue === 'CostDepo' && (
                <TabPanel value="CostDepo" className='p-0'>
                    <TabCostDepo
                        key={10}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostDepo={props.setSelectedDetailCostDepo}
                        selectedDetailCostDepo={props.selectedDetailCostDepo}
                        CostDepoList={props.CostDepoList}
                        setCostDepoList={props.setCostDepoList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estCostUSDDepo={props.estCostUSDDepo}
                        estCostIDRDepo={props.estCostIDRDepo}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSDepo={props.totalCostSDepo}
                        totalCostNDepo={props.totalCostNDepo}
                        openCloseCD={openCloseCD}
                        setOpenCloseCD={setOpenCloseCD}
                        openCloseCD2={openCloseCD2}
                        setOpenCloseCD2={setOpenCloseCD2}
                    />
                </TabPanel>
            )
        )
    }

    const tabCostTrucking = (tabValue) => {
        return (
            tabValue === 'CostTrucking' && (
                <TabPanel value="CostTrucking" className='p-0'>
                    <TabCostTrucking
                        key={11}
                        isReadOnly={props.isReadOnly}
                        setSelectedDetailCostTrucking={props.setSelectedDetailCostTrucking}
                        selectedDetailCostTrucking={props.selectedDetailCostTrucking}
                        CostTruckingList={props.CostTruckingList}
                        setCostTruckingList={props.setCostTruckingList}
                        ignoreAccess={props.ignoreAccess}
                        handleAdd={handleAdd}
                        handleEdit={handleEdit}
                        handleConvert={handleConvert}
                        handleDelete={handleDelete}
                        setIsIncome={setIsIncome}
                        estCostUSDTrucking={props.estCostUSDTrucking}
                        estCostIDRTrucking={props.estCostIDRTrucking}
                        shipmentNo={props.shipmentNo}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}

                        totalCostSTrucking={props.totalCostSTrucking}
                        totalCostNTrucking={props.totalCostNTrucking}
                        openCloseCT={openCloseCT}
                        setOpenCloseCT={setOpenCloseCT}
                        openCloseCT2={openCloseCT2}
                        setOpenCloseCT2={setOpenCloseCT2}
                    />
                </TabPanel>
            )
        )
    }

    const mainTab = () => {
        switch (props.jobId) {
            case '1':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostSSLine"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostSSLine" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost SSLine
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncShipper(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostSSLine(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountShipper(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '2':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostSSLine"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostSSLine" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost SSLine
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncConsignee(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostSSLine(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountConsignee(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '3':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostIATA"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostIATA" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost IATA
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncShipper(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostIATA(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountShipper(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '4':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostIATA"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostIATA" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost IATA
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncConsignee(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostIATA(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountConsignee(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '5':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostSSLine"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostSSLine" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost SSLine
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncShipper(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostSSLine(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountShipper(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '6':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostSSLine"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostSSLine" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost SSLine
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncConsignee(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostSSLine(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountConsignee(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '7':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostIATA"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostIATA" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost IATA
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncShipper(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostIATA(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountShipper(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '8':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostIATA"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostIATA" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost IATA
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncConsignee(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostIATA(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountConsignee(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '9':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostSSLine"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostSSLine" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost SSLine
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostIATA"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostIATA" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost IATA
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncShipper(props.tabValue)}
                            {tabIncConsignee(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostSSLine(props.tabValue)}
                            {tabCostIATA(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountShipper(props.tabValue)}
                            {tabCostDiscountConsignee(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            case '10':
                return (
                    <Box sx={{ width: '100%' }}>
                        <TabContext value={props.tabValue}>
                            <Tabs
                                sx={{ width: '100%', marginTop: '24px', height: 'fit-content' }}
                                variant="fullWidth"
                                value={props.tabValue}
                                onChange={handleChange}
                                textColor="primary"
                                TabIndicatorProps={{
                                    sx: {
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                        backgroundColor: "#0087C2 !important",
                                    },
                                }}
                                TabScrollButtonProps={{
                                    sx: {
                                        backgroundColor: "#0087C2 !important",
                                        height: 'fit-content !important',
                                        maxHeight: '32px !important',
                                    },
                                }}
                            >
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="IncAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "IncAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Income Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostSSLine"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostSSLine" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost SSLine
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostIATA"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostIATA" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost IATA
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostEMKL"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostEMKL" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost EMKL
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountShipper"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountShipper" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Shipper
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDiscountConsignee"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDiscountConsignee" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Discount Consignee
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostAgent"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostAgent" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Agent
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostDepo"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostDepo" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Depo
                                        </Typography>
                                    }
                                />
                                <Tab
                                    sx={{
                                        padding: '4px 12px !important',
                                        minHeight: '35px',
                                        textTransform: "none",
                                        backgroundColor: '#D7F0FF',
                                        '&.Mui-selected': {
                                            color: '#f2f2f2',
                                            backgroundColor: '#0087C2',
                                        },
                                    }}
                                    value="CostTrucking"
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.tabValue === "CostTrucking" ? '#F2FAFF' : '#083A50',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Cost Trucking
                                        </Typography>
                                    }
                                />
                            </Tabs>

                            {tabIncShipper(props.tabValue)}
                            {tabIncConsignee(props.tabValue)}
                            {tabIncAgent(props.tabValue)}
                            {tabCostSSLine(props.tabValue)}
                            {tabCostIATA(props.tabValue)}
                            {tabCostEMKL(props.tabValue)}
                            {tabCostDiscountShipper(props.tabValue)}
                            {tabCostDiscountConsignee(props.tabValue)}
                            {tabCostAgent(props.tabValue)}
                            {tabCostDepo(props.tabValue)}
                            {tabCostTrucking(props.tabValue)}
                        </TabContext>
                    </Box>
                );
            default:
                return
        }
    }

    const handleModalContact = (data) => {
        if (data === 'search') {
            if (showModalContact === true) {
                setShowModalContact(false)
            } else {
                getDataContact(1, 50)
                setShowModalContact(true)
            }
        }
        else if (data === 'delete') {
            setContactId(0);
            setContactName('');
        }
    }

    const handleModalAccount = (data) => {
        if (data === 'search') {
            if (showModalAccount === true) {
                setShowModalAccount(false)
            } else {
                setShowModalAccount(true)
                getDataAccount(1, 50)
            }
        }
        else if (data === 'delete') {
            setAccountId(0);
            setAccountCode('');
            setAccountName('');
            setDesc('');
            props.setIsContainer(false);
            setSize('All');
            setContainerFormat('All');
            setQtyUnit(0);
            setAmount(0);
        }
    }

    return (
        <div ref={props.detailRef}>
            <Box sx={{ width: '100%' }}>
                {mainTab(props.tabValue)}
            </Box>

            {
                showDetail === true && (
                    <ModalDetail
                        isReadOnly={props.isReadOnly}
                        isDummy={isDummy}
                        containerSizeData={props.containerSizeData}
                        isViewOnly={isViewOnly}
                        isAdditional={isAdditional}
                        TabType={TabType}
                        isAdd={isAdd}

                        contact={contact}
                        contactType={contactType}
                        showDetail={showDetail}
                        handleModalDetail={handleModalDetail}
                        isIncome={isIncome}
                        SelectedContact={SelectedContact}
                        SelectedAccount={SelectedAccount}
                        setSelectedAccount={setSelectedAccount}
                        setSelectedContact={setSelectedContact}
                        handleModalContact={handleModalContact}
                        handleModalAccount={handleModalAccount}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}

                        setIncShipperList={props.setIncShipperList}
                        setIncConsigneeList={props.setIncConsigneeList}
                        setIncAgentList={props.setIncAgentList}
                        setCostSSLineList={props.setCostSSLineList}
                        setCostIATAList={props.setCostIATAList}
                        setCostEMKLList={props.setCostEMKLList}
                        setCostDiscountList={props.setCostDiscountList}
                        setCostDiscountShipperList={props.setCostDiscountShipperList}
                        setCostDiscountConsigneeList={props.setCostDiscountConsigneeList}
                        setCostAgentList={props.setCostAgentList}
                        setCostDepoList={props.setCostDepoList}
                        setCostTruckingList={props.setCostTruckingList}
                        IncShipperList={props.IncShipperList}
                        IncConsigneeList={props.IncConsigneeList}
                        IncAgentList={props.IncAgentList}
                        CostSSLineList={props.CostSSLineList}
                        CostIATAList={props.CostIATAList}
                        CostEMKLList={props.CostEMKLList}
                        CostDiscountList={props.CostDiscountList}
                        CostDiscountShipperList={props.CostDiscountShipperList}
                        CostDiscountConsigneeList={props.CostDiscountConsigneeList}
                        CostAgentList={props.CostAgentList}
                        CostDepoList={props.CostDepoList}
                        CostTruckingList={props.CostTruckingList}

                        contactId={contactId}
                        setContactId={setContactId}
                        contactName={contactName}
                        setContactName={setContactName}
                        accountId={accountId}
                        setAccountId={setAccountId}
                        accountCode={accountCode}
                        setAccountCode={setAccountCode}
                        accountName={accountName}
                        setAccountName={setAccountName}
                        SplitAccount={SplitAccount}
                        Currency={Currency}
                        CostTrucking={CostTrucking}
                        CostToCost={CostToCost}
                        QtyUnit={QtyUnit}
                        PerUnitCost={PerUnitCost}
                        Amount={Amount}
                        OriginalRate={OriginalRate}
                        OriginalUSD={OriginalUSD}
                        sign={sign}
                        eplId={eplId}
                        setSplitAccount={setSplitAccount}
                        setCurrency={setCurrency}
                        setCostTrucking={setCostTrucking}
                        setCostToCost={setCostToCost}
                        setQtyUnit={setQtyUnit}
                        setPerUnitCost={setPerUnitCost}
                        setAmount={setAmount}
                        setOriginalRate={setOriginalRate}
                        setOriginalUSD={setOriginalUSD}
                        setSign={setSign}
                        setEplId={setEplId}
                        clearForm={clearForm}
                        sequence={sequence}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}
                        Size={Size}
                        setSize={setSize}
                        Desc={Desc}
                        setDesc={setDesc}
                        refId={refId}
                        setRefId={setRefId}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                        containerSize={props.containerSize}
                        setContainerSize={props.setContainerSize}
                        isContainer={props.isContainer}
                        setIsContainer={props.setIsContainer}

                        containerTypeId={containerTypeId}
                        setContainerTypeId={setContainerTypeId}
                        containerTypeCode={containerTypeCode}
                        setContainerTypeCode={setContainerTypeCode}
                        containerTypeName={containerTypeName}
                        setContainerTypeName={setContainerTypeName}
                        containerFormat={containerFormat}
                        setContainerFormat={setContainerFormat}
                        isOthers={isOthers}
                        setIsOthers={setIsOthers}

                        COACode={COACode}
                        setCOACode={setCOACode}
                        COAName={COAName}
                        setCOAName={setCOAName}

                        showModalAccount={showModalAccount}
                        dataAccount={dataAccount}
                        isLoadingAccount={isLoadingAccount}
                        dataMapAccount={dataMapAccount}
                        dataTableAccount={dataTableAccount}
                        columnDataAccount={columnDataAccount}
                        totalRowsAccount={totalRowsAccount}
                        getDataAccount={getDataAccount}
                    />
                )
            }

            {
                showModalContact === true && (
                    <ModalContact
                        show={showModalContact}
                        handleModalContact={handleModalContact}
                        setSelectedData={(e) => setSelectedContact(e)}
                        SelectedData={SelectedContact}
                        contact={contact}
                        contactType={contactType}
                        data={data}
                        isLoading={isLoading}
                        dataMap={dataMap}
                        dataTable={dataTable}
                        columnData={columnData}
                        totalRows={totalRows}
                        getData={getDataContact}
                        setContactId={setContactId}
                        setContactName={setContactName}
                    />
                )
            }

            {
                isHandlingFee === true && (
                    <ModalHandlingFee
                        showDetail={isHandlingFee}
                        handleModalDetail={handleClickHF}
                        isReadOnly={props.isReadOnly}

                        TabType={TabType}
                        contact={contact}
                        contactType={contactType}
                        setSelectedContact={setSelectedContact}
                        handleModalContact={handleModalContact}

                        contactId={contactId}
                        setContactId={setContactId}
                        contactName={contactName}
                        setContactName={setContactName}
                        sign={sign}
                        setSign={setSign}
                    />
                )
            }

            {
                isProfitSplit === true && (
                    <ModalProfitSplit
                        showDetail={isProfitSplit}
                        handleModalDetail={handleClickPS}
                        isReadOnly={props.isReadOnly}

                        TabType={TabType}
                        contact={contact}
                        contactType={contactType}
                        setSelectedContact={setSelectedContact}
                        handleModalContact={handleModalContact}

                        contactId={contactId}
                        setContactId={setContactId}
                        contactName={contactName}
                        setContactName={setContactName}
                        sign={sign}
                        setSign={setSign}
                    />
                )
            }

            {
                isStorage === true && (
                    <ModalStorage
                        showDetail={isStorage}
                        handleModalDetail={handleClickS}
                        isReadOnly={props.isReadOnly}

                        TabType={TabType}
                        contact={contact}
                        contactType={contactType}
                        setSelectedContact={setSelectedContact}
                        handleModalContact={handleModalContact}

                        contactId={contactId}
                        setContactId={setContactId}
                        contactName={contactName}
                        setContactName={setContactName}
                        Currency={Currency}
                        setCurrency={setCurrency}
                        QtyUnit={QtyUnit}
                        setQtyUnit={setQtyUnit}
                        PerUnitCost={PerUnitCost}
                        setPerUnitCost={setPerUnitCost}
                        Amount={Amount}
                        setAmount={setAmount}

                        setIncShipperList={props.setIncShipperList}
                        setIncConsigneeList={props.setIncConsigneeList}
                        setIncAgentList={props.setIncAgentList}
                        setCostSSLineList={props.setCostSSLineList}
                        setCostIATAList={props.setCostIATAList}
                        setCostEMKLList={props.setCostEMKLList}
                        setCostDiscountList={props.setCostDiscountList}
                        setCostDiscountShipperList={props.setCostDiscountShipperList}
                        setCostDiscountConsigneeList={props.setCostDiscountConsigneeList}
                        setCostAgentList={props.setCostAgentList}
                        setCostDepoList={props.setCostDepoList}
                        setCostTruckingList={props.setCostTruckingList}
                        IncShipperList={props.IncShipperList}
                        IncConsigneeList={props.IncConsigneeList}
                        IncAgentList={props.IncAgentList}
                        CostSSLineList={props.CostSSLineList}
                        CostIATAList={props.CostIATAList}
                        CostEMKLList={props.CostEMKLList}
                        CostDiscountList={props.CostDiscountList}
                        CostDiscountShipperList={props.CostDiscountShipperList}
                        CostDiscountConsigneeList={props.CostDiscountConsigneeList}
                        CostAgentList={props.CostAgentList}
                        CostDepoList={props.CostDepoList}
                        CostTruckingList={props.CostTruckingList}
                    />
                )
            }

            {
                isTrucking === true && (
                    <ModalTrucking
                        isReadOnly={props.isReadOnly}
                        isDummy={isDummy}
                        containerSizeData={props.containerSizeData}
                        isViewOnly={isViewOnly}
                        isAdditional={isAdditional}
                        TabType={TabType}
                        isAdd={isAdd}

                        contact={contact}
                        contactType={contactType}
                        showDetail={isTrucking}
                        handleModalDetail={handleClickT}
                        isIncome={isIncome}
                        SelectedContact={SelectedContact}
                        SelectedAccount={SelectedAccount}
                        setSelectedAccount={setSelectedAccount}
                        setSelectedContact={setSelectedContact}
                        handleModalContact={handleModalContact}
                        handleModalAccount={handleModalAccount}
                        dataDetail={props.dataDetail}
                        setDataDetail={props.setDataDetail}

                        setIncShipperList={props.setIncShipperList}
                        setIncConsigneeList={props.setIncConsigneeList}
                        setIncAgentList={props.setIncAgentList}
                        setCostSSLineList={props.setCostSSLineList}
                        setCostIATAList={props.setCostIATAList}
                        setCostEMKLList={props.setCostEMKLList}
                        setCostDiscountList={props.setCostDiscountList}
                        setCostDiscountShipperList={props.setCostDiscountShipperList}
                        setCostDiscountConsigneeList={props.setCostDiscountConsigneeList}
                        setCostAgentList={props.setCostAgentList}
                        setCostDepoList={props.setCostDepoList}
                        setCostTruckingList={props.setCostTruckingList}
                        IncShipperList={props.IncShipperList}
                        IncConsigneeList={props.IncConsigneeList}
                        IncAgentList={props.IncAgentList}
                        CostSSLineList={props.CostSSLineList}
                        CostIATAList={props.CostIATAList}
                        CostEMKLList={props.CostEMKLList}
                        CostDiscountList={props.CostDiscountList}
                        CostDiscountShipperList={props.CostDiscountShipperList}
                        CostDiscountConsigneeList={props.CostDiscountConsigneeList}
                        CostAgentList={props.CostAgentList}
                        CostDepoList={props.CostDepoList}
                        CostTruckingList={props.CostTruckingList}

                        contactId={contactId}
                        setContactId={setContactId}
                        contactName={contactName}
                        setContactName={setContactName}
                        accountId={accountId}
                        setAccountId={setAccountId}
                        accountCode={accountCode}
                        setAccountCode={setAccountCode}
                        accountName={accountName}
                        setAccountName={setAccountName}
                        SplitAccount={SplitAccount}
                        Currency={Currency}
                        CostTrucking={CostTrucking}
                        CostToCost={CostToCost}
                        QtyUnit={QtyUnit}
                        PerUnitCost={PerUnitCost}
                        Amount={Amount}
                        OriginalRate={OriginalRate}
                        OriginalUSD={OriginalUSD}
                        sign={sign}
                        eplId={eplId}
                        setSplitAccount={setSplitAccount}
                        setCurrency={setCurrency}
                        setCostTrucking={setCostTrucking}
                        setCostToCost={setCostToCost}
                        setQtyUnit={setQtyUnit}
                        setPerUnitCost={setPerUnitCost}
                        setAmount={setAmount}
                        setOriginalRate={setOriginalRate}
                        setOriginalUSD={setOriginalUSD}
                        setSign={setSign}
                        setEplId={setEplId}
                        clearForm={clearForm}
                        sequence={sequence}
                        countSum={props.countSum}
                        countTotalData={props.countTotalData}
                        Size={Size}
                        setSize={setSize}
                        Desc={Desc}
                        setDesc={setDesc}
                        refId={refId}
                        setRefId={setRefId}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                        containerSize={props.containerSize}
                        setContainerSize={props.setContainerSize}
                        isContainer={props.isContainer}
                        setIsContainer={props.setIsContainer}

                        containerTypeId={containerTypeId}
                        setContainerTypeId={setContainerTypeId}
                        containerTypeCode={containerTypeCode}
                        setContainerTypeCode={setContainerTypeCode}
                        containerTypeName={containerTypeName}
                        setContainerTypeName={setContainerTypeName}
                        containerFormat={containerFormat}
                        setContainerFormat={setContainerFormat}
                        isOthers={isOthers}
                        setIsOthers={setIsOthers}

                        COACode={COACode}
                        setCOACode={setCOACode}
                        COAName={COAName}
                        setCOAName={setCOAName}

                        showModalAccount={showModalAccount}
                        dataAccount={dataAccount}
                        isLoadingAccount={isLoadingAccount}
                        dataMapAccount={dataMapAccount}
                        dataTableAccount={dataTableAccount}
                        columnDataAccount={columnDataAccount}
                        totalRowsAccount={totalRowsAccount}
                        getDataAccount={getDataAccount}
                    />
                )
            }
        </div>
    )
}

export default React.memo(TabEPL)