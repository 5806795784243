import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService, { branchId, companyId, countryId, isInfossNo, userCode, userId, username } from '../../../../services/ApiService'
import BankAdvCreateEditButton from './ComponentBankAdv/BankAdvCreateEditButton'
import BankAdvDetail from './ContentBankAdv/BankAdvDetail'
import BankAdvHeader from './ContentBankAdv/BankAdvHeader'
import BankAdvTotal from './ContentBankAdv/BankAdvTotal'
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import { forwardRef } from 'react';
import { Box, Grid, Card, CardContent } from '@mui/material'
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo'
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { defaultDate, getDefaultDateFormat, setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal, newWindowConfig } from '../../../../utils/masks.jsx';
import CustomToastContainer, { showToast } from '../../../../components/ui'
import { autoFocusField, PrintPDF } from '../../../../utils/reusableFunction.jsx'

const CreateEditBankAdv = forwardRef((props, ref) => {
  const dataLocal = JSON.parse(localStorage.getItem(username + "-DataBA"));

  const { bankAdvId } = useParams()

  const bankRef = useRef(null);
  const employeeRef = useRef(null);
  const detailRef = useRef(null);
  const infossNoRef = useRef(null);

  const [dataDetail, setDataDetail] = useState([])
  const [selectedDetail, setSelectedDetail] = useState({})
  const DefDate = defaultDate
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

  const [bankAdvanceNo, setBankAdvanceNo] = useState(0)
  const [bankId, setBankId] = useState(0)
  const [reference, setReference] = useState('')
  const [employeeId, setEmployeeId] = useState(0)
  const [employeeCode, setEmployeeCode] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [bankAdvanceTo, setBankAdvanceTo] = useState('O')
  const [rate, setRate] = useState(0);
  const [rateReal, setRateReal] = useState(0);
  const [exRateDate, setExRateDate] = useState(DefDate)
  const [exRateId, setExRateId] = useState(0)
  const [printing, setPrinting] = useState(0)

  const [bankCode, setBankCode] = useState('')
  const [bankIntName, setBankIntName] = useState('')
  const [bankName, setBankName] = useState('')
  const [bankAccNum, setBankAccNum] = useState('')

  const [totalUSD, setTotalUSD] = useState(0)
  const [totalIDR, setTotalIDR] = useState(0)
  const [totalInIDR, setTotalInIDR] = useState(0)

  const [totalUSDReal, setTotalUSDReal] = useState(0)
  const [totalIDRReal, setTotalIDRReal] = useState(0)
  const [totalInIDRReal, setTotalInIDRReal] = useState(0)

  const [printedOn, setPrintedOn] = useState('')
  const [paidOn, setPaidOn] = useState('')

  const paramBankAdv = 'bankAdvance/bankAdvance'
  const paramRegUserAccess = 'regUserAccess/regUserAccess';

  const history = useNavigate()
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [check, setCheck] = useState(0);
  const titleDetail = 'Bank'

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');
  const [isRemarks, setIsRemarks] = useState(false);
  const [approvedCreditRemarks, setApprovedCreditRemarks] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [documentStatus, setDocumentStatus] = useState('');
  const [infossNo, setInfossNo] = useState('');
  // const [isViewOnly, setIsViewOnly] = useState(false);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Bank Advance', link: '/Majura/BankAdvance', style: '' },
    { label: 'Create Bank Advance', link: '/Majura/BankAdvance/Create', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  const BreadcrumbsEditItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Bank Advance', link: '/Majura/BankAdvance', style: '' },
    { label: 'Detail Bank Advance', link: '/Majura/BankAdvance/' + bankAdvId, style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Bank Advance - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankAdvId])

  useImperativeHandle(ref, () => ({
    localSave() {
      saveLocalData();
    }
  }));

  function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
    if (rowStatus !== 'ACT') {
      setDocumentStatus('DELETED');
    }
    else if (isApproved) {
      setDocumentStatus('APPROVED');
    }
    else {
      setDocumentStatus('ONGOING');
    }
  }

  const saveLocalData = () => {
    let id = bankAdvId ? bankAdvId : 0;
    let datePaid = updateDateFormat(paidOn, true);
    let datePrint = updateDateFormat(printedOn, true);

    const payload = {
      "bankAdvance": {
        "rowStatus": "ACT",
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        "id": id,

        "bankId": bankId,
        'noInfoss': infossNo,
        "bankCode": bankCode,
        "bankIntName": bankIntName,
        "bankName": bankName,
        "bankAccNum": bankAccNum,
        "reference": reference,
        "approved": isApprove,
        "approvedOn": defaultDate,
        "employeeId": employeeId,
        "employeeCode": employeeCode,
        "employeeName": employeeName,
        "bankAdvanceTo": bankAdvanceTo,
        "totalUSD": totalUSDReal,
        "totalIDR": totalIDRReal,
        "paid": false,
        "paidOn": datePaid,
        "rate": rateReal,
        "exRateDate": exRateDate,
        "printing": printing,
        "printedOn": datePrint,
        "deleted": false,
        "deletedOn": defaultDate,
        "exRateId": exRateId,
        "approvedCredit": false,
        "approvedCreditById": 0,
        "approvedCreditOn": defaultDate,
        "approvedCreditRemarks": approvedCreditRemarks,
        "userId": userId,
        "userCode": userCode,
        "SettlementIDR": 0,
        "UnSettledIDR": totalIDRReal,
        "SettlementUSD": 0,
        "UnSettledUSD": totalUSDReal,
      },
      "bankAdvanceDetails": dataDetail
    }

    localStorage.setItem(username + "-DataBA", JSON.stringify(payload));
  }

  const getLocalData = () => {
    let bankAdvanceData = dataLocal.bankAdvance;
    let bankAdvanceDetailData = dataLocal.bankAdvanceDetails;

    if (bankAdvId) {
      setIsEdit(true);
    }

    setBankAdvanceNo(bankAdvanceData.bankAdvanceNo)
    setInfossNo(bankAdvanceData.noInfoss)
    setBankId(bankAdvanceData.bankId)
    setReference(bankAdvanceData.reference)
    setEmployeeId(bankAdvanceData.employeeId)
    setBankAdvanceTo(bankAdvanceData.bankAdvanceTo)
    setTotalUSD(bankAdvanceData.totalUSD)
    setTotalIDR(bankAdvanceData.totalIDR)
    setRateReal(bankAdvanceData.rate)
    setIsApprove(bankAdvanceData.approved);
    handleDocumentStatus(bankAdvanceData.rowStatus, bankAdvanceData.approved)
    setRate(convertToNominal(bankAdvanceData.rate));
    setExRateDate(getDefaultDateFormat(bankAdvanceData.exRateDate))
    setPrinting(bankAdvanceData.printing)
    setExRateId(bankAdvanceData.exRateId)
    setBankCode(bankAdvanceData.bankCode)
    setBankIntName(bankAdvanceData.bankIntName)
    setPrinting(bankAdvanceData.printing)
    setBankName(bankAdvanceData.bankName)
    setBankAccNum(bankAdvanceData.bankAccNum)
    setApprovedCreditRemarks(bankAdvanceData.approvedCreditRemarks)
    setEmployeeCode(bankAdvanceData.employeeCode)
    setEmployeeName(bankAdvanceData.employeeName)
    setDateFormat(bankAdvanceData.printedOn, setPrintedOn);
    setDateFormat(bankAdvanceData.paidOn, setPaidOn);

    setDataDetail(bankAdvanceDetailData)
    sumTotal(bankAdvanceDetailData, bankAdvanceData.rate)

    localStorage.removeItem(username + '-DataBA');
    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
  }

  const getData = () => {
    ApiService.FinAccPostById(paramBankAdv, bankAdvId)
      .then((response) => {
        if (response.data.code === 200) {
          var result = response.data.data.bankAdvance
          setIsEdit(true);
          setInfossNo(result.noInfoss)
          setBankAdvanceNo(result.bankAdvanceNo)
          setBankId(result.bankId)
          setReference(result.reference)
          setEmployeeId(result.employeeId)
          setBankAdvanceTo(result.bankAdvanceTo)
          setTotalUSD(result.totalUSD)
          setTotalIDR(result.totalIDR)
          setRateReal(result.rate)
          setPrinting(result.printing)
          setIsApprove(result.approved);
          handleDocumentStatus(result.rowStatus, result.approved)
          setRate(convertToNominal(result.rate))
          setExRateDate(getDefaultDateFormat(result.exRateDate))
          setExRateId(result.exRateId)
          setBankCode(result.bankCode)
          setBankIntName(result.bankIntName)
          setPrinting(result.printing)
          setBankName(result.bankName)
          setBankAccNum(result.bankAccNum)
          setApprovedCreditRemarks(result.approvedCreditRemarks)
          setDataDetail(result.bankAdvanceDetails)
          setEmployeeCode(result.employeeCode)
          setEmployeeName(result.employeeName)
          setDateFormat(result.printedOn, setPrintedOn);
          setDateFormat(result.paidOn, setPaidOn);

          sumTotal(result.bankAdvanceDetails, result.rate)

          if (!dataLocal) {
            setIsLoadingBackDrop(false);
          }
        }
        else {
          handleBack();
        }
      })
      .catch(function (error) {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const getDataAccess = () => {
    if (!dataLocal) {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false
    let tempArray = [];
    let checked = 0;

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FBAD')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);
            tempArray = response.data.data.userAccess;

            tempArray.forEach(element => {
              if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                checked = 1;
              }
            });

            setCheck(checked);

            if (dataLocal) {
              setIsLoadingBackDrop(true);
              setTimeout(function () { getLocalData(); }, 2000);
            } else {
              if (!bankAdvId) {
                getDateExchRate()
                handleDocumentStatus();
              } else {
                getData()
              }
            }
          }
          else {
            handleBack();
          }
        })
        .catch(function (error) {
          setIsLoadingBackDrop(false);
          console.error('error saat fetch', error)
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
        })
    }
    else {
      if (!dataLocal) {
        setIsLoadingBackDrop(false);
      }

      history('/Majura');
    }
  }

  const handleSave = (isPrint = false) => {
    setIsLoadingBackDrop(true);
    let id = bankAdvId ? bankAdvId : 0;
    let datePaid = updateDateFormat(paidOn, true);
    let datePrint = updateDateFormat(printedOn, true);

    const payload = {
      "bankAdvance": {
        "rowStatus": "ACT",
        'noInfoss': infossNo,
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        "id": id,
        "bankAdvanceNo": bankAdvanceNo,
        "bankId": bankId,
        "bankCode": bankCode,
        "bankIntName": bankIntName,
        "bankName": bankName,
        "bankAccNum": bankAccNum,
        "reference": reference,
        "approved": false,
        "approvedOn": defaultDate,
        "employeeId": employeeId,
        "employeeCode": employeeCode,
        "employeeName": employeeName,
        "bankAdvanceTo": bankAdvanceTo,
        "totalUSD": totalUSDReal,
        "totalIDR": totalIDRReal,
        "paid": false,
        "paidOn": datePaid,
        "rate": rateReal,
        "exRateDate": exRateDate,
        "printing": printing,
        "printedOn": datePrint,
        "deleted": false,
        "deletedOn": defaultDate,
        "exRateId": exRateId,
        "approvedCredit": false,
        "approvedCreditById": 0,
        "approvedCreditOn": defaultDate,
        "approvedCreditRemarks": approvedCreditRemarks,
        "userId": userId,
        "userCode": userCode,
        "SettlementIDR": 0,
        "UnSettledIDR": totalIDRReal,
        "SettlementUSD": 0,
        "UnSettledUSD": totalUSDReal,
      },
      "bankAdvanceDetails": dataDetail
    }

    let errorMessage = undefined;

    if (payload.bankAdvance.employeeId === 0) {
      errorMessage = 'Please Select Employee Data!';
      autoFocusField(employeeRef);
    }
    else if (payload.bankAdvance.bankId === 0) {
      errorMessage = 'Please Select Bank Data!';
      autoFocusField(bankRef);
    }
    else if (payload.bankAdvanceDetails.length === 0) {
      errorMessage = 'Please Fill Detail Data!';
      autoFocusField(detailRef);
    }
    else if (payload.bankAdvance.noInfoss === '' && isInfossNo) {
      errorMessage = 'Please Fill Infoss Number!';
      autoFocusField(infossNoRef);
    }

    if (errorMessage === undefined) {
      ApiService.HelpCreateData(payload, paramBankAdv, id)
        .then((res) => {
          if (res.data.code === 200) {
            let message = ''
            if (!bankAdvId) {
              ApiService.CreateFinAcc(paramBankAdv, payload)
                .then((res) => {
                  if (res.data.code === 200) {
                    message = 'Success create data';
                    showToast({ type: 'success', message: message });

                    setTimeout(
                      function () {
                        localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                        history('/Majura/BankAdvance/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                        window.location.reload();
                        localStorage.removeItem('tempId');
                      },
                      1250);
                  }
                  else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: res.data.message });
                  }
                })
            }
            else {
              ApiService.EditFinAcc(paramBankAdv, payload)
                .then((res) => {
                  if (res.data.code === 200) {
                    if (!isPrint) {
                      message = 'Success update data';
                      showToast({ type: 'success', message: message });

                      setTimeout(
                        function () {
                          history('/Majura/BankAdvance/' + bankAdvId, { replace: true });
                          window.location.reload();
                        },
                        1250);
                    }
                    else {
                      Print();
                    }
                  }
                  else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: res.data.message });
                  }
                })
            }
          }
          else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Failed to Create Help Data' });
          }
        })
        .catch(function (error) {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
        })
    }
    else {
      setIsLoadingBackDrop(false);
      showToast({ type: 'error', message: errorMessage });
    }
  }

  const handleBack = () => {
    history('/Majura/BankAdvance')
  }

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
      setApprovedCreditRemarks('');
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationBA(approveRemarks = '') {
    if (titleConfirmation === 'Print') {
      checkDataCondition('Print')
    }
    else if (titleConfirmation === 'Approve') {
      Approval();
    }
    else if (titleConfirmation === 'Approval Credit') {
      ApprovalCr(approveRemarks);
    }
    else if (titleConfirmation === "Add") {
      Add();
    }
    setIsRemarks(false);
    setIsModalConfirmation(false);
  }

  const checkDataCondition = async (isAction) => {
    if (!bankAdvId && isAction !== 'Save') {
      showToast({ type: 'error', message: 'Create The Data First!' });
      return;
    }

    try {
      setIsLoadingBackDrop(true);

      if (isAction === 'Save' && !bankAdvId) {
        handleSave(false);
        return;
      }

      const response = bankAdvId ? await ApiService.FinAccPostById(paramBankAdv, bankAdvId) : null;

      if (response && response.data.code !== 200) {
        throw new Error('Failed to fetch data');
      }

      const data = response ? response.data.data.bankAdvance : null;

      const showErrorToast = (message) => {
        showToast({ type: 'error', message });
      };

      const handlePrintPDF = () => {
        getData();
        if (data.approved) {
          setIsRemarks(false);
          setTitleConfirmation('Print')
          setTitleModalConfirmation('Bank Advance');
          handleClickConfirmation();
        }
        else {
          showToast({ type: 'error', message: 'Bank Advance Need To Be Approved!' });
        }
      };

      const handleApprove = () => {
        getData();
        if (data.approved === false) {
          setIsRemarks(false);
          setTitleConfirmation('Approve')
          setTitleModalConfirmation('Bank Advance');
          handleClickConfirmation();
        }
        else {
          showToast({ type: 'error', message: 'Bank Advance Already Been Approved!' });
        }
      }

      const handleApprovalCr = () => {
        getData();
        setTitleConfirmation('Approval Credit')
        setTitleModalConfirmation('Bank Advance');
        handleClickConfirmation();
        setIsRemarks(true);
      }

      switch (isAction) {
        case 'Save':
          if (data && data.printing > 0) {
            getData();
            showErrorToast('Data Already Printed, Cannot Be Edited!');
          } else {
            handleSave(false);
          }
          break;
        case 'Print':
          getData();
          !data.approved ? handleSave(true) : Print();
          break;
        case 'PrintPDF':
          handlePrintPDF();
          break;
        case 'Approve':
          handleApprove();
          break;
        case 'ApprovalCr':
          handleApprovalCr();
          break;
        default:
          showErrorToast('Invalid Action!');
          break;
      }
    } catch (error) {
      console.error('Error when fetching data:', error);
      showToast({ type: 'error', message: 'Error When Fetching Data, Please Contact The Administrator!' });
    }
  }

  const Print = () => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccUpdateStatusPrint(paramBankAdv, bankAdvId).then((res) => {
      let message = '';
      message = res.data.message;
      if (res.data.code !== 200) {
        showToast({ type: 'error', message: message });
      }
      else {
        let reportLink = 'Transactions/Finance/BankAdvanceReport/BankAdvanceReport/PrintBankAdvance?Id=' + bankAdvId;
        PrintPDF(setIsLoadingBackDrop, 'Bank Advance', reportLink, [], true);
      }
    });
  }

  const ApprovalCr = (approveRemarks) => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccApproveCredit('bankAdvance', bankAdvId, approveRemarks)
      .then((res) => {
        if (res.data.code === 200) {
          showToast({ type: 'success', message: 'Approval Credit Success!' });
          setIsLoadingBackDrop(false);
          handleClickConfirmation();

          setTimeout(
            function () {
              history('/Majura/BankAdvance/' + bankAdvId, { replace: true });
              window.location.reload();
            },
            1250);
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Approve Credit!, ' + res.data.error });
        }
      })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Approve Credit!, ' + error });
      });
  }

  const Approval = () => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccApprove(paramBankAdv, bankAdvId)
      .then((res) => {
        if (res.data.code === 200) {
          setIsLoadingBackDrop(false);
          setIsApprove(true);
          showToast({ type: 'success', message: 'Approve Bank Advance Success!' });

          setTimeout(
            function () {
              history('/Majura/BankAdvance/' + bankAdvId, { replace: true });
              window.location.reload();
            },
            1250);
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Approve Bank Advance, ' + res.data.message });
        }
      })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Approve Bank Advance, ' + error });
      });
  }

  const handleAdd = () => {
    setTitleConfirmation("Add");
    setTitleModalConfirmation("Bank Advance");
    handleClickConfirmation();
  }

  const Add = () => {
    window.open('/Majura/BankAdvance/Create', newWindowConfig);
  }

  const getDateExchRate = () => {
    ApiService.PostByExRateDate()
      .then((response) => {
        if (response.data.code === 200) {
          setExRateId(response.data.data.exchangeRate.id);
          setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
          setRateReal(response.data.data.exchangeRate.exRate1);
          setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);
        }
        else {
          handleBack();
        }

        GetFormatCurrency();
      })
      .catch(function (error) {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const GetFormatCurrency = () => {
    setTotalUSD(convertToNominal(0));
    setTotalIDR(convertToNominal(0));
    setTotalInIDR(convertToNominal(0));

    if (!dataLocal) {
      setIsLoadingBackDrop(false);
    }
  }

  const sumTotal = (data, rate) => {
    let sumUSD = 0
    let sumIDR = 0
    let sumInIDR = 0
    data.forEach(el => {
      sumUSD += parseFloat(el.amountUSD)
      sumIDR += parseFloat(el.amountIDR)
    });

    setTotalUSDReal(sumUSD)
    setTotalIDRReal(sumIDR)
    setTotalUSD(convertToNominal(`${sumUSD}`));
    setTotalIDR(convertToNominal(`${sumIDR}`));

    sumInIDR = (sumUSD * rateReal) + sumIDR
    setTotalInIDRReal(sumInIDR)
    setTotalInIDR(convertToNominal(`${sumInIDR}`));
  }

  const ButtonComponents = () => {
    return (
      <BankAdvCreateEditButton
        handleSave={() => checkDataCondition('Save')}
        handleBack={handleBack}
        handlePrint={() => checkDataCondition('PrintPDF')}
        handleApproval={() => checkDataCondition('Approve')}
        handleApprovalCr={() => checkDataCondition('ApprovalCr')}
        DataRegUserAccess={DataRegUserAccess}
        check={check}
        isEdit={isEdit}
        handleAdd={handleAdd}
        printing={printing}
      />
    )
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={
              bankAdvId ?
                'Bank Advance (' + reference + ')'
                :
                'Bank Advance ( New Document )'
            }
            BreadcrumbsItems={bankAdvId ? BreadcrumbsEditItems : BreadcrumbsItems}
            type={'Form'}
            ticketId={0}
            BackFunction={handleBack}
            documentStatus={documentStatus}
          />

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <BankAdvHeader
                infossNoRef={infossNoRef}
                bankRef={bankRef}
                employeeRef={employeeRef}
                infossNo={infossNo}
                setInfossNo={setInfossNo}
                bankAdvanceNo={bankAdvanceNo}
                setBankAdvanceNo={setBankAdvanceNo}
                bankId={bankId}
                setBankId={setBankId}
                isEdit={isEdit}
                reference={reference}
                setReference={setReference}
                bankAdvanceTo={bankAdvanceTo}
                setBankAdvanceTo={setBankAdvanceTo}
                bankCode={bankCode}
                setBankCode={setBankCode}
                bankIntName={bankIntName}
                setBankIntName={setBankIntName}
                bankName={bankName}
                setBankName={setBankName}
                setDataDetail={setDataDetail}
                bankAccNum={bankAccNum}
                setBankAccNum={setBankAccNum}

                printedOn={printedOn}
                paidOn={paidOn}
                rate={rate}
                exRateDate={exRateDate}
                setEmployeeId={setEmployeeId}
                setEmployeeCode={setEmployeeCode}
                employeeCode={employeeCode}
                setEmployeeName={setEmployeeName}
                employeeName={employeeName}
                printing={printing}
                isApprove={isApprove}
              />
            </CardContent>
          </Card>

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <BankAdvDetail
                detailRef={detailRef}
                dataDetail={dataDetail}
                setDataDetail={setDataDetail}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                bankId={bankId}
                employeeId={employeeId}
                BankAdvId={bankAdvId}
                sumTotal={sumTotal}
                rate={rateReal}
                totalUSD={totalUSD}
                totalIDR={totalIDR}
                titleDetail={titleDetail}
                printing={printing}
                isEdit={isEdit}
                advanceTo={bankAdvanceTo}
              />

              <BankAdvTotal
                titleDetail={titleDetail}
                totalUSD={totalUSD}
                totalIDR={totalIDR}
                totalInIDR={totalInIDR}
                totalInIDRReal={totalInIDRReal}
                printing={printing}
                isEdit={isEdit}
              />
            </CardContent>
          </Card>
        </Grid>

        {
          isModalConfirmation && (
            <ModalConfirmation
              isModal={isModalConfirmation}
              titleModal={titleModalConfirmation}
              title={titleConfirmation}
              handleClick={handleClickConfirmation}
              ConfirmationData={ConfirmationBA}

              isRemarks={isRemarks}
              Remarks={approvedCreditRemarks}
              setRemarks={setApprovedCreditRemarks}
            />
          )
        }
      </Box>

      <ButtonInfo
        ButtonComponents={ButtonComponents}
      />

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})

export default CreateEditBankAdv