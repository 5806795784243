import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType
} from "@microsoft/signalr";
import jwtDecode from "jwt-decode";
import axios from "axios";

import { urlAuth } from "../pages/Account/Login/Login";

const baseurl = process.env.REACT_APP_API_URL_MASTER;
const baseurlFinAcc = process.env.REACT_APP_API_URL_FINACC;
export const baseurlReport = process.env.REACT_APP_API_URL_REPORT;
const baseurlIdentity = process.env.REACT_APP_API_URL_IDENTITY;
const baseurlOperation = process.env.REACT_APP_API_URL_OPERATION;
const baseurlWarehouse = process.env.REACT_APP_API_URL_WAREHOUSE;
export const baseUrlTracking = process.env.REACT_APP_URL_TRACKING;

export const userId = JSON.parse(localStorage.getItem("userId"));
export const roleId = JSON.parse(localStorage.getItem("roleId"));
export const groupId = JSON.parse(localStorage.getItem("groupId"));
export const branchId = JSON.parse(localStorage.getItem("branchId"));
export const userCode = JSON.parse(localStorage.getItem("userCode"));
export const username = JSON.parse(localStorage.getItem("username"));
export const roleName = JSON.parse(localStorage.getItem("roleName"));
export const idleTimer = JSON.parse(localStorage.getItem("idleTimer"));
export const countryId = JSON.parse(localStorage.getItem("countryId"));
export const companyId = JSON.parse(localStorage.getItem("companyId"));
export const branchName = JSON.parse(localStorage.getItem("branchName"));
export const countryName = JSON.parse(localStorage.getItem("countryName"));
export const companyName = JSON.parse(localStorage.getItem("companyName"));
export const formatCurrency = JSON.parse(
  localStorage.getItem("formatCurrency")
);
export const parentCompanyId = JSON.parse(
  localStorage.getItem("parentCompanyId")
);
export const isInfossNo =
  JSON.parse(localStorage.getItem("isInfossNo")) === "false" ? false : true;

export const isInfossNoList =
  JSON.parse(localStorage.getItem("isInfossNoList")) === "false" ? false : true;

export const isListAsc =
  JSON.parse(localStorage.getItem("isListAsc")) === "false" ? false : true;

function shouldKeep(key, prefixes) {
  return prefixes.some((prefix) => key.startsWith(prefix));
}

function removeAllExcept(prefixesToKeep) {
  const keys = Object.keys(localStorage);

  keys.forEach((key) => {
    if (!shouldKeep(key, prefixesToKeep)) {
      localStorage.removeItem(key);
    }
  });
}

const payload = {
  params: [],
  filters: [],
  ordersBy: [],
  userId: 0,
  userCode: userCode,
  branchId: branchId,
  countryId: countryId,
  companyId: companyId
};

const customPayload = {
  dc: "D",
  dncn: "",
  notes: "",
  roleId: 0,
  groupId: 0,
  source: "",
  jobCode: "",
  filters: [],
  currency: "",
  isGeneral: "",
  userId: userId,
  cancelation: "",
  rowStatus: "ACT",
  contactTypeId: 0,
  pageCode: "PAGE",
  customerType: "",
  constantCode: "",
  dataFrom: "FEND",
  userCode: userCode,
  branchId: branchId,
  countryId: countryId,
  companyId: companyId
};

const fePayload = {
  fePayload: {
    id: 0,
    refId: 0,
    apiUrl: "",
    apiName: "",
    payload: "",
    userId: userId,
    rowStatus: "ACT",
    userCode: userCode,
    branchId: branchId,
    countryId: countryId,
    companyId: companyId
  }
};

export let signalRConnection = new HubConnectionBuilder()
  .withUrl(baseurlOperation + "todo/toDoNotif", {
    accessTokenFactory: () => JSON.parse(localStorage.getItem("token")),
    transport: HttpTransportType.LongPolling
  })
  .configureLogging(LogLevel.Information)
  .build();

export let signalRConnectionCB = new HubConnectionBuilder()
  .withUrl(baseurlFinAcc + "closingBalance/closingBalanceNotif", {
    accessTokenFactory: () => JSON.parse(localStorage.getItem("token")),
    transport: HttpTransportType.LongPolling
  })
  .configureLogging(LogLevel.Information)
  .build();

export let signalRConnectionDashboard = new HubConnectionBuilder()
  .withUrl(baseurlReport + "recapitulation/financeDashboard/isDashboardNotif", {
    accessTokenFactory: () => JSON.parse(localStorage.getItem("token")),
    transport: HttpTransportType.LongPolling
  })
  .configureLogging(LogLevel.Information)
  .build();

export let signalRConnectionBS = new HubConnectionBuilder()
  .withUrl(baseurlReport + "recapitulation/financeDashboard/bsDashboardNotif", {
    accessTokenFactory: () => JSON.parse(localStorage.getItem("token")),
    transport: HttpTransportType.LongPolling
  })
  .configureLogging(LogLevel.Information)
  .build();

const headers = {
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
  }
};

const reportHeaders = {
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
  },
  responseType: "blob"
};

const axiosJwt = axios.create();
axiosJwt.interceptors.request.use(
  async (config) => {
    const currentDate = new Date();
    let expiredToken = parseInt(
      JSON.parse(localStorage.getItem("expiredToken"))
    );

    if (expiredToken * 1000 < currentDate.getTime()) {
      const refreshPayload = {
        refreshToken: JSON.parse(localStorage.getItem("refreshToken"))
      };

      const refreshHeaders = {
        headers: {
          "Access-Control-Allow-Origin": true,
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        },
        withCredentials: true
      };

      const response = await axios.post(
        urlAuth + "RefreshToken",
        refreshPayload,
        refreshHeaders
      );

      if (response.data.code === 200) {
        let token = response.data.data.token;
        let refToken = response.data.data.refreshToken;
        let decode = jwtDecode(token);

        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("refreshToken", JSON.stringify(refToken));
        localStorage.setItem("expiredToken", JSON.stringify(decode.exp));
      } else {
        removeAllExcept([
          username + "-isLogOut",
          username + "-lastUrl",
          username + "-Data"
        ]);

        signalRConnection.stop();
        signalRConnectionCB.stop();
        signalRConnectionBS.stop();
        signalRConnectionDashboard.stop();

        window.location.reload();
      }
    }

    config.headers.Authorization = `Bearer ${JSON.parse(
      localStorage.getItem("token")
    )}`;

    return config;
  },
  (error) => {
    console.log("intercept error", error);
    return Promise.reject(error);
  }
);

class ApiService {
  refreshToken = async () => {
    try {
      const currentDate = new Date();
      let token = JSON.parse(localStorage.getItem("token"));
      let expiredToken = parseInt(
        JSON.parse(localStorage.getItem("expiredToken"))
      );

      if (expiredToken * 1000 < currentDate.getTime()) {
        const refreshPayload = {
          refreshToken: JSON.parse(localStorage.getItem("refreshToken"))
        };

        const refreshHeaders = {
          headers: {
            "Access-Control-Allow-Origin": true,
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
          },
          withCredentials: true
        };

        const response = await axios.post(
          urlAuth + "RefreshToken",
          refreshPayload,
          refreshHeaders
        );

        if (response.data.code === 200) {
          token = response.data.data.token;
          let refToken = response.data.data.refreshToken;
          const decoded = jwtDecode(response.data.data.token);

          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem("refreshToken", JSON.stringify(refToken));
          localStorage.setItem("expiredToken", JSON.stringify(decoded.exp));
        } else {
          removeAllExcept([
            username + "-isLogOut",
            username + "-lastUrl",
            username + "-Data"
          ]);

          signalRConnection.stop();
          signalRConnectionCB.stop();
          signalRConnectionBS.stop();
          signalRConnectionDashboard.stop();

          window.location.reload();
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      }
    }
  };

  //Master Api
  createData(param, Data) {
    return axiosJwt.post(baseurl + param + "/Create", Data, headers);
  }

  updateData(param, Data) {
    return axiosJwt.put(baseurl + param + "/Update", Data, headers);
  }

  deleteData(param, Id) {
    return axiosJwt.put(baseurl + param + "/Delete?id=" + Id, payload, headers);
  }

  unDeleteData(param, Id) {
    return axiosJwt.put(
      baseurl + param + "/UnDelete?id=" + Id,
      payload,
      headers
    );
  }

  PostByPage(param, pageNumber, pageSize, filter) {
    payload.filters = filter;
    // payload.params = [{ 'attr': 'rowStatus', 'value': 'ACT' }];
    payload.params = [];
    payload.ordersBy = [];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  CustomPostByPage(
    param,
    pageNumber,
    pageSize,
    contactTypeId = 0,
    source,
    filter,
    dc = "D",
    constCode = "",
    jobCode = "",
    dataFrom = "FEND",
    dncn = ""
  ) {
    customPayload.dc = dc;
    customPayload.dncn = dncn;
    customPayload.source = source;
    customPayload.filters = filter;
    customPayload.jobCode = jobCode;
    customPayload.dataFrom = dataFrom;
    customPayload.constantCode = constCode;
    customPayload.contactTypeId = contactTypeId;
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      customPayload,
      headers
    );
  }

  DynamicPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    if (pageCode === "PAGE") {
      parameter = [...parameter, { attr: "rowStatus", value: "ACT" }];
    }
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  ListMasterDynamicPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  PostById(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurl + param + "/postbyid?id=" + id,
      payload,
      headers
    );
  }

  PostByTicketId(param, id) {
    return axiosJwt.post(baseurlOperation + param + "/postbyTicketid?id=" + id);
  }

  CustomMasterUpdate(param, data) {
    return axiosJwt.put(baseurl + param, data, headers);
  }

  //Operation Api
  ListOperationDynamicPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  CreateOperation(param, data) {
    return axiosJwt.post(baseurlOperation + param + "/Create", data, headers);
  }

  EditOperation(param, data) {
    return axiosJwt.put(baseurlOperation + param + "/Update", data, headers);
  }

  OperationPostByPage(param, pageNumber, pageSize, filter) {
    payload.filters = filter;
    payload.params = [{ attr: "rowStatus", value: "ACT" }];
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  OperationPostById(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation + param + "/postbyid?id=" + id,
      payload
    );
  }

  OperationDelete(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlOperation + param + "/Delete?id=" + id,
      payload,
      headers
    );
  }

  OperationDelivery(param, data) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlOperation + param + "/UpdateStatusDelivered",
      data,
      headers
    );
  }

  OperationCloseJob(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlOperation + param + "/CloseJob?id=" + id,
      payload,
      headers
    );
  }

  OperationUpdateStatusPrint(param, id, data = {}) {
    return axiosJwt.put(
      baseurlOperation + param + "/UpdateStatusPrint?Id=" + id,
      data,
      headers
    );
  }

  OperationUpdateStatusRePrint(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlOperation + param + "/UpdateStatusRePrint?Id=" + id,
      payload,
      headers
    );
  }

  OperationUnDelete(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation + param + "/UnDelete?Id=" + id,
      payload,
      headers
    );
  }

  ApproveOperation(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlOperation + param + "/Approve?id=" + id,
      payload,
      headers
    );
  }

  ApprovalCreditOperation(param, id, data) {
    return axiosJwt.put(
      baseurlOperation + param + "/ApproveCreditLimit?Id=" + id,
      data,
      headers
    );
  }

  UnApproveOperation(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlOperation + param + "/UnApprove?id=" + id,
      payload,
      headers
    );
  }

  OperationPrintDO(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation + param + "/PrintDoRead?Id=" + id,
      payload,
      headers
    );
  }

  OperationReadDelivery(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation + param + "/DeliveredRead?Id=" + id,
      payload,
      headers
    );
  }

  OperationDynamicPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    if (pageCode === "PAGE") {
      parameter = [...parameter, { attr: "rowStatus", value: "ACT" }];
    }
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  OperationPostByDetail(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    isOutstanding = false
  ) {
    let parameter = params;
    parameter = [...parameter];
    payload.filters = filter;
    payload.params = parameter;
    let outstanding = false;
    if (isOutstanding !== undefined) {
      outstanding = isOutstanding;
    }
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostByDetail?pageCode=" +
      pageCode +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&isOutstanding=" +
      outstanding,
      payload,
      headers
    );
  }

  //Api Service FinAcc
  ListFinAccPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  FinAccPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    if (pageCode === "PAGE") {
      parameter = [...parameter, { attr: "rowStatus", value: "ACT" }];
    }
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  FinAccCustomPostByPage(
    type,
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "/" +
      type +
      "?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  FinAccPostByDetail(
    param,
    pageNumber,
    pageSize,
    params,
    filter,
    pageCode = "PAGE"
  ) {
    let parameter = params;
    parameter = [...parameter];
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = [];
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "/PostByDetail?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  FinAccPostById(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlFinAcc + param + "/postbyid?id=" + id,
      payload,
      headers
    );
  }

  CreateFinAcc(param, data) {
    return axiosJwt.post(baseurlFinAcc + param + "/Create", data, headers);
  }

  EditFinAcc(param, data) {
    return axiosJwt.put(baseurlFinAcc + param + "/Update", data, headers);
  }

  FinAccDelete(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlFinAcc + param + "/Delete?id=" + id,
      payload,
      headers
    );
  }

  FinAccPostBySearchJobType(param, pageNumber, pageSize, id, filter) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "/PostBySearch?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&jobTypeId=" +
      id,
      payload,
      headers
    );
  }

  FinAccUpdateStatusPrint(param, id, data = {}) {
    let tempPayload = data;

    if (Object.keys(tempPayload).length === 0) {
      tempPayload = payload;
      tempPayload.filters = [];
      tempPayload.params = [];
    }
    return axiosJwt.put(
      baseurlFinAcc + param + "/UpdateStatusPrint?Id=" + id,
      tempPayload,
      headers
    );
  }

  FinAccApprove(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlFinAcc + param + "/Approve?Id=" + id,
      payload,
      headers
    );
  }

  FinAccUpdateStatusApprovedAcc(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlFinAcc + param + "/UpdateStatusApprovedAcc?Id=" + id,
      payload,
      headers
    );
  }

  FinAccUpdateStatusUnApprovedAcc(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlFinAcc + param + "/UpdateStatusUnApprovedAcc?Id=" + id,
      payload,
      headers
    );
  }

  FinAccUpdateStatusRePrint(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlFinAcc + param + "/UpdateStatusRePrint?Id=" + id,
      payload,
      headers
    );
  }

  FinAccApproveCredit(param, id, remarks) {
    let data = {
      [param]: {
        id: id,
        approvalCreditRemarks: remarks
      }
    };

    return axiosJwt.put(
      baseurlFinAcc + param + "/" + param + "/ApproveCredit",
      data,
      headers
    );
  }

  //Api Service Report
  ReportPrint(param, variable, params = [], type = "") {
    payload.params = params;
    payload.filters = [];
    if (type !== "") {
      if (type === "Excel" || type === "xls") {
        let headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          Accept: "application/vnd.ms-excel"
        };

        reportHeaders.headers = headers;
      } else {
        let headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        };

        reportHeaders.headers = headers;
      }
    } else {
      let headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
      };

      reportHeaders.headers = headers;
    }
    return axiosJwt.post(
      baseurlReport + param + variable,
      payload,
      reportHeaders
    );
  }

  GetReportPrint(param, variable, type = "") {
    if (type !== "") {
      if (type === "Excel" || type === "xls") {
        let headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
          Accept: "application/vnd.ms-excel"
        };

        reportHeaders.headers = headers;
      } else {
        let headers = {
          "Content-type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        };

        reportHeaders.headers = headers;
      }
    } else {
      let headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
      };

      reportHeaders.headers = headers;
    }
    return axiosJwt.get(baseurlReport + param + variable, reportHeaders);
  }

  ReportPostByPage(param, pageCode, params) {
    let parameter = params;
    parameter = [...parameter, { attr: "rowStatus", value: "ACT" }];
    payload.params = parameter;
    payload.filters = [];
    return axiosJwt.post(
      baseurlReport + param + "?pageCode=" + pageCode,
      payload,
      headers
    );
  }

  //Api Identity
  IdentityPostByPage(param, pageCode, pageNumber, pageSize, params, filter) {
    let parameter = params;
    if (pageCode === "PAGE") {
      parameter = [...parameter, { attr: "rowStatus", value: "ACT" }];
    }
    payload.filters = filter;
    payload.params = parameter;
    return axiosJwt.post(
      baseurlIdentity +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  IdentityPostById(param, id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlIdentity + param + "/postbyid?id=" + id,
      payload,
      headers
    );
  }

  IdentityCreateData(param, Data) {
    return axiosJwt.post(baseurlIdentity + param + "/Create", Data, headers);
  }

  IdentityUpdateData(param, Data) {
    return axiosJwt.put(baseurlIdentity + param + "/Update", Data, headers);
  }

  IdentityRefreshPrintToken() {
    return axiosJwt.post(
      baseurlIdentity + "authentication/authentication/RefreshTempToken",
      headers
    );
  }

  IdentityDeleteData(param, Id) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlIdentity + param + "/Delete?id=" + Id,
      payload,
      headers
    );
  }

  //Api Warehouse
  createDataWarehouse(param, Data) {
    return axiosJwt.post(baseurlWarehouse + param + "/Create", Data, headers);
  }

  updateDataWarehouse(param, Data) {
    return axiosJwt.put(baseurlWarehouse + param + "/Update", Data, headers);
  }

  PostByPageWarehouse(param, pageNumber, pageSize, filter = [], params = []) {
    payload.filters = filter;
    payload.params = params;
    return axiosJwt.post(
      baseurlWarehouse +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  DynamicPostByPageWarehouse(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    payload.filters = filter;
    payload.params = params;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlWarehouse +
      param +
      "/PostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  PostByIdWarehouse(param, id) {
    return axiosJwt.post(
      baseurlWarehouse + param + "/postbyid?id=" + id,
      payload,
      headers
    );
  }

  DeleteDataWarehouse(param, Id) {
    return axiosJwt.put(
      baseurlWarehouse + param + "/Delete?id=" + Id,
      payload,
      headers
    );
  }

  PrintQRWarehouse(param, inBoundNo, Id) {
    return axiosJwt.get(
      baseurlWarehouse +
      param +
      "/PrintQR?inboundno=" +
      inBoundNo +
      "&Id=" +
      Id,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        },
        responseType: "blob"
      }
    );
  }

  ApproveWarehouse(param, id) {
    return axiosJwt.put(
      baseurlWarehouse + param + "/Approve?Id=" + id,
      headers
    );
  }

  PutAwayWarehouse(param, id) {
    return axiosJwt.post(
      baseurlWarehouse + param + "/PutAway?Id=" + id,
      headers
    );
  }

  PickingWarehouse(param, id) {
    return axiosJwt.post(
      baseurlWarehouse + param + "/PickingList?Id=" + id,
      headers
    );
  }

  ReportWarehouse(param, reportType, filter, params = []) {
    payload.filters = filter;
    payload.params = params;
    return axiosJwt.post(
      baseurlWarehouse + param + "/report?reportType=" + reportType,
      payload,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        },
        responseType: "blob"
      }
    );
  }

  ReportWarehouseAdjustment(param, reportType, filter, params = []) {
    payload.filters = filter;
    payload.params = params;
    return axiosJwt.post(
      baseurlWarehouse + param + "/Print?reportType=" + reportType,
      payload,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`
        },
        responseType: "blob"
      }
    );
  }

  //Help API
  HelpCreateData(payload, param, refId) {
    let tempPayload = JSON.stringify(payload);
    fePayload.fePayload.apiName = param;
    fePayload.fePayload.refId = refId;
    fePayload.fePayload.payload = tempPayload;
    return axiosJwt.post(
      baseurlOperation + "Help/fePayload/fePayload/Create",
      fePayload,
      headers
    );
  }

  //Custom API
  ChangePassword(param, data) {
    return axiosJwt.put(
      baseurlIdentity + param + "/ChangePassword",
      data,
      headers
    );
  }

  IdentityCustomPostByPage(param, pageNumber, pageSize, params, filter, orderBy) {
    payload.filters = filter;
    payload.ordersBy = orderBy;
    payload.params = params;
    return axiosJwt.post(baseurlIdentity + param + '&PageNumber=' + pageNumber + '&PageSize=' + pageSize, payload, headers);
  }
  
  IdentityCustomPut(param, data) {
    return axiosJwt.put(baseurlIdentity + param, data, headers);
  }

  ContactPostByPage(
    param,
    contactTypeId,
    pageNumber,
    pageSize,
    filter,
    orderBy
  ) {
    payload.filters = filter;
    payload.ordersBy = orderBy;
    payload.params = [
      {
        attr: "contactTypeId",
        value: "" + contactTypeId
      },
      { attr: "rowStatus", value: "ACT" }
    ];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageCode=SEARCH&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  CustomFinancePostByPage(param, payload, pageNumber, pageSize) {
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "pageCode=PAGE&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }
  
  CustomFinAccPostByPage(param, pageNumber, pageSize, params, filter, orderBy) {
    payload.filters = filter;
    payload.params = params;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlFinAcc +
      param +
      "?pageCode=PAGE&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  RegContactPostById(param, contactTypeId, contactId) {
    payload.filters = [];
    payload.ordersBy = [];
    payload.params = [
      {
        attr: "contactTypeId",
        value: "" + contactTypeId
      },
      {
        attr: "contactId",
        value: "" + contactId
      },
      { attr: "rowStatus", value: "ACT" }
    ];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageCode=SEARCH&pageNumber=" +
      1 +
      "&pageSize=" +
      1,
      payload,
      headers
    );
  }

  BookingPContactPostByPage(
    param,
    contactTypeId1,
    contactTypeId2,
    pageNumber,
    pageSize,
    filter,
    orderBy
  ) {
    payload.filters = filter;
    payload.ordersBy = orderBy;
    payload.params = [
      {
        attr: "contactTypeId",
        value: "" + contactTypeId1 + ", " + contactTypeId2
      }
    ];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageCode=SEARCH&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  InvoiceDeletePostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    payload.filters = filter;
    payload.params = params;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostByPageInvReqDel?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  InvoiceDeletePostById(param, data, isNotFinance = true) {
    if (isNotFinance) {
      return axiosJwt.post(
        baseurlOperation + param + "/PostByIdInvReqDel",
        data,
        headers
      );
    } else {
      return axiosJwt.post(
        baseurlFinAcc + param + "/PostByIdGeneralInvReqDel",
        data,
        headers
      );
    }
  }

  ApproveInvoiceDelete(param, data, isNotFinance = true) {
    if (isNotFinance) {
      return axiosJwt.put(
        baseurlOperation + param + "/ApproveInvReqDel",
        data,
        headers
      );
    } else {
      return axiosJwt.put(
        baseurlFinAcc + param + "/ApproveGeneralInvReqDel",
        data,
        headers
      );
    }
  }

  ApproveGeneralOperation(param, data) {
    return axiosJwt.put(
      baseurlOperation + param + "/ApproveGeneral",
      data,
      headers
    );
  }

  OutstandingInvoicePostBySearch(
    param,
    idCustomer,
    idBillTo = 0,
    bcId = 0,
    filter
  ) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostBySearchOutStandingInv?customerId=" +
      idCustomer +
      "&billId=" +
      idBillTo +
      "&bookingConfirmationId=" +
      bcId,
      payload,
      headers
    );
  }

  RegClosingPeriodPostById(param, date) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurl + param + "/PostById?date=" + date,
      payload,
      headers
    );
  }

  PostByExRateDate(tgl) {
    payload.filters = [];
    payload.params = [];

    let date = "";
    if (tgl) {
      date = tgl;
    } else {
      date = new Date().toISOString().split("T")[0];
    }

    return axiosJwt.post(
      baseurl + "exchangeRate/exchangeRate/PostByExRateDate?exRateDate=" + date,
      payload,
      headers
    );
  }

  PostByExRateDateKMK(tgl) {
    payload.filters = [];
    payload.params = [];

    let date = "";
    if (tgl) {
      date = tgl;
    } else {
      date = new Date().toISOString().split("T")[0];
    }

    return axiosJwt.post(
      baseurl + "kursKMK/kursKMK/PostByExRateDate?exRateDate=" + date,
      payload,
      headers
    );
  }

  MarketingPostBySearch(param, pageNumber, pageSize, dept, filter) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostBySearch?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&department=" +
      dept,
      payload,
      headers
    );
  }

  CityPostBySearch(param, pageNumber, pageSize, countryId, filter) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostBySearch?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&countryId=" +
      countryId,
      payload,
      headers
    );
  }

  CurrencyPostBySearch(param, pageNumber, pageSize, filter) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurl +
      param +
      "/PostBySearchCurrency?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  COAPostBySearch(
    param,
    pageNumber,
    pageSize,
    params,
    filter,
    accLevel = "6",
    mathSign = ">=",
    orderBy
  ) {
    payload.filters = filter;
    let parameter = params;
    parameter = [
      ...parameter,
      {
        attr: "isWithSubledger",
        value: "1"
      },
      {
        attr: "accountLevel",
        value: "" + accLevel,
        mathSign: mathSign
      }
    ];
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurl +
      param +
      "/PostByPage?pageCode=SEARCH&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  ChangeProfile(data) {
    return axiosJwt.post(urlAuth + "ChangeProfile", data);
  }

  ShipmentPostBySearch(param, pageNumber, pageSize, isIncome) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostBySearchShipmentNo?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&isIncome=" +
      isIncome,
      payload,
      headers
    );
  }

  ShipmentRoutingPostBySearch(
    param,
    pageCode,
    pageNumber,
    pageSize,
    filter,
    params,
    orderBy
  ) {
    let parameter = params;
    parameter = [...parameter];
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostBySearchRouting?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  CustomerEplPostBySearch(
    param,
    pageNumber,
    pageSize,
    eplId,
    customerId,
    customerTypeId,
    isIncome
  ) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostBySearchCustomerEPL?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&eplId=" +
      eplId +
      "&customerId=" +
      customerId +
      "&customerTypeId=" +
      customerTypeId +
      "&isIncome=" +
      isIncome,
      payload,
      headers
    );
  }

  PrintDO(param, data) {
    return axiosJwt.post(baseurlOperation + param + "/PrintDo", data, headers);
  }

  ShipmentSeaContainerPostBySearch(
    param,
    pageNumber,
    pageSize,
    params,
    filter
  ) {
    let parameter = params;
    parameter = [...parameter];
    payload.filters = filter;
    payload.params = parameter;
    return axiosJwt.post(
      baseurlOperation +
      param +
      "/PostBySeaContainer?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  MasterCustomPut(param, data) {
    return axiosJwt.put(baseurl + param, data, headers);
  }

  OperationCustomPost(param, data) {
    return axiosJwt.post(baseurlOperation + param, data, headers);
  }

  OperationCustomPostByPage(
    param,
    pageCode,
    pageNumber,
    pageSize,
    params,
    filter,
    orderBy
  ) {
    let parameter = params;
    if (pageCode === "PAGE") {
      parameter = [...parameter, { attr: "rowStatus", value: "ACT" }];
    }
    payload.filters = filter;
    payload.params = parameter;
    payload.ordersBy = orderBy;

    return axiosJwt.post(
      baseurlOperation +
      param +
      "pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize +
      "&pageCode=" +
      pageCode,
      payload,
      headers
    );
  }

  OperationCustomPut(param, data) {
    return axiosJwt.put(baseurlOperation + param, data, headers);
  }

  OperationCustomReadId(param, id) {
    return axiosJwt.post(baseurlOperation + param, headers);
  }

  FinAccCustomPost(param, data) {
    return axiosJwt.post(baseurlFinAcc + param, data, headers);
  }

  FinAccBackDatePrint(param, id, date) {
    payload.filters = [];
    payload.params = [];
    return axiosJwt.put(
      baseurlFinAcc +
      param +
      "/UpdateStatusPrint?Id=" +
      id +
      "&printedOn=" +
      date,
      payload,
      headers
    );
  }

  CategoryPostByPage(param) {
    return axiosJwt.post(
      baseurlWarehouse + param + "/CategoryPostByPage",
      headers
    );
  }

  AvailableQuantity(param, warehouseId, productId, unitId) {
    return axiosJwt.post(
      baseurlWarehouse +
      param +
      "/AvailableQuantity?WarehousesId=" +
      warehouseId +
      "&ProductId=" +
      productId +
      "&UnitId=" +
      unitId,
      headers
    );
  }

  PostByPageWarehouseLocationType(param, pageNumber, pageSize, filter) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurl +
      param +
      "/WarehouseLocationTypePostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  PostByPagePickingStrategy(param, pageNumber, pageSize, filter) {
    payload.filters = filter;
    payload.params = [];
    return axiosJwt.post(
      baseurl +
      param +
      "/PickingStrategyPostByPage?pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
      payload,
      headers
    );
  }

  PostByIdWarehouseLocationType(param, id) {
    return axiosJwt.post(
      baseurl + param + "/WarehouseLocationTypePostById?id=" + id,
      payload,
      headers
    );
  }

  PostByIdPickingStrategy(param, id) {
    return axiosJwt.post(
      baseurl + param + "/PickingStrategyPostById?id=" + id,
      payload,
      headers
    );
  }

  PostByPageBackDatePeriod(param) {
    return axiosJwt.get(baseurl + param, headers);
  }

  GetDataFinance(param) {
    return axiosJwt.get(baseurlFinAcc + param, headers);
  }

  logOut() {
    const currentDate = new Date();
    let token = JSON.parse(localStorage.getItem("token"));
    let jobTypeId = JSON.parse(localStorage.getItem("jobTypeId"));
    let refToken = JSON.parse(localStorage.getItem("refreshToken"));
    let expiredToken = parseInt(
      JSON.parse(localStorage.getItem("expiredToken"))
    );

    removeAllExcept([
      username + "-isLogOut",
      username + "-lastUrl",
      username + "-Data"
    ]);

    signalRConnection.stop();
    signalRConnectionCB.stop();
    signalRConnectionBS.stop();
    signalRConnectionDashboard.stop();

    if (expiredToken * 1000 < currentDate.getTime()) {
      const refreshPayload = { refreshToken: refToken };

      const refreshHeaders = {
        headers: {
          "Access-Control-Allow-Origin": true,
          Authorization: `Bearer ${token}`
        },
        withCredentials: true
      };

      axios
        .post(urlAuth + "RefreshToken", refreshPayload, refreshHeaders)
        .then((response) => {
          if (response.data.code === 200) {
            token = response.data.data.token;
            refToken = response.data.data.refreshToken;

            let logOutPayload = { refreshToken: refToken };

            let logOutHeaders = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };

            try {
              return axios.post(
                urlAuth + "LogOut?JobTypeId=" + jobTypeId,
                logOutPayload,
                logOutHeaders
              );
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response);
          }
        });
    } else {
      let logOutPayload = { refreshToken: refToken };

      let logOutHeaders = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      try {
        return axios.post(
          urlAuth + "LogOut?JobTypeId=" + jobTypeId,
          logOutPayload,
          logOutHeaders
        );
      } catch (error) {
        console.log(error);
      }
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService();
