import { Grid, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { CustomRadioGroup, Icon, Paginate, PopUp, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks';
import ApiService from '../../../../services/ApiService';
import { handleDataTable } from '../../../../utils/reusableFunction';

const SelectDataBooking = memo((props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [isEPL, setIsEPL] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const createSelection = [
        { 'id': 1, 'name': 'Yes', 'code': true },
        { 'id': 2, 'name': 'No', 'code': false }
    ]

    useEffect(() => {
        getDataBooking(1, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const emptyState = useCallback(() => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    }, []);

    const getDataBooking = useCallback((pageNumber, pageSize, filters, orderBy) => {
        setIsLoading(true);
        let params = [];
        params = [
            ...params,
            { 'attr': 'jobTypeId', 'value': '' + props.jobType },
            { 'attr': 'approved', 'value': 'true' },
            { 'attr': 'shipmentId', 'value': '0' },
            { 'attr': 'rowStatus', 'value': 'ACT' },
        ];
        ApiService.OperationDynamicPostByPage('bookingConfirmation/bookingConfirmation', 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});

                    let temp = response.data.data.bookingConfirmation;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp;
                    })

                    setDataMap(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setColumnData(response.data.data.columns.headerColumns);
                    setTotalRows(response.data.totalRowCount);

                    setIsLoading(false);
                }

                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
            })
    }, [props]);

    const Select = useCallback(() => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            window.open('/Majura/ShipmentOrder/Create/' + props.jobType + '/' + SelectedData.id + '/' + isEPL, newWindowConfig);
        }

        emptyState();

        props.setIsModal(false);
    }, [props, SelectedData, isEPL, emptyState]);

    const Cancel = useCallback(() => {
        emptyState();
        props.handleClick();
    }, [props, emptyState]);

    const handleReset = useCallback(() => {
        emptyState();
        getDataBooking(1, 50);
    }, [emptyState, getDataBooking]);

    const CellDoubleClick = useCallback((row) => {
        Select();
    }, [Select]);

    const onChangePaging = useCallback((value) => {
        setNumPage(value);
        getDataBooking(value, rowsCount, filter, sortArr);
    }, [rowsCount, filter, sortArr, getDataBooking]);

    const onChangeLimit = useCallback((value) => {
        setNumPage(1);
        setRowsCount(value);
        getDataBooking(1, value, filter, sortArr);
    }, [filter, sortArr, getDataBooking]);

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Create Shipment Order'}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div style={{ marginTop: '4px' }}>
                        <CustomRadioGroup
                            isTitle={true}
                            title={'Create Auto EPL from Booking Confirmation'}
                            isChecked={isEPL}
                            setIsChecked={setIsEPL}
                            dataGroup={createSelection}
                        />
                    </div>

                    <div>
                        <Typography
                            sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '19.6px',
                                marginBottom: '-8px',
                                marginTop: '16px'
                            }}
                        >
                            Booking Confirmation
                        </Typography>

                        <div>
                            {
                                ButtonComponent.RefreshButton(
                                    sortArr,
                                    rowsCount,
                                    columnData,
                                    setGoTo,
                                    handleReset,
                                    setFilterBy,
                                    filterBy,
                                    setFilter,
                                    setNumPage,
                                    setRowsCount,
                                    getDataBooking,
                                    stateX,
                                    setStateX
                                )
                            }
                        </div>

                        <div
                            className="rounded-10 table-responsive"
                            style={{
                                maxHeight: '375px',
                                marginTop: '16px',
                                padding: '2px 16px',
                                borderRadius: '12px',
                                background: '#F2F2F2'
                            }}
                        >
                            {
                                isLoading ?
                                    (
                                        <LoadingSpinner />
                                    )
                                    :
                                    (
                                        <>
                                            <div className="mt-1">
                                                <TableX
                                                    uniqueKey={'id'}
                                                    goTo={goTo}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={getDataBooking}
                                                    dataTable={dataTable}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    CellDoubleClick={CellDoubleClick}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                />
                                            </div>
                                        </>
                                    )
                            }
                        </div>

                        <div style={{ marginTop: '16px' }}>
                            <Paginate
                                page={numPage}
                                limit={rowsCount}
                                totalData={totalRows}
                                onChange={onChangePaging}
                                onChangeLimit={onChangeLimit}
                            />
                        </div>
                    </div>
                </Grid>

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div >
        </PopUp >
    )
})

export default memo(SelectDataBooking);