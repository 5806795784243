import {
    TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody,
    Grid, TableFooter, Typography,
    Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ModalGeneralInvoice from '../Modals/ModalGeneralInvoice';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const GeneralInvoiceDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.footer}`]: {
            background: '#E4E8EC',
            color: '#083A50 !important',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '10px 16px !important',
            left: 0,
            bottom: 0,
            zIndex: 2,
            position: 'sticky',
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [AccountId, setAccountId] = useState(0);
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [Description, setDescription] = useState('');
    const [AmountIDR, setAmountIDR] = useState(0);
    const [AmountUSD, setAmountUSD] = useState(0);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddPaymentFor = () => {
        if (props.checkInput(props.GeneralTo) === true) {
            setDetailEdit(false);
            setTitleModal('General Invoice Detail');
            if (props.GeneralInvoiceList.length > 0) {
                let data = props.GeneralInvoiceList.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            handleClick();
        }
        else {
            showToast({ type: 'error', message: 'Please Input Mandatory Data!' });
        }
    };

    const ShowEditPaymentFor = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setDetailEdit(true);
                setTitleModal('General Invoice Detail');

                setAccountId(selectedDetail.accountId);
                setAccountCode(selectedDetail.accountCode);
                setAccountName(selectedDetail.accountName);
                setDescription(selectedDetail.description);
                setAmountUSD(selectedDetail.amountUSD);
                setAmountIDR(selectedDetail.amountIDR);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    };

    const DeletePaymentFor = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.GeneralInvoiceList.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setGeneralInvoiceList(result);

            props.calculateTotal(result, props.RateCount);

            setSelectedDetail({});
        }
    }

    const handleDoubleClick = () => {
        ShowEditPaymentFor();
    }

    return (
        <div ref={props.detailRef}>
            <form>
                <Box>
                    <Box>
                        <Grid container item spacing={2} direction="row">
                            <Grid item xs={10}>
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    General Invoice Detail
                                </Typography>
                            </Grid>
                        </Grid>

                        <TableContainer sx={{ maxHeight: 235, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Acc. Code</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount IDR</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount VAT USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount VAT IDR</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.GeneralInvoiceList.length > 0 ?
                                            props.GeneralInvoiceList.map((el) => {
                                                return (
                                                    <TableRow
                                                        key={el.sequence}
                                                        onClick={() => setSelectedDetail(el)}
                                                        onDoubleClick={() => handleDoubleClick()}
                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                        hover={true}
                                                    >
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                            <Tooltip title={el.accountName}>
                                                                {el.accountCode}
                                                            </Tooltip>
                                                        </StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountUSD)}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountIDR)}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountVatUSD)}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountVatIDR)}</StyledTableBodyCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>

                                <TableFooter sx={{ left: 0, bottom: 0, zIndex: 2, position: 'sticky' }} hidden={props.GeneralInvoiceList.length === 0 ? true : false}>
                                    <TableRow>
                                        <StyledTableFooterCell
                                            colSpan={2}
                                            sx={{
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'center',
                                                background: '#f5f5f5',
                                            }}
                                        >
                                            Total Amount
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalUSD)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalIDR)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalVATUSD)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalVATIDR)}
                                        </StyledTableFooterCell>
                                    </TableRow>

                                    <TableRow>
                                        <StyledTableFooterCell
                                            colSpan={2}
                                            sx={{
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'center',
                                                background: '#f5f5f5',
                                            }}
                                        >
                                            Total Amount In IDR
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            colSpan={2}
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalInIDR)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            colSpan={2}
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalVATInIDR)}
                                        </StyledTableFooterCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>

                        <div className='mt-4'>
                            {
                                CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddPaymentFor, ShowEditPaymentFor, DeletePaymentFor, props.printing > 0 ? true : false)
                            }
                        </div>
                    </Box>
                </Box>
            </form>
            
            {
                isModal && (
                    <ModalGeneralInvoice
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        VATData={props.VATData}
                        VATId={props.VATId}
                        generalInvoiceId={props.generalInvoiceId}
                        setSelectedDetail={setSelectedDetail}
                        RateCount={props.RateCount}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        calculateTotal={props.calculateTotal}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        printing={props.printing}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        GeneralInvoiceList={props.GeneralInvoiceList}
                        setGeneralInvoiceList={props.setGeneralInvoiceList}
        
                        AccountId={AccountId}
                        setAccountId={setAccountId}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        Description={Description}
                        setDescription={setDescription}
                        AmountUSD={AmountUSD}
                        setAmountUSD={setAmountUSD}
                        AmountIDR={AmountIDR}
                        setAmountIDR={setAmountIDR}
                    />
                )
            }
        </div>
    )
}

export default GeneralInvoiceDetail;